/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from "@tanstack/react-router"

// Import Routes

import { Route as rootRoute } from "./routes/__root"
import { Route as UnprotectedImport } from "./routes/_unprotected"
import { Route as UnauthenticatedImport } from "./routes/_unauthenticated"
import { Route as ProtectedImport } from "./routes/_protected"
import { Route as UnauthenticatedIndexImport } from "./routes/_unauthenticated/index"
import { Route as UnauthenticatedResetPasswordImport } from "./routes/_unauthenticated/reset-password"
import { Route as UnauthenticatedLoginImport } from "./routes/_unauthenticated/login"
import { Route as UnauthenticatedCreateAccountImport } from "./routes/_unauthenticated/create-account"
import { Route as ProtectedWelcomeImport } from "./routes/_protected/welcome"
import { Route as ProtectedUserInfoImport } from "./routes/_protected/user-info"
import { Route as ProtectedTrialImport } from "./routes/_protected/trial"
import { Route as ProtectedInvitationsImport } from "./routes/_protected/invitations"
import { Route as ProtectedDashboardImport } from "./routes/_protected/dashboard"
import { Route as ProtectedCreateCompanyImport } from "./routes/_protected/create-company"
import { Route as GuestCustomerIdIndexImport } from "./routes/guest/$customerId/index"
import { Route as GuestProjectProjectIdImport } from "./routes/guest/project/$projectId"
import { Route as GuestOrderOrderIdImport } from "./routes/guest/order/$orderId"
import { Route as ProtectedTrialSubscribeImport } from "./routes/_protected/trial/subscribe"
import { Route as ProtectedTrialExpiredImport } from "./routes/_protected/trial/expired"
import { Route as ProtectedTrialConfirmationImport } from "./routes/_protected/trial/confirmation"
import { Route as ProtectedDashboardProductsIndexImport } from "./routes/_protected/dashboard/products/index"
import { Route as ProtectedDashboardOverviewIndexImport } from "./routes/_protected/dashboard/overview/index"
import { Route as ProtectedDashboardOrdersIndexImport } from "./routes/_protected/dashboard/orders/index"
import { Route as ProtectedDashboardEmployeesIndexImport } from "./routes/_protected/dashboard/employees/index"
import { Route as ProtectedDashboardEhsIndexImport } from "./routes/_protected/dashboard/ehs/index"
import { Route as ProtectedDashboardSettingsLayoutImport } from "./routes/_protected/dashboard/settings/_layout"
import { Route as ProtectedDashboardServiceContractNewImport } from "./routes/_protected/dashboard/service-contract/new"
import { Route as ProtectedDashboardSalesLayoutImport } from "./routes/_protected/dashboard/sales/_layout"
import { Route as ProtectedDashboardProjectNewImport } from "./routes/_protected/dashboard/project/new"
import { Route as ProtectedDashboardProjectLayoutImport } from "./routes/_protected/dashboard/project/_layout"
import { Route as ProtectedDashboardInternalRoutinesNewImport } from "./routes/_protected/dashboard/internal-routines/new"
import { Route as ProtectedDashboardFacilityNewImport } from "./routes/_protected/dashboard/facility/new"
import { Route as ProtectedDashboardEquipmentNewImport } from "./routes/_protected/dashboard/equipment/new"
import { Route as ProtectedDashboardEconomyLayoutImport } from "./routes/_protected/dashboard/economy/_layout"
import { Route as ProtectedDashboardDeviationNewImport } from "./routes/_protected/dashboard/deviation/new"
import { Route as ProtectedDashboardCustomersNewImport } from "./routes/_protected/dashboard/customers/new"
import { Route as ProtectedDashboardCustomersLayoutImport } from "./routes/_protected/dashboard/customers/_layout"
import { Route as ProtectedDashboardChecklistNewImport } from "./routes/_protected/dashboard/checklist/new"
import { Route as UnprotectedOfferPathUriDetailsIndexImport } from "./routes/_unprotected/offer/$pathUri/details/index"
import { Route as ProtectedDashboardSalesNewIndexImport } from "./routes/_protected/dashboard/sales/new/index"
import { Route as ProtectedDashboardProductsNewIndexImport } from "./routes/_protected/dashboard/products/new/index"
import { Route as ProtectedDashboardOrdersNewIndexImport } from "./routes/_protected/dashboard/orders/new/index"
import { Route as ProtectedDashboardCustomersCustomerIdIndexImport } from "./routes/_protected/dashboard/customers/$customerId/index"
import { Route as ProtectedDashboardSettingsProjectPhasesEditImport } from "./routes/_protected/dashboard/settings/project-phases/edit"
import { Route as ProtectedDashboardSettingsLayoutVideoGuideImport } from "./routes/_protected/dashboard/settings/_layout/video-guide"
import { Route as ProtectedDashboardSettingsLayoutTaskTemplateImport } from "./routes/_protected/dashboard/settings/_layout/task-template"
import { Route as ProtectedDashboardSettingsLayoutProjectImport } from "./routes/_protected/dashboard/settings/_layout/project"
import { Route as ProtectedDashboardSettingsLayoutOrderImport } from "./routes/_protected/dashboard/settings/_layout/order"
import { Route as ProtectedDashboardSettingsLayoutIntegrationImport } from "./routes/_protected/dashboard/settings/_layout/integration"
import { Route as ProtectedDashboardSettingsLayoutEquipmentImport } from "./routes/_protected/dashboard/settings/_layout/equipment"
import { Route as ProtectedDashboardSettingsLayoutDocumentationTemplateImport } from "./routes/_protected/dashboard/settings/_layout/documentation-template"
import { Route as ProtectedDashboardSettingsLayoutCompanyImport } from "./routes/_protected/dashboard/settings/_layout/company"
import { Route as ProtectedDashboardSettingsLayoutChecklistTemplateImport } from "./routes/_protected/dashboard/settings/_layout/checklist-template"
import { Route as ProtectedDashboardServiceContractServiceContractIdUpdateImport } from "./routes/_protected/dashboard/service-contract/$serviceContractId/update"
import { Route as ProtectedDashboardSalesSalesOpportunityNewImport } from "./routes/_protected/dashboard/sales/sales-opportunity.new"
import { Route as ProtectedDashboardSalesOfferPreviewImport } from "./routes/_protected/dashboard/sales/offer/preview"
import { Route as ProtectedDashboardSalesOfferNewImport } from "./routes/_protected/dashboard/sales/offer/new"
import { Route as ProtectedDashboardSalesDirectSaleNewImport } from "./routes/_protected/dashboard/sales/direct-sale/new"
import { Route as ProtectedDashboardSalesLayoutSalesOpportunityImport } from "./routes/_protected/dashboard/sales/_layout/sales-opportunity"
import { Route as ProtectedDashboardSalesLayoutReportImport } from "./routes/_protected/dashboard/sales/_layout/report"
import { Route as ProtectedDashboardSalesLayoutOfferImport } from "./routes/_protected/dashboard/sales/_layout/offer"
import { Route as ProtectedDashboardSalesLayoutDirectSaleImport } from "./routes/_protected/dashboard/sales/_layout/direct-sale"
import { Route as ProtectedDashboardResourcesEmployeeIdUpdateImport } from "./routes/_protected/dashboard/resources/$employeeId/update"
import { Route as ProtectedDashboardProjectLayoutTaskImport } from "./routes/_protected/dashboard/project/_layout/task"
import { Route as ProtectedDashboardProjectLayoutProgressPlanImport } from "./routes/_protected/dashboard/project/_layout/progress-plan"
import { Route as ProtectedDashboardProjectLayoutOverviewImport } from "./routes/_protected/dashboard/project/_layout/overview"
import { Route as ProtectedDashboardProjectLayoutDeviationReportImport } from "./routes/_protected/dashboard/project/_layout/deviation-report"
import { Route as ProtectedDashboardProjectProjectIdUpdateImport } from "./routes/_protected/dashboard/project/$projectId/update"
import { Route as ProtectedDashboardProjectProjectIdLayoutImport } from "./routes/_protected/dashboard/project/$projectId/_layout"
import { Route as ProtectedDashboardProductsEstimationsNewImport } from "./routes/_protected/dashboard/products/estimations/new"
import { Route as ProtectedDashboardProductsProductIdUpdateImport } from "./routes/_protected/dashboard/products/$productId/update"
import { Route as ProtectedDashboardOrdersOrderIdUpdateImport } from "./routes/_protected/dashboard/orders/$orderId/update"
import { Route as ProtectedDashboardOrdersOrderIdLayoutImport } from "./routes/_protected/dashboard/orders/$orderId/_layout"
import { Route as ProtectedDashboardLocationResourceIdUpdateImport } from "./routes/_protected/dashboard/location/$resourceId/update"
import { Route as ProtectedDashboardLocationResourceIdNewImport } from "./routes/_protected/dashboard/location/$resourceId/new"
import { Route as ProtectedDashboardInternalRoutinesRoutineIdUpdateImport } from "./routes/_protected/dashboard/internal-routines/$routineId/update"
import { Route as ProtectedDashboardFacilityFacilityIdUpdateImport } from "./routes/_protected/dashboard/facility/$facilityId/update"
import { Route as ProtectedDashboardFacilityFacilityIdDetailsImport } from "./routes/_protected/dashboard/facility/$facilityId/details"
import { Route as ProtectedDashboardEquipmentEquipmentIdUpdateImport } from "./routes/_protected/dashboard/equipment/$equipmentId/update"
import { Route as ProtectedDashboardEhsTemplateNewImport } from "./routes/_protected/dashboard/ehs/template/new"
import { Route as ProtectedDashboardEhsTemplateIdNewImport } from "./routes/_protected/dashboard/ehs/$templateId/new"
import { Route as ProtectedDashboardEhsEhsIdUpdateImport } from "./routes/_protected/dashboard/ehs/$ehsId/update"
import { Route as ProtectedDashboardEconomyInvoiceNewImport } from "./routes/_protected/dashboard/economy/invoice.new"
import { Route as ProtectedDashboardEconomyLayoutTimeReportImport } from "./routes/_protected/dashboard/economy/_layout/time-report"
import { Route as ProtectedDashboardEconomyLayoutTimeListImport } from "./routes/_protected/dashboard/economy/_layout/time-list"
import { Route as ProtectedDashboardEconomyLayoutPriceModelImport } from "./routes/_protected/dashboard/economy/_layout/price-model"
import { Route as ProtectedDashboardEconomyLayoutInvoiceImport } from "./routes/_protected/dashboard/economy/_layout/invoice"
import { Route as ProtectedDashboardEconomyLayoutActivityImport } from "./routes/_protected/dashboard/economy/_layout/activity"
import { Route as ProtectedDashboardDeviationDeviationIdUpdateImport } from "./routes/_protected/dashboard/deviation/$deviationId/update"
import { Route as ProtectedDashboardCustomersLayoutCustomersImport } from "./routes/_protected/dashboard/customers/_layout/customers"
import { Route as ProtectedDashboardCustomersCustomerIdEditImport } from "./routes/_protected/dashboard/customers/$customerId/edit"
import { Route as ProtectedDashboardChecklistChecklistIdUpdateImport } from "./routes/_protected/dashboard/checklist/$checklistId/update"
import { Route as ProtectedDashboardProjectProjectIdWorkersIndexImport } from "./routes/_protected/dashboard/project/$projectId/workers/index"
import { Route as ProtectedDashboardProjectProjectIdPhasesIndexImport } from "./routes/_protected/dashboard/project/$projectId/phases/index"
import { Route as ProtectedDashboardOrdersTemplateTemplateIdIndexImport } from "./routes/_protected/dashboard/orders/template/$templateId/index"
import { Route as ProtectedDashboardCustomersLayoutInboxIndexImport } from "./routes/_protected/dashboard/customers/_layout/inbox/index"
import { Route as ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdUpdateImport } from "./routes/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/update"
import { Route as ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutImport } from "./routes/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout"
import { Route as ProtectedDashboardSalesOfferPathUriPreviewImport } from "./routes/_protected/dashboard/sales/offer/$pathUri.preview"
import { Route as ProtectedDashboardSalesOfferOfferIdUpdateImport } from "./routes/_protected/dashboard/sales/offer/$offerId/update"
import { Route as ProtectedDashboardSalesOfferOfferIdAdditionalWorkImport } from "./routes/_protected/dashboard/sales/offer/$offerId/additional-work"
import { Route as ProtectedDashboardSalesInspectionInspectionIdUpdateImport } from "./routes/_protected/dashboard/sales/inspection/$inspectionId/update"
import { Route as ProtectedDashboardSalesInspectionCustomerIdNewImport } from "./routes/_protected/dashboard/sales/inspection/$customerId/new"
import { Route as ProtectedDashboardProjectProjectIdTaskTaskIdImport } from "./routes/_protected/dashboard/project/$projectId/task/$taskId"
import { Route as ProtectedDashboardProjectProjectIdProductsAddImport } from "./routes/_protected/dashboard/project/$projectId/products/add"
import { Route as ProtectedDashboardProjectProjectIdOfferNewImport } from "./routes/_protected/dashboard/project/$projectId/offer.new"
import { Route as ProtectedDashboardProjectProjectIdLayoutTaskImport } from "./routes/_protected/dashboard/project/$projectId/_layout/task"
import { Route as ProtectedDashboardProjectProjectIdLayoutSubProjectImport } from "./routes/_protected/dashboard/project/$projectId/_layout/sub-project"
import { Route as ProtectedDashboardProjectProjectIdLayoutProgressPlanImport } from "./routes/_protected/dashboard/project/$projectId/_layout/progress-plan"
import { Route as ProtectedDashboardProjectProjectIdLayoutProductImport } from "./routes/_protected/dashboard/project/$projectId/_layout/product"
import { Route as ProtectedDashboardProjectProjectIdLayoutOverviewOldImport } from "./routes/_protected/dashboard/project/$projectId/_layout/overview-old"
import { Route as ProtectedDashboardProjectProjectIdLayoutOverviewImport } from "./routes/_protected/dashboard/project/$projectId/_layout/overview"
import { Route as ProtectedDashboardProjectProjectIdLayoutDocumentationImport } from "./routes/_protected/dashboard/project/$projectId/_layout/documentation"
import { Route as ProtectedDashboardProjectProjectIdLayoutDeviationImport } from "./routes/_protected/dashboard/project/$projectId/_layout/deviation"
import { Route as ProtectedDashboardProjectProjectIdLayoutChecklistImport } from "./routes/_protected/dashboard/project/$projectId/_layout/checklist"
import { Route as ProtectedDashboardProjectProjectIdLayoutLayoutImport } from "./routes/_protected/dashboard/project/$projectId/_layout/_layout"
import { Route as ProtectedDashboardProductsEstimationsEstimationIdUpdateImport } from "./routes/_protected/dashboard/products/estimations/$estimationId/update"
import { Route as ProtectedDashboardOrdersTemplateTemplateIdUpdateImport } from "./routes/_protected/dashboard/orders/template/$templateId/update"
import { Route as ProtectedDashboardOrdersTemplateTemplateIdProductImport } from "./routes/_protected/dashboard/orders/template/$templateId/product"
import { Route as ProtectedDashboardOrdersOrderIdProductsAddImport } from "./routes/_protected/dashboard/orders/$orderId/products/add"
import { Route as ProtectedDashboardOrdersOrderIdLayoutProductImport } from "./routes/_protected/dashboard/orders/$orderId/_layout/product"
import { Route as ProtectedDashboardOrdersOrderIdLayoutOverviewImport } from "./routes/_protected/dashboard/orders/$orderId/_layout/overview"
import { Route as ProtectedDashboardOrdersOrderIdLayoutDocumentationImport } from "./routes/_protected/dashboard/orders/$orderId/_layout/documentation"
import { Route as ProtectedDashboardOrdersOrderIdLayoutDeviationImport } from "./routes/_protected/dashboard/orders/$orderId/_layout/deviation"
import { Route as ProtectedDashboardOrdersOrderIdLayoutChecklistImport } from "./routes/_protected/dashboard/orders/$orderId/_layout/checklist"
import { Route as ProtectedDashboardEhsTemplateTemplateIdUpdateImport } from "./routes/_protected/dashboard/ehs/template/$templateId/update"
import { Route as ProtectedDashboardCustomersCustomerIdProjectNewImport } from "./routes/_protected/dashboard/customers/$customerId/project.new"
import { Route as ProtectedDashboardCustomersCustomerIdOrderNewImport } from "./routes/_protected/dashboard/customers/$customerId/order/new"
import { Route as ProtectedDashboardProjectProjectIdLayoutEhsIndexImport } from "./routes/_protected/dashboard/project/$projectId/_layout/ehs/index"
import { Route as ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdOfferNewImport } from "./routes/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/new"
import { Route as ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutOverviewImport } from "./routes/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/overview"
import { Route as ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutOfferImport } from "./routes/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/offer"
import { Route as ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutInspectionImport } from "./routes/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/inspection"
import { Route as ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutDocumentationImport } from "./routes/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/documentation"
import { Route as ProtectedDashboardProjectProjectIdLayoutLayoutEconomyIndexImport } from "./routes/_protected/dashboard/project/$projectId/_layout/_layout.economy/index"
import { Route as ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdOfferOfferIdUpdateImport } from "./routes/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/$offerId/update"
import { Route as ProtectedDashboardProjectProjectIdLayoutLayoutEconomyTimeImport } from "./routes/_protected/dashboard/project/$projectId/_layout/_layout.economy/time"
import { Route as ProtectedDashboardProjectProjectIdLayoutLayoutEconomySubcontractorImport } from "./routes/_protected/dashboard/project/$projectId/_layout/_layout.economy/subcontractor"
import { Route as ProtectedDashboardProjectProjectIdLayoutLayoutEconomyInvoiceImport } from "./routes/_protected/dashboard/project/$projectId/_layout/_layout.economy/invoice"
import { Route as ProtectedDashboardProjectProjectIdLayoutLayoutEconomyContractImport } from "./routes/_protected/dashboard/project/$projectId/_layout/_layout.economy/contract"
import { Route as ProtectedDashboardProjectProjectIdLayoutLayoutEconomyChangeOrderImport } from "./routes/_protected/dashboard/project/$projectId/_layout/_layout.economy/change-order"
import { Route as ProtectedDashboardProjectProjectIdLayoutLayoutEconomyBudgetImport } from "./routes/_protected/dashboard/project/$projectId/_layout/_layout.economy/budget"

// Create Virtual Routes

const ProtectedDashboardSettingsImport = createFileRoute(
  "/_protected/dashboard/settings",
)()
const ProtectedDashboardSalesImport = createFileRoute(
  "/_protected/dashboard/sales",
)()
const ProtectedDashboardProjectImport = createFileRoute(
  "/_protected/dashboard/project",
)()
const ProtectedDashboardEconomyImport = createFileRoute(
  "/_protected/dashboard/economy",
)()
const ProtectedDashboardCustomersImport = createFileRoute(
  "/_protected/dashboard/customers",
)()
const ProtectedDashboardProjectProjectIdImport = createFileRoute(
  "/_protected/dashboard/project/$projectId",
)()
const ProtectedDashboardOrdersOrderIdImport = createFileRoute(
  "/_protected/dashboard/orders/$orderId",
)()
const ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdImport =
  createFileRoute(
    "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId",
  )()

// Create/Update Routes

const UnprotectedRoute = UnprotectedImport.update({
  id: "/_unprotected",
  getParentRoute: () => rootRoute,
} as any)

const UnauthenticatedRoute = UnauthenticatedImport.update({
  id: "/_unauthenticated",
  getParentRoute: () => rootRoute,
} as any)

const ProtectedRoute = ProtectedImport.update({
  id: "/_protected",
  getParentRoute: () => rootRoute,
} as any)

const UnauthenticatedIndexRoute = UnauthenticatedIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => UnauthenticatedRoute,
} as any)

const UnauthenticatedResetPasswordRoute =
  UnauthenticatedResetPasswordImport.update({
    id: "/reset-password",
    path: "/reset-password",
    getParentRoute: () => UnauthenticatedRoute,
  } as any)

const UnauthenticatedLoginRoute = UnauthenticatedLoginImport.update({
  id: "/login",
  path: "/login",
  getParentRoute: () => UnauthenticatedRoute,
} as any)

const UnauthenticatedCreateAccountRoute =
  UnauthenticatedCreateAccountImport.update({
    id: "/create-account",
    path: "/create-account",
    getParentRoute: () => UnauthenticatedRoute,
  } as any)

const ProtectedWelcomeRoute = ProtectedWelcomeImport.update({
  id: "/welcome",
  path: "/welcome",
  getParentRoute: () => ProtectedRoute,
} as any)

const ProtectedUserInfoRoute = ProtectedUserInfoImport.update({
  id: "/user-info",
  path: "/user-info",
  getParentRoute: () => ProtectedRoute,
} as any)

const ProtectedTrialRoute = ProtectedTrialImport.update({
  id: "/trial",
  path: "/trial",
  getParentRoute: () => ProtectedRoute,
} as any)

const ProtectedInvitationsRoute = ProtectedInvitationsImport.update({
  id: "/invitations",
  path: "/invitations",
  getParentRoute: () => ProtectedRoute,
} as any)

const ProtectedDashboardRoute = ProtectedDashboardImport.update({
  id: "/dashboard",
  path: "/dashboard",
  getParentRoute: () => ProtectedRoute,
} as any)

const ProtectedCreateCompanyRoute = ProtectedCreateCompanyImport.update({
  id: "/create-company",
  path: "/create-company",
  getParentRoute: () => ProtectedRoute,
} as any)

const ProtectedDashboardSettingsRoute = ProtectedDashboardSettingsImport.update(
  {
    id: "/settings",
    path: "/settings",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any,
)

const ProtectedDashboardSalesRoute = ProtectedDashboardSalesImport.update({
  id: "/sales",
  path: "/sales",
  getParentRoute: () => ProtectedDashboardRoute,
} as any)

const ProtectedDashboardProjectRoute = ProtectedDashboardProjectImport.update({
  id: "/project",
  path: "/project",
  getParentRoute: () => ProtectedDashboardRoute,
} as any)

const ProtectedDashboardEconomyRoute = ProtectedDashboardEconomyImport.update({
  id: "/economy",
  path: "/economy",
  getParentRoute: () => ProtectedDashboardRoute,
} as any)

const ProtectedDashboardCustomersRoute =
  ProtectedDashboardCustomersImport.update({
    id: "/customers",
    path: "/customers",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const GuestCustomerIdIndexRoute = GuestCustomerIdIndexImport.update({
  id: "/guest/$customerId/",
  path: "/guest/$customerId/",
  getParentRoute: () => rootRoute,
} as any)

const GuestProjectProjectIdRoute = GuestProjectProjectIdImport.update({
  id: "/guest/project/$projectId",
  path: "/guest/project/$projectId",
  getParentRoute: () => rootRoute,
} as any)

const GuestOrderOrderIdRoute = GuestOrderOrderIdImport.update({
  id: "/guest/order/$orderId",
  path: "/guest/order/$orderId",
  getParentRoute: () => rootRoute,
} as any)

const ProtectedTrialSubscribeRoute = ProtectedTrialSubscribeImport.update({
  id: "/subscribe",
  path: "/subscribe",
  getParentRoute: () => ProtectedTrialRoute,
} as any)

const ProtectedTrialExpiredRoute = ProtectedTrialExpiredImport.update({
  id: "/expired",
  path: "/expired",
  getParentRoute: () => ProtectedTrialRoute,
} as any)

const ProtectedTrialConfirmationRoute = ProtectedTrialConfirmationImport.update(
  {
    id: "/confirmation",
    path: "/confirmation",
    getParentRoute: () => ProtectedTrialRoute,
  } as any,
)

const ProtectedDashboardProjectProjectIdRoute =
  ProtectedDashboardProjectProjectIdImport.update({
    id: "/$projectId",
    path: "/$projectId",
    getParentRoute: () => ProtectedDashboardProjectRoute,
  } as any)

const ProtectedDashboardOrdersOrderIdRoute =
  ProtectedDashboardOrdersOrderIdImport.update({
    id: "/orders/$orderId",
    path: "/orders/$orderId",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardProductsIndexRoute =
  ProtectedDashboardProductsIndexImport.update({
    id: "/products/",
    path: "/products/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardOverviewIndexRoute =
  ProtectedDashboardOverviewIndexImport.update({
    id: "/overview/",
    path: "/overview/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardOrdersIndexRoute =
  ProtectedDashboardOrdersIndexImport.update({
    id: "/orders/",
    path: "/orders/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardEmployeesIndexRoute =
  ProtectedDashboardEmployeesIndexImport.update({
    id: "/employees/",
    path: "/employees/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardEhsIndexRoute = ProtectedDashboardEhsIndexImport.update(
  {
    id: "/ehs/",
    path: "/ehs/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any,
)

const ProtectedDashboardSettingsLayoutRoute =
  ProtectedDashboardSettingsLayoutImport.update({
    id: "/_layout",
    getParentRoute: () => ProtectedDashboardSettingsRoute,
  } as any)

const ProtectedDashboardServiceContractNewRoute =
  ProtectedDashboardServiceContractNewImport.update({
    id: "/service-contract/new",
    path: "/service-contract/new",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardSalesLayoutRoute =
  ProtectedDashboardSalesLayoutImport.update({
    id: "/_layout",
    getParentRoute: () => ProtectedDashboardSalesRoute,
  } as any)

const ProtectedDashboardProjectNewRoute =
  ProtectedDashboardProjectNewImport.update({
    id: "/new",
    path: "/new",
    getParentRoute: () => ProtectedDashboardProjectRoute,
  } as any)

const ProtectedDashboardProjectLayoutRoute =
  ProtectedDashboardProjectLayoutImport.update({
    id: "/_layout",
    getParentRoute: () => ProtectedDashboardProjectRoute,
  } as any)

const ProtectedDashboardInternalRoutinesNewRoute =
  ProtectedDashboardInternalRoutinesNewImport.update({
    id: "/internal-routines/new",
    path: "/internal-routines/new",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardFacilityNewRoute =
  ProtectedDashboardFacilityNewImport.update({
    id: "/facility/new",
    path: "/facility/new",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardEquipmentNewRoute =
  ProtectedDashboardEquipmentNewImport.update({
    id: "/equipment/new",
    path: "/equipment/new",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardEconomyLayoutRoute =
  ProtectedDashboardEconomyLayoutImport.update({
    id: "/_layout",
    getParentRoute: () => ProtectedDashboardEconomyRoute,
  } as any)

const ProtectedDashboardDeviationNewRoute =
  ProtectedDashboardDeviationNewImport.update({
    id: "/deviation/new",
    path: "/deviation/new",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardCustomersNewRoute =
  ProtectedDashboardCustomersNewImport.update({
    id: "/new",
    path: "/new",
    getParentRoute: () => ProtectedDashboardCustomersRoute,
  } as any)

const ProtectedDashboardCustomersLayoutRoute =
  ProtectedDashboardCustomersLayoutImport.update({
    id: "/_layout",
    getParentRoute: () => ProtectedDashboardCustomersRoute,
  } as any)

const ProtectedDashboardChecklistNewRoute =
  ProtectedDashboardChecklistNewImport.update({
    id: "/checklist/new",
    path: "/checklist/new",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdRoute =
  ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdImport.update({
    id: "/sales-opportunity/$salesOpportunityId",
    path: "/sales-opportunity/$salesOpportunityId",
    getParentRoute: () => ProtectedDashboardSalesRoute,
  } as any)

const UnprotectedOfferPathUriDetailsIndexRoute =
  UnprotectedOfferPathUriDetailsIndexImport.update({
    id: "/offer/$pathUri/details/",
    path: "/offer/$pathUri/details/",
    getParentRoute: () => UnprotectedRoute,
  } as any)

const ProtectedDashboardSalesNewIndexRoute =
  ProtectedDashboardSalesNewIndexImport.update({
    id: "/new/",
    path: "/new/",
    getParentRoute: () => ProtectedDashboardSalesRoute,
  } as any)

const ProtectedDashboardProductsNewIndexRoute =
  ProtectedDashboardProductsNewIndexImport.update({
    id: "/products/new/",
    path: "/products/new/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardOrdersNewIndexRoute =
  ProtectedDashboardOrdersNewIndexImport.update({
    id: "/orders/new/",
    path: "/orders/new/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardCustomersCustomerIdIndexRoute =
  ProtectedDashboardCustomersCustomerIdIndexImport.update({
    id: "/$customerId/",
    path: "/$customerId/",
    getParentRoute: () => ProtectedDashboardCustomersRoute,
  } as any)

const ProtectedDashboardSettingsProjectPhasesEditRoute =
  ProtectedDashboardSettingsProjectPhasesEditImport.update({
    id: "/project-phases/edit",
    path: "/project-phases/edit",
    getParentRoute: () => ProtectedDashboardSettingsRoute,
  } as any)

const ProtectedDashboardSettingsLayoutVideoGuideRoute =
  ProtectedDashboardSettingsLayoutVideoGuideImport.update({
    id: "/video-guide",
    path: "/video-guide",
    getParentRoute: () => ProtectedDashboardSettingsLayoutRoute,
  } as any)

const ProtectedDashboardSettingsLayoutTaskTemplateRoute =
  ProtectedDashboardSettingsLayoutTaskTemplateImport.update({
    id: "/task-template",
    path: "/task-template",
    getParentRoute: () => ProtectedDashboardSettingsLayoutRoute,
  } as any)

const ProtectedDashboardSettingsLayoutProjectRoute =
  ProtectedDashboardSettingsLayoutProjectImport.update({
    id: "/project",
    path: "/project",
    getParentRoute: () => ProtectedDashboardSettingsLayoutRoute,
  } as any)

const ProtectedDashboardSettingsLayoutOrderRoute =
  ProtectedDashboardSettingsLayoutOrderImport.update({
    id: "/order",
    path: "/order",
    getParentRoute: () => ProtectedDashboardSettingsLayoutRoute,
  } as any)

const ProtectedDashboardSettingsLayoutIntegrationRoute =
  ProtectedDashboardSettingsLayoutIntegrationImport.update({
    id: "/integration",
    path: "/integration",
    getParentRoute: () => ProtectedDashboardSettingsLayoutRoute,
  } as any)

const ProtectedDashboardSettingsLayoutEquipmentRoute =
  ProtectedDashboardSettingsLayoutEquipmentImport.update({
    id: "/equipment",
    path: "/equipment",
    getParentRoute: () => ProtectedDashboardSettingsLayoutRoute,
  } as any)

const ProtectedDashboardSettingsLayoutDocumentationTemplateRoute =
  ProtectedDashboardSettingsLayoutDocumentationTemplateImport.update({
    id: "/documentation-template",
    path: "/documentation-template",
    getParentRoute: () => ProtectedDashboardSettingsLayoutRoute,
  } as any)

const ProtectedDashboardSettingsLayoutCompanyRoute =
  ProtectedDashboardSettingsLayoutCompanyImport.update({
    id: "/company",
    path: "/company",
    getParentRoute: () => ProtectedDashboardSettingsLayoutRoute,
  } as any)

const ProtectedDashboardSettingsLayoutChecklistTemplateRoute =
  ProtectedDashboardSettingsLayoutChecklistTemplateImport.update({
    id: "/checklist-template",
    path: "/checklist-template",
    getParentRoute: () => ProtectedDashboardSettingsLayoutRoute,
  } as any)

const ProtectedDashboardServiceContractServiceContractIdUpdateRoute =
  ProtectedDashboardServiceContractServiceContractIdUpdateImport.update({
    id: "/service-contract/$serviceContractId/update",
    path: "/service-contract/$serviceContractId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardSalesSalesOpportunityNewRoute =
  ProtectedDashboardSalesSalesOpportunityNewImport.update({
    id: "/sales-opportunity/new",
    path: "/sales-opportunity/new",
    getParentRoute: () => ProtectedDashboardSalesRoute,
  } as any)

const ProtectedDashboardSalesOfferPreviewRoute =
  ProtectedDashboardSalesOfferPreviewImport.update({
    id: "/offer/preview",
    path: "/offer/preview",
    getParentRoute: () => ProtectedDashboardSalesRoute,
  } as any)

const ProtectedDashboardSalesOfferNewRoute =
  ProtectedDashboardSalesOfferNewImport.update({
    id: "/offer/new",
    path: "/offer/new",
    getParentRoute: () => ProtectedDashboardSalesRoute,
  } as any)

const ProtectedDashboardSalesDirectSaleNewRoute =
  ProtectedDashboardSalesDirectSaleNewImport.update({
    id: "/direct-sale/new",
    path: "/direct-sale/new",
    getParentRoute: () => ProtectedDashboardSalesRoute,
  } as any)

const ProtectedDashboardSalesLayoutSalesOpportunityRoute =
  ProtectedDashboardSalesLayoutSalesOpportunityImport.update({
    id: "/sales-opportunity",
    path: "/sales-opportunity",
    getParentRoute: () => ProtectedDashboardSalesLayoutRoute,
  } as any)

const ProtectedDashboardSalesLayoutReportRoute =
  ProtectedDashboardSalesLayoutReportImport.update({
    id: "/report",
    path: "/report",
    getParentRoute: () => ProtectedDashboardSalesLayoutRoute,
  } as any)

const ProtectedDashboardSalesLayoutOfferRoute =
  ProtectedDashboardSalesLayoutOfferImport.update({
    id: "/offer",
    path: "/offer",
    getParentRoute: () => ProtectedDashboardSalesLayoutRoute,
  } as any)

const ProtectedDashboardSalesLayoutDirectSaleRoute =
  ProtectedDashboardSalesLayoutDirectSaleImport.update({
    id: "/direct-sale",
    path: "/direct-sale",
    getParentRoute: () => ProtectedDashboardSalesLayoutRoute,
  } as any)

const ProtectedDashboardResourcesEmployeeIdUpdateRoute =
  ProtectedDashboardResourcesEmployeeIdUpdateImport.update({
    id: "/resources/$employeeId/update",
    path: "/resources/$employeeId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardProjectLayoutTaskRoute =
  ProtectedDashboardProjectLayoutTaskImport.update({
    id: "/task",
    path: "/task",
    getParentRoute: () => ProtectedDashboardProjectLayoutRoute,
  } as any)

const ProtectedDashboardProjectLayoutProgressPlanRoute =
  ProtectedDashboardProjectLayoutProgressPlanImport.update({
    id: "/progress-plan",
    path: "/progress-plan",
    getParentRoute: () => ProtectedDashboardProjectLayoutRoute,
  } as any)

const ProtectedDashboardProjectLayoutOverviewRoute =
  ProtectedDashboardProjectLayoutOverviewImport.update({
    id: "/overview",
    path: "/overview",
    getParentRoute: () => ProtectedDashboardProjectLayoutRoute,
  } as any)

const ProtectedDashboardProjectLayoutDeviationReportRoute =
  ProtectedDashboardProjectLayoutDeviationReportImport.update({
    id: "/deviation-report",
    path: "/deviation-report",
    getParentRoute: () => ProtectedDashboardProjectLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdUpdateRoute =
  ProtectedDashboardProjectProjectIdUpdateImport.update({
    id: "/update",
    path: "/update",
    getParentRoute: () => ProtectedDashboardProjectProjectIdRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutRoute =
  ProtectedDashboardProjectProjectIdLayoutImport.update({
    id: "/_layout",
    getParentRoute: () => ProtectedDashboardProjectProjectIdRoute,
  } as any)

const ProtectedDashboardProductsEstimationsNewRoute =
  ProtectedDashboardProductsEstimationsNewImport.update({
    id: "/products/estimations/new",
    path: "/products/estimations/new",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardProductsProductIdUpdateRoute =
  ProtectedDashboardProductsProductIdUpdateImport.update({
    id: "/products/$productId/update",
    path: "/products/$productId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardOrdersOrderIdUpdateRoute =
  ProtectedDashboardOrdersOrderIdUpdateImport.update({
    id: "/update",
    path: "/update",
    getParentRoute: () => ProtectedDashboardOrdersOrderIdRoute,
  } as any)

const ProtectedDashboardOrdersOrderIdLayoutRoute =
  ProtectedDashboardOrdersOrderIdLayoutImport.update({
    id: "/_layout",
    getParentRoute: () => ProtectedDashboardOrdersOrderIdRoute,
  } as any)

const ProtectedDashboardLocationResourceIdUpdateRoute =
  ProtectedDashboardLocationResourceIdUpdateImport.update({
    id: "/location/$resourceId/update",
    path: "/location/$resourceId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardLocationResourceIdNewRoute =
  ProtectedDashboardLocationResourceIdNewImport.update({
    id: "/location/$resourceId/new",
    path: "/location/$resourceId/new",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardInternalRoutinesRoutineIdUpdateRoute =
  ProtectedDashboardInternalRoutinesRoutineIdUpdateImport.update({
    id: "/internal-routines/$routineId/update",
    path: "/internal-routines/$routineId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardFacilityFacilityIdUpdateRoute =
  ProtectedDashboardFacilityFacilityIdUpdateImport.update({
    id: "/facility/$facilityId/update",
    path: "/facility/$facilityId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardFacilityFacilityIdDetailsRoute =
  ProtectedDashboardFacilityFacilityIdDetailsImport.update({
    id: "/facility/$facilityId/details",
    path: "/facility/$facilityId/details",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardEquipmentEquipmentIdUpdateRoute =
  ProtectedDashboardEquipmentEquipmentIdUpdateImport.update({
    id: "/equipment/$equipmentId/update",
    path: "/equipment/$equipmentId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardEhsTemplateNewRoute =
  ProtectedDashboardEhsTemplateNewImport.update({
    id: "/ehs/template/new",
    path: "/ehs/template/new",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardEhsTemplateIdNewRoute =
  ProtectedDashboardEhsTemplateIdNewImport.update({
    id: "/ehs/$templateId/new",
    path: "/ehs/$templateId/new",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardEhsEhsIdUpdateRoute =
  ProtectedDashboardEhsEhsIdUpdateImport.update({
    id: "/ehs/$ehsId/update",
    path: "/ehs/$ehsId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardEconomyInvoiceNewRoute =
  ProtectedDashboardEconomyInvoiceNewImport.update({
    id: "/invoice/new",
    path: "/invoice/new",
    getParentRoute: () => ProtectedDashboardEconomyRoute,
  } as any)

const ProtectedDashboardEconomyLayoutTimeReportRoute =
  ProtectedDashboardEconomyLayoutTimeReportImport.update({
    id: "/time-report",
    path: "/time-report",
    getParentRoute: () => ProtectedDashboardEconomyLayoutRoute,
  } as any)

const ProtectedDashboardEconomyLayoutTimeListRoute =
  ProtectedDashboardEconomyLayoutTimeListImport.update({
    id: "/time-list",
    path: "/time-list",
    getParentRoute: () => ProtectedDashboardEconomyLayoutRoute,
  } as any)

const ProtectedDashboardEconomyLayoutPriceModelRoute =
  ProtectedDashboardEconomyLayoutPriceModelImport.update({
    id: "/price-model",
    path: "/price-model",
    getParentRoute: () => ProtectedDashboardEconomyLayoutRoute,
  } as any)

const ProtectedDashboardEconomyLayoutInvoiceRoute =
  ProtectedDashboardEconomyLayoutInvoiceImport.update({
    id: "/invoice",
    path: "/invoice",
    getParentRoute: () => ProtectedDashboardEconomyLayoutRoute,
  } as any)

const ProtectedDashboardEconomyLayoutActivityRoute =
  ProtectedDashboardEconomyLayoutActivityImport.update({
    id: "/activity",
    path: "/activity",
    getParentRoute: () => ProtectedDashboardEconomyLayoutRoute,
  } as any)

const ProtectedDashboardDeviationDeviationIdUpdateRoute =
  ProtectedDashboardDeviationDeviationIdUpdateImport.update({
    id: "/deviation/$deviationId/update",
    path: "/deviation/$deviationId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardCustomersLayoutCustomersRoute =
  ProtectedDashboardCustomersLayoutCustomersImport.update({
    id: "/customers",
    path: "/customers",
    getParentRoute: () => ProtectedDashboardCustomersLayoutRoute,
  } as any)

const ProtectedDashboardCustomersCustomerIdEditRoute =
  ProtectedDashboardCustomersCustomerIdEditImport.update({
    id: "/$customerId/edit",
    path: "/$customerId/edit",
    getParentRoute: () => ProtectedDashboardCustomersRoute,
  } as any)

const ProtectedDashboardChecklistChecklistIdUpdateRoute =
  ProtectedDashboardChecklistChecklistIdUpdateImport.update({
    id: "/checklist/$checklistId/update",
    path: "/checklist/$checklistId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardProjectProjectIdWorkersIndexRoute =
  ProtectedDashboardProjectProjectIdWorkersIndexImport.update({
    id: "/workers/",
    path: "/workers/",
    getParentRoute: () => ProtectedDashboardProjectProjectIdRoute,
  } as any)

const ProtectedDashboardProjectProjectIdPhasesIndexRoute =
  ProtectedDashboardProjectProjectIdPhasesIndexImport.update({
    id: "/phases/",
    path: "/phases/",
    getParentRoute: () => ProtectedDashboardProjectProjectIdRoute,
  } as any)

const ProtectedDashboardOrdersTemplateTemplateIdIndexRoute =
  ProtectedDashboardOrdersTemplateTemplateIdIndexImport.update({
    id: "/orders/template/$templateId/",
    path: "/orders/template/$templateId/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardCustomersLayoutInboxIndexRoute =
  ProtectedDashboardCustomersLayoutInboxIndexImport.update({
    id: "/inbox/",
    path: "/inbox/",
    getParentRoute: () => ProtectedDashboardCustomersLayoutRoute,
  } as any)

const ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdUpdateRoute =
  ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdUpdateImport.update({
    id: "/update",
    path: "/update",
    getParentRoute: () =>
      ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdRoute,
  } as any)

const ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutRoute =
  ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutImport.update({
    id: "/_layout",
    getParentRoute: () =>
      ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdRoute,
  } as any)

const ProtectedDashboardSalesOfferPathUriPreviewRoute =
  ProtectedDashboardSalesOfferPathUriPreviewImport.update({
    id: "/offer/$pathUri/preview",
    path: "/offer/$pathUri/preview",
    getParentRoute: () => ProtectedDashboardSalesRoute,
  } as any)

const ProtectedDashboardSalesOfferOfferIdUpdateRoute =
  ProtectedDashboardSalesOfferOfferIdUpdateImport.update({
    id: "/offer/$offerId/update",
    path: "/offer/$offerId/update",
    getParentRoute: () => ProtectedDashboardSalesRoute,
  } as any)

const ProtectedDashboardSalesOfferOfferIdAdditionalWorkRoute =
  ProtectedDashboardSalesOfferOfferIdAdditionalWorkImport.update({
    id: "/offer/$offerId/additional-work",
    path: "/offer/$offerId/additional-work",
    getParentRoute: () => ProtectedDashboardSalesRoute,
  } as any)

const ProtectedDashboardSalesInspectionInspectionIdUpdateRoute =
  ProtectedDashboardSalesInspectionInspectionIdUpdateImport.update({
    id: "/inspection/$inspectionId/update",
    path: "/inspection/$inspectionId/update",
    getParentRoute: () => ProtectedDashboardSalesRoute,
  } as any)

const ProtectedDashboardSalesInspectionCustomerIdNewRoute =
  ProtectedDashboardSalesInspectionCustomerIdNewImport.update({
    id: "/inspection/$customerId/new",
    path: "/inspection/$customerId/new",
    getParentRoute: () => ProtectedDashboardSalesRoute,
  } as any)

const ProtectedDashboardProjectProjectIdTaskTaskIdRoute =
  ProtectedDashboardProjectProjectIdTaskTaskIdImport.update({
    id: "/task/$taskId",
    path: "/task/$taskId",
    getParentRoute: () => ProtectedDashboardProjectProjectIdRoute,
  } as any)

const ProtectedDashboardProjectProjectIdProductsAddRoute =
  ProtectedDashboardProjectProjectIdProductsAddImport.update({
    id: "/products/add",
    path: "/products/add",
    getParentRoute: () => ProtectedDashboardProjectProjectIdRoute,
  } as any)

const ProtectedDashboardProjectProjectIdOfferNewRoute =
  ProtectedDashboardProjectProjectIdOfferNewImport.update({
    id: "/offer/new",
    path: "/offer/new",
    getParentRoute: () => ProtectedDashboardProjectProjectIdRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutTaskRoute =
  ProtectedDashboardProjectProjectIdLayoutTaskImport.update({
    id: "/task",
    path: "/task",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutSubProjectRoute =
  ProtectedDashboardProjectProjectIdLayoutSubProjectImport.update({
    id: "/sub-project",
    path: "/sub-project",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutProgressPlanRoute =
  ProtectedDashboardProjectProjectIdLayoutProgressPlanImport.update({
    id: "/progress-plan",
    path: "/progress-plan",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutProductRoute =
  ProtectedDashboardProjectProjectIdLayoutProductImport.update({
    id: "/product",
    path: "/product",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutOverviewOldRoute =
  ProtectedDashboardProjectProjectIdLayoutOverviewOldImport.update({
    id: "/overview-old",
    path: "/overview-old",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutOverviewRoute =
  ProtectedDashboardProjectProjectIdLayoutOverviewImport.update({
    id: "/overview",
    path: "/overview",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutDocumentationRoute =
  ProtectedDashboardProjectProjectIdLayoutDocumentationImport.update({
    id: "/documentation",
    path: "/documentation",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutDeviationRoute =
  ProtectedDashboardProjectProjectIdLayoutDeviationImport.update({
    id: "/deviation",
    path: "/deviation",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutChecklistRoute =
  ProtectedDashboardProjectProjectIdLayoutChecklistImport.update({
    id: "/checklist",
    path: "/checklist",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutLayoutRoute =
  ProtectedDashboardProjectProjectIdLayoutLayoutImport.update({
    id: "/_layout",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutRoute,
  } as any)

const ProtectedDashboardProductsEstimationsEstimationIdUpdateRoute =
  ProtectedDashboardProductsEstimationsEstimationIdUpdateImport.update({
    id: "/products/estimations/$estimationId/update",
    path: "/products/estimations/$estimationId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardOrdersTemplateTemplateIdUpdateRoute =
  ProtectedDashboardOrdersTemplateTemplateIdUpdateImport.update({
    id: "/orders/template/$templateId/update",
    path: "/orders/template/$templateId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardOrdersTemplateTemplateIdProductRoute =
  ProtectedDashboardOrdersTemplateTemplateIdProductImport.update({
    id: "/orders/template/$templateId/product",
    path: "/orders/template/$templateId/product",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardOrdersOrderIdProductsAddRoute =
  ProtectedDashboardOrdersOrderIdProductsAddImport.update({
    id: "/products/add",
    path: "/products/add",
    getParentRoute: () => ProtectedDashboardOrdersOrderIdRoute,
  } as any)

const ProtectedDashboardOrdersOrderIdLayoutProductRoute =
  ProtectedDashboardOrdersOrderIdLayoutProductImport.update({
    id: "/product",
    path: "/product",
    getParentRoute: () => ProtectedDashboardOrdersOrderIdLayoutRoute,
  } as any)

const ProtectedDashboardOrdersOrderIdLayoutOverviewRoute =
  ProtectedDashboardOrdersOrderIdLayoutOverviewImport.update({
    id: "/overview",
    path: "/overview",
    getParentRoute: () => ProtectedDashboardOrdersOrderIdLayoutRoute,
  } as any)

const ProtectedDashboardOrdersOrderIdLayoutDocumentationRoute =
  ProtectedDashboardOrdersOrderIdLayoutDocumentationImport.update({
    id: "/documentation",
    path: "/documentation",
    getParentRoute: () => ProtectedDashboardOrdersOrderIdLayoutRoute,
  } as any)

const ProtectedDashboardOrdersOrderIdLayoutDeviationRoute =
  ProtectedDashboardOrdersOrderIdLayoutDeviationImport.update({
    id: "/deviation",
    path: "/deviation",
    getParentRoute: () => ProtectedDashboardOrdersOrderIdLayoutRoute,
  } as any)

const ProtectedDashboardOrdersOrderIdLayoutChecklistRoute =
  ProtectedDashboardOrdersOrderIdLayoutChecklistImport.update({
    id: "/checklist",
    path: "/checklist",
    getParentRoute: () => ProtectedDashboardOrdersOrderIdLayoutRoute,
  } as any)

const ProtectedDashboardEhsTemplateTemplateIdUpdateRoute =
  ProtectedDashboardEhsTemplateTemplateIdUpdateImport.update({
    id: "/ehs/template/$templateId/update",
    path: "/ehs/template/$templateId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardCustomersCustomerIdProjectNewRoute =
  ProtectedDashboardCustomersCustomerIdProjectNewImport.update({
    id: "/$customerId/project/new",
    path: "/$customerId/project/new",
    getParentRoute: () => ProtectedDashboardCustomersRoute,
  } as any)

const ProtectedDashboardCustomersCustomerIdOrderNewRoute =
  ProtectedDashboardCustomersCustomerIdOrderNewImport.update({
    id: "/$customerId/order/new",
    path: "/$customerId/order/new",
    getParentRoute: () => ProtectedDashboardCustomersRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutEhsIndexRoute =
  ProtectedDashboardProjectProjectIdLayoutEhsIndexImport.update({
    id: "/ehs/",
    path: "/ehs/",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutRoute,
  } as any)

const ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdOfferNewRoute =
  ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdOfferNewImport.update(
    {
      id: "/offer/new",
      path: "/offer/new",
      getParentRoute: () =>
        ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdRoute,
    } as any,
  )

const ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutOverviewRoute =
  ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutOverviewImport.update(
    {
      id: "/overview",
      path: "/overview",
      getParentRoute: () =>
        ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutRoute,
    } as any,
  )

const ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutOfferRoute =
  ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutOfferImport.update(
    {
      id: "/offer",
      path: "/offer",
      getParentRoute: () =>
        ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutRoute,
    } as any,
  )

const ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutInspectionRoute =
  ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutInspectionImport.update(
    {
      id: "/inspection",
      path: "/inspection",
      getParentRoute: () =>
        ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutRoute,
    } as any,
  )

const ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutDocumentationRoute =
  ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutDocumentationImport.update(
    {
      id: "/documentation",
      path: "/documentation",
      getParentRoute: () =>
        ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutRoute,
    } as any,
  )

const ProtectedDashboardProjectProjectIdLayoutLayoutEconomyIndexRoute =
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomyIndexImport.update({
    id: "/economy/",
    path: "/economy/",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutLayoutRoute,
  } as any)

const ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdOfferOfferIdUpdateRoute =
  ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdOfferOfferIdUpdateImport.update(
    {
      id: "/offer/$offerId/update",
      path: "/offer/$offerId/update",
      getParentRoute: () =>
        ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdRoute,
    } as any,
  )

const ProtectedDashboardProjectProjectIdLayoutLayoutEconomyTimeRoute =
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomyTimeImport.update({
    id: "/economy/time",
    path: "/economy/time",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutLayoutEconomySubcontractorRoute =
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomySubcontractorImport.update(
    {
      id: "/economy/subcontractor",
      path: "/economy/subcontractor",
      getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutLayoutRoute,
    } as any,
  )

const ProtectedDashboardProjectProjectIdLayoutLayoutEconomyInvoiceRoute =
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomyInvoiceImport.update({
    id: "/economy/invoice",
    path: "/economy/invoice",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutLayoutEconomyContractRoute =
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomyContractImport.update({
    id: "/economy/contract",
    path: "/economy/contract",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutLayoutEconomyChangeOrderRoute =
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomyChangeOrderImport.update(
    {
      id: "/economy/change-order",
      path: "/economy/change-order",
      getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutLayoutRoute,
    } as any,
  )

const ProtectedDashboardProjectProjectIdLayoutLayoutEconomyBudgetRoute =
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomyBudgetImport.update({
    id: "/economy/budget",
    path: "/economy/budget",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutLayoutRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module "@tanstack/react-router" {
  interface FileRoutesByPath {
    "/_protected": {
      id: "/_protected"
      path: ""
      fullPath: ""
      preLoaderRoute: typeof ProtectedImport
      parentRoute: typeof rootRoute
    }
    "/_unauthenticated": {
      id: "/_unauthenticated"
      path: ""
      fullPath: ""
      preLoaderRoute: typeof UnauthenticatedImport
      parentRoute: typeof rootRoute
    }
    "/_unprotected": {
      id: "/_unprotected"
      path: ""
      fullPath: ""
      preLoaderRoute: typeof UnprotectedImport
      parentRoute: typeof rootRoute
    }
    "/_protected/create-company": {
      id: "/_protected/create-company"
      path: "/create-company"
      fullPath: "/create-company"
      preLoaderRoute: typeof ProtectedCreateCompanyImport
      parentRoute: typeof ProtectedImport
    }
    "/_protected/dashboard": {
      id: "/_protected/dashboard"
      path: "/dashboard"
      fullPath: "/dashboard"
      preLoaderRoute: typeof ProtectedDashboardImport
      parentRoute: typeof ProtectedImport
    }
    "/_protected/invitations": {
      id: "/_protected/invitations"
      path: "/invitations"
      fullPath: "/invitations"
      preLoaderRoute: typeof ProtectedInvitationsImport
      parentRoute: typeof ProtectedImport
    }
    "/_protected/trial": {
      id: "/_protected/trial"
      path: "/trial"
      fullPath: "/trial"
      preLoaderRoute: typeof ProtectedTrialImport
      parentRoute: typeof ProtectedImport
    }
    "/_protected/user-info": {
      id: "/_protected/user-info"
      path: "/user-info"
      fullPath: "/user-info"
      preLoaderRoute: typeof ProtectedUserInfoImport
      parentRoute: typeof ProtectedImport
    }
    "/_protected/welcome": {
      id: "/_protected/welcome"
      path: "/welcome"
      fullPath: "/welcome"
      preLoaderRoute: typeof ProtectedWelcomeImport
      parentRoute: typeof ProtectedImport
    }
    "/_unauthenticated/create-account": {
      id: "/_unauthenticated/create-account"
      path: "/create-account"
      fullPath: "/create-account"
      preLoaderRoute: typeof UnauthenticatedCreateAccountImport
      parentRoute: typeof UnauthenticatedImport
    }
    "/_unauthenticated/login": {
      id: "/_unauthenticated/login"
      path: "/login"
      fullPath: "/login"
      preLoaderRoute: typeof UnauthenticatedLoginImport
      parentRoute: typeof UnauthenticatedImport
    }
    "/_unauthenticated/reset-password": {
      id: "/_unauthenticated/reset-password"
      path: "/reset-password"
      fullPath: "/reset-password"
      preLoaderRoute: typeof UnauthenticatedResetPasswordImport
      parentRoute: typeof UnauthenticatedImport
    }
    "/_unauthenticated/": {
      id: "/_unauthenticated/"
      path: "/"
      fullPath: "/"
      preLoaderRoute: typeof UnauthenticatedIndexImport
      parentRoute: typeof UnauthenticatedImport
    }
    "/_protected/trial/confirmation": {
      id: "/_protected/trial/confirmation"
      path: "/confirmation"
      fullPath: "/trial/confirmation"
      preLoaderRoute: typeof ProtectedTrialConfirmationImport
      parentRoute: typeof ProtectedTrialImport
    }
    "/_protected/trial/expired": {
      id: "/_protected/trial/expired"
      path: "/expired"
      fullPath: "/trial/expired"
      preLoaderRoute: typeof ProtectedTrialExpiredImport
      parentRoute: typeof ProtectedTrialImport
    }
    "/_protected/trial/subscribe": {
      id: "/_protected/trial/subscribe"
      path: "/subscribe"
      fullPath: "/trial/subscribe"
      preLoaderRoute: typeof ProtectedTrialSubscribeImport
      parentRoute: typeof ProtectedTrialImport
    }
    "/guest/order/$orderId": {
      id: "/guest/order/$orderId"
      path: "/guest/order/$orderId"
      fullPath: "/guest/order/$orderId"
      preLoaderRoute: typeof GuestOrderOrderIdImport
      parentRoute: typeof rootRoute
    }
    "/guest/project/$projectId": {
      id: "/guest/project/$projectId"
      path: "/guest/project/$projectId"
      fullPath: "/guest/project/$projectId"
      preLoaderRoute: typeof GuestProjectProjectIdImport
      parentRoute: typeof rootRoute
    }
    "/guest/$customerId/": {
      id: "/guest/$customerId/"
      path: "/guest/$customerId"
      fullPath: "/guest/$customerId"
      preLoaderRoute: typeof GuestCustomerIdIndexImport
      parentRoute: typeof rootRoute
    }
    "/_protected/dashboard/checklist/new": {
      id: "/_protected/dashboard/checklist/new"
      path: "/checklist/new"
      fullPath: "/dashboard/checklist/new"
      preLoaderRoute: typeof ProtectedDashboardChecklistNewImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/customers": {
      id: "/_protected/dashboard/customers"
      path: "/customers"
      fullPath: "/dashboard/customers"
      preLoaderRoute: typeof ProtectedDashboardCustomersImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/customers/_layout": {
      id: "/_protected/dashboard/customers/_layout"
      path: "/customers"
      fullPath: "/dashboard/customers"
      preLoaderRoute: typeof ProtectedDashboardCustomersLayoutImport
      parentRoute: typeof ProtectedDashboardCustomersRoute
    }
    "/_protected/dashboard/customers/new": {
      id: "/_protected/dashboard/customers/new"
      path: "/new"
      fullPath: "/dashboard/customers/new"
      preLoaderRoute: typeof ProtectedDashboardCustomersNewImport
      parentRoute: typeof ProtectedDashboardCustomersImport
    }
    "/_protected/dashboard/deviation/new": {
      id: "/_protected/dashboard/deviation/new"
      path: "/deviation/new"
      fullPath: "/dashboard/deviation/new"
      preLoaderRoute: typeof ProtectedDashboardDeviationNewImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/economy": {
      id: "/_protected/dashboard/economy"
      path: "/economy"
      fullPath: "/dashboard/economy"
      preLoaderRoute: typeof ProtectedDashboardEconomyImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/economy/_layout": {
      id: "/_protected/dashboard/economy/_layout"
      path: "/economy"
      fullPath: "/dashboard/economy"
      preLoaderRoute: typeof ProtectedDashboardEconomyLayoutImport
      parentRoute: typeof ProtectedDashboardEconomyRoute
    }
    "/_protected/dashboard/equipment/new": {
      id: "/_protected/dashboard/equipment/new"
      path: "/equipment/new"
      fullPath: "/dashboard/equipment/new"
      preLoaderRoute: typeof ProtectedDashboardEquipmentNewImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/facility/new": {
      id: "/_protected/dashboard/facility/new"
      path: "/facility/new"
      fullPath: "/dashboard/facility/new"
      preLoaderRoute: typeof ProtectedDashboardFacilityNewImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/internal-routines/new": {
      id: "/_protected/dashboard/internal-routines/new"
      path: "/internal-routines/new"
      fullPath: "/dashboard/internal-routines/new"
      preLoaderRoute: typeof ProtectedDashboardInternalRoutinesNewImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/project": {
      id: "/_protected/dashboard/project"
      path: "/project"
      fullPath: "/dashboard/project"
      preLoaderRoute: typeof ProtectedDashboardProjectImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/project/_layout": {
      id: "/_protected/dashboard/project/_layout"
      path: "/project"
      fullPath: "/dashboard/project"
      preLoaderRoute: typeof ProtectedDashboardProjectLayoutImport
      parentRoute: typeof ProtectedDashboardProjectRoute
    }
    "/_protected/dashboard/project/new": {
      id: "/_protected/dashboard/project/new"
      path: "/new"
      fullPath: "/dashboard/project/new"
      preLoaderRoute: typeof ProtectedDashboardProjectNewImport
      parentRoute: typeof ProtectedDashboardProjectImport
    }
    "/_protected/dashboard/sales": {
      id: "/_protected/dashboard/sales"
      path: "/sales"
      fullPath: "/dashboard/sales"
      preLoaderRoute: typeof ProtectedDashboardSalesImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/sales/_layout": {
      id: "/_protected/dashboard/sales/_layout"
      path: "/sales"
      fullPath: "/dashboard/sales"
      preLoaderRoute: typeof ProtectedDashboardSalesLayoutImport
      parentRoute: typeof ProtectedDashboardSalesRoute
    }
    "/_protected/dashboard/service-contract/new": {
      id: "/_protected/dashboard/service-contract/new"
      path: "/service-contract/new"
      fullPath: "/dashboard/service-contract/new"
      preLoaderRoute: typeof ProtectedDashboardServiceContractNewImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/settings": {
      id: "/_protected/dashboard/settings"
      path: "/settings"
      fullPath: "/dashboard/settings"
      preLoaderRoute: typeof ProtectedDashboardSettingsImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/settings/_layout": {
      id: "/_protected/dashboard/settings/_layout"
      path: "/settings"
      fullPath: "/dashboard/settings"
      preLoaderRoute: typeof ProtectedDashboardSettingsLayoutImport
      parentRoute: typeof ProtectedDashboardSettingsRoute
    }
    "/_protected/dashboard/ehs/": {
      id: "/_protected/dashboard/ehs/"
      path: "/ehs"
      fullPath: "/dashboard/ehs"
      preLoaderRoute: typeof ProtectedDashboardEhsIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/employees/": {
      id: "/_protected/dashboard/employees/"
      path: "/employees"
      fullPath: "/dashboard/employees"
      preLoaderRoute: typeof ProtectedDashboardEmployeesIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/orders/": {
      id: "/_protected/dashboard/orders/"
      path: "/orders"
      fullPath: "/dashboard/orders"
      preLoaderRoute: typeof ProtectedDashboardOrdersIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/overview/": {
      id: "/_protected/dashboard/overview/"
      path: "/overview"
      fullPath: "/dashboard/overview"
      preLoaderRoute: typeof ProtectedDashboardOverviewIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/products/": {
      id: "/_protected/dashboard/products/"
      path: "/products"
      fullPath: "/dashboard/products"
      preLoaderRoute: typeof ProtectedDashboardProductsIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/checklist/$checklistId/update": {
      id: "/_protected/dashboard/checklist/$checklistId/update"
      path: "/checklist/$checklistId/update"
      fullPath: "/dashboard/checklist/$checklistId/update"
      preLoaderRoute: typeof ProtectedDashboardChecklistChecklistIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/customers/$customerId/edit": {
      id: "/_protected/dashboard/customers/$customerId/edit"
      path: "/$customerId/edit"
      fullPath: "/dashboard/customers/$customerId/edit"
      preLoaderRoute: typeof ProtectedDashboardCustomersCustomerIdEditImport
      parentRoute: typeof ProtectedDashboardCustomersImport
    }
    "/_protected/dashboard/customers/_layout/customers": {
      id: "/_protected/dashboard/customers/_layout/customers"
      path: "/customers"
      fullPath: "/dashboard/customers/customers"
      preLoaderRoute: typeof ProtectedDashboardCustomersLayoutCustomersImport
      parentRoute: typeof ProtectedDashboardCustomersLayoutImport
    }
    "/_protected/dashboard/deviation/$deviationId/update": {
      id: "/_protected/dashboard/deviation/$deviationId/update"
      path: "/deviation/$deviationId/update"
      fullPath: "/dashboard/deviation/$deviationId/update"
      preLoaderRoute: typeof ProtectedDashboardDeviationDeviationIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/economy/_layout/activity": {
      id: "/_protected/dashboard/economy/_layout/activity"
      path: "/activity"
      fullPath: "/dashboard/economy/activity"
      preLoaderRoute: typeof ProtectedDashboardEconomyLayoutActivityImport
      parentRoute: typeof ProtectedDashboardEconomyLayoutImport
    }
    "/_protected/dashboard/economy/_layout/invoice": {
      id: "/_protected/dashboard/economy/_layout/invoice"
      path: "/invoice"
      fullPath: "/dashboard/economy/invoice"
      preLoaderRoute: typeof ProtectedDashboardEconomyLayoutInvoiceImport
      parentRoute: typeof ProtectedDashboardEconomyLayoutImport
    }
    "/_protected/dashboard/economy/_layout/price-model": {
      id: "/_protected/dashboard/economy/_layout/price-model"
      path: "/price-model"
      fullPath: "/dashboard/economy/price-model"
      preLoaderRoute: typeof ProtectedDashboardEconomyLayoutPriceModelImport
      parentRoute: typeof ProtectedDashboardEconomyLayoutImport
    }
    "/_protected/dashboard/economy/_layout/time-list": {
      id: "/_protected/dashboard/economy/_layout/time-list"
      path: "/time-list"
      fullPath: "/dashboard/economy/time-list"
      preLoaderRoute: typeof ProtectedDashboardEconomyLayoutTimeListImport
      parentRoute: typeof ProtectedDashboardEconomyLayoutImport
    }
    "/_protected/dashboard/economy/_layout/time-report": {
      id: "/_protected/dashboard/economy/_layout/time-report"
      path: "/time-report"
      fullPath: "/dashboard/economy/time-report"
      preLoaderRoute: typeof ProtectedDashboardEconomyLayoutTimeReportImport
      parentRoute: typeof ProtectedDashboardEconomyLayoutImport
    }
    "/_protected/dashboard/economy/invoice/new": {
      id: "/_protected/dashboard/economy/invoice/new"
      path: "/invoice/new"
      fullPath: "/dashboard/economy/invoice/new"
      preLoaderRoute: typeof ProtectedDashboardEconomyInvoiceNewImport
      parentRoute: typeof ProtectedDashboardEconomyImport
    }
    "/_protected/dashboard/ehs/$ehsId/update": {
      id: "/_protected/dashboard/ehs/$ehsId/update"
      path: "/ehs/$ehsId/update"
      fullPath: "/dashboard/ehs/$ehsId/update"
      preLoaderRoute: typeof ProtectedDashboardEhsEhsIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/ehs/$templateId/new": {
      id: "/_protected/dashboard/ehs/$templateId/new"
      path: "/ehs/$templateId/new"
      fullPath: "/dashboard/ehs/$templateId/new"
      preLoaderRoute: typeof ProtectedDashboardEhsTemplateIdNewImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/ehs/template/new": {
      id: "/_protected/dashboard/ehs/template/new"
      path: "/ehs/template/new"
      fullPath: "/dashboard/ehs/template/new"
      preLoaderRoute: typeof ProtectedDashboardEhsTemplateNewImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/equipment/$equipmentId/update": {
      id: "/_protected/dashboard/equipment/$equipmentId/update"
      path: "/equipment/$equipmentId/update"
      fullPath: "/dashboard/equipment/$equipmentId/update"
      preLoaderRoute: typeof ProtectedDashboardEquipmentEquipmentIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/facility/$facilityId/details": {
      id: "/_protected/dashboard/facility/$facilityId/details"
      path: "/facility/$facilityId/details"
      fullPath: "/dashboard/facility/$facilityId/details"
      preLoaderRoute: typeof ProtectedDashboardFacilityFacilityIdDetailsImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/facility/$facilityId/update": {
      id: "/_protected/dashboard/facility/$facilityId/update"
      path: "/facility/$facilityId/update"
      fullPath: "/dashboard/facility/$facilityId/update"
      preLoaderRoute: typeof ProtectedDashboardFacilityFacilityIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/internal-routines/$routineId/update": {
      id: "/_protected/dashboard/internal-routines/$routineId/update"
      path: "/internal-routines/$routineId/update"
      fullPath: "/dashboard/internal-routines/$routineId/update"
      preLoaderRoute: typeof ProtectedDashboardInternalRoutinesRoutineIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/location/$resourceId/new": {
      id: "/_protected/dashboard/location/$resourceId/new"
      path: "/location/$resourceId/new"
      fullPath: "/dashboard/location/$resourceId/new"
      preLoaderRoute: typeof ProtectedDashboardLocationResourceIdNewImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/location/$resourceId/update": {
      id: "/_protected/dashboard/location/$resourceId/update"
      path: "/location/$resourceId/update"
      fullPath: "/dashboard/location/$resourceId/update"
      preLoaderRoute: typeof ProtectedDashboardLocationResourceIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/orders/$orderId": {
      id: "/_protected/dashboard/orders/$orderId"
      path: "/orders/$orderId"
      fullPath: "/dashboard/orders/$orderId"
      preLoaderRoute: typeof ProtectedDashboardOrdersOrderIdImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/orders/$orderId/_layout": {
      id: "/_protected/dashboard/orders/$orderId/_layout"
      path: "/orders/$orderId"
      fullPath: "/dashboard/orders/$orderId"
      preLoaderRoute: typeof ProtectedDashboardOrdersOrderIdLayoutImport
      parentRoute: typeof ProtectedDashboardOrdersOrderIdRoute
    }
    "/_protected/dashboard/orders/$orderId/update": {
      id: "/_protected/dashboard/orders/$orderId/update"
      path: "/update"
      fullPath: "/dashboard/orders/$orderId/update"
      preLoaderRoute: typeof ProtectedDashboardOrdersOrderIdUpdateImport
      parentRoute: typeof ProtectedDashboardOrdersOrderIdImport
    }
    "/_protected/dashboard/products/$productId/update": {
      id: "/_protected/dashboard/products/$productId/update"
      path: "/products/$productId/update"
      fullPath: "/dashboard/products/$productId/update"
      preLoaderRoute: typeof ProtectedDashboardProductsProductIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/products/estimations/new": {
      id: "/_protected/dashboard/products/estimations/new"
      path: "/products/estimations/new"
      fullPath: "/dashboard/products/estimations/new"
      preLoaderRoute: typeof ProtectedDashboardProductsEstimationsNewImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/project/$projectId": {
      id: "/_protected/dashboard/project/$projectId"
      path: "/$projectId"
      fullPath: "/dashboard/project/$projectId"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdImport
      parentRoute: typeof ProtectedDashboardProjectImport
    }
    "/_protected/dashboard/project/$projectId/_layout": {
      id: "/_protected/dashboard/project/$projectId/_layout"
      path: "/$projectId"
      fullPath: "/dashboard/project/$projectId"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdRoute
    }
    "/_protected/dashboard/project/$projectId/update": {
      id: "/_protected/dashboard/project/$projectId/update"
      path: "/update"
      fullPath: "/dashboard/project/$projectId/update"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdUpdateImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdImport
    }
    "/_protected/dashboard/project/_layout/deviation-report": {
      id: "/_protected/dashboard/project/_layout/deviation-report"
      path: "/deviation-report"
      fullPath: "/dashboard/project/deviation-report"
      preLoaderRoute: typeof ProtectedDashboardProjectLayoutDeviationReportImport
      parentRoute: typeof ProtectedDashboardProjectLayoutImport
    }
    "/_protected/dashboard/project/_layout/overview": {
      id: "/_protected/dashboard/project/_layout/overview"
      path: "/overview"
      fullPath: "/dashboard/project/overview"
      preLoaderRoute: typeof ProtectedDashboardProjectLayoutOverviewImport
      parentRoute: typeof ProtectedDashboardProjectLayoutImport
    }
    "/_protected/dashboard/project/_layout/progress-plan": {
      id: "/_protected/dashboard/project/_layout/progress-plan"
      path: "/progress-plan"
      fullPath: "/dashboard/project/progress-plan"
      preLoaderRoute: typeof ProtectedDashboardProjectLayoutProgressPlanImport
      parentRoute: typeof ProtectedDashboardProjectLayoutImport
    }
    "/_protected/dashboard/project/_layout/task": {
      id: "/_protected/dashboard/project/_layout/task"
      path: "/task"
      fullPath: "/dashboard/project/task"
      preLoaderRoute: typeof ProtectedDashboardProjectLayoutTaskImport
      parentRoute: typeof ProtectedDashboardProjectLayoutImport
    }
    "/_protected/dashboard/resources/$employeeId/update": {
      id: "/_protected/dashboard/resources/$employeeId/update"
      path: "/resources/$employeeId/update"
      fullPath: "/dashboard/resources/$employeeId/update"
      preLoaderRoute: typeof ProtectedDashboardResourcesEmployeeIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/sales/_layout/direct-sale": {
      id: "/_protected/dashboard/sales/_layout/direct-sale"
      path: "/direct-sale"
      fullPath: "/dashboard/sales/direct-sale"
      preLoaderRoute: typeof ProtectedDashboardSalesLayoutDirectSaleImport
      parentRoute: typeof ProtectedDashboardSalesLayoutImport
    }
    "/_protected/dashboard/sales/_layout/offer": {
      id: "/_protected/dashboard/sales/_layout/offer"
      path: "/offer"
      fullPath: "/dashboard/sales/offer"
      preLoaderRoute: typeof ProtectedDashboardSalesLayoutOfferImport
      parentRoute: typeof ProtectedDashboardSalesLayoutImport
    }
    "/_protected/dashboard/sales/_layout/report": {
      id: "/_protected/dashboard/sales/_layout/report"
      path: "/report"
      fullPath: "/dashboard/sales/report"
      preLoaderRoute: typeof ProtectedDashboardSalesLayoutReportImport
      parentRoute: typeof ProtectedDashboardSalesLayoutImport
    }
    "/_protected/dashboard/sales/_layout/sales-opportunity": {
      id: "/_protected/dashboard/sales/_layout/sales-opportunity"
      path: "/sales-opportunity"
      fullPath: "/dashboard/sales/sales-opportunity"
      preLoaderRoute: typeof ProtectedDashboardSalesLayoutSalesOpportunityImport
      parentRoute: typeof ProtectedDashboardSalesLayoutImport
    }
    "/_protected/dashboard/sales/direct-sale/new": {
      id: "/_protected/dashboard/sales/direct-sale/new"
      path: "/direct-sale/new"
      fullPath: "/dashboard/sales/direct-sale/new"
      preLoaderRoute: typeof ProtectedDashboardSalesDirectSaleNewImport
      parentRoute: typeof ProtectedDashboardSalesImport
    }
    "/_protected/dashboard/sales/offer/new": {
      id: "/_protected/dashboard/sales/offer/new"
      path: "/offer/new"
      fullPath: "/dashboard/sales/offer/new"
      preLoaderRoute: typeof ProtectedDashboardSalesOfferNewImport
      parentRoute: typeof ProtectedDashboardSalesImport
    }
    "/_protected/dashboard/sales/offer/preview": {
      id: "/_protected/dashboard/sales/offer/preview"
      path: "/offer/preview"
      fullPath: "/dashboard/sales/offer/preview"
      preLoaderRoute: typeof ProtectedDashboardSalesOfferPreviewImport
      parentRoute: typeof ProtectedDashboardSalesImport
    }
    "/_protected/dashboard/sales/sales-opportunity/new": {
      id: "/_protected/dashboard/sales/sales-opportunity/new"
      path: "/sales-opportunity/new"
      fullPath: "/dashboard/sales/sales-opportunity/new"
      preLoaderRoute: typeof ProtectedDashboardSalesSalesOpportunityNewImport
      parentRoute: typeof ProtectedDashboardSalesImport
    }
    "/_protected/dashboard/service-contract/$serviceContractId/update": {
      id: "/_protected/dashboard/service-contract/$serviceContractId/update"
      path: "/service-contract/$serviceContractId/update"
      fullPath: "/dashboard/service-contract/$serviceContractId/update"
      preLoaderRoute: typeof ProtectedDashboardServiceContractServiceContractIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/settings/_layout/checklist-template": {
      id: "/_protected/dashboard/settings/_layout/checklist-template"
      path: "/checklist-template"
      fullPath: "/dashboard/settings/checklist-template"
      preLoaderRoute: typeof ProtectedDashboardSettingsLayoutChecklistTemplateImport
      parentRoute: typeof ProtectedDashboardSettingsLayoutImport
    }
    "/_protected/dashboard/settings/_layout/company": {
      id: "/_protected/dashboard/settings/_layout/company"
      path: "/company"
      fullPath: "/dashboard/settings/company"
      preLoaderRoute: typeof ProtectedDashboardSettingsLayoutCompanyImport
      parentRoute: typeof ProtectedDashboardSettingsLayoutImport
    }
    "/_protected/dashboard/settings/_layout/documentation-template": {
      id: "/_protected/dashboard/settings/_layout/documentation-template"
      path: "/documentation-template"
      fullPath: "/dashboard/settings/documentation-template"
      preLoaderRoute: typeof ProtectedDashboardSettingsLayoutDocumentationTemplateImport
      parentRoute: typeof ProtectedDashboardSettingsLayoutImport
    }
    "/_protected/dashboard/settings/_layout/equipment": {
      id: "/_protected/dashboard/settings/_layout/equipment"
      path: "/equipment"
      fullPath: "/dashboard/settings/equipment"
      preLoaderRoute: typeof ProtectedDashboardSettingsLayoutEquipmentImport
      parentRoute: typeof ProtectedDashboardSettingsLayoutImport
    }
    "/_protected/dashboard/settings/_layout/integration": {
      id: "/_protected/dashboard/settings/_layout/integration"
      path: "/integration"
      fullPath: "/dashboard/settings/integration"
      preLoaderRoute: typeof ProtectedDashboardSettingsLayoutIntegrationImport
      parentRoute: typeof ProtectedDashboardSettingsLayoutImport
    }
    "/_protected/dashboard/settings/_layout/order": {
      id: "/_protected/dashboard/settings/_layout/order"
      path: "/order"
      fullPath: "/dashboard/settings/order"
      preLoaderRoute: typeof ProtectedDashboardSettingsLayoutOrderImport
      parentRoute: typeof ProtectedDashboardSettingsLayoutImport
    }
    "/_protected/dashboard/settings/_layout/project": {
      id: "/_protected/dashboard/settings/_layout/project"
      path: "/project"
      fullPath: "/dashboard/settings/project"
      preLoaderRoute: typeof ProtectedDashboardSettingsLayoutProjectImport
      parentRoute: typeof ProtectedDashboardSettingsLayoutImport
    }
    "/_protected/dashboard/settings/_layout/task-template": {
      id: "/_protected/dashboard/settings/_layout/task-template"
      path: "/task-template"
      fullPath: "/dashboard/settings/task-template"
      preLoaderRoute: typeof ProtectedDashboardSettingsLayoutTaskTemplateImport
      parentRoute: typeof ProtectedDashboardSettingsLayoutImport
    }
    "/_protected/dashboard/settings/_layout/video-guide": {
      id: "/_protected/dashboard/settings/_layout/video-guide"
      path: "/video-guide"
      fullPath: "/dashboard/settings/video-guide"
      preLoaderRoute: typeof ProtectedDashboardSettingsLayoutVideoGuideImport
      parentRoute: typeof ProtectedDashboardSettingsLayoutImport
    }
    "/_protected/dashboard/settings/project-phases/edit": {
      id: "/_protected/dashboard/settings/project-phases/edit"
      path: "/project-phases/edit"
      fullPath: "/dashboard/settings/project-phases/edit"
      preLoaderRoute: typeof ProtectedDashboardSettingsProjectPhasesEditImport
      parentRoute: typeof ProtectedDashboardSettingsImport
    }
    "/_protected/dashboard/customers/$customerId/": {
      id: "/_protected/dashboard/customers/$customerId/"
      path: "/$customerId"
      fullPath: "/dashboard/customers/$customerId"
      preLoaderRoute: typeof ProtectedDashboardCustomersCustomerIdIndexImport
      parentRoute: typeof ProtectedDashboardCustomersImport
    }
    "/_protected/dashboard/orders/new/": {
      id: "/_protected/dashboard/orders/new/"
      path: "/orders/new"
      fullPath: "/dashboard/orders/new"
      preLoaderRoute: typeof ProtectedDashboardOrdersNewIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/products/new/": {
      id: "/_protected/dashboard/products/new/"
      path: "/products/new"
      fullPath: "/dashboard/products/new"
      preLoaderRoute: typeof ProtectedDashboardProductsNewIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/sales/new/": {
      id: "/_protected/dashboard/sales/new/"
      path: "/new"
      fullPath: "/dashboard/sales/new"
      preLoaderRoute: typeof ProtectedDashboardSalesNewIndexImport
      parentRoute: typeof ProtectedDashboardSalesImport
    }
    "/_unprotected/offer/$pathUri/details/": {
      id: "/_unprotected/offer/$pathUri/details/"
      path: "/offer/$pathUri/details"
      fullPath: "/offer/$pathUri/details"
      preLoaderRoute: typeof UnprotectedOfferPathUriDetailsIndexImport
      parentRoute: typeof UnprotectedImport
    }
    "/_protected/dashboard/customers/$customerId/order/new": {
      id: "/_protected/dashboard/customers/$customerId/order/new"
      path: "/$customerId/order/new"
      fullPath: "/dashboard/customers/$customerId/order/new"
      preLoaderRoute: typeof ProtectedDashboardCustomersCustomerIdOrderNewImport
      parentRoute: typeof ProtectedDashboardCustomersImport
    }
    "/_protected/dashboard/customers/$customerId/project/new": {
      id: "/_protected/dashboard/customers/$customerId/project/new"
      path: "/$customerId/project/new"
      fullPath: "/dashboard/customers/$customerId/project/new"
      preLoaderRoute: typeof ProtectedDashboardCustomersCustomerIdProjectNewImport
      parentRoute: typeof ProtectedDashboardCustomersImport
    }
    "/_protected/dashboard/ehs/template/$templateId/update": {
      id: "/_protected/dashboard/ehs/template/$templateId/update"
      path: "/ehs/template/$templateId/update"
      fullPath: "/dashboard/ehs/template/$templateId/update"
      preLoaderRoute: typeof ProtectedDashboardEhsTemplateTemplateIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/orders/$orderId/_layout/checklist": {
      id: "/_protected/dashboard/orders/$orderId/_layout/checklist"
      path: "/checklist"
      fullPath: "/dashboard/orders/$orderId/checklist"
      preLoaderRoute: typeof ProtectedDashboardOrdersOrderIdLayoutChecklistImport
      parentRoute: typeof ProtectedDashboardOrdersOrderIdLayoutImport
    }
    "/_protected/dashboard/orders/$orderId/_layout/deviation": {
      id: "/_protected/dashboard/orders/$orderId/_layout/deviation"
      path: "/deviation"
      fullPath: "/dashboard/orders/$orderId/deviation"
      preLoaderRoute: typeof ProtectedDashboardOrdersOrderIdLayoutDeviationImport
      parentRoute: typeof ProtectedDashboardOrdersOrderIdLayoutImport
    }
    "/_protected/dashboard/orders/$orderId/_layout/documentation": {
      id: "/_protected/dashboard/orders/$orderId/_layout/documentation"
      path: "/documentation"
      fullPath: "/dashboard/orders/$orderId/documentation"
      preLoaderRoute: typeof ProtectedDashboardOrdersOrderIdLayoutDocumentationImport
      parentRoute: typeof ProtectedDashboardOrdersOrderIdLayoutImport
    }
    "/_protected/dashboard/orders/$orderId/_layout/overview": {
      id: "/_protected/dashboard/orders/$orderId/_layout/overview"
      path: "/overview"
      fullPath: "/dashboard/orders/$orderId/overview"
      preLoaderRoute: typeof ProtectedDashboardOrdersOrderIdLayoutOverviewImport
      parentRoute: typeof ProtectedDashboardOrdersOrderIdLayoutImport
    }
    "/_protected/dashboard/orders/$orderId/_layout/product": {
      id: "/_protected/dashboard/orders/$orderId/_layout/product"
      path: "/product"
      fullPath: "/dashboard/orders/$orderId/product"
      preLoaderRoute: typeof ProtectedDashboardOrdersOrderIdLayoutProductImport
      parentRoute: typeof ProtectedDashboardOrdersOrderIdLayoutImport
    }
    "/_protected/dashboard/orders/$orderId/products/add": {
      id: "/_protected/dashboard/orders/$orderId/products/add"
      path: "/products/add"
      fullPath: "/dashboard/orders/$orderId/products/add"
      preLoaderRoute: typeof ProtectedDashboardOrdersOrderIdProductsAddImport
      parentRoute: typeof ProtectedDashboardOrdersOrderIdImport
    }
    "/_protected/dashboard/orders/template/$templateId/product": {
      id: "/_protected/dashboard/orders/template/$templateId/product"
      path: "/orders/template/$templateId/product"
      fullPath: "/dashboard/orders/template/$templateId/product"
      preLoaderRoute: typeof ProtectedDashboardOrdersTemplateTemplateIdProductImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/orders/template/$templateId/update": {
      id: "/_protected/dashboard/orders/template/$templateId/update"
      path: "/orders/template/$templateId/update"
      fullPath: "/dashboard/orders/template/$templateId/update"
      preLoaderRoute: typeof ProtectedDashboardOrdersTemplateTemplateIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/products/estimations/$estimationId/update": {
      id: "/_protected/dashboard/products/estimations/$estimationId/update"
      path: "/products/estimations/$estimationId/update"
      fullPath: "/dashboard/products/estimations/$estimationId/update"
      preLoaderRoute: typeof ProtectedDashboardProductsEstimationsEstimationIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/project/$projectId/_layout/_layout": {
      id: "/_protected/dashboard/project/$projectId/_layout/_layout"
      path: ""
      fullPath: "/dashboard/project/$projectId"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/checklist": {
      id: "/_protected/dashboard/project/$projectId/_layout/checklist"
      path: "/checklist"
      fullPath: "/dashboard/project/$projectId/checklist"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutChecklistImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/deviation": {
      id: "/_protected/dashboard/project/$projectId/_layout/deviation"
      path: "/deviation"
      fullPath: "/dashboard/project/$projectId/deviation"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutDeviationImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/documentation": {
      id: "/_protected/dashboard/project/$projectId/_layout/documentation"
      path: "/documentation"
      fullPath: "/dashboard/project/$projectId/documentation"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutDocumentationImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/overview": {
      id: "/_protected/dashboard/project/$projectId/_layout/overview"
      path: "/overview"
      fullPath: "/dashboard/project/$projectId/overview"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutOverviewImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/overview-old": {
      id: "/_protected/dashboard/project/$projectId/_layout/overview-old"
      path: "/overview-old"
      fullPath: "/dashboard/project/$projectId/overview-old"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutOverviewOldImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/product": {
      id: "/_protected/dashboard/project/$projectId/_layout/product"
      path: "/product"
      fullPath: "/dashboard/project/$projectId/product"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutProductImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/progress-plan": {
      id: "/_protected/dashboard/project/$projectId/_layout/progress-plan"
      path: "/progress-plan"
      fullPath: "/dashboard/project/$projectId/progress-plan"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutProgressPlanImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/sub-project": {
      id: "/_protected/dashboard/project/$projectId/_layout/sub-project"
      path: "/sub-project"
      fullPath: "/dashboard/project/$projectId/sub-project"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutSubProjectImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/task": {
      id: "/_protected/dashboard/project/$projectId/_layout/task"
      path: "/task"
      fullPath: "/dashboard/project/$projectId/task"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutTaskImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutImport
    }
    "/_protected/dashboard/project/$projectId/offer/new": {
      id: "/_protected/dashboard/project/$projectId/offer/new"
      path: "/offer/new"
      fullPath: "/dashboard/project/$projectId/offer/new"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdOfferNewImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdImport
    }
    "/_protected/dashboard/project/$projectId/products/add": {
      id: "/_protected/dashboard/project/$projectId/products/add"
      path: "/products/add"
      fullPath: "/dashboard/project/$projectId/products/add"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdProductsAddImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdImport
    }
    "/_protected/dashboard/project/$projectId/task/$taskId": {
      id: "/_protected/dashboard/project/$projectId/task/$taskId"
      path: "/task/$taskId"
      fullPath: "/dashboard/project/$projectId/task/$taskId"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdTaskTaskIdImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdImport
    }
    "/_protected/dashboard/sales/inspection/$customerId/new": {
      id: "/_protected/dashboard/sales/inspection/$customerId/new"
      path: "/inspection/$customerId/new"
      fullPath: "/dashboard/sales/inspection/$customerId/new"
      preLoaderRoute: typeof ProtectedDashboardSalesInspectionCustomerIdNewImport
      parentRoute: typeof ProtectedDashboardSalesImport
    }
    "/_protected/dashboard/sales/inspection/$inspectionId/update": {
      id: "/_protected/dashboard/sales/inspection/$inspectionId/update"
      path: "/inspection/$inspectionId/update"
      fullPath: "/dashboard/sales/inspection/$inspectionId/update"
      preLoaderRoute: typeof ProtectedDashboardSalesInspectionInspectionIdUpdateImport
      parentRoute: typeof ProtectedDashboardSalesImport
    }
    "/_protected/dashboard/sales/offer/$offerId/additional-work": {
      id: "/_protected/dashboard/sales/offer/$offerId/additional-work"
      path: "/offer/$offerId/additional-work"
      fullPath: "/dashboard/sales/offer/$offerId/additional-work"
      preLoaderRoute: typeof ProtectedDashboardSalesOfferOfferIdAdditionalWorkImport
      parentRoute: typeof ProtectedDashboardSalesImport
    }
    "/_protected/dashboard/sales/offer/$offerId/update": {
      id: "/_protected/dashboard/sales/offer/$offerId/update"
      path: "/offer/$offerId/update"
      fullPath: "/dashboard/sales/offer/$offerId/update"
      preLoaderRoute: typeof ProtectedDashboardSalesOfferOfferIdUpdateImport
      parentRoute: typeof ProtectedDashboardSalesImport
    }
    "/_protected/dashboard/sales/offer/$pathUri/preview": {
      id: "/_protected/dashboard/sales/offer/$pathUri/preview"
      path: "/offer/$pathUri/preview"
      fullPath: "/dashboard/sales/offer/$pathUri/preview"
      preLoaderRoute: typeof ProtectedDashboardSalesOfferPathUriPreviewImport
      parentRoute: typeof ProtectedDashboardSalesImport
    }
    "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId": {
      id: "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId"
      path: "/sales-opportunity/$salesOpportunityId"
      fullPath: "/dashboard/sales/sales-opportunity/$salesOpportunityId"
      preLoaderRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdImport
      parentRoute: typeof ProtectedDashboardSalesImport
    }
    "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout": {
      id: "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout"
      path: "/sales-opportunity/$salesOpportunityId"
      fullPath: "/dashboard/sales/sales-opportunity/$salesOpportunityId"
      preLoaderRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutImport
      parentRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdRoute
    }
    "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/update": {
      id: "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/update"
      path: "/update"
      fullPath: "/dashboard/sales/sales-opportunity/$salesOpportunityId/update"
      preLoaderRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdUpdateImport
      parentRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdImport
    }
    "/_protected/dashboard/customers/_layout/inbox/": {
      id: "/_protected/dashboard/customers/_layout/inbox/"
      path: "/inbox"
      fullPath: "/dashboard/customers/inbox"
      preLoaderRoute: typeof ProtectedDashboardCustomersLayoutInboxIndexImport
      parentRoute: typeof ProtectedDashboardCustomersLayoutImport
    }
    "/_protected/dashboard/orders/template/$templateId/": {
      id: "/_protected/dashboard/orders/template/$templateId/"
      path: "/orders/template/$templateId"
      fullPath: "/dashboard/orders/template/$templateId"
      preLoaderRoute: typeof ProtectedDashboardOrdersTemplateTemplateIdIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/project/$projectId/phases/": {
      id: "/_protected/dashboard/project/$projectId/phases/"
      path: "/phases"
      fullPath: "/dashboard/project/$projectId/phases"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdPhasesIndexImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdImport
    }
    "/_protected/dashboard/project/$projectId/workers/": {
      id: "/_protected/dashboard/project/$projectId/workers/"
      path: "/workers"
      fullPath: "/dashboard/project/$projectId/workers"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdWorkersIndexImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdImport
    }
    "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/documentation": {
      id: "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/documentation"
      path: "/documentation"
      fullPath: "/dashboard/sales/sales-opportunity/$salesOpportunityId/documentation"
      preLoaderRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutDocumentationImport
      parentRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutImport
    }
    "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/inspection": {
      id: "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/inspection"
      path: "/inspection"
      fullPath: "/dashboard/sales/sales-opportunity/$salesOpportunityId/inspection"
      preLoaderRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutInspectionImport
      parentRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutImport
    }
    "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/offer": {
      id: "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/offer"
      path: "/offer"
      fullPath: "/dashboard/sales/sales-opportunity/$salesOpportunityId/offer"
      preLoaderRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutOfferImport
      parentRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutImport
    }
    "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/overview": {
      id: "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/overview"
      path: "/overview"
      fullPath: "/dashboard/sales/sales-opportunity/$salesOpportunityId/overview"
      preLoaderRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutOverviewImport
      parentRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutImport
    }
    "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/new": {
      id: "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/new"
      path: "/offer/new"
      fullPath: "/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/new"
      preLoaderRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdOfferNewImport
      parentRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdImport
    }
    "/_protected/dashboard/project/$projectId/_layout/ehs/": {
      id: "/_protected/dashboard/project/$projectId/_layout/ehs/"
      path: "/ehs"
      fullPath: "/dashboard/project/$projectId/ehs"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutEhsIndexImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/budget": {
      id: "/_protected/dashboard/project/$projectId/_layout/_layout/economy/budget"
      path: "/economy/budget"
      fullPath: "/dashboard/project/$projectId/economy/budget"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyBudgetImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/change-order": {
      id: "/_protected/dashboard/project/$projectId/_layout/_layout/economy/change-order"
      path: "/economy/change-order"
      fullPath: "/dashboard/project/$projectId/economy/change-order"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyChangeOrderImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/contract": {
      id: "/_protected/dashboard/project/$projectId/_layout/_layout/economy/contract"
      path: "/economy/contract"
      fullPath: "/dashboard/project/$projectId/economy/contract"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyContractImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/invoice": {
      id: "/_protected/dashboard/project/$projectId/_layout/_layout/economy/invoice"
      path: "/economy/invoice"
      fullPath: "/dashboard/project/$projectId/economy/invoice"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyInvoiceImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/subcontractor": {
      id: "/_protected/dashboard/project/$projectId/_layout/_layout/economy/subcontractor"
      path: "/economy/subcontractor"
      fullPath: "/dashboard/project/$projectId/economy/subcontractor"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomySubcontractorImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/time": {
      id: "/_protected/dashboard/project/$projectId/_layout/_layout/economy/time"
      path: "/economy/time"
      fullPath: "/dashboard/project/$projectId/economy/time"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyTimeImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutImport
    }
    "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/$offerId/update": {
      id: "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/$offerId/update"
      path: "/offer/$offerId/update"
      fullPath: "/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/$offerId/update"
      preLoaderRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdOfferOfferIdUpdateImport
      parentRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdImport
    }
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/": {
      id: "/_protected/dashboard/project/$projectId/_layout/_layout/economy/"
      path: "/economy"
      fullPath: "/dashboard/project/$projectId/economy"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyIndexImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutImport
    }
  }
}

// Create and export the route tree

interface ProtectedDashboardCustomersLayoutRouteChildren {
  ProtectedDashboardCustomersLayoutCustomersRoute: typeof ProtectedDashboardCustomersLayoutCustomersRoute
  ProtectedDashboardCustomersLayoutInboxIndexRoute: typeof ProtectedDashboardCustomersLayoutInboxIndexRoute
}

const ProtectedDashboardCustomersLayoutRouteChildren: ProtectedDashboardCustomersLayoutRouteChildren =
  {
    ProtectedDashboardCustomersLayoutCustomersRoute:
      ProtectedDashboardCustomersLayoutCustomersRoute,
    ProtectedDashboardCustomersLayoutInboxIndexRoute:
      ProtectedDashboardCustomersLayoutInboxIndexRoute,
  }

const ProtectedDashboardCustomersLayoutRouteWithChildren =
  ProtectedDashboardCustomersLayoutRoute._addFileChildren(
    ProtectedDashboardCustomersLayoutRouteChildren,
  )

interface ProtectedDashboardCustomersRouteChildren {
  ProtectedDashboardCustomersLayoutRoute: typeof ProtectedDashboardCustomersLayoutRouteWithChildren
  ProtectedDashboardCustomersNewRoute: typeof ProtectedDashboardCustomersNewRoute
  ProtectedDashboardCustomersCustomerIdEditRoute: typeof ProtectedDashboardCustomersCustomerIdEditRoute
  ProtectedDashboardCustomersCustomerIdIndexRoute: typeof ProtectedDashboardCustomersCustomerIdIndexRoute
  ProtectedDashboardCustomersCustomerIdOrderNewRoute: typeof ProtectedDashboardCustomersCustomerIdOrderNewRoute
  ProtectedDashboardCustomersCustomerIdProjectNewRoute: typeof ProtectedDashboardCustomersCustomerIdProjectNewRoute
}

const ProtectedDashboardCustomersRouteChildren: ProtectedDashboardCustomersRouteChildren =
  {
    ProtectedDashboardCustomersLayoutRoute:
      ProtectedDashboardCustomersLayoutRouteWithChildren,
    ProtectedDashboardCustomersNewRoute: ProtectedDashboardCustomersNewRoute,
    ProtectedDashboardCustomersCustomerIdEditRoute:
      ProtectedDashboardCustomersCustomerIdEditRoute,
    ProtectedDashboardCustomersCustomerIdIndexRoute:
      ProtectedDashboardCustomersCustomerIdIndexRoute,
    ProtectedDashboardCustomersCustomerIdOrderNewRoute:
      ProtectedDashboardCustomersCustomerIdOrderNewRoute,
    ProtectedDashboardCustomersCustomerIdProjectNewRoute:
      ProtectedDashboardCustomersCustomerIdProjectNewRoute,
  }

const ProtectedDashboardCustomersRouteWithChildren =
  ProtectedDashboardCustomersRoute._addFileChildren(
    ProtectedDashboardCustomersRouteChildren,
  )

interface ProtectedDashboardEconomyLayoutRouteChildren {
  ProtectedDashboardEconomyLayoutActivityRoute: typeof ProtectedDashboardEconomyLayoutActivityRoute
  ProtectedDashboardEconomyLayoutInvoiceRoute: typeof ProtectedDashboardEconomyLayoutInvoiceRoute
  ProtectedDashboardEconomyLayoutPriceModelRoute: typeof ProtectedDashboardEconomyLayoutPriceModelRoute
  ProtectedDashboardEconomyLayoutTimeListRoute: typeof ProtectedDashboardEconomyLayoutTimeListRoute
  ProtectedDashboardEconomyLayoutTimeReportRoute: typeof ProtectedDashboardEconomyLayoutTimeReportRoute
}

const ProtectedDashboardEconomyLayoutRouteChildren: ProtectedDashboardEconomyLayoutRouteChildren =
  {
    ProtectedDashboardEconomyLayoutActivityRoute:
      ProtectedDashboardEconomyLayoutActivityRoute,
    ProtectedDashboardEconomyLayoutInvoiceRoute:
      ProtectedDashboardEconomyLayoutInvoiceRoute,
    ProtectedDashboardEconomyLayoutPriceModelRoute:
      ProtectedDashboardEconomyLayoutPriceModelRoute,
    ProtectedDashboardEconomyLayoutTimeListRoute:
      ProtectedDashboardEconomyLayoutTimeListRoute,
    ProtectedDashboardEconomyLayoutTimeReportRoute:
      ProtectedDashboardEconomyLayoutTimeReportRoute,
  }

const ProtectedDashboardEconomyLayoutRouteWithChildren =
  ProtectedDashboardEconomyLayoutRoute._addFileChildren(
    ProtectedDashboardEconomyLayoutRouteChildren,
  )

interface ProtectedDashboardEconomyRouteChildren {
  ProtectedDashboardEconomyLayoutRoute: typeof ProtectedDashboardEconomyLayoutRouteWithChildren
  ProtectedDashboardEconomyInvoiceNewRoute: typeof ProtectedDashboardEconomyInvoiceNewRoute
}

const ProtectedDashboardEconomyRouteChildren: ProtectedDashboardEconomyRouteChildren =
  {
    ProtectedDashboardEconomyLayoutRoute:
      ProtectedDashboardEconomyLayoutRouteWithChildren,
    ProtectedDashboardEconomyInvoiceNewRoute:
      ProtectedDashboardEconomyInvoiceNewRoute,
  }

const ProtectedDashboardEconomyRouteWithChildren =
  ProtectedDashboardEconomyRoute._addFileChildren(
    ProtectedDashboardEconomyRouteChildren,
  )

interface ProtectedDashboardProjectLayoutRouteChildren {
  ProtectedDashboardProjectLayoutDeviationReportRoute: typeof ProtectedDashboardProjectLayoutDeviationReportRoute
  ProtectedDashboardProjectLayoutOverviewRoute: typeof ProtectedDashboardProjectLayoutOverviewRoute
  ProtectedDashboardProjectLayoutProgressPlanRoute: typeof ProtectedDashboardProjectLayoutProgressPlanRoute
  ProtectedDashboardProjectLayoutTaskRoute: typeof ProtectedDashboardProjectLayoutTaskRoute
}

const ProtectedDashboardProjectLayoutRouteChildren: ProtectedDashboardProjectLayoutRouteChildren =
  {
    ProtectedDashboardProjectLayoutDeviationReportRoute:
      ProtectedDashboardProjectLayoutDeviationReportRoute,
    ProtectedDashboardProjectLayoutOverviewRoute:
      ProtectedDashboardProjectLayoutOverviewRoute,
    ProtectedDashboardProjectLayoutProgressPlanRoute:
      ProtectedDashboardProjectLayoutProgressPlanRoute,
    ProtectedDashboardProjectLayoutTaskRoute:
      ProtectedDashboardProjectLayoutTaskRoute,
  }

const ProtectedDashboardProjectLayoutRouteWithChildren =
  ProtectedDashboardProjectLayoutRoute._addFileChildren(
    ProtectedDashboardProjectLayoutRouteChildren,
  )

interface ProtectedDashboardProjectProjectIdLayoutLayoutRouteChildren {
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomyBudgetRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyBudgetRoute
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomyChangeOrderRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyChangeOrderRoute
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomyContractRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyContractRoute
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomyInvoiceRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyInvoiceRoute
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomySubcontractorRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomySubcontractorRoute
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomyTimeRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyTimeRoute
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomyIndexRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyIndexRoute
}

const ProtectedDashboardProjectProjectIdLayoutLayoutRouteChildren: ProtectedDashboardProjectProjectIdLayoutLayoutRouteChildren =
  {
    ProtectedDashboardProjectProjectIdLayoutLayoutEconomyBudgetRoute:
      ProtectedDashboardProjectProjectIdLayoutLayoutEconomyBudgetRoute,
    ProtectedDashboardProjectProjectIdLayoutLayoutEconomyChangeOrderRoute:
      ProtectedDashboardProjectProjectIdLayoutLayoutEconomyChangeOrderRoute,
    ProtectedDashboardProjectProjectIdLayoutLayoutEconomyContractRoute:
      ProtectedDashboardProjectProjectIdLayoutLayoutEconomyContractRoute,
    ProtectedDashboardProjectProjectIdLayoutLayoutEconomyInvoiceRoute:
      ProtectedDashboardProjectProjectIdLayoutLayoutEconomyInvoiceRoute,
    ProtectedDashboardProjectProjectIdLayoutLayoutEconomySubcontractorRoute:
      ProtectedDashboardProjectProjectIdLayoutLayoutEconomySubcontractorRoute,
    ProtectedDashboardProjectProjectIdLayoutLayoutEconomyTimeRoute:
      ProtectedDashboardProjectProjectIdLayoutLayoutEconomyTimeRoute,
    ProtectedDashboardProjectProjectIdLayoutLayoutEconomyIndexRoute:
      ProtectedDashboardProjectProjectIdLayoutLayoutEconomyIndexRoute,
  }

const ProtectedDashboardProjectProjectIdLayoutLayoutRouteWithChildren =
  ProtectedDashboardProjectProjectIdLayoutLayoutRoute._addFileChildren(
    ProtectedDashboardProjectProjectIdLayoutLayoutRouteChildren,
  )

interface ProtectedDashboardProjectProjectIdLayoutRouteChildren {
  ProtectedDashboardProjectProjectIdLayoutLayoutRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutRouteWithChildren
  ProtectedDashboardProjectProjectIdLayoutChecklistRoute: typeof ProtectedDashboardProjectProjectIdLayoutChecklistRoute
  ProtectedDashboardProjectProjectIdLayoutDeviationRoute: typeof ProtectedDashboardProjectProjectIdLayoutDeviationRoute
  ProtectedDashboardProjectProjectIdLayoutDocumentationRoute: typeof ProtectedDashboardProjectProjectIdLayoutDocumentationRoute
  ProtectedDashboardProjectProjectIdLayoutOverviewRoute: typeof ProtectedDashboardProjectProjectIdLayoutOverviewRoute
  ProtectedDashboardProjectProjectIdLayoutOverviewOldRoute: typeof ProtectedDashboardProjectProjectIdLayoutOverviewOldRoute
  ProtectedDashboardProjectProjectIdLayoutProductRoute: typeof ProtectedDashboardProjectProjectIdLayoutProductRoute
  ProtectedDashboardProjectProjectIdLayoutProgressPlanRoute: typeof ProtectedDashboardProjectProjectIdLayoutProgressPlanRoute
  ProtectedDashboardProjectProjectIdLayoutSubProjectRoute: typeof ProtectedDashboardProjectProjectIdLayoutSubProjectRoute
  ProtectedDashboardProjectProjectIdLayoutTaskRoute: typeof ProtectedDashboardProjectProjectIdLayoutTaskRoute
  ProtectedDashboardProjectProjectIdLayoutEhsIndexRoute: typeof ProtectedDashboardProjectProjectIdLayoutEhsIndexRoute
}

const ProtectedDashboardProjectProjectIdLayoutRouteChildren: ProtectedDashboardProjectProjectIdLayoutRouteChildren =
  {
    ProtectedDashboardProjectProjectIdLayoutLayoutRoute:
      ProtectedDashboardProjectProjectIdLayoutLayoutRouteWithChildren,
    ProtectedDashboardProjectProjectIdLayoutChecklistRoute:
      ProtectedDashboardProjectProjectIdLayoutChecklistRoute,
    ProtectedDashboardProjectProjectIdLayoutDeviationRoute:
      ProtectedDashboardProjectProjectIdLayoutDeviationRoute,
    ProtectedDashboardProjectProjectIdLayoutDocumentationRoute:
      ProtectedDashboardProjectProjectIdLayoutDocumentationRoute,
    ProtectedDashboardProjectProjectIdLayoutOverviewRoute:
      ProtectedDashboardProjectProjectIdLayoutOverviewRoute,
    ProtectedDashboardProjectProjectIdLayoutOverviewOldRoute:
      ProtectedDashboardProjectProjectIdLayoutOverviewOldRoute,
    ProtectedDashboardProjectProjectIdLayoutProductRoute:
      ProtectedDashboardProjectProjectIdLayoutProductRoute,
    ProtectedDashboardProjectProjectIdLayoutProgressPlanRoute:
      ProtectedDashboardProjectProjectIdLayoutProgressPlanRoute,
    ProtectedDashboardProjectProjectIdLayoutSubProjectRoute:
      ProtectedDashboardProjectProjectIdLayoutSubProjectRoute,
    ProtectedDashboardProjectProjectIdLayoutTaskRoute:
      ProtectedDashboardProjectProjectIdLayoutTaskRoute,
    ProtectedDashboardProjectProjectIdLayoutEhsIndexRoute:
      ProtectedDashboardProjectProjectIdLayoutEhsIndexRoute,
  }

const ProtectedDashboardProjectProjectIdLayoutRouteWithChildren =
  ProtectedDashboardProjectProjectIdLayoutRoute._addFileChildren(
    ProtectedDashboardProjectProjectIdLayoutRouteChildren,
  )

interface ProtectedDashboardProjectProjectIdRouteChildren {
  ProtectedDashboardProjectProjectIdLayoutRoute: typeof ProtectedDashboardProjectProjectIdLayoutRouteWithChildren
  ProtectedDashboardProjectProjectIdUpdateRoute: typeof ProtectedDashboardProjectProjectIdUpdateRoute
  ProtectedDashboardProjectProjectIdOfferNewRoute: typeof ProtectedDashboardProjectProjectIdOfferNewRoute
  ProtectedDashboardProjectProjectIdProductsAddRoute: typeof ProtectedDashboardProjectProjectIdProductsAddRoute
  ProtectedDashboardProjectProjectIdTaskTaskIdRoute: typeof ProtectedDashboardProjectProjectIdTaskTaskIdRoute
  ProtectedDashboardProjectProjectIdPhasesIndexRoute: typeof ProtectedDashboardProjectProjectIdPhasesIndexRoute
  ProtectedDashboardProjectProjectIdWorkersIndexRoute: typeof ProtectedDashboardProjectProjectIdWorkersIndexRoute
}

const ProtectedDashboardProjectProjectIdRouteChildren: ProtectedDashboardProjectProjectIdRouteChildren =
  {
    ProtectedDashboardProjectProjectIdLayoutRoute:
      ProtectedDashboardProjectProjectIdLayoutRouteWithChildren,
    ProtectedDashboardProjectProjectIdUpdateRoute:
      ProtectedDashboardProjectProjectIdUpdateRoute,
    ProtectedDashboardProjectProjectIdOfferNewRoute:
      ProtectedDashboardProjectProjectIdOfferNewRoute,
    ProtectedDashboardProjectProjectIdProductsAddRoute:
      ProtectedDashboardProjectProjectIdProductsAddRoute,
    ProtectedDashboardProjectProjectIdTaskTaskIdRoute:
      ProtectedDashboardProjectProjectIdTaskTaskIdRoute,
    ProtectedDashboardProjectProjectIdPhasesIndexRoute:
      ProtectedDashboardProjectProjectIdPhasesIndexRoute,
    ProtectedDashboardProjectProjectIdWorkersIndexRoute:
      ProtectedDashboardProjectProjectIdWorkersIndexRoute,
  }

const ProtectedDashboardProjectProjectIdRouteWithChildren =
  ProtectedDashboardProjectProjectIdRoute._addFileChildren(
    ProtectedDashboardProjectProjectIdRouteChildren,
  )

interface ProtectedDashboardProjectRouteChildren {
  ProtectedDashboardProjectLayoutRoute: typeof ProtectedDashboardProjectLayoutRouteWithChildren
  ProtectedDashboardProjectNewRoute: typeof ProtectedDashboardProjectNewRoute
  ProtectedDashboardProjectProjectIdRoute: typeof ProtectedDashboardProjectProjectIdRouteWithChildren
}

const ProtectedDashboardProjectRouteChildren: ProtectedDashboardProjectRouteChildren =
  {
    ProtectedDashboardProjectLayoutRoute:
      ProtectedDashboardProjectLayoutRouteWithChildren,
    ProtectedDashboardProjectNewRoute: ProtectedDashboardProjectNewRoute,
    ProtectedDashboardProjectProjectIdRoute:
      ProtectedDashboardProjectProjectIdRouteWithChildren,
  }

const ProtectedDashboardProjectRouteWithChildren =
  ProtectedDashboardProjectRoute._addFileChildren(
    ProtectedDashboardProjectRouteChildren,
  )

interface ProtectedDashboardSalesLayoutRouteChildren {
  ProtectedDashboardSalesLayoutDirectSaleRoute: typeof ProtectedDashboardSalesLayoutDirectSaleRoute
  ProtectedDashboardSalesLayoutOfferRoute: typeof ProtectedDashboardSalesLayoutOfferRoute
  ProtectedDashboardSalesLayoutReportRoute: typeof ProtectedDashboardSalesLayoutReportRoute
  ProtectedDashboardSalesLayoutSalesOpportunityRoute: typeof ProtectedDashboardSalesLayoutSalesOpportunityRoute
}

const ProtectedDashboardSalesLayoutRouteChildren: ProtectedDashboardSalesLayoutRouteChildren =
  {
    ProtectedDashboardSalesLayoutDirectSaleRoute:
      ProtectedDashboardSalesLayoutDirectSaleRoute,
    ProtectedDashboardSalesLayoutOfferRoute:
      ProtectedDashboardSalesLayoutOfferRoute,
    ProtectedDashboardSalesLayoutReportRoute:
      ProtectedDashboardSalesLayoutReportRoute,
    ProtectedDashboardSalesLayoutSalesOpportunityRoute:
      ProtectedDashboardSalesLayoutSalesOpportunityRoute,
  }

const ProtectedDashboardSalesLayoutRouteWithChildren =
  ProtectedDashboardSalesLayoutRoute._addFileChildren(
    ProtectedDashboardSalesLayoutRouteChildren,
  )

interface ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutRouteChildren {
  ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutDocumentationRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutDocumentationRoute
  ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutInspectionRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutInspectionRoute
  ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutOfferRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutOfferRoute
  ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutOverviewRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutOverviewRoute
}

const ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutRouteChildren: ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutRouteChildren =
  {
    ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutDocumentationRoute:
      ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutDocumentationRoute,
    ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutInspectionRoute:
      ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutInspectionRoute,
    ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutOfferRoute:
      ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutOfferRoute,
    ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutOverviewRoute:
      ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutOverviewRoute,
  }

const ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutRouteWithChildren =
  ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutRoute._addFileChildren(
    ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutRouteChildren,
  )

interface ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdRouteChildren {
  ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutRouteWithChildren
  ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdUpdateRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdUpdateRoute
  ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdOfferNewRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdOfferNewRoute
  ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdOfferOfferIdUpdateRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdOfferOfferIdUpdateRoute
}

const ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdRouteChildren: ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdRouteChildren =
  {
    ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutRoute:
      ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutRouteWithChildren,
    ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdUpdateRoute:
      ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdUpdateRoute,
    ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdOfferNewRoute:
      ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdOfferNewRoute,
    ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdOfferOfferIdUpdateRoute:
      ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdOfferOfferIdUpdateRoute,
  }

const ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdRouteWithChildren =
  ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdRoute._addFileChildren(
    ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdRouteChildren,
  )

interface ProtectedDashboardSalesRouteChildren {
  ProtectedDashboardSalesLayoutRoute: typeof ProtectedDashboardSalesLayoutRouteWithChildren
  ProtectedDashboardSalesDirectSaleNewRoute: typeof ProtectedDashboardSalesDirectSaleNewRoute
  ProtectedDashboardSalesOfferNewRoute: typeof ProtectedDashboardSalesOfferNewRoute
  ProtectedDashboardSalesOfferPreviewRoute: typeof ProtectedDashboardSalesOfferPreviewRoute
  ProtectedDashboardSalesSalesOpportunityNewRoute: typeof ProtectedDashboardSalesSalesOpportunityNewRoute
  ProtectedDashboardSalesNewIndexRoute: typeof ProtectedDashboardSalesNewIndexRoute
  ProtectedDashboardSalesInspectionCustomerIdNewRoute: typeof ProtectedDashboardSalesInspectionCustomerIdNewRoute
  ProtectedDashboardSalesInspectionInspectionIdUpdateRoute: typeof ProtectedDashboardSalesInspectionInspectionIdUpdateRoute
  ProtectedDashboardSalesOfferOfferIdAdditionalWorkRoute: typeof ProtectedDashboardSalesOfferOfferIdAdditionalWorkRoute
  ProtectedDashboardSalesOfferOfferIdUpdateRoute: typeof ProtectedDashboardSalesOfferOfferIdUpdateRoute
  ProtectedDashboardSalesOfferPathUriPreviewRoute: typeof ProtectedDashboardSalesOfferPathUriPreviewRoute
  ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdRoute: typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdRouteWithChildren
}

const ProtectedDashboardSalesRouteChildren: ProtectedDashboardSalesRouteChildren =
  {
    ProtectedDashboardSalesLayoutRoute:
      ProtectedDashboardSalesLayoutRouteWithChildren,
    ProtectedDashboardSalesDirectSaleNewRoute:
      ProtectedDashboardSalesDirectSaleNewRoute,
    ProtectedDashboardSalesOfferNewRoute: ProtectedDashboardSalesOfferNewRoute,
    ProtectedDashboardSalesOfferPreviewRoute:
      ProtectedDashboardSalesOfferPreviewRoute,
    ProtectedDashboardSalesSalesOpportunityNewRoute:
      ProtectedDashboardSalesSalesOpportunityNewRoute,
    ProtectedDashboardSalesNewIndexRoute: ProtectedDashboardSalesNewIndexRoute,
    ProtectedDashboardSalesInspectionCustomerIdNewRoute:
      ProtectedDashboardSalesInspectionCustomerIdNewRoute,
    ProtectedDashboardSalesInspectionInspectionIdUpdateRoute:
      ProtectedDashboardSalesInspectionInspectionIdUpdateRoute,
    ProtectedDashboardSalesOfferOfferIdAdditionalWorkRoute:
      ProtectedDashboardSalesOfferOfferIdAdditionalWorkRoute,
    ProtectedDashboardSalesOfferOfferIdUpdateRoute:
      ProtectedDashboardSalesOfferOfferIdUpdateRoute,
    ProtectedDashboardSalesOfferPathUriPreviewRoute:
      ProtectedDashboardSalesOfferPathUriPreviewRoute,
    ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdRoute:
      ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdRouteWithChildren,
  }

const ProtectedDashboardSalesRouteWithChildren =
  ProtectedDashboardSalesRoute._addFileChildren(
    ProtectedDashboardSalesRouteChildren,
  )

interface ProtectedDashboardSettingsLayoutRouteChildren {
  ProtectedDashboardSettingsLayoutChecklistTemplateRoute: typeof ProtectedDashboardSettingsLayoutChecklistTemplateRoute
  ProtectedDashboardSettingsLayoutCompanyRoute: typeof ProtectedDashboardSettingsLayoutCompanyRoute
  ProtectedDashboardSettingsLayoutDocumentationTemplateRoute: typeof ProtectedDashboardSettingsLayoutDocumentationTemplateRoute
  ProtectedDashboardSettingsLayoutEquipmentRoute: typeof ProtectedDashboardSettingsLayoutEquipmentRoute
  ProtectedDashboardSettingsLayoutIntegrationRoute: typeof ProtectedDashboardSettingsLayoutIntegrationRoute
  ProtectedDashboardSettingsLayoutOrderRoute: typeof ProtectedDashboardSettingsLayoutOrderRoute
  ProtectedDashboardSettingsLayoutProjectRoute: typeof ProtectedDashboardSettingsLayoutProjectRoute
  ProtectedDashboardSettingsLayoutTaskTemplateRoute: typeof ProtectedDashboardSettingsLayoutTaskTemplateRoute
  ProtectedDashboardSettingsLayoutVideoGuideRoute: typeof ProtectedDashboardSettingsLayoutVideoGuideRoute
}

const ProtectedDashboardSettingsLayoutRouteChildren: ProtectedDashboardSettingsLayoutRouteChildren =
  {
    ProtectedDashboardSettingsLayoutChecklistTemplateRoute:
      ProtectedDashboardSettingsLayoutChecklistTemplateRoute,
    ProtectedDashboardSettingsLayoutCompanyRoute:
      ProtectedDashboardSettingsLayoutCompanyRoute,
    ProtectedDashboardSettingsLayoutDocumentationTemplateRoute:
      ProtectedDashboardSettingsLayoutDocumentationTemplateRoute,
    ProtectedDashboardSettingsLayoutEquipmentRoute:
      ProtectedDashboardSettingsLayoutEquipmentRoute,
    ProtectedDashboardSettingsLayoutIntegrationRoute:
      ProtectedDashboardSettingsLayoutIntegrationRoute,
    ProtectedDashboardSettingsLayoutOrderRoute:
      ProtectedDashboardSettingsLayoutOrderRoute,
    ProtectedDashboardSettingsLayoutProjectRoute:
      ProtectedDashboardSettingsLayoutProjectRoute,
    ProtectedDashboardSettingsLayoutTaskTemplateRoute:
      ProtectedDashboardSettingsLayoutTaskTemplateRoute,
    ProtectedDashboardSettingsLayoutVideoGuideRoute:
      ProtectedDashboardSettingsLayoutVideoGuideRoute,
  }

const ProtectedDashboardSettingsLayoutRouteWithChildren =
  ProtectedDashboardSettingsLayoutRoute._addFileChildren(
    ProtectedDashboardSettingsLayoutRouteChildren,
  )

interface ProtectedDashboardSettingsRouteChildren {
  ProtectedDashboardSettingsLayoutRoute: typeof ProtectedDashboardSettingsLayoutRouteWithChildren
  ProtectedDashboardSettingsProjectPhasesEditRoute: typeof ProtectedDashboardSettingsProjectPhasesEditRoute
}

const ProtectedDashboardSettingsRouteChildren: ProtectedDashboardSettingsRouteChildren =
  {
    ProtectedDashboardSettingsLayoutRoute:
      ProtectedDashboardSettingsLayoutRouteWithChildren,
    ProtectedDashboardSettingsProjectPhasesEditRoute:
      ProtectedDashboardSettingsProjectPhasesEditRoute,
  }

const ProtectedDashboardSettingsRouteWithChildren =
  ProtectedDashboardSettingsRoute._addFileChildren(
    ProtectedDashboardSettingsRouteChildren,
  )

interface ProtectedDashboardOrdersOrderIdLayoutRouteChildren {
  ProtectedDashboardOrdersOrderIdLayoutChecklistRoute: typeof ProtectedDashboardOrdersOrderIdLayoutChecklistRoute
  ProtectedDashboardOrdersOrderIdLayoutDeviationRoute: typeof ProtectedDashboardOrdersOrderIdLayoutDeviationRoute
  ProtectedDashboardOrdersOrderIdLayoutDocumentationRoute: typeof ProtectedDashboardOrdersOrderIdLayoutDocumentationRoute
  ProtectedDashboardOrdersOrderIdLayoutOverviewRoute: typeof ProtectedDashboardOrdersOrderIdLayoutOverviewRoute
  ProtectedDashboardOrdersOrderIdLayoutProductRoute: typeof ProtectedDashboardOrdersOrderIdLayoutProductRoute
}

const ProtectedDashboardOrdersOrderIdLayoutRouteChildren: ProtectedDashboardOrdersOrderIdLayoutRouteChildren =
  {
    ProtectedDashboardOrdersOrderIdLayoutChecklistRoute:
      ProtectedDashboardOrdersOrderIdLayoutChecklistRoute,
    ProtectedDashboardOrdersOrderIdLayoutDeviationRoute:
      ProtectedDashboardOrdersOrderIdLayoutDeviationRoute,
    ProtectedDashboardOrdersOrderIdLayoutDocumentationRoute:
      ProtectedDashboardOrdersOrderIdLayoutDocumentationRoute,
    ProtectedDashboardOrdersOrderIdLayoutOverviewRoute:
      ProtectedDashboardOrdersOrderIdLayoutOverviewRoute,
    ProtectedDashboardOrdersOrderIdLayoutProductRoute:
      ProtectedDashboardOrdersOrderIdLayoutProductRoute,
  }

const ProtectedDashboardOrdersOrderIdLayoutRouteWithChildren =
  ProtectedDashboardOrdersOrderIdLayoutRoute._addFileChildren(
    ProtectedDashboardOrdersOrderIdLayoutRouteChildren,
  )

interface ProtectedDashboardOrdersOrderIdRouteChildren {
  ProtectedDashboardOrdersOrderIdLayoutRoute: typeof ProtectedDashboardOrdersOrderIdLayoutRouteWithChildren
  ProtectedDashboardOrdersOrderIdUpdateRoute: typeof ProtectedDashboardOrdersOrderIdUpdateRoute
  ProtectedDashboardOrdersOrderIdProductsAddRoute: typeof ProtectedDashboardOrdersOrderIdProductsAddRoute
}

const ProtectedDashboardOrdersOrderIdRouteChildren: ProtectedDashboardOrdersOrderIdRouteChildren =
  {
    ProtectedDashboardOrdersOrderIdLayoutRoute:
      ProtectedDashboardOrdersOrderIdLayoutRouteWithChildren,
    ProtectedDashboardOrdersOrderIdUpdateRoute:
      ProtectedDashboardOrdersOrderIdUpdateRoute,
    ProtectedDashboardOrdersOrderIdProductsAddRoute:
      ProtectedDashboardOrdersOrderIdProductsAddRoute,
  }

const ProtectedDashboardOrdersOrderIdRouteWithChildren =
  ProtectedDashboardOrdersOrderIdRoute._addFileChildren(
    ProtectedDashboardOrdersOrderIdRouteChildren,
  )

interface ProtectedDashboardRouteChildren {
  ProtectedDashboardChecklistNewRoute: typeof ProtectedDashboardChecklistNewRoute
  ProtectedDashboardCustomersRoute: typeof ProtectedDashboardCustomersRouteWithChildren
  ProtectedDashboardDeviationNewRoute: typeof ProtectedDashboardDeviationNewRoute
  ProtectedDashboardEconomyRoute: typeof ProtectedDashboardEconomyRouteWithChildren
  ProtectedDashboardEquipmentNewRoute: typeof ProtectedDashboardEquipmentNewRoute
  ProtectedDashboardFacilityNewRoute: typeof ProtectedDashboardFacilityNewRoute
  ProtectedDashboardInternalRoutinesNewRoute: typeof ProtectedDashboardInternalRoutinesNewRoute
  ProtectedDashboardProjectRoute: typeof ProtectedDashboardProjectRouteWithChildren
  ProtectedDashboardSalesRoute: typeof ProtectedDashboardSalesRouteWithChildren
  ProtectedDashboardServiceContractNewRoute: typeof ProtectedDashboardServiceContractNewRoute
  ProtectedDashboardSettingsRoute: typeof ProtectedDashboardSettingsRouteWithChildren
  ProtectedDashboardEhsIndexRoute: typeof ProtectedDashboardEhsIndexRoute
  ProtectedDashboardEmployeesIndexRoute: typeof ProtectedDashboardEmployeesIndexRoute
  ProtectedDashboardOrdersIndexRoute: typeof ProtectedDashboardOrdersIndexRoute
  ProtectedDashboardOverviewIndexRoute: typeof ProtectedDashboardOverviewIndexRoute
  ProtectedDashboardProductsIndexRoute: typeof ProtectedDashboardProductsIndexRoute
  ProtectedDashboardChecklistChecklistIdUpdateRoute: typeof ProtectedDashboardChecklistChecklistIdUpdateRoute
  ProtectedDashboardDeviationDeviationIdUpdateRoute: typeof ProtectedDashboardDeviationDeviationIdUpdateRoute
  ProtectedDashboardEhsEhsIdUpdateRoute: typeof ProtectedDashboardEhsEhsIdUpdateRoute
  ProtectedDashboardEhsTemplateIdNewRoute: typeof ProtectedDashboardEhsTemplateIdNewRoute
  ProtectedDashboardEhsTemplateNewRoute: typeof ProtectedDashboardEhsTemplateNewRoute
  ProtectedDashboardEquipmentEquipmentIdUpdateRoute: typeof ProtectedDashboardEquipmentEquipmentIdUpdateRoute
  ProtectedDashboardFacilityFacilityIdDetailsRoute: typeof ProtectedDashboardFacilityFacilityIdDetailsRoute
  ProtectedDashboardFacilityFacilityIdUpdateRoute: typeof ProtectedDashboardFacilityFacilityIdUpdateRoute
  ProtectedDashboardInternalRoutinesRoutineIdUpdateRoute: typeof ProtectedDashboardInternalRoutinesRoutineIdUpdateRoute
  ProtectedDashboardLocationResourceIdNewRoute: typeof ProtectedDashboardLocationResourceIdNewRoute
  ProtectedDashboardLocationResourceIdUpdateRoute: typeof ProtectedDashboardLocationResourceIdUpdateRoute
  ProtectedDashboardOrdersOrderIdRoute: typeof ProtectedDashboardOrdersOrderIdRouteWithChildren
  ProtectedDashboardProductsProductIdUpdateRoute: typeof ProtectedDashboardProductsProductIdUpdateRoute
  ProtectedDashboardProductsEstimationsNewRoute: typeof ProtectedDashboardProductsEstimationsNewRoute
  ProtectedDashboardResourcesEmployeeIdUpdateRoute: typeof ProtectedDashboardResourcesEmployeeIdUpdateRoute
  ProtectedDashboardServiceContractServiceContractIdUpdateRoute: typeof ProtectedDashboardServiceContractServiceContractIdUpdateRoute
  ProtectedDashboardOrdersNewIndexRoute: typeof ProtectedDashboardOrdersNewIndexRoute
  ProtectedDashboardProductsNewIndexRoute: typeof ProtectedDashboardProductsNewIndexRoute
  ProtectedDashboardEhsTemplateTemplateIdUpdateRoute: typeof ProtectedDashboardEhsTemplateTemplateIdUpdateRoute
  ProtectedDashboardOrdersTemplateTemplateIdProductRoute: typeof ProtectedDashboardOrdersTemplateTemplateIdProductRoute
  ProtectedDashboardOrdersTemplateTemplateIdUpdateRoute: typeof ProtectedDashboardOrdersTemplateTemplateIdUpdateRoute
  ProtectedDashboardProductsEstimationsEstimationIdUpdateRoute: typeof ProtectedDashboardProductsEstimationsEstimationIdUpdateRoute
  ProtectedDashboardOrdersTemplateTemplateIdIndexRoute: typeof ProtectedDashboardOrdersTemplateTemplateIdIndexRoute
}

const ProtectedDashboardRouteChildren: ProtectedDashboardRouteChildren = {
  ProtectedDashboardChecklistNewRoute: ProtectedDashboardChecklistNewRoute,
  ProtectedDashboardCustomersRoute:
    ProtectedDashboardCustomersRouteWithChildren,
  ProtectedDashboardDeviationNewRoute: ProtectedDashboardDeviationNewRoute,
  ProtectedDashboardEconomyRoute: ProtectedDashboardEconomyRouteWithChildren,
  ProtectedDashboardEquipmentNewRoute: ProtectedDashboardEquipmentNewRoute,
  ProtectedDashboardFacilityNewRoute: ProtectedDashboardFacilityNewRoute,
  ProtectedDashboardInternalRoutinesNewRoute:
    ProtectedDashboardInternalRoutinesNewRoute,
  ProtectedDashboardProjectRoute: ProtectedDashboardProjectRouteWithChildren,
  ProtectedDashboardSalesRoute: ProtectedDashboardSalesRouteWithChildren,
  ProtectedDashboardServiceContractNewRoute:
    ProtectedDashboardServiceContractNewRoute,
  ProtectedDashboardSettingsRoute: ProtectedDashboardSettingsRouteWithChildren,
  ProtectedDashboardEhsIndexRoute: ProtectedDashboardEhsIndexRoute,
  ProtectedDashboardEmployeesIndexRoute: ProtectedDashboardEmployeesIndexRoute,
  ProtectedDashboardOrdersIndexRoute: ProtectedDashboardOrdersIndexRoute,
  ProtectedDashboardOverviewIndexRoute: ProtectedDashboardOverviewIndexRoute,
  ProtectedDashboardProductsIndexRoute: ProtectedDashboardProductsIndexRoute,
  ProtectedDashboardChecklistChecklistIdUpdateRoute:
    ProtectedDashboardChecklistChecklistIdUpdateRoute,
  ProtectedDashboardDeviationDeviationIdUpdateRoute:
    ProtectedDashboardDeviationDeviationIdUpdateRoute,
  ProtectedDashboardEhsEhsIdUpdateRoute: ProtectedDashboardEhsEhsIdUpdateRoute,
  ProtectedDashboardEhsTemplateIdNewRoute:
    ProtectedDashboardEhsTemplateIdNewRoute,
  ProtectedDashboardEhsTemplateNewRoute: ProtectedDashboardEhsTemplateNewRoute,
  ProtectedDashboardEquipmentEquipmentIdUpdateRoute:
    ProtectedDashboardEquipmentEquipmentIdUpdateRoute,
  ProtectedDashboardFacilityFacilityIdDetailsRoute:
    ProtectedDashboardFacilityFacilityIdDetailsRoute,
  ProtectedDashboardFacilityFacilityIdUpdateRoute:
    ProtectedDashboardFacilityFacilityIdUpdateRoute,
  ProtectedDashboardInternalRoutinesRoutineIdUpdateRoute:
    ProtectedDashboardInternalRoutinesRoutineIdUpdateRoute,
  ProtectedDashboardLocationResourceIdNewRoute:
    ProtectedDashboardLocationResourceIdNewRoute,
  ProtectedDashboardLocationResourceIdUpdateRoute:
    ProtectedDashboardLocationResourceIdUpdateRoute,
  ProtectedDashboardOrdersOrderIdRoute:
    ProtectedDashboardOrdersOrderIdRouteWithChildren,
  ProtectedDashboardProductsProductIdUpdateRoute:
    ProtectedDashboardProductsProductIdUpdateRoute,
  ProtectedDashboardProductsEstimationsNewRoute:
    ProtectedDashboardProductsEstimationsNewRoute,
  ProtectedDashboardResourcesEmployeeIdUpdateRoute:
    ProtectedDashboardResourcesEmployeeIdUpdateRoute,
  ProtectedDashboardServiceContractServiceContractIdUpdateRoute:
    ProtectedDashboardServiceContractServiceContractIdUpdateRoute,
  ProtectedDashboardOrdersNewIndexRoute: ProtectedDashboardOrdersNewIndexRoute,
  ProtectedDashboardProductsNewIndexRoute:
    ProtectedDashboardProductsNewIndexRoute,
  ProtectedDashboardEhsTemplateTemplateIdUpdateRoute:
    ProtectedDashboardEhsTemplateTemplateIdUpdateRoute,
  ProtectedDashboardOrdersTemplateTemplateIdProductRoute:
    ProtectedDashboardOrdersTemplateTemplateIdProductRoute,
  ProtectedDashboardOrdersTemplateTemplateIdUpdateRoute:
    ProtectedDashboardOrdersTemplateTemplateIdUpdateRoute,
  ProtectedDashboardProductsEstimationsEstimationIdUpdateRoute:
    ProtectedDashboardProductsEstimationsEstimationIdUpdateRoute,
  ProtectedDashboardOrdersTemplateTemplateIdIndexRoute:
    ProtectedDashboardOrdersTemplateTemplateIdIndexRoute,
}

const ProtectedDashboardRouteWithChildren =
  ProtectedDashboardRoute._addFileChildren(ProtectedDashboardRouteChildren)

interface ProtectedTrialRouteChildren {
  ProtectedTrialConfirmationRoute: typeof ProtectedTrialConfirmationRoute
  ProtectedTrialExpiredRoute: typeof ProtectedTrialExpiredRoute
  ProtectedTrialSubscribeRoute: typeof ProtectedTrialSubscribeRoute
}

const ProtectedTrialRouteChildren: ProtectedTrialRouteChildren = {
  ProtectedTrialConfirmationRoute: ProtectedTrialConfirmationRoute,
  ProtectedTrialExpiredRoute: ProtectedTrialExpiredRoute,
  ProtectedTrialSubscribeRoute: ProtectedTrialSubscribeRoute,
}

const ProtectedTrialRouteWithChildren = ProtectedTrialRoute._addFileChildren(
  ProtectedTrialRouteChildren,
)

interface ProtectedRouteChildren {
  ProtectedCreateCompanyRoute: typeof ProtectedCreateCompanyRoute
  ProtectedDashboardRoute: typeof ProtectedDashboardRouteWithChildren
  ProtectedInvitationsRoute: typeof ProtectedInvitationsRoute
  ProtectedTrialRoute: typeof ProtectedTrialRouteWithChildren
  ProtectedUserInfoRoute: typeof ProtectedUserInfoRoute
  ProtectedWelcomeRoute: typeof ProtectedWelcomeRoute
}

const ProtectedRouteChildren: ProtectedRouteChildren = {
  ProtectedCreateCompanyRoute: ProtectedCreateCompanyRoute,
  ProtectedDashboardRoute: ProtectedDashboardRouteWithChildren,
  ProtectedInvitationsRoute: ProtectedInvitationsRoute,
  ProtectedTrialRoute: ProtectedTrialRouteWithChildren,
  ProtectedUserInfoRoute: ProtectedUserInfoRoute,
  ProtectedWelcomeRoute: ProtectedWelcomeRoute,
}

const ProtectedRouteWithChildren = ProtectedRoute._addFileChildren(
  ProtectedRouteChildren,
)

interface UnauthenticatedRouteChildren {
  UnauthenticatedCreateAccountRoute: typeof UnauthenticatedCreateAccountRoute
  UnauthenticatedLoginRoute: typeof UnauthenticatedLoginRoute
  UnauthenticatedResetPasswordRoute: typeof UnauthenticatedResetPasswordRoute
  UnauthenticatedIndexRoute: typeof UnauthenticatedIndexRoute
}

const UnauthenticatedRouteChildren: UnauthenticatedRouteChildren = {
  UnauthenticatedCreateAccountRoute: UnauthenticatedCreateAccountRoute,
  UnauthenticatedLoginRoute: UnauthenticatedLoginRoute,
  UnauthenticatedResetPasswordRoute: UnauthenticatedResetPasswordRoute,
  UnauthenticatedIndexRoute: UnauthenticatedIndexRoute,
}

const UnauthenticatedRouteWithChildren = UnauthenticatedRoute._addFileChildren(
  UnauthenticatedRouteChildren,
)

interface UnprotectedRouteChildren {
  UnprotectedOfferPathUriDetailsIndexRoute: typeof UnprotectedOfferPathUriDetailsIndexRoute
}

const UnprotectedRouteChildren: UnprotectedRouteChildren = {
  UnprotectedOfferPathUriDetailsIndexRoute:
    UnprotectedOfferPathUriDetailsIndexRoute,
}

const UnprotectedRouteWithChildren = UnprotectedRoute._addFileChildren(
  UnprotectedRouteChildren,
)

export interface FileRoutesByFullPath {
  "": typeof UnprotectedRouteWithChildren
  "/create-company": typeof ProtectedCreateCompanyRoute
  "/dashboard": typeof ProtectedDashboardRouteWithChildren
  "/invitations": typeof ProtectedInvitationsRoute
  "/trial": typeof ProtectedTrialRouteWithChildren
  "/user-info": typeof ProtectedUserInfoRoute
  "/welcome": typeof ProtectedWelcomeRoute
  "/create-account": typeof UnauthenticatedCreateAccountRoute
  "/login": typeof UnauthenticatedLoginRoute
  "/reset-password": typeof UnauthenticatedResetPasswordRoute
  "/": typeof UnauthenticatedIndexRoute
  "/trial/confirmation": typeof ProtectedTrialConfirmationRoute
  "/trial/expired": typeof ProtectedTrialExpiredRoute
  "/trial/subscribe": typeof ProtectedTrialSubscribeRoute
  "/guest/order/$orderId": typeof GuestOrderOrderIdRoute
  "/guest/project/$projectId": typeof GuestProjectProjectIdRoute
  "/guest/$customerId": typeof GuestCustomerIdIndexRoute
  "/dashboard/checklist/new": typeof ProtectedDashboardChecklistNewRoute
  "/dashboard/customers": typeof ProtectedDashboardCustomersLayoutRouteWithChildren
  "/dashboard/customers/new": typeof ProtectedDashboardCustomersNewRoute
  "/dashboard/deviation/new": typeof ProtectedDashboardDeviationNewRoute
  "/dashboard/economy": typeof ProtectedDashboardEconomyLayoutRouteWithChildren
  "/dashboard/equipment/new": typeof ProtectedDashboardEquipmentNewRoute
  "/dashboard/facility/new": typeof ProtectedDashboardFacilityNewRoute
  "/dashboard/internal-routines/new": typeof ProtectedDashboardInternalRoutinesNewRoute
  "/dashboard/project": typeof ProtectedDashboardProjectLayoutRouteWithChildren
  "/dashboard/project/new": typeof ProtectedDashboardProjectNewRoute
  "/dashboard/sales": typeof ProtectedDashboardSalesLayoutRouteWithChildren
  "/dashboard/service-contract/new": typeof ProtectedDashboardServiceContractNewRoute
  "/dashboard/settings": typeof ProtectedDashboardSettingsLayoutRouteWithChildren
  "/dashboard/ehs": typeof ProtectedDashboardEhsIndexRoute
  "/dashboard/employees": typeof ProtectedDashboardEmployeesIndexRoute
  "/dashboard/orders": typeof ProtectedDashboardOrdersIndexRoute
  "/dashboard/overview": typeof ProtectedDashboardOverviewIndexRoute
  "/dashboard/products": typeof ProtectedDashboardProductsIndexRoute
  "/dashboard/checklist/$checklistId/update": typeof ProtectedDashboardChecklistChecklistIdUpdateRoute
  "/dashboard/customers/$customerId/edit": typeof ProtectedDashboardCustomersCustomerIdEditRoute
  "/dashboard/customers/customers": typeof ProtectedDashboardCustomersLayoutCustomersRoute
  "/dashboard/deviation/$deviationId/update": typeof ProtectedDashboardDeviationDeviationIdUpdateRoute
  "/dashboard/economy/activity": typeof ProtectedDashboardEconomyLayoutActivityRoute
  "/dashboard/economy/invoice": typeof ProtectedDashboardEconomyLayoutInvoiceRoute
  "/dashboard/economy/price-model": typeof ProtectedDashboardEconomyLayoutPriceModelRoute
  "/dashboard/economy/time-list": typeof ProtectedDashboardEconomyLayoutTimeListRoute
  "/dashboard/economy/time-report": typeof ProtectedDashboardEconomyLayoutTimeReportRoute
  "/dashboard/economy/invoice/new": typeof ProtectedDashboardEconomyInvoiceNewRoute
  "/dashboard/ehs/$ehsId/update": typeof ProtectedDashboardEhsEhsIdUpdateRoute
  "/dashboard/ehs/$templateId/new": typeof ProtectedDashboardEhsTemplateIdNewRoute
  "/dashboard/ehs/template/new": typeof ProtectedDashboardEhsTemplateNewRoute
  "/dashboard/equipment/$equipmentId/update": typeof ProtectedDashboardEquipmentEquipmentIdUpdateRoute
  "/dashboard/facility/$facilityId/details": typeof ProtectedDashboardFacilityFacilityIdDetailsRoute
  "/dashboard/facility/$facilityId/update": typeof ProtectedDashboardFacilityFacilityIdUpdateRoute
  "/dashboard/internal-routines/$routineId/update": typeof ProtectedDashboardInternalRoutinesRoutineIdUpdateRoute
  "/dashboard/location/$resourceId/new": typeof ProtectedDashboardLocationResourceIdNewRoute
  "/dashboard/location/$resourceId/update": typeof ProtectedDashboardLocationResourceIdUpdateRoute
  "/dashboard/orders/$orderId": typeof ProtectedDashboardOrdersOrderIdLayoutRouteWithChildren
  "/dashboard/orders/$orderId/update": typeof ProtectedDashboardOrdersOrderIdUpdateRoute
  "/dashboard/products/$productId/update": typeof ProtectedDashboardProductsProductIdUpdateRoute
  "/dashboard/products/estimations/new": typeof ProtectedDashboardProductsEstimationsNewRoute
  "/dashboard/project/$projectId": typeof ProtectedDashboardProjectProjectIdLayoutLayoutRouteWithChildren
  "/dashboard/project/$projectId/update": typeof ProtectedDashboardProjectProjectIdUpdateRoute
  "/dashboard/project/deviation-report": typeof ProtectedDashboardProjectLayoutDeviationReportRoute
  "/dashboard/project/overview": typeof ProtectedDashboardProjectLayoutOverviewRoute
  "/dashboard/project/progress-plan": typeof ProtectedDashboardProjectLayoutProgressPlanRoute
  "/dashboard/project/task": typeof ProtectedDashboardProjectLayoutTaskRoute
  "/dashboard/resources/$employeeId/update": typeof ProtectedDashboardResourcesEmployeeIdUpdateRoute
  "/dashboard/sales/direct-sale": typeof ProtectedDashboardSalesLayoutDirectSaleRoute
  "/dashboard/sales/offer": typeof ProtectedDashboardSalesLayoutOfferRoute
  "/dashboard/sales/report": typeof ProtectedDashboardSalesLayoutReportRoute
  "/dashboard/sales/sales-opportunity": typeof ProtectedDashboardSalesLayoutSalesOpportunityRoute
  "/dashboard/sales/direct-sale/new": typeof ProtectedDashboardSalesDirectSaleNewRoute
  "/dashboard/sales/offer/new": typeof ProtectedDashboardSalesOfferNewRoute
  "/dashboard/sales/offer/preview": typeof ProtectedDashboardSalesOfferPreviewRoute
  "/dashboard/sales/sales-opportunity/new": typeof ProtectedDashboardSalesSalesOpportunityNewRoute
  "/dashboard/service-contract/$serviceContractId/update": typeof ProtectedDashboardServiceContractServiceContractIdUpdateRoute
  "/dashboard/settings/checklist-template": typeof ProtectedDashboardSettingsLayoutChecklistTemplateRoute
  "/dashboard/settings/company": typeof ProtectedDashboardSettingsLayoutCompanyRoute
  "/dashboard/settings/documentation-template": typeof ProtectedDashboardSettingsLayoutDocumentationTemplateRoute
  "/dashboard/settings/equipment": typeof ProtectedDashboardSettingsLayoutEquipmentRoute
  "/dashboard/settings/integration": typeof ProtectedDashboardSettingsLayoutIntegrationRoute
  "/dashboard/settings/order": typeof ProtectedDashboardSettingsLayoutOrderRoute
  "/dashboard/settings/project": typeof ProtectedDashboardSettingsLayoutProjectRoute
  "/dashboard/settings/task-template": typeof ProtectedDashboardSettingsLayoutTaskTemplateRoute
  "/dashboard/settings/video-guide": typeof ProtectedDashboardSettingsLayoutVideoGuideRoute
  "/dashboard/settings/project-phases/edit": typeof ProtectedDashboardSettingsProjectPhasesEditRoute
  "/dashboard/customers/$customerId": typeof ProtectedDashboardCustomersCustomerIdIndexRoute
  "/dashboard/orders/new": typeof ProtectedDashboardOrdersNewIndexRoute
  "/dashboard/products/new": typeof ProtectedDashboardProductsNewIndexRoute
  "/dashboard/sales/new": typeof ProtectedDashboardSalesNewIndexRoute
  "/offer/$pathUri/details": typeof UnprotectedOfferPathUriDetailsIndexRoute
  "/dashboard/customers/$customerId/order/new": typeof ProtectedDashboardCustomersCustomerIdOrderNewRoute
  "/dashboard/customers/$customerId/project/new": typeof ProtectedDashboardCustomersCustomerIdProjectNewRoute
  "/dashboard/ehs/template/$templateId/update": typeof ProtectedDashboardEhsTemplateTemplateIdUpdateRoute
  "/dashboard/orders/$orderId/checklist": typeof ProtectedDashboardOrdersOrderIdLayoutChecklistRoute
  "/dashboard/orders/$orderId/deviation": typeof ProtectedDashboardOrdersOrderIdLayoutDeviationRoute
  "/dashboard/orders/$orderId/documentation": typeof ProtectedDashboardOrdersOrderIdLayoutDocumentationRoute
  "/dashboard/orders/$orderId/overview": typeof ProtectedDashboardOrdersOrderIdLayoutOverviewRoute
  "/dashboard/orders/$orderId/product": typeof ProtectedDashboardOrdersOrderIdLayoutProductRoute
  "/dashboard/orders/$orderId/products/add": typeof ProtectedDashboardOrdersOrderIdProductsAddRoute
  "/dashboard/orders/template/$templateId/product": typeof ProtectedDashboardOrdersTemplateTemplateIdProductRoute
  "/dashboard/orders/template/$templateId/update": typeof ProtectedDashboardOrdersTemplateTemplateIdUpdateRoute
  "/dashboard/products/estimations/$estimationId/update": typeof ProtectedDashboardProductsEstimationsEstimationIdUpdateRoute
  "/dashboard/project/$projectId/checklist": typeof ProtectedDashboardProjectProjectIdLayoutChecklistRoute
  "/dashboard/project/$projectId/deviation": typeof ProtectedDashboardProjectProjectIdLayoutDeviationRoute
  "/dashboard/project/$projectId/documentation": typeof ProtectedDashboardProjectProjectIdLayoutDocumentationRoute
  "/dashboard/project/$projectId/overview": typeof ProtectedDashboardProjectProjectIdLayoutOverviewRoute
  "/dashboard/project/$projectId/overview-old": typeof ProtectedDashboardProjectProjectIdLayoutOverviewOldRoute
  "/dashboard/project/$projectId/product": typeof ProtectedDashboardProjectProjectIdLayoutProductRoute
  "/dashboard/project/$projectId/progress-plan": typeof ProtectedDashboardProjectProjectIdLayoutProgressPlanRoute
  "/dashboard/project/$projectId/sub-project": typeof ProtectedDashboardProjectProjectIdLayoutSubProjectRoute
  "/dashboard/project/$projectId/task": typeof ProtectedDashboardProjectProjectIdLayoutTaskRoute
  "/dashboard/project/$projectId/offer/new": typeof ProtectedDashboardProjectProjectIdOfferNewRoute
  "/dashboard/project/$projectId/products/add": typeof ProtectedDashboardProjectProjectIdProductsAddRoute
  "/dashboard/project/$projectId/task/$taskId": typeof ProtectedDashboardProjectProjectIdTaskTaskIdRoute
  "/dashboard/sales/inspection/$customerId/new": typeof ProtectedDashboardSalesInspectionCustomerIdNewRoute
  "/dashboard/sales/inspection/$inspectionId/update": typeof ProtectedDashboardSalesInspectionInspectionIdUpdateRoute
  "/dashboard/sales/offer/$offerId/additional-work": typeof ProtectedDashboardSalesOfferOfferIdAdditionalWorkRoute
  "/dashboard/sales/offer/$offerId/update": typeof ProtectedDashboardSalesOfferOfferIdUpdateRoute
  "/dashboard/sales/offer/$pathUri/preview": typeof ProtectedDashboardSalesOfferPathUriPreviewRoute
  "/dashboard/sales/sales-opportunity/$salesOpportunityId": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutRouteWithChildren
  "/dashboard/sales/sales-opportunity/$salesOpportunityId/update": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdUpdateRoute
  "/dashboard/customers/inbox": typeof ProtectedDashboardCustomersLayoutInboxIndexRoute
  "/dashboard/orders/template/$templateId": typeof ProtectedDashboardOrdersTemplateTemplateIdIndexRoute
  "/dashboard/project/$projectId/phases": typeof ProtectedDashboardProjectProjectIdPhasesIndexRoute
  "/dashboard/project/$projectId/workers": typeof ProtectedDashboardProjectProjectIdWorkersIndexRoute
  "/dashboard/sales/sales-opportunity/$salesOpportunityId/documentation": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutDocumentationRoute
  "/dashboard/sales/sales-opportunity/$salesOpportunityId/inspection": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutInspectionRoute
  "/dashboard/sales/sales-opportunity/$salesOpportunityId/offer": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutOfferRoute
  "/dashboard/sales/sales-opportunity/$salesOpportunityId/overview": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutOverviewRoute
  "/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/new": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdOfferNewRoute
  "/dashboard/project/$projectId/ehs": typeof ProtectedDashboardProjectProjectIdLayoutEhsIndexRoute
  "/dashboard/project/$projectId/economy/budget": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyBudgetRoute
  "/dashboard/project/$projectId/economy/change-order": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyChangeOrderRoute
  "/dashboard/project/$projectId/economy/contract": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyContractRoute
  "/dashboard/project/$projectId/economy/invoice": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyInvoiceRoute
  "/dashboard/project/$projectId/economy/subcontractor": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomySubcontractorRoute
  "/dashboard/project/$projectId/economy/time": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyTimeRoute
  "/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/$offerId/update": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdOfferOfferIdUpdateRoute
  "/dashboard/project/$projectId/economy": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyIndexRoute
}

export interface FileRoutesByTo {
  "": typeof UnprotectedRouteWithChildren
  "/create-company": typeof ProtectedCreateCompanyRoute
  "/dashboard": typeof ProtectedDashboardRouteWithChildren
  "/invitations": typeof ProtectedInvitationsRoute
  "/trial": typeof ProtectedTrialRouteWithChildren
  "/user-info": typeof ProtectedUserInfoRoute
  "/welcome": typeof ProtectedWelcomeRoute
  "/create-account": typeof UnauthenticatedCreateAccountRoute
  "/login": typeof UnauthenticatedLoginRoute
  "/reset-password": typeof UnauthenticatedResetPasswordRoute
  "/": typeof UnauthenticatedIndexRoute
  "/trial/confirmation": typeof ProtectedTrialConfirmationRoute
  "/trial/expired": typeof ProtectedTrialExpiredRoute
  "/trial/subscribe": typeof ProtectedTrialSubscribeRoute
  "/guest/order/$orderId": typeof GuestOrderOrderIdRoute
  "/guest/project/$projectId": typeof GuestProjectProjectIdRoute
  "/guest/$customerId": typeof GuestCustomerIdIndexRoute
  "/dashboard/checklist/new": typeof ProtectedDashboardChecklistNewRoute
  "/dashboard/customers": typeof ProtectedDashboardCustomersLayoutRouteWithChildren
  "/dashboard/customers/new": typeof ProtectedDashboardCustomersNewRoute
  "/dashboard/deviation/new": typeof ProtectedDashboardDeviationNewRoute
  "/dashboard/economy": typeof ProtectedDashboardEconomyLayoutRouteWithChildren
  "/dashboard/equipment/new": typeof ProtectedDashboardEquipmentNewRoute
  "/dashboard/facility/new": typeof ProtectedDashboardFacilityNewRoute
  "/dashboard/internal-routines/new": typeof ProtectedDashboardInternalRoutinesNewRoute
  "/dashboard/project": typeof ProtectedDashboardProjectLayoutRouteWithChildren
  "/dashboard/project/new": typeof ProtectedDashboardProjectNewRoute
  "/dashboard/sales": typeof ProtectedDashboardSalesLayoutRouteWithChildren
  "/dashboard/service-contract/new": typeof ProtectedDashboardServiceContractNewRoute
  "/dashboard/settings": typeof ProtectedDashboardSettingsLayoutRouteWithChildren
  "/dashboard/ehs": typeof ProtectedDashboardEhsIndexRoute
  "/dashboard/employees": typeof ProtectedDashboardEmployeesIndexRoute
  "/dashboard/orders": typeof ProtectedDashboardOrdersIndexRoute
  "/dashboard/overview": typeof ProtectedDashboardOverviewIndexRoute
  "/dashboard/products": typeof ProtectedDashboardProductsIndexRoute
  "/dashboard/checklist/$checklistId/update": typeof ProtectedDashboardChecklistChecklistIdUpdateRoute
  "/dashboard/customers/$customerId/edit": typeof ProtectedDashboardCustomersCustomerIdEditRoute
  "/dashboard/customers/customers": typeof ProtectedDashboardCustomersLayoutCustomersRoute
  "/dashboard/deviation/$deviationId/update": typeof ProtectedDashboardDeviationDeviationIdUpdateRoute
  "/dashboard/economy/activity": typeof ProtectedDashboardEconomyLayoutActivityRoute
  "/dashboard/economy/invoice": typeof ProtectedDashboardEconomyLayoutInvoiceRoute
  "/dashboard/economy/price-model": typeof ProtectedDashboardEconomyLayoutPriceModelRoute
  "/dashboard/economy/time-list": typeof ProtectedDashboardEconomyLayoutTimeListRoute
  "/dashboard/economy/time-report": typeof ProtectedDashboardEconomyLayoutTimeReportRoute
  "/dashboard/economy/invoice/new": typeof ProtectedDashboardEconomyInvoiceNewRoute
  "/dashboard/ehs/$ehsId/update": typeof ProtectedDashboardEhsEhsIdUpdateRoute
  "/dashboard/ehs/$templateId/new": typeof ProtectedDashboardEhsTemplateIdNewRoute
  "/dashboard/ehs/template/new": typeof ProtectedDashboardEhsTemplateNewRoute
  "/dashboard/equipment/$equipmentId/update": typeof ProtectedDashboardEquipmentEquipmentIdUpdateRoute
  "/dashboard/facility/$facilityId/details": typeof ProtectedDashboardFacilityFacilityIdDetailsRoute
  "/dashboard/facility/$facilityId/update": typeof ProtectedDashboardFacilityFacilityIdUpdateRoute
  "/dashboard/internal-routines/$routineId/update": typeof ProtectedDashboardInternalRoutinesRoutineIdUpdateRoute
  "/dashboard/location/$resourceId/new": typeof ProtectedDashboardLocationResourceIdNewRoute
  "/dashboard/location/$resourceId/update": typeof ProtectedDashboardLocationResourceIdUpdateRoute
  "/dashboard/orders/$orderId": typeof ProtectedDashboardOrdersOrderIdLayoutRouteWithChildren
  "/dashboard/orders/$orderId/update": typeof ProtectedDashboardOrdersOrderIdUpdateRoute
  "/dashboard/products/$productId/update": typeof ProtectedDashboardProductsProductIdUpdateRoute
  "/dashboard/products/estimations/new": typeof ProtectedDashboardProductsEstimationsNewRoute
  "/dashboard/project/$projectId": typeof ProtectedDashboardProjectProjectIdLayoutLayoutRouteWithChildren
  "/dashboard/project/$projectId/update": typeof ProtectedDashboardProjectProjectIdUpdateRoute
  "/dashboard/project/deviation-report": typeof ProtectedDashboardProjectLayoutDeviationReportRoute
  "/dashboard/project/overview": typeof ProtectedDashboardProjectLayoutOverviewRoute
  "/dashboard/project/progress-plan": typeof ProtectedDashboardProjectLayoutProgressPlanRoute
  "/dashboard/project/task": typeof ProtectedDashboardProjectLayoutTaskRoute
  "/dashboard/resources/$employeeId/update": typeof ProtectedDashboardResourcesEmployeeIdUpdateRoute
  "/dashboard/sales/direct-sale": typeof ProtectedDashboardSalesLayoutDirectSaleRoute
  "/dashboard/sales/offer": typeof ProtectedDashboardSalesLayoutOfferRoute
  "/dashboard/sales/report": typeof ProtectedDashboardSalesLayoutReportRoute
  "/dashboard/sales/sales-opportunity": typeof ProtectedDashboardSalesLayoutSalesOpportunityRoute
  "/dashboard/sales/direct-sale/new": typeof ProtectedDashboardSalesDirectSaleNewRoute
  "/dashboard/sales/offer/new": typeof ProtectedDashboardSalesOfferNewRoute
  "/dashboard/sales/offer/preview": typeof ProtectedDashboardSalesOfferPreviewRoute
  "/dashboard/sales/sales-opportunity/new": typeof ProtectedDashboardSalesSalesOpportunityNewRoute
  "/dashboard/service-contract/$serviceContractId/update": typeof ProtectedDashboardServiceContractServiceContractIdUpdateRoute
  "/dashboard/settings/checklist-template": typeof ProtectedDashboardSettingsLayoutChecklistTemplateRoute
  "/dashboard/settings/company": typeof ProtectedDashboardSettingsLayoutCompanyRoute
  "/dashboard/settings/documentation-template": typeof ProtectedDashboardSettingsLayoutDocumentationTemplateRoute
  "/dashboard/settings/equipment": typeof ProtectedDashboardSettingsLayoutEquipmentRoute
  "/dashboard/settings/integration": typeof ProtectedDashboardSettingsLayoutIntegrationRoute
  "/dashboard/settings/order": typeof ProtectedDashboardSettingsLayoutOrderRoute
  "/dashboard/settings/project": typeof ProtectedDashboardSettingsLayoutProjectRoute
  "/dashboard/settings/task-template": typeof ProtectedDashboardSettingsLayoutTaskTemplateRoute
  "/dashboard/settings/video-guide": typeof ProtectedDashboardSettingsLayoutVideoGuideRoute
  "/dashboard/settings/project-phases/edit": typeof ProtectedDashboardSettingsProjectPhasesEditRoute
  "/dashboard/customers/$customerId": typeof ProtectedDashboardCustomersCustomerIdIndexRoute
  "/dashboard/orders/new": typeof ProtectedDashboardOrdersNewIndexRoute
  "/dashboard/products/new": typeof ProtectedDashboardProductsNewIndexRoute
  "/dashboard/sales/new": typeof ProtectedDashboardSalesNewIndexRoute
  "/offer/$pathUri/details": typeof UnprotectedOfferPathUriDetailsIndexRoute
  "/dashboard/customers/$customerId/order/new": typeof ProtectedDashboardCustomersCustomerIdOrderNewRoute
  "/dashboard/customers/$customerId/project/new": typeof ProtectedDashboardCustomersCustomerIdProjectNewRoute
  "/dashboard/ehs/template/$templateId/update": typeof ProtectedDashboardEhsTemplateTemplateIdUpdateRoute
  "/dashboard/orders/$orderId/checklist": typeof ProtectedDashboardOrdersOrderIdLayoutChecklistRoute
  "/dashboard/orders/$orderId/deviation": typeof ProtectedDashboardOrdersOrderIdLayoutDeviationRoute
  "/dashboard/orders/$orderId/documentation": typeof ProtectedDashboardOrdersOrderIdLayoutDocumentationRoute
  "/dashboard/orders/$orderId/overview": typeof ProtectedDashboardOrdersOrderIdLayoutOverviewRoute
  "/dashboard/orders/$orderId/product": typeof ProtectedDashboardOrdersOrderIdLayoutProductRoute
  "/dashboard/orders/$orderId/products/add": typeof ProtectedDashboardOrdersOrderIdProductsAddRoute
  "/dashboard/orders/template/$templateId/product": typeof ProtectedDashboardOrdersTemplateTemplateIdProductRoute
  "/dashboard/orders/template/$templateId/update": typeof ProtectedDashboardOrdersTemplateTemplateIdUpdateRoute
  "/dashboard/products/estimations/$estimationId/update": typeof ProtectedDashboardProductsEstimationsEstimationIdUpdateRoute
  "/dashboard/project/$projectId/checklist": typeof ProtectedDashboardProjectProjectIdLayoutChecklistRoute
  "/dashboard/project/$projectId/deviation": typeof ProtectedDashboardProjectProjectIdLayoutDeviationRoute
  "/dashboard/project/$projectId/documentation": typeof ProtectedDashboardProjectProjectIdLayoutDocumentationRoute
  "/dashboard/project/$projectId/overview": typeof ProtectedDashboardProjectProjectIdLayoutOverviewRoute
  "/dashboard/project/$projectId/overview-old": typeof ProtectedDashboardProjectProjectIdLayoutOverviewOldRoute
  "/dashboard/project/$projectId/product": typeof ProtectedDashboardProjectProjectIdLayoutProductRoute
  "/dashboard/project/$projectId/progress-plan": typeof ProtectedDashboardProjectProjectIdLayoutProgressPlanRoute
  "/dashboard/project/$projectId/sub-project": typeof ProtectedDashboardProjectProjectIdLayoutSubProjectRoute
  "/dashboard/project/$projectId/task": typeof ProtectedDashboardProjectProjectIdLayoutTaskRoute
  "/dashboard/project/$projectId/offer/new": typeof ProtectedDashboardProjectProjectIdOfferNewRoute
  "/dashboard/project/$projectId/products/add": typeof ProtectedDashboardProjectProjectIdProductsAddRoute
  "/dashboard/project/$projectId/task/$taskId": typeof ProtectedDashboardProjectProjectIdTaskTaskIdRoute
  "/dashboard/sales/inspection/$customerId/new": typeof ProtectedDashboardSalesInspectionCustomerIdNewRoute
  "/dashboard/sales/inspection/$inspectionId/update": typeof ProtectedDashboardSalesInspectionInspectionIdUpdateRoute
  "/dashboard/sales/offer/$offerId/additional-work": typeof ProtectedDashboardSalesOfferOfferIdAdditionalWorkRoute
  "/dashboard/sales/offer/$offerId/update": typeof ProtectedDashboardSalesOfferOfferIdUpdateRoute
  "/dashboard/sales/offer/$pathUri/preview": typeof ProtectedDashboardSalesOfferPathUriPreviewRoute
  "/dashboard/sales/sales-opportunity/$salesOpportunityId": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutRouteWithChildren
  "/dashboard/sales/sales-opportunity/$salesOpportunityId/update": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdUpdateRoute
  "/dashboard/customers/inbox": typeof ProtectedDashboardCustomersLayoutInboxIndexRoute
  "/dashboard/orders/template/$templateId": typeof ProtectedDashboardOrdersTemplateTemplateIdIndexRoute
  "/dashboard/project/$projectId/phases": typeof ProtectedDashboardProjectProjectIdPhasesIndexRoute
  "/dashboard/project/$projectId/workers": typeof ProtectedDashboardProjectProjectIdWorkersIndexRoute
  "/dashboard/sales/sales-opportunity/$salesOpportunityId/documentation": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutDocumentationRoute
  "/dashboard/sales/sales-opportunity/$salesOpportunityId/inspection": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutInspectionRoute
  "/dashboard/sales/sales-opportunity/$salesOpportunityId/offer": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutOfferRoute
  "/dashboard/sales/sales-opportunity/$salesOpportunityId/overview": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutOverviewRoute
  "/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/new": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdOfferNewRoute
  "/dashboard/project/$projectId/ehs": typeof ProtectedDashboardProjectProjectIdLayoutEhsIndexRoute
  "/dashboard/project/$projectId/economy/budget": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyBudgetRoute
  "/dashboard/project/$projectId/economy/change-order": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyChangeOrderRoute
  "/dashboard/project/$projectId/economy/contract": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyContractRoute
  "/dashboard/project/$projectId/economy/invoice": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyInvoiceRoute
  "/dashboard/project/$projectId/economy/subcontractor": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomySubcontractorRoute
  "/dashboard/project/$projectId/economy/time": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyTimeRoute
  "/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/$offerId/update": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdOfferOfferIdUpdateRoute
  "/dashboard/project/$projectId/economy": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  "/_protected": typeof ProtectedRouteWithChildren
  "/_unauthenticated": typeof UnauthenticatedRouteWithChildren
  "/_unprotected": typeof UnprotectedRouteWithChildren
  "/_protected/create-company": typeof ProtectedCreateCompanyRoute
  "/_protected/dashboard": typeof ProtectedDashboardRouteWithChildren
  "/_protected/invitations": typeof ProtectedInvitationsRoute
  "/_protected/trial": typeof ProtectedTrialRouteWithChildren
  "/_protected/user-info": typeof ProtectedUserInfoRoute
  "/_protected/welcome": typeof ProtectedWelcomeRoute
  "/_unauthenticated/create-account": typeof UnauthenticatedCreateAccountRoute
  "/_unauthenticated/login": typeof UnauthenticatedLoginRoute
  "/_unauthenticated/reset-password": typeof UnauthenticatedResetPasswordRoute
  "/_unauthenticated/": typeof UnauthenticatedIndexRoute
  "/_protected/trial/confirmation": typeof ProtectedTrialConfirmationRoute
  "/_protected/trial/expired": typeof ProtectedTrialExpiredRoute
  "/_protected/trial/subscribe": typeof ProtectedTrialSubscribeRoute
  "/guest/order/$orderId": typeof GuestOrderOrderIdRoute
  "/guest/project/$projectId": typeof GuestProjectProjectIdRoute
  "/guest/$customerId/": typeof GuestCustomerIdIndexRoute
  "/_protected/dashboard/checklist/new": typeof ProtectedDashboardChecklistNewRoute
  "/_protected/dashboard/customers": typeof ProtectedDashboardCustomersRouteWithChildren
  "/_protected/dashboard/customers/_layout": typeof ProtectedDashboardCustomersLayoutRouteWithChildren
  "/_protected/dashboard/customers/new": typeof ProtectedDashboardCustomersNewRoute
  "/_protected/dashboard/deviation/new": typeof ProtectedDashboardDeviationNewRoute
  "/_protected/dashboard/economy": typeof ProtectedDashboardEconomyRouteWithChildren
  "/_protected/dashboard/economy/_layout": typeof ProtectedDashboardEconomyLayoutRouteWithChildren
  "/_protected/dashboard/equipment/new": typeof ProtectedDashboardEquipmentNewRoute
  "/_protected/dashboard/facility/new": typeof ProtectedDashboardFacilityNewRoute
  "/_protected/dashboard/internal-routines/new": typeof ProtectedDashboardInternalRoutinesNewRoute
  "/_protected/dashboard/project": typeof ProtectedDashboardProjectRouteWithChildren
  "/_protected/dashboard/project/_layout": typeof ProtectedDashboardProjectLayoutRouteWithChildren
  "/_protected/dashboard/project/new": typeof ProtectedDashboardProjectNewRoute
  "/_protected/dashboard/sales": typeof ProtectedDashboardSalesRouteWithChildren
  "/_protected/dashboard/sales/_layout": typeof ProtectedDashboardSalesLayoutRouteWithChildren
  "/_protected/dashboard/service-contract/new": typeof ProtectedDashboardServiceContractNewRoute
  "/_protected/dashboard/settings": typeof ProtectedDashboardSettingsRouteWithChildren
  "/_protected/dashboard/settings/_layout": typeof ProtectedDashboardSettingsLayoutRouteWithChildren
  "/_protected/dashboard/ehs/": typeof ProtectedDashboardEhsIndexRoute
  "/_protected/dashboard/employees/": typeof ProtectedDashboardEmployeesIndexRoute
  "/_protected/dashboard/orders/": typeof ProtectedDashboardOrdersIndexRoute
  "/_protected/dashboard/overview/": typeof ProtectedDashboardOverviewIndexRoute
  "/_protected/dashboard/products/": typeof ProtectedDashboardProductsIndexRoute
  "/_protected/dashboard/checklist/$checklistId/update": typeof ProtectedDashboardChecklistChecklistIdUpdateRoute
  "/_protected/dashboard/customers/$customerId/edit": typeof ProtectedDashboardCustomersCustomerIdEditRoute
  "/_protected/dashboard/customers/_layout/customers": typeof ProtectedDashboardCustomersLayoutCustomersRoute
  "/_protected/dashboard/deviation/$deviationId/update": typeof ProtectedDashboardDeviationDeviationIdUpdateRoute
  "/_protected/dashboard/economy/_layout/activity": typeof ProtectedDashboardEconomyLayoutActivityRoute
  "/_protected/dashboard/economy/_layout/invoice": typeof ProtectedDashboardEconomyLayoutInvoiceRoute
  "/_protected/dashboard/economy/_layout/price-model": typeof ProtectedDashboardEconomyLayoutPriceModelRoute
  "/_protected/dashboard/economy/_layout/time-list": typeof ProtectedDashboardEconomyLayoutTimeListRoute
  "/_protected/dashboard/economy/_layout/time-report": typeof ProtectedDashboardEconomyLayoutTimeReportRoute
  "/_protected/dashboard/economy/invoice/new": typeof ProtectedDashboardEconomyInvoiceNewRoute
  "/_protected/dashboard/ehs/$ehsId/update": typeof ProtectedDashboardEhsEhsIdUpdateRoute
  "/_protected/dashboard/ehs/$templateId/new": typeof ProtectedDashboardEhsTemplateIdNewRoute
  "/_protected/dashboard/ehs/template/new": typeof ProtectedDashboardEhsTemplateNewRoute
  "/_protected/dashboard/equipment/$equipmentId/update": typeof ProtectedDashboardEquipmentEquipmentIdUpdateRoute
  "/_protected/dashboard/facility/$facilityId/details": typeof ProtectedDashboardFacilityFacilityIdDetailsRoute
  "/_protected/dashboard/facility/$facilityId/update": typeof ProtectedDashboardFacilityFacilityIdUpdateRoute
  "/_protected/dashboard/internal-routines/$routineId/update": typeof ProtectedDashboardInternalRoutinesRoutineIdUpdateRoute
  "/_protected/dashboard/location/$resourceId/new": typeof ProtectedDashboardLocationResourceIdNewRoute
  "/_protected/dashboard/location/$resourceId/update": typeof ProtectedDashboardLocationResourceIdUpdateRoute
  "/_protected/dashboard/orders/$orderId": typeof ProtectedDashboardOrdersOrderIdRouteWithChildren
  "/_protected/dashboard/orders/$orderId/_layout": typeof ProtectedDashboardOrdersOrderIdLayoutRouteWithChildren
  "/_protected/dashboard/orders/$orderId/update": typeof ProtectedDashboardOrdersOrderIdUpdateRoute
  "/_protected/dashboard/products/$productId/update": typeof ProtectedDashboardProductsProductIdUpdateRoute
  "/_protected/dashboard/products/estimations/new": typeof ProtectedDashboardProductsEstimationsNewRoute
  "/_protected/dashboard/project/$projectId": typeof ProtectedDashboardProjectProjectIdRouteWithChildren
  "/_protected/dashboard/project/$projectId/_layout": typeof ProtectedDashboardProjectProjectIdLayoutRouteWithChildren
  "/_protected/dashboard/project/$projectId/update": typeof ProtectedDashboardProjectProjectIdUpdateRoute
  "/_protected/dashboard/project/_layout/deviation-report": typeof ProtectedDashboardProjectLayoutDeviationReportRoute
  "/_protected/dashboard/project/_layout/overview": typeof ProtectedDashboardProjectLayoutOverviewRoute
  "/_protected/dashboard/project/_layout/progress-plan": typeof ProtectedDashboardProjectLayoutProgressPlanRoute
  "/_protected/dashboard/project/_layout/task": typeof ProtectedDashboardProjectLayoutTaskRoute
  "/_protected/dashboard/resources/$employeeId/update": typeof ProtectedDashboardResourcesEmployeeIdUpdateRoute
  "/_protected/dashboard/sales/_layout/direct-sale": typeof ProtectedDashboardSalesLayoutDirectSaleRoute
  "/_protected/dashboard/sales/_layout/offer": typeof ProtectedDashboardSalesLayoutOfferRoute
  "/_protected/dashboard/sales/_layout/report": typeof ProtectedDashboardSalesLayoutReportRoute
  "/_protected/dashboard/sales/_layout/sales-opportunity": typeof ProtectedDashboardSalesLayoutSalesOpportunityRoute
  "/_protected/dashboard/sales/direct-sale/new": typeof ProtectedDashboardSalesDirectSaleNewRoute
  "/_protected/dashboard/sales/offer/new": typeof ProtectedDashboardSalesOfferNewRoute
  "/_protected/dashboard/sales/offer/preview": typeof ProtectedDashboardSalesOfferPreviewRoute
  "/_protected/dashboard/sales/sales-opportunity/new": typeof ProtectedDashboardSalesSalesOpportunityNewRoute
  "/_protected/dashboard/service-contract/$serviceContractId/update": typeof ProtectedDashboardServiceContractServiceContractIdUpdateRoute
  "/_protected/dashboard/settings/_layout/checklist-template": typeof ProtectedDashboardSettingsLayoutChecklistTemplateRoute
  "/_protected/dashboard/settings/_layout/company": typeof ProtectedDashboardSettingsLayoutCompanyRoute
  "/_protected/dashboard/settings/_layout/documentation-template": typeof ProtectedDashboardSettingsLayoutDocumentationTemplateRoute
  "/_protected/dashboard/settings/_layout/equipment": typeof ProtectedDashboardSettingsLayoutEquipmentRoute
  "/_protected/dashboard/settings/_layout/integration": typeof ProtectedDashboardSettingsLayoutIntegrationRoute
  "/_protected/dashboard/settings/_layout/order": typeof ProtectedDashboardSettingsLayoutOrderRoute
  "/_protected/dashboard/settings/_layout/project": typeof ProtectedDashboardSettingsLayoutProjectRoute
  "/_protected/dashboard/settings/_layout/task-template": typeof ProtectedDashboardSettingsLayoutTaskTemplateRoute
  "/_protected/dashboard/settings/_layout/video-guide": typeof ProtectedDashboardSettingsLayoutVideoGuideRoute
  "/_protected/dashboard/settings/project-phases/edit": typeof ProtectedDashboardSettingsProjectPhasesEditRoute
  "/_protected/dashboard/customers/$customerId/": typeof ProtectedDashboardCustomersCustomerIdIndexRoute
  "/_protected/dashboard/orders/new/": typeof ProtectedDashboardOrdersNewIndexRoute
  "/_protected/dashboard/products/new/": typeof ProtectedDashboardProductsNewIndexRoute
  "/_protected/dashboard/sales/new/": typeof ProtectedDashboardSalesNewIndexRoute
  "/_unprotected/offer/$pathUri/details/": typeof UnprotectedOfferPathUriDetailsIndexRoute
  "/_protected/dashboard/customers/$customerId/order/new": typeof ProtectedDashboardCustomersCustomerIdOrderNewRoute
  "/_protected/dashboard/customers/$customerId/project/new": typeof ProtectedDashboardCustomersCustomerIdProjectNewRoute
  "/_protected/dashboard/ehs/template/$templateId/update": typeof ProtectedDashboardEhsTemplateTemplateIdUpdateRoute
  "/_protected/dashboard/orders/$orderId/_layout/checklist": typeof ProtectedDashboardOrdersOrderIdLayoutChecklistRoute
  "/_protected/dashboard/orders/$orderId/_layout/deviation": typeof ProtectedDashboardOrdersOrderIdLayoutDeviationRoute
  "/_protected/dashboard/orders/$orderId/_layout/documentation": typeof ProtectedDashboardOrdersOrderIdLayoutDocumentationRoute
  "/_protected/dashboard/orders/$orderId/_layout/overview": typeof ProtectedDashboardOrdersOrderIdLayoutOverviewRoute
  "/_protected/dashboard/orders/$orderId/_layout/product": typeof ProtectedDashboardOrdersOrderIdLayoutProductRoute
  "/_protected/dashboard/orders/$orderId/products/add": typeof ProtectedDashboardOrdersOrderIdProductsAddRoute
  "/_protected/dashboard/orders/template/$templateId/product": typeof ProtectedDashboardOrdersTemplateTemplateIdProductRoute
  "/_protected/dashboard/orders/template/$templateId/update": typeof ProtectedDashboardOrdersTemplateTemplateIdUpdateRoute
  "/_protected/dashboard/products/estimations/$estimationId/update": typeof ProtectedDashboardProductsEstimationsEstimationIdUpdateRoute
  "/_protected/dashboard/project/$projectId/_layout/_layout": typeof ProtectedDashboardProjectProjectIdLayoutLayoutRouteWithChildren
  "/_protected/dashboard/project/$projectId/_layout/checklist": typeof ProtectedDashboardProjectProjectIdLayoutChecklistRoute
  "/_protected/dashboard/project/$projectId/_layout/deviation": typeof ProtectedDashboardProjectProjectIdLayoutDeviationRoute
  "/_protected/dashboard/project/$projectId/_layout/documentation": typeof ProtectedDashboardProjectProjectIdLayoutDocumentationRoute
  "/_protected/dashboard/project/$projectId/_layout/overview": typeof ProtectedDashboardProjectProjectIdLayoutOverviewRoute
  "/_protected/dashboard/project/$projectId/_layout/overview-old": typeof ProtectedDashboardProjectProjectIdLayoutOverviewOldRoute
  "/_protected/dashboard/project/$projectId/_layout/product": typeof ProtectedDashboardProjectProjectIdLayoutProductRoute
  "/_protected/dashboard/project/$projectId/_layout/progress-plan": typeof ProtectedDashboardProjectProjectIdLayoutProgressPlanRoute
  "/_protected/dashboard/project/$projectId/_layout/sub-project": typeof ProtectedDashboardProjectProjectIdLayoutSubProjectRoute
  "/_protected/dashboard/project/$projectId/_layout/task": typeof ProtectedDashboardProjectProjectIdLayoutTaskRoute
  "/_protected/dashboard/project/$projectId/offer/new": typeof ProtectedDashboardProjectProjectIdOfferNewRoute
  "/_protected/dashboard/project/$projectId/products/add": typeof ProtectedDashboardProjectProjectIdProductsAddRoute
  "/_protected/dashboard/project/$projectId/task/$taskId": typeof ProtectedDashboardProjectProjectIdTaskTaskIdRoute
  "/_protected/dashboard/sales/inspection/$customerId/new": typeof ProtectedDashboardSalesInspectionCustomerIdNewRoute
  "/_protected/dashboard/sales/inspection/$inspectionId/update": typeof ProtectedDashboardSalesInspectionInspectionIdUpdateRoute
  "/_protected/dashboard/sales/offer/$offerId/additional-work": typeof ProtectedDashboardSalesOfferOfferIdAdditionalWorkRoute
  "/_protected/dashboard/sales/offer/$offerId/update": typeof ProtectedDashboardSalesOfferOfferIdUpdateRoute
  "/_protected/dashboard/sales/offer/$pathUri/preview": typeof ProtectedDashboardSalesOfferPathUriPreviewRoute
  "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdRouteWithChildren
  "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutRouteWithChildren
  "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/update": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdUpdateRoute
  "/_protected/dashboard/customers/_layout/inbox/": typeof ProtectedDashboardCustomersLayoutInboxIndexRoute
  "/_protected/dashboard/orders/template/$templateId/": typeof ProtectedDashboardOrdersTemplateTemplateIdIndexRoute
  "/_protected/dashboard/project/$projectId/phases/": typeof ProtectedDashboardProjectProjectIdPhasesIndexRoute
  "/_protected/dashboard/project/$projectId/workers/": typeof ProtectedDashboardProjectProjectIdWorkersIndexRoute
  "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/documentation": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutDocumentationRoute
  "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/inspection": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutInspectionRoute
  "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/offer": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutOfferRoute
  "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/overview": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdLayoutOverviewRoute
  "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/new": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdOfferNewRoute
  "/_protected/dashboard/project/$projectId/_layout/ehs/": typeof ProtectedDashboardProjectProjectIdLayoutEhsIndexRoute
  "/_protected/dashboard/project/$projectId/_layout/_layout/economy/budget": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyBudgetRoute
  "/_protected/dashboard/project/$projectId/_layout/_layout/economy/change-order": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyChangeOrderRoute
  "/_protected/dashboard/project/$projectId/_layout/_layout/economy/contract": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyContractRoute
  "/_protected/dashboard/project/$projectId/_layout/_layout/economy/invoice": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyInvoiceRoute
  "/_protected/dashboard/project/$projectId/_layout/_layout/economy/subcontractor": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomySubcontractorRoute
  "/_protected/dashboard/project/$projectId/_layout/_layout/economy/time": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyTimeRoute
  "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/$offerId/update": typeof ProtectedDashboardSalesSalesOpportunitySalesOpportunityIdOfferOfferIdUpdateRoute
  "/_protected/dashboard/project/$projectId/_layout/_layout/economy/": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ""
    | "/create-company"
    | "/dashboard"
    | "/invitations"
    | "/trial"
    | "/user-info"
    | "/welcome"
    | "/create-account"
    | "/login"
    | "/reset-password"
    | "/"
    | "/trial/confirmation"
    | "/trial/expired"
    | "/trial/subscribe"
    | "/guest/order/$orderId"
    | "/guest/project/$projectId"
    | "/guest/$customerId"
    | "/dashboard/checklist/new"
    | "/dashboard/customers"
    | "/dashboard/customers/new"
    | "/dashboard/deviation/new"
    | "/dashboard/economy"
    | "/dashboard/equipment/new"
    | "/dashboard/facility/new"
    | "/dashboard/internal-routines/new"
    | "/dashboard/project"
    | "/dashboard/project/new"
    | "/dashboard/sales"
    | "/dashboard/service-contract/new"
    | "/dashboard/settings"
    | "/dashboard/ehs"
    | "/dashboard/employees"
    | "/dashboard/orders"
    | "/dashboard/overview"
    | "/dashboard/products"
    | "/dashboard/checklist/$checklistId/update"
    | "/dashboard/customers/$customerId/edit"
    | "/dashboard/customers/customers"
    | "/dashboard/deviation/$deviationId/update"
    | "/dashboard/economy/activity"
    | "/dashboard/economy/invoice"
    | "/dashboard/economy/price-model"
    | "/dashboard/economy/time-list"
    | "/dashboard/economy/time-report"
    | "/dashboard/economy/invoice/new"
    | "/dashboard/ehs/$ehsId/update"
    | "/dashboard/ehs/$templateId/new"
    | "/dashboard/ehs/template/new"
    | "/dashboard/equipment/$equipmentId/update"
    | "/dashboard/facility/$facilityId/details"
    | "/dashboard/facility/$facilityId/update"
    | "/dashboard/internal-routines/$routineId/update"
    | "/dashboard/location/$resourceId/new"
    | "/dashboard/location/$resourceId/update"
    | "/dashboard/orders/$orderId"
    | "/dashboard/orders/$orderId/update"
    | "/dashboard/products/$productId/update"
    | "/dashboard/products/estimations/new"
    | "/dashboard/project/$projectId"
    | "/dashboard/project/$projectId/update"
    | "/dashboard/project/deviation-report"
    | "/dashboard/project/overview"
    | "/dashboard/project/progress-plan"
    | "/dashboard/project/task"
    | "/dashboard/resources/$employeeId/update"
    | "/dashboard/sales/direct-sale"
    | "/dashboard/sales/offer"
    | "/dashboard/sales/report"
    | "/dashboard/sales/sales-opportunity"
    | "/dashboard/sales/direct-sale/new"
    | "/dashboard/sales/offer/new"
    | "/dashboard/sales/offer/preview"
    | "/dashboard/sales/sales-opportunity/new"
    | "/dashboard/service-contract/$serviceContractId/update"
    | "/dashboard/settings/checklist-template"
    | "/dashboard/settings/company"
    | "/dashboard/settings/documentation-template"
    | "/dashboard/settings/equipment"
    | "/dashboard/settings/integration"
    | "/dashboard/settings/order"
    | "/dashboard/settings/project"
    | "/dashboard/settings/task-template"
    | "/dashboard/settings/video-guide"
    | "/dashboard/settings/project-phases/edit"
    | "/dashboard/customers/$customerId"
    | "/dashboard/orders/new"
    | "/dashboard/products/new"
    | "/dashboard/sales/new"
    | "/offer/$pathUri/details"
    | "/dashboard/customers/$customerId/order/new"
    | "/dashboard/customers/$customerId/project/new"
    | "/dashboard/ehs/template/$templateId/update"
    | "/dashboard/orders/$orderId/checklist"
    | "/dashboard/orders/$orderId/deviation"
    | "/dashboard/orders/$orderId/documentation"
    | "/dashboard/orders/$orderId/overview"
    | "/dashboard/orders/$orderId/product"
    | "/dashboard/orders/$orderId/products/add"
    | "/dashboard/orders/template/$templateId/product"
    | "/dashboard/orders/template/$templateId/update"
    | "/dashboard/products/estimations/$estimationId/update"
    | "/dashboard/project/$projectId/checklist"
    | "/dashboard/project/$projectId/deviation"
    | "/dashboard/project/$projectId/documentation"
    | "/dashboard/project/$projectId/overview"
    | "/dashboard/project/$projectId/overview-old"
    | "/dashboard/project/$projectId/product"
    | "/dashboard/project/$projectId/progress-plan"
    | "/dashboard/project/$projectId/sub-project"
    | "/dashboard/project/$projectId/task"
    | "/dashboard/project/$projectId/offer/new"
    | "/dashboard/project/$projectId/products/add"
    | "/dashboard/project/$projectId/task/$taskId"
    | "/dashboard/sales/inspection/$customerId/new"
    | "/dashboard/sales/inspection/$inspectionId/update"
    | "/dashboard/sales/offer/$offerId/additional-work"
    | "/dashboard/sales/offer/$offerId/update"
    | "/dashboard/sales/offer/$pathUri/preview"
    | "/dashboard/sales/sales-opportunity/$salesOpportunityId"
    | "/dashboard/sales/sales-opportunity/$salesOpportunityId/update"
    | "/dashboard/customers/inbox"
    | "/dashboard/orders/template/$templateId"
    | "/dashboard/project/$projectId/phases"
    | "/dashboard/project/$projectId/workers"
    | "/dashboard/sales/sales-opportunity/$salesOpportunityId/documentation"
    | "/dashboard/sales/sales-opportunity/$salesOpportunityId/inspection"
    | "/dashboard/sales/sales-opportunity/$salesOpportunityId/offer"
    | "/dashboard/sales/sales-opportunity/$salesOpportunityId/overview"
    | "/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/new"
    | "/dashboard/project/$projectId/ehs"
    | "/dashboard/project/$projectId/economy/budget"
    | "/dashboard/project/$projectId/economy/change-order"
    | "/dashboard/project/$projectId/economy/contract"
    | "/dashboard/project/$projectId/economy/invoice"
    | "/dashboard/project/$projectId/economy/subcontractor"
    | "/dashboard/project/$projectId/economy/time"
    | "/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/$offerId/update"
    | "/dashboard/project/$projectId/economy"
  fileRoutesByTo: FileRoutesByTo
  to:
    | ""
    | "/create-company"
    | "/dashboard"
    | "/invitations"
    | "/trial"
    | "/user-info"
    | "/welcome"
    | "/create-account"
    | "/login"
    | "/reset-password"
    | "/"
    | "/trial/confirmation"
    | "/trial/expired"
    | "/trial/subscribe"
    | "/guest/order/$orderId"
    | "/guest/project/$projectId"
    | "/guest/$customerId"
    | "/dashboard/checklist/new"
    | "/dashboard/customers"
    | "/dashboard/customers/new"
    | "/dashboard/deviation/new"
    | "/dashboard/economy"
    | "/dashboard/equipment/new"
    | "/dashboard/facility/new"
    | "/dashboard/internal-routines/new"
    | "/dashboard/project"
    | "/dashboard/project/new"
    | "/dashboard/sales"
    | "/dashboard/service-contract/new"
    | "/dashboard/settings"
    | "/dashboard/ehs"
    | "/dashboard/employees"
    | "/dashboard/orders"
    | "/dashboard/overview"
    | "/dashboard/products"
    | "/dashboard/checklist/$checklistId/update"
    | "/dashboard/customers/$customerId/edit"
    | "/dashboard/customers/customers"
    | "/dashboard/deviation/$deviationId/update"
    | "/dashboard/economy/activity"
    | "/dashboard/economy/invoice"
    | "/dashboard/economy/price-model"
    | "/dashboard/economy/time-list"
    | "/dashboard/economy/time-report"
    | "/dashboard/economy/invoice/new"
    | "/dashboard/ehs/$ehsId/update"
    | "/dashboard/ehs/$templateId/new"
    | "/dashboard/ehs/template/new"
    | "/dashboard/equipment/$equipmentId/update"
    | "/dashboard/facility/$facilityId/details"
    | "/dashboard/facility/$facilityId/update"
    | "/dashboard/internal-routines/$routineId/update"
    | "/dashboard/location/$resourceId/new"
    | "/dashboard/location/$resourceId/update"
    | "/dashboard/orders/$orderId"
    | "/dashboard/orders/$orderId/update"
    | "/dashboard/products/$productId/update"
    | "/dashboard/products/estimations/new"
    | "/dashboard/project/$projectId"
    | "/dashboard/project/$projectId/update"
    | "/dashboard/project/deviation-report"
    | "/dashboard/project/overview"
    | "/dashboard/project/progress-plan"
    | "/dashboard/project/task"
    | "/dashboard/resources/$employeeId/update"
    | "/dashboard/sales/direct-sale"
    | "/dashboard/sales/offer"
    | "/dashboard/sales/report"
    | "/dashboard/sales/sales-opportunity"
    | "/dashboard/sales/direct-sale/new"
    | "/dashboard/sales/offer/new"
    | "/dashboard/sales/offer/preview"
    | "/dashboard/sales/sales-opportunity/new"
    | "/dashboard/service-contract/$serviceContractId/update"
    | "/dashboard/settings/checklist-template"
    | "/dashboard/settings/company"
    | "/dashboard/settings/documentation-template"
    | "/dashboard/settings/equipment"
    | "/dashboard/settings/integration"
    | "/dashboard/settings/order"
    | "/dashboard/settings/project"
    | "/dashboard/settings/task-template"
    | "/dashboard/settings/video-guide"
    | "/dashboard/settings/project-phases/edit"
    | "/dashboard/customers/$customerId"
    | "/dashboard/orders/new"
    | "/dashboard/products/new"
    | "/dashboard/sales/new"
    | "/offer/$pathUri/details"
    | "/dashboard/customers/$customerId/order/new"
    | "/dashboard/customers/$customerId/project/new"
    | "/dashboard/ehs/template/$templateId/update"
    | "/dashboard/orders/$orderId/checklist"
    | "/dashboard/orders/$orderId/deviation"
    | "/dashboard/orders/$orderId/documentation"
    | "/dashboard/orders/$orderId/overview"
    | "/dashboard/orders/$orderId/product"
    | "/dashboard/orders/$orderId/products/add"
    | "/dashboard/orders/template/$templateId/product"
    | "/dashboard/orders/template/$templateId/update"
    | "/dashboard/products/estimations/$estimationId/update"
    | "/dashboard/project/$projectId/checklist"
    | "/dashboard/project/$projectId/deviation"
    | "/dashboard/project/$projectId/documentation"
    | "/dashboard/project/$projectId/overview"
    | "/dashboard/project/$projectId/overview-old"
    | "/dashboard/project/$projectId/product"
    | "/dashboard/project/$projectId/progress-plan"
    | "/dashboard/project/$projectId/sub-project"
    | "/dashboard/project/$projectId/task"
    | "/dashboard/project/$projectId/offer/new"
    | "/dashboard/project/$projectId/products/add"
    | "/dashboard/project/$projectId/task/$taskId"
    | "/dashboard/sales/inspection/$customerId/new"
    | "/dashboard/sales/inspection/$inspectionId/update"
    | "/dashboard/sales/offer/$offerId/additional-work"
    | "/dashboard/sales/offer/$offerId/update"
    | "/dashboard/sales/offer/$pathUri/preview"
    | "/dashboard/sales/sales-opportunity/$salesOpportunityId"
    | "/dashboard/sales/sales-opportunity/$salesOpportunityId/update"
    | "/dashboard/customers/inbox"
    | "/dashboard/orders/template/$templateId"
    | "/dashboard/project/$projectId/phases"
    | "/dashboard/project/$projectId/workers"
    | "/dashboard/sales/sales-opportunity/$salesOpportunityId/documentation"
    | "/dashboard/sales/sales-opportunity/$salesOpportunityId/inspection"
    | "/dashboard/sales/sales-opportunity/$salesOpportunityId/offer"
    | "/dashboard/sales/sales-opportunity/$salesOpportunityId/overview"
    | "/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/new"
    | "/dashboard/project/$projectId/ehs"
    | "/dashboard/project/$projectId/economy/budget"
    | "/dashboard/project/$projectId/economy/change-order"
    | "/dashboard/project/$projectId/economy/contract"
    | "/dashboard/project/$projectId/economy/invoice"
    | "/dashboard/project/$projectId/economy/subcontractor"
    | "/dashboard/project/$projectId/economy/time"
    | "/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/$offerId/update"
    | "/dashboard/project/$projectId/economy"
  id:
    | "__root__"
    | "/_protected"
    | "/_unauthenticated"
    | "/_unprotected"
    | "/_protected/create-company"
    | "/_protected/dashboard"
    | "/_protected/invitations"
    | "/_protected/trial"
    | "/_protected/user-info"
    | "/_protected/welcome"
    | "/_unauthenticated/create-account"
    | "/_unauthenticated/login"
    | "/_unauthenticated/reset-password"
    | "/_unauthenticated/"
    | "/_protected/trial/confirmation"
    | "/_protected/trial/expired"
    | "/_protected/trial/subscribe"
    | "/guest/order/$orderId"
    | "/guest/project/$projectId"
    | "/guest/$customerId/"
    | "/_protected/dashboard/checklist/new"
    | "/_protected/dashboard/customers"
    | "/_protected/dashboard/customers/_layout"
    | "/_protected/dashboard/customers/new"
    | "/_protected/dashboard/deviation/new"
    | "/_protected/dashboard/economy"
    | "/_protected/dashboard/economy/_layout"
    | "/_protected/dashboard/equipment/new"
    | "/_protected/dashboard/facility/new"
    | "/_protected/dashboard/internal-routines/new"
    | "/_protected/dashboard/project"
    | "/_protected/dashboard/project/_layout"
    | "/_protected/dashboard/project/new"
    | "/_protected/dashboard/sales"
    | "/_protected/dashboard/sales/_layout"
    | "/_protected/dashboard/service-contract/new"
    | "/_protected/dashboard/settings"
    | "/_protected/dashboard/settings/_layout"
    | "/_protected/dashboard/ehs/"
    | "/_protected/dashboard/employees/"
    | "/_protected/dashboard/orders/"
    | "/_protected/dashboard/overview/"
    | "/_protected/dashboard/products/"
    | "/_protected/dashboard/checklist/$checklistId/update"
    | "/_protected/dashboard/customers/$customerId/edit"
    | "/_protected/dashboard/customers/_layout/customers"
    | "/_protected/dashboard/deviation/$deviationId/update"
    | "/_protected/dashboard/economy/_layout/activity"
    | "/_protected/dashboard/economy/_layout/invoice"
    | "/_protected/dashboard/economy/_layout/price-model"
    | "/_protected/dashboard/economy/_layout/time-list"
    | "/_protected/dashboard/economy/_layout/time-report"
    | "/_protected/dashboard/economy/invoice/new"
    | "/_protected/dashboard/ehs/$ehsId/update"
    | "/_protected/dashboard/ehs/$templateId/new"
    | "/_protected/dashboard/ehs/template/new"
    | "/_protected/dashboard/equipment/$equipmentId/update"
    | "/_protected/dashboard/facility/$facilityId/details"
    | "/_protected/dashboard/facility/$facilityId/update"
    | "/_protected/dashboard/internal-routines/$routineId/update"
    | "/_protected/dashboard/location/$resourceId/new"
    | "/_protected/dashboard/location/$resourceId/update"
    | "/_protected/dashboard/orders/$orderId"
    | "/_protected/dashboard/orders/$orderId/_layout"
    | "/_protected/dashboard/orders/$orderId/update"
    | "/_protected/dashboard/products/$productId/update"
    | "/_protected/dashboard/products/estimations/new"
    | "/_protected/dashboard/project/$projectId"
    | "/_protected/dashboard/project/$projectId/_layout"
    | "/_protected/dashboard/project/$projectId/update"
    | "/_protected/dashboard/project/_layout/deviation-report"
    | "/_protected/dashboard/project/_layout/overview"
    | "/_protected/dashboard/project/_layout/progress-plan"
    | "/_protected/dashboard/project/_layout/task"
    | "/_protected/dashboard/resources/$employeeId/update"
    | "/_protected/dashboard/sales/_layout/direct-sale"
    | "/_protected/dashboard/sales/_layout/offer"
    | "/_protected/dashboard/sales/_layout/report"
    | "/_protected/dashboard/sales/_layout/sales-opportunity"
    | "/_protected/dashboard/sales/direct-sale/new"
    | "/_protected/dashboard/sales/offer/new"
    | "/_protected/dashboard/sales/offer/preview"
    | "/_protected/dashboard/sales/sales-opportunity/new"
    | "/_protected/dashboard/service-contract/$serviceContractId/update"
    | "/_protected/dashboard/settings/_layout/checklist-template"
    | "/_protected/dashboard/settings/_layout/company"
    | "/_protected/dashboard/settings/_layout/documentation-template"
    | "/_protected/dashboard/settings/_layout/equipment"
    | "/_protected/dashboard/settings/_layout/integration"
    | "/_protected/dashboard/settings/_layout/order"
    | "/_protected/dashboard/settings/_layout/project"
    | "/_protected/dashboard/settings/_layout/task-template"
    | "/_protected/dashboard/settings/_layout/video-guide"
    | "/_protected/dashboard/settings/project-phases/edit"
    | "/_protected/dashboard/customers/$customerId/"
    | "/_protected/dashboard/orders/new/"
    | "/_protected/dashboard/products/new/"
    | "/_protected/dashboard/sales/new/"
    | "/_unprotected/offer/$pathUri/details/"
    | "/_protected/dashboard/customers/$customerId/order/new"
    | "/_protected/dashboard/customers/$customerId/project/new"
    | "/_protected/dashboard/ehs/template/$templateId/update"
    | "/_protected/dashboard/orders/$orderId/_layout/checklist"
    | "/_protected/dashboard/orders/$orderId/_layout/deviation"
    | "/_protected/dashboard/orders/$orderId/_layout/documentation"
    | "/_protected/dashboard/orders/$orderId/_layout/overview"
    | "/_protected/dashboard/orders/$orderId/_layout/product"
    | "/_protected/dashboard/orders/$orderId/products/add"
    | "/_protected/dashboard/orders/template/$templateId/product"
    | "/_protected/dashboard/orders/template/$templateId/update"
    | "/_protected/dashboard/products/estimations/$estimationId/update"
    | "/_protected/dashboard/project/$projectId/_layout/_layout"
    | "/_protected/dashboard/project/$projectId/_layout/checklist"
    | "/_protected/dashboard/project/$projectId/_layout/deviation"
    | "/_protected/dashboard/project/$projectId/_layout/documentation"
    | "/_protected/dashboard/project/$projectId/_layout/overview"
    | "/_protected/dashboard/project/$projectId/_layout/overview-old"
    | "/_protected/dashboard/project/$projectId/_layout/product"
    | "/_protected/dashboard/project/$projectId/_layout/progress-plan"
    | "/_protected/dashboard/project/$projectId/_layout/sub-project"
    | "/_protected/dashboard/project/$projectId/_layout/task"
    | "/_protected/dashboard/project/$projectId/offer/new"
    | "/_protected/dashboard/project/$projectId/products/add"
    | "/_protected/dashboard/project/$projectId/task/$taskId"
    | "/_protected/dashboard/sales/inspection/$customerId/new"
    | "/_protected/dashboard/sales/inspection/$inspectionId/update"
    | "/_protected/dashboard/sales/offer/$offerId/additional-work"
    | "/_protected/dashboard/sales/offer/$offerId/update"
    | "/_protected/dashboard/sales/offer/$pathUri/preview"
    | "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId"
    | "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout"
    | "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/update"
    | "/_protected/dashboard/customers/_layout/inbox/"
    | "/_protected/dashboard/orders/template/$templateId/"
    | "/_protected/dashboard/project/$projectId/phases/"
    | "/_protected/dashboard/project/$projectId/workers/"
    | "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/documentation"
    | "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/inspection"
    | "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/offer"
    | "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/overview"
    | "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/new"
    | "/_protected/dashboard/project/$projectId/_layout/ehs/"
    | "/_protected/dashboard/project/$projectId/_layout/_layout/economy/budget"
    | "/_protected/dashboard/project/$projectId/_layout/_layout/economy/change-order"
    | "/_protected/dashboard/project/$projectId/_layout/_layout/economy/contract"
    | "/_protected/dashboard/project/$projectId/_layout/_layout/economy/invoice"
    | "/_protected/dashboard/project/$projectId/_layout/_layout/economy/subcontractor"
    | "/_protected/dashboard/project/$projectId/_layout/_layout/economy/time"
    | "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/$offerId/update"
    | "/_protected/dashboard/project/$projectId/_layout/_layout/economy/"
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  ProtectedRoute: typeof ProtectedRouteWithChildren
  UnauthenticatedRoute: typeof UnauthenticatedRouteWithChildren
  UnprotectedRoute: typeof UnprotectedRouteWithChildren
  GuestOrderOrderIdRoute: typeof GuestOrderOrderIdRoute
  GuestProjectProjectIdRoute: typeof GuestProjectProjectIdRoute
  GuestCustomerIdIndexRoute: typeof GuestCustomerIdIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  ProtectedRoute: ProtectedRouteWithChildren,
  UnauthenticatedRoute: UnauthenticatedRouteWithChildren,
  UnprotectedRoute: UnprotectedRouteWithChildren,
  GuestOrderOrderIdRoute: GuestOrderOrderIdRoute,
  GuestProjectProjectIdRoute: GuestProjectProjectIdRoute,
  GuestCustomerIdIndexRoute: GuestCustomerIdIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_protected",
        "/_unauthenticated",
        "/_unprotected",
        "/guest/order/$orderId",
        "/guest/project/$projectId",
        "/guest/$customerId/"
      ]
    },
    "/_protected": {
      "filePath": "_protected.tsx",
      "children": [
        "/_protected/create-company",
        "/_protected/dashboard",
        "/_protected/invitations",
        "/_protected/trial",
        "/_protected/user-info",
        "/_protected/welcome"
      ]
    },
    "/_unauthenticated": {
      "filePath": "_unauthenticated.tsx",
      "children": [
        "/_unauthenticated/create-account",
        "/_unauthenticated/login",
        "/_unauthenticated/reset-password",
        "/_unauthenticated/"
      ]
    },
    "/_unprotected": {
      "filePath": "_unprotected.tsx",
      "children": [
        "/_unprotected/offer/$pathUri/details/"
      ]
    },
    "/_protected/create-company": {
      "filePath": "_protected/create-company.tsx",
      "parent": "/_protected"
    },
    "/_protected/dashboard": {
      "filePath": "_protected/dashboard.tsx",
      "parent": "/_protected",
      "children": [
        "/_protected/dashboard/checklist/new",
        "/_protected/dashboard/customers",
        "/_protected/dashboard/deviation/new",
        "/_protected/dashboard/economy",
        "/_protected/dashboard/equipment/new",
        "/_protected/dashboard/facility/new",
        "/_protected/dashboard/internal-routines/new",
        "/_protected/dashboard/project",
        "/_protected/dashboard/sales",
        "/_protected/dashboard/service-contract/new",
        "/_protected/dashboard/settings",
        "/_protected/dashboard/ehs/",
        "/_protected/dashboard/employees/",
        "/_protected/dashboard/orders/",
        "/_protected/dashboard/overview/",
        "/_protected/dashboard/products/",
        "/_protected/dashboard/checklist/$checklistId/update",
        "/_protected/dashboard/deviation/$deviationId/update",
        "/_protected/dashboard/ehs/$ehsId/update",
        "/_protected/dashboard/ehs/$templateId/new",
        "/_protected/dashboard/ehs/template/new",
        "/_protected/dashboard/equipment/$equipmentId/update",
        "/_protected/dashboard/facility/$facilityId/details",
        "/_protected/dashboard/facility/$facilityId/update",
        "/_protected/dashboard/internal-routines/$routineId/update",
        "/_protected/dashboard/location/$resourceId/new",
        "/_protected/dashboard/location/$resourceId/update",
        "/_protected/dashboard/orders/$orderId",
        "/_protected/dashboard/products/$productId/update",
        "/_protected/dashboard/products/estimations/new",
        "/_protected/dashboard/resources/$employeeId/update",
        "/_protected/dashboard/service-contract/$serviceContractId/update",
        "/_protected/dashboard/orders/new/",
        "/_protected/dashboard/products/new/",
        "/_protected/dashboard/ehs/template/$templateId/update",
        "/_protected/dashboard/orders/template/$templateId/product",
        "/_protected/dashboard/orders/template/$templateId/update",
        "/_protected/dashboard/products/estimations/$estimationId/update",
        "/_protected/dashboard/orders/template/$templateId/"
      ]
    },
    "/_protected/invitations": {
      "filePath": "_protected/invitations.tsx",
      "parent": "/_protected"
    },
    "/_protected/trial": {
      "filePath": "_protected/trial.tsx",
      "parent": "/_protected",
      "children": [
        "/_protected/trial/confirmation",
        "/_protected/trial/expired",
        "/_protected/trial/subscribe"
      ]
    },
    "/_protected/user-info": {
      "filePath": "_protected/user-info.tsx",
      "parent": "/_protected"
    },
    "/_protected/welcome": {
      "filePath": "_protected/welcome.tsx",
      "parent": "/_protected"
    },
    "/_unauthenticated/create-account": {
      "filePath": "_unauthenticated/create-account.tsx",
      "parent": "/_unauthenticated"
    },
    "/_unauthenticated/login": {
      "filePath": "_unauthenticated/login.tsx",
      "parent": "/_unauthenticated"
    },
    "/_unauthenticated/reset-password": {
      "filePath": "_unauthenticated/reset-password.tsx",
      "parent": "/_unauthenticated"
    },
    "/_unauthenticated/": {
      "filePath": "_unauthenticated/index.tsx",
      "parent": "/_unauthenticated"
    },
    "/_protected/trial/confirmation": {
      "filePath": "_protected/trial/confirmation.tsx",
      "parent": "/_protected/trial"
    },
    "/_protected/trial/expired": {
      "filePath": "_protected/trial/expired.tsx",
      "parent": "/_protected/trial"
    },
    "/_protected/trial/subscribe": {
      "filePath": "_protected/trial/subscribe.tsx",
      "parent": "/_protected/trial"
    },
    "/guest/order/$orderId": {
      "filePath": "guest/order/$orderId.tsx"
    },
    "/guest/project/$projectId": {
      "filePath": "guest/project/$projectId.tsx"
    },
    "/guest/$customerId/": {
      "filePath": "guest/$customerId/index.tsx"
    },
    "/_protected/dashboard/checklist/new": {
      "filePath": "_protected/dashboard/checklist/new.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/customers": {
      "filePath": "_protected/dashboard/customers",
      "parent": "/_protected/dashboard",
      "children": [
        "/_protected/dashboard/customers/_layout",
        "/_protected/dashboard/customers/new",
        "/_protected/dashboard/customers/$customerId/edit",
        "/_protected/dashboard/customers/$customerId/",
        "/_protected/dashboard/customers/$customerId/order/new",
        "/_protected/dashboard/customers/$customerId/project/new"
      ]
    },
    "/_protected/dashboard/customers/_layout": {
      "filePath": "_protected/dashboard/customers/_layout.tsx",
      "parent": "/_protected/dashboard/customers",
      "children": [
        "/_protected/dashboard/customers/_layout/customers",
        "/_protected/dashboard/customers/_layout/inbox/"
      ]
    },
    "/_protected/dashboard/customers/new": {
      "filePath": "_protected/dashboard/customers/new.tsx",
      "parent": "/_protected/dashboard/customers"
    },
    "/_protected/dashboard/deviation/new": {
      "filePath": "_protected/dashboard/deviation/new.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/economy": {
      "filePath": "_protected/dashboard/economy",
      "parent": "/_protected/dashboard",
      "children": [
        "/_protected/dashboard/economy/_layout",
        "/_protected/dashboard/economy/invoice/new"
      ]
    },
    "/_protected/dashboard/economy/_layout": {
      "filePath": "_protected/dashboard/economy/_layout.tsx",
      "parent": "/_protected/dashboard/economy",
      "children": [
        "/_protected/dashboard/economy/_layout/activity",
        "/_protected/dashboard/economy/_layout/invoice",
        "/_protected/dashboard/economy/_layout/price-model",
        "/_protected/dashboard/economy/_layout/time-list",
        "/_protected/dashboard/economy/_layout/time-report"
      ]
    },
    "/_protected/dashboard/equipment/new": {
      "filePath": "_protected/dashboard/equipment/new.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/facility/new": {
      "filePath": "_protected/dashboard/facility/new.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/internal-routines/new": {
      "filePath": "_protected/dashboard/internal-routines/new.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/project": {
      "filePath": "_protected/dashboard/project",
      "parent": "/_protected/dashboard",
      "children": [
        "/_protected/dashboard/project/_layout",
        "/_protected/dashboard/project/new",
        "/_protected/dashboard/project/$projectId"
      ]
    },
    "/_protected/dashboard/project/_layout": {
      "filePath": "_protected/dashboard/project/_layout.tsx",
      "parent": "/_protected/dashboard/project",
      "children": [
        "/_protected/dashboard/project/_layout/deviation-report",
        "/_protected/dashboard/project/_layout/overview",
        "/_protected/dashboard/project/_layout/progress-plan",
        "/_protected/dashboard/project/_layout/task"
      ]
    },
    "/_protected/dashboard/project/new": {
      "filePath": "_protected/dashboard/project/new.tsx",
      "parent": "/_protected/dashboard/project"
    },
    "/_protected/dashboard/sales": {
      "filePath": "_protected/dashboard/sales",
      "parent": "/_protected/dashboard",
      "children": [
        "/_protected/dashboard/sales/_layout",
        "/_protected/dashboard/sales/direct-sale/new",
        "/_protected/dashboard/sales/offer/new",
        "/_protected/dashboard/sales/offer/preview",
        "/_protected/dashboard/sales/sales-opportunity/new",
        "/_protected/dashboard/sales/new/",
        "/_protected/dashboard/sales/inspection/$customerId/new",
        "/_protected/dashboard/sales/inspection/$inspectionId/update",
        "/_protected/dashboard/sales/offer/$offerId/additional-work",
        "/_protected/dashboard/sales/offer/$offerId/update",
        "/_protected/dashboard/sales/offer/$pathUri/preview",
        "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId"
      ]
    },
    "/_protected/dashboard/sales/_layout": {
      "filePath": "_protected/dashboard/sales/_layout.tsx",
      "parent": "/_protected/dashboard/sales",
      "children": [
        "/_protected/dashboard/sales/_layout/direct-sale",
        "/_protected/dashboard/sales/_layout/offer",
        "/_protected/dashboard/sales/_layout/report",
        "/_protected/dashboard/sales/_layout/sales-opportunity"
      ]
    },
    "/_protected/dashboard/service-contract/new": {
      "filePath": "_protected/dashboard/service-contract/new.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/settings": {
      "filePath": "_protected/dashboard/settings",
      "parent": "/_protected/dashboard",
      "children": [
        "/_protected/dashboard/settings/_layout",
        "/_protected/dashboard/settings/project-phases/edit"
      ]
    },
    "/_protected/dashboard/settings/_layout": {
      "filePath": "_protected/dashboard/settings/_layout.tsx",
      "parent": "/_protected/dashboard/settings",
      "children": [
        "/_protected/dashboard/settings/_layout/checklist-template",
        "/_protected/dashboard/settings/_layout/company",
        "/_protected/dashboard/settings/_layout/documentation-template",
        "/_protected/dashboard/settings/_layout/equipment",
        "/_protected/dashboard/settings/_layout/integration",
        "/_protected/dashboard/settings/_layout/order",
        "/_protected/dashboard/settings/_layout/project",
        "/_protected/dashboard/settings/_layout/task-template",
        "/_protected/dashboard/settings/_layout/video-guide"
      ]
    },
    "/_protected/dashboard/ehs/": {
      "filePath": "_protected/dashboard/ehs/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/employees/": {
      "filePath": "_protected/dashboard/employees/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/orders/": {
      "filePath": "_protected/dashboard/orders/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/overview/": {
      "filePath": "_protected/dashboard/overview/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/products/": {
      "filePath": "_protected/dashboard/products/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/checklist/$checklistId/update": {
      "filePath": "_protected/dashboard/checklist/$checklistId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/customers/$customerId/edit": {
      "filePath": "_protected/dashboard/customers/$customerId/edit.tsx",
      "parent": "/_protected/dashboard/customers"
    },
    "/_protected/dashboard/customers/_layout/customers": {
      "filePath": "_protected/dashboard/customers/_layout/customers.tsx",
      "parent": "/_protected/dashboard/customers/_layout"
    },
    "/_protected/dashboard/deviation/$deviationId/update": {
      "filePath": "_protected/dashboard/deviation/$deviationId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/economy/_layout/activity": {
      "filePath": "_protected/dashboard/economy/_layout/activity.tsx",
      "parent": "/_protected/dashboard/economy/_layout"
    },
    "/_protected/dashboard/economy/_layout/invoice": {
      "filePath": "_protected/dashboard/economy/_layout/invoice.tsx",
      "parent": "/_protected/dashboard/economy/_layout"
    },
    "/_protected/dashboard/economy/_layout/price-model": {
      "filePath": "_protected/dashboard/economy/_layout/price-model.tsx",
      "parent": "/_protected/dashboard/economy/_layout"
    },
    "/_protected/dashboard/economy/_layout/time-list": {
      "filePath": "_protected/dashboard/economy/_layout/time-list.tsx",
      "parent": "/_protected/dashboard/economy/_layout"
    },
    "/_protected/dashboard/economy/_layout/time-report": {
      "filePath": "_protected/dashboard/economy/_layout/time-report.tsx",
      "parent": "/_protected/dashboard/economy/_layout"
    },
    "/_protected/dashboard/economy/invoice/new": {
      "filePath": "_protected/dashboard/economy/invoice.new.tsx",
      "parent": "/_protected/dashboard/economy"
    },
    "/_protected/dashboard/ehs/$ehsId/update": {
      "filePath": "_protected/dashboard/ehs/$ehsId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/ehs/$templateId/new": {
      "filePath": "_protected/dashboard/ehs/$templateId/new.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/ehs/template/new": {
      "filePath": "_protected/dashboard/ehs/template/new.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/equipment/$equipmentId/update": {
      "filePath": "_protected/dashboard/equipment/$equipmentId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/facility/$facilityId/details": {
      "filePath": "_protected/dashboard/facility/$facilityId/details.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/facility/$facilityId/update": {
      "filePath": "_protected/dashboard/facility/$facilityId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/internal-routines/$routineId/update": {
      "filePath": "_protected/dashboard/internal-routines/$routineId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/location/$resourceId/new": {
      "filePath": "_protected/dashboard/location/$resourceId/new.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/location/$resourceId/update": {
      "filePath": "_protected/dashboard/location/$resourceId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/orders/$orderId": {
      "filePath": "_protected/dashboard/orders/$orderId",
      "parent": "/_protected/dashboard",
      "children": [
        "/_protected/dashboard/orders/$orderId/_layout",
        "/_protected/dashboard/orders/$orderId/update",
        "/_protected/dashboard/orders/$orderId/products/add"
      ]
    },
    "/_protected/dashboard/orders/$orderId/_layout": {
      "filePath": "_protected/dashboard/orders/$orderId/_layout.tsx",
      "parent": "/_protected/dashboard/orders/$orderId",
      "children": [
        "/_protected/dashboard/orders/$orderId/_layout/checklist",
        "/_protected/dashboard/orders/$orderId/_layout/deviation",
        "/_protected/dashboard/orders/$orderId/_layout/documentation",
        "/_protected/dashboard/orders/$orderId/_layout/overview",
        "/_protected/dashboard/orders/$orderId/_layout/product"
      ]
    },
    "/_protected/dashboard/orders/$orderId/update": {
      "filePath": "_protected/dashboard/orders/$orderId/update.tsx",
      "parent": "/_protected/dashboard/orders/$orderId"
    },
    "/_protected/dashboard/products/$productId/update": {
      "filePath": "_protected/dashboard/products/$productId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/products/estimations/new": {
      "filePath": "_protected/dashboard/products/estimations/new.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/project/$projectId": {
      "filePath": "_protected/dashboard/project/$projectId",
      "parent": "/_protected/dashboard/project",
      "children": [
        "/_protected/dashboard/project/$projectId/_layout",
        "/_protected/dashboard/project/$projectId/update",
        "/_protected/dashboard/project/$projectId/offer/new",
        "/_protected/dashboard/project/$projectId/products/add",
        "/_protected/dashboard/project/$projectId/task/$taskId",
        "/_protected/dashboard/project/$projectId/phases/",
        "/_protected/dashboard/project/$projectId/workers/"
      ]
    },
    "/_protected/dashboard/project/$projectId/_layout": {
      "filePath": "_protected/dashboard/project/$projectId/_layout.tsx",
      "parent": "/_protected/dashboard/project/$projectId",
      "children": [
        "/_protected/dashboard/project/$projectId/_layout/_layout",
        "/_protected/dashboard/project/$projectId/_layout/checklist",
        "/_protected/dashboard/project/$projectId/_layout/deviation",
        "/_protected/dashboard/project/$projectId/_layout/documentation",
        "/_protected/dashboard/project/$projectId/_layout/overview",
        "/_protected/dashboard/project/$projectId/_layout/overview-old",
        "/_protected/dashboard/project/$projectId/_layout/product",
        "/_protected/dashboard/project/$projectId/_layout/progress-plan",
        "/_protected/dashboard/project/$projectId/_layout/sub-project",
        "/_protected/dashboard/project/$projectId/_layout/task",
        "/_protected/dashboard/project/$projectId/_layout/ehs/"
      ]
    },
    "/_protected/dashboard/project/$projectId/update": {
      "filePath": "_protected/dashboard/project/$projectId/update.tsx",
      "parent": "/_protected/dashboard/project/$projectId"
    },
    "/_protected/dashboard/project/_layout/deviation-report": {
      "filePath": "_protected/dashboard/project/_layout/deviation-report.tsx",
      "parent": "/_protected/dashboard/project/_layout"
    },
    "/_protected/dashboard/project/_layout/overview": {
      "filePath": "_protected/dashboard/project/_layout/overview.tsx",
      "parent": "/_protected/dashboard/project/_layout"
    },
    "/_protected/dashboard/project/_layout/progress-plan": {
      "filePath": "_protected/dashboard/project/_layout/progress-plan.tsx",
      "parent": "/_protected/dashboard/project/_layout"
    },
    "/_protected/dashboard/project/_layout/task": {
      "filePath": "_protected/dashboard/project/_layout/task.tsx",
      "parent": "/_protected/dashboard/project/_layout"
    },
    "/_protected/dashboard/resources/$employeeId/update": {
      "filePath": "_protected/dashboard/resources/$employeeId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/sales/_layout/direct-sale": {
      "filePath": "_protected/dashboard/sales/_layout/direct-sale.tsx",
      "parent": "/_protected/dashboard/sales/_layout"
    },
    "/_protected/dashboard/sales/_layout/offer": {
      "filePath": "_protected/dashboard/sales/_layout/offer.tsx",
      "parent": "/_protected/dashboard/sales/_layout"
    },
    "/_protected/dashboard/sales/_layout/report": {
      "filePath": "_protected/dashboard/sales/_layout/report.tsx",
      "parent": "/_protected/dashboard/sales/_layout"
    },
    "/_protected/dashboard/sales/_layout/sales-opportunity": {
      "filePath": "_protected/dashboard/sales/_layout/sales-opportunity.tsx",
      "parent": "/_protected/dashboard/sales/_layout"
    },
    "/_protected/dashboard/sales/direct-sale/new": {
      "filePath": "_protected/dashboard/sales/direct-sale/new.tsx",
      "parent": "/_protected/dashboard/sales"
    },
    "/_protected/dashboard/sales/offer/new": {
      "filePath": "_protected/dashboard/sales/offer/new.tsx",
      "parent": "/_protected/dashboard/sales"
    },
    "/_protected/dashboard/sales/offer/preview": {
      "filePath": "_protected/dashboard/sales/offer/preview.tsx",
      "parent": "/_protected/dashboard/sales"
    },
    "/_protected/dashboard/sales/sales-opportunity/new": {
      "filePath": "_protected/dashboard/sales/sales-opportunity.new.tsx",
      "parent": "/_protected/dashboard/sales"
    },
    "/_protected/dashboard/service-contract/$serviceContractId/update": {
      "filePath": "_protected/dashboard/service-contract/$serviceContractId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/settings/_layout/checklist-template": {
      "filePath": "_protected/dashboard/settings/_layout/checklist-template.tsx",
      "parent": "/_protected/dashboard/settings/_layout"
    },
    "/_protected/dashboard/settings/_layout/company": {
      "filePath": "_protected/dashboard/settings/_layout/company.tsx",
      "parent": "/_protected/dashboard/settings/_layout"
    },
    "/_protected/dashboard/settings/_layout/documentation-template": {
      "filePath": "_protected/dashboard/settings/_layout/documentation-template.tsx",
      "parent": "/_protected/dashboard/settings/_layout"
    },
    "/_protected/dashboard/settings/_layout/equipment": {
      "filePath": "_protected/dashboard/settings/_layout/equipment.tsx",
      "parent": "/_protected/dashboard/settings/_layout"
    },
    "/_protected/dashboard/settings/_layout/integration": {
      "filePath": "_protected/dashboard/settings/_layout/integration.tsx",
      "parent": "/_protected/dashboard/settings/_layout"
    },
    "/_protected/dashboard/settings/_layout/order": {
      "filePath": "_protected/dashboard/settings/_layout/order.tsx",
      "parent": "/_protected/dashboard/settings/_layout"
    },
    "/_protected/dashboard/settings/_layout/project": {
      "filePath": "_protected/dashboard/settings/_layout/project.tsx",
      "parent": "/_protected/dashboard/settings/_layout"
    },
    "/_protected/dashboard/settings/_layout/task-template": {
      "filePath": "_protected/dashboard/settings/_layout/task-template.tsx",
      "parent": "/_protected/dashboard/settings/_layout"
    },
    "/_protected/dashboard/settings/_layout/video-guide": {
      "filePath": "_protected/dashboard/settings/_layout/video-guide.tsx",
      "parent": "/_protected/dashboard/settings/_layout"
    },
    "/_protected/dashboard/settings/project-phases/edit": {
      "filePath": "_protected/dashboard/settings/project-phases/edit.tsx",
      "parent": "/_protected/dashboard/settings"
    },
    "/_protected/dashboard/customers/$customerId/": {
      "filePath": "_protected/dashboard/customers/$customerId/index.tsx",
      "parent": "/_protected/dashboard/customers"
    },
    "/_protected/dashboard/orders/new/": {
      "filePath": "_protected/dashboard/orders/new/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/products/new/": {
      "filePath": "_protected/dashboard/products/new/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/sales/new/": {
      "filePath": "_protected/dashboard/sales/new/index.tsx",
      "parent": "/_protected/dashboard/sales"
    },
    "/_unprotected/offer/$pathUri/details/": {
      "filePath": "_unprotected/offer/$pathUri/details/index.tsx",
      "parent": "/_unprotected"
    },
    "/_protected/dashboard/customers/$customerId/order/new": {
      "filePath": "_protected/dashboard/customers/$customerId/order/new.tsx",
      "parent": "/_protected/dashboard/customers"
    },
    "/_protected/dashboard/customers/$customerId/project/new": {
      "filePath": "_protected/dashboard/customers/$customerId/project.new.tsx",
      "parent": "/_protected/dashboard/customers"
    },
    "/_protected/dashboard/ehs/template/$templateId/update": {
      "filePath": "_protected/dashboard/ehs/template/$templateId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/orders/$orderId/_layout/checklist": {
      "filePath": "_protected/dashboard/orders/$orderId/_layout/checklist.tsx",
      "parent": "/_protected/dashboard/orders/$orderId/_layout"
    },
    "/_protected/dashboard/orders/$orderId/_layout/deviation": {
      "filePath": "_protected/dashboard/orders/$orderId/_layout/deviation.tsx",
      "parent": "/_protected/dashboard/orders/$orderId/_layout"
    },
    "/_protected/dashboard/orders/$orderId/_layout/documentation": {
      "filePath": "_protected/dashboard/orders/$orderId/_layout/documentation.tsx",
      "parent": "/_protected/dashboard/orders/$orderId/_layout"
    },
    "/_protected/dashboard/orders/$orderId/_layout/overview": {
      "filePath": "_protected/dashboard/orders/$orderId/_layout/overview.tsx",
      "parent": "/_protected/dashboard/orders/$orderId/_layout"
    },
    "/_protected/dashboard/orders/$orderId/_layout/product": {
      "filePath": "_protected/dashboard/orders/$orderId/_layout/product.tsx",
      "parent": "/_protected/dashboard/orders/$orderId/_layout"
    },
    "/_protected/dashboard/orders/$orderId/products/add": {
      "filePath": "_protected/dashboard/orders/$orderId/products/add.tsx",
      "parent": "/_protected/dashboard/orders/$orderId"
    },
    "/_protected/dashboard/orders/template/$templateId/product": {
      "filePath": "_protected/dashboard/orders/template/$templateId/product.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/orders/template/$templateId/update": {
      "filePath": "_protected/dashboard/orders/template/$templateId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/products/estimations/$estimationId/update": {
      "filePath": "_protected/dashboard/products/estimations/$estimationId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/project/$projectId/_layout/_layout": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/_layout.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout",
      "children": [
        "/_protected/dashboard/project/$projectId/_layout/_layout/economy/budget",
        "/_protected/dashboard/project/$projectId/_layout/_layout/economy/change-order",
        "/_protected/dashboard/project/$projectId/_layout/_layout/economy/contract",
        "/_protected/dashboard/project/$projectId/_layout/_layout/economy/invoice",
        "/_protected/dashboard/project/$projectId/_layout/_layout/economy/subcontractor",
        "/_protected/dashboard/project/$projectId/_layout/_layout/economy/time",
        "/_protected/dashboard/project/$projectId/_layout/_layout/economy/"
      ]
    },
    "/_protected/dashboard/project/$projectId/_layout/checklist": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/checklist.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/deviation": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/deviation.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/documentation": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/documentation.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/overview": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/overview.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/overview-old": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/overview-old.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/product": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/product.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/progress-plan": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/progress-plan.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/sub-project": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/sub-project.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/task": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/task.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout"
    },
    "/_protected/dashboard/project/$projectId/offer/new": {
      "filePath": "_protected/dashboard/project/$projectId/offer.new.tsx",
      "parent": "/_protected/dashboard/project/$projectId"
    },
    "/_protected/dashboard/project/$projectId/products/add": {
      "filePath": "_protected/dashboard/project/$projectId/products/add.tsx",
      "parent": "/_protected/dashboard/project/$projectId"
    },
    "/_protected/dashboard/project/$projectId/task/$taskId": {
      "filePath": "_protected/dashboard/project/$projectId/task/$taskId.tsx",
      "parent": "/_protected/dashboard/project/$projectId"
    },
    "/_protected/dashboard/sales/inspection/$customerId/new": {
      "filePath": "_protected/dashboard/sales/inspection/$customerId/new.tsx",
      "parent": "/_protected/dashboard/sales"
    },
    "/_protected/dashboard/sales/inspection/$inspectionId/update": {
      "filePath": "_protected/dashboard/sales/inspection/$inspectionId/update.tsx",
      "parent": "/_protected/dashboard/sales"
    },
    "/_protected/dashboard/sales/offer/$offerId/additional-work": {
      "filePath": "_protected/dashboard/sales/offer/$offerId/additional-work.tsx",
      "parent": "/_protected/dashboard/sales"
    },
    "/_protected/dashboard/sales/offer/$offerId/update": {
      "filePath": "_protected/dashboard/sales/offer/$offerId/update.tsx",
      "parent": "/_protected/dashboard/sales"
    },
    "/_protected/dashboard/sales/offer/$pathUri/preview": {
      "filePath": "_protected/dashboard/sales/offer/$pathUri.preview.tsx",
      "parent": "/_protected/dashboard/sales"
    },
    "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId": {
      "filePath": "_protected/dashboard/sales/sales-opportunity/$salesOpportunityId",
      "parent": "/_protected/dashboard/sales",
      "children": [
        "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout",
        "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/update",
        "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/new",
        "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/$offerId/update"
      ]
    },
    "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout": {
      "filePath": "_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout.tsx",
      "parent": "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId",
      "children": [
        "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/documentation",
        "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/inspection",
        "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/offer",
        "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/overview"
      ]
    },
    "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/update": {
      "filePath": "_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/update.tsx",
      "parent": "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId"
    },
    "/_protected/dashboard/customers/_layout/inbox/": {
      "filePath": "_protected/dashboard/customers/_layout/inbox/index.tsx",
      "parent": "/_protected/dashboard/customers/_layout"
    },
    "/_protected/dashboard/orders/template/$templateId/": {
      "filePath": "_protected/dashboard/orders/template/$templateId/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/project/$projectId/phases/": {
      "filePath": "_protected/dashboard/project/$projectId/phases/index.tsx",
      "parent": "/_protected/dashboard/project/$projectId"
    },
    "/_protected/dashboard/project/$projectId/workers/": {
      "filePath": "_protected/dashboard/project/$projectId/workers/index.tsx",
      "parent": "/_protected/dashboard/project/$projectId"
    },
    "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/documentation": {
      "filePath": "_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/documentation.tsx",
      "parent": "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout"
    },
    "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/inspection": {
      "filePath": "_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/inspection.tsx",
      "parent": "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout"
    },
    "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/offer": {
      "filePath": "_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/offer.tsx",
      "parent": "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout"
    },
    "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/overview": {
      "filePath": "_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/overview.tsx",
      "parent": "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout"
    },
    "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/new": {
      "filePath": "_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/new.tsx",
      "parent": "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId"
    },
    "/_protected/dashboard/project/$projectId/_layout/ehs/": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/ehs/index.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/budget": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/_layout.economy/budget.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/change-order": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/_layout.economy/change-order.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/contract": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/_layout.economy/contract.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/invoice": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/_layout.economy/invoice.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/subcontractor": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/_layout.economy/subcontractor.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/time": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/_layout.economy/time.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout/_layout"
    },
    "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/$offerId/update": {
      "filePath": "_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/$offerId/update.tsx",
      "parent": "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId"
    },
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/_layout.economy/index.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout/_layout"
    }
  }
}
ROUTE_MANIFEST_END */
