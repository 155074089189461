import { z } from "zod";

export const productTabs = z.enum([
  "products",
  "categories",
  "units",
  "priceAdjustment",
  "estimations"
] as const);

export const facilityDetailsTabs = z.enum([
  "overview",
  "serviceContracts",
  "documents",
  "workOrders"
] as const);

export const recurringOrderTabs = z.enum([
  "overview",
  "checklist",
  "products",
  "documentation"
] as const);

export const customerDetailsTabs = z.enum([
  "overview",
  "contactPersonsAndAddresses",
  "economy",
  "sales",
  "documents",
  "projects",
  "orders",
  "facilities"
] as const);

export const projectTabs = z.enum([
  "projectOverview",
  "tasks",
  "progressPlan",
  "deviationReports"
] as const);

export const projectDetailsGuestTabs = z.enum([
  "progressPlan",
  "documentation",
  "checklists",
  "ehs",
  "deviations"
] as const);

export const orderDetailsGuestTabs = z.enum([
  "documentation",
  "checklists",
  "ehs",
  "deviations"
] as const);

export const orderTabs = z.enum([
  "overview",
  "repeating",
  "deviationReports",
  "archive"
] as const);

export const salesDetailsTabs = z.enum([
  "overview",
  "documentation",
  "inspections",
  "offers"
] as const);

export const taskDetailsTabs = z.enum([
  "overview",
  "documentation",
  "checklists",
  "deviations",
  "products"
] as const);

export const resourceTabs = z.enum([
  "users",
  "resource-planner",
  "invitations",
  "equipment"
] as const);

export const ehsResourceType = z.enum(["project", "task", "company"]);

export const internalRoutinesAndEhsTabs = z.enum([
  "internalRoutines",
  "ehs"
] as const);

export const inspectionType = z.enum(["digital", "physical"]);
