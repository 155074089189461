import { createFileRoute } from "@tanstack/react-router";
import PageTitle from "../../../../../../components/common/PageTitle";
import React from "react";

export const Route = createFileRoute(
  "/_protected/dashboard/sales/offer/$offerId/additional-work"
)({
  component: AdditionalWorkOfferPage
});

function AdditionalWorkOfferPage() {
  return (
    <>
      <PageTitle title={"Tilleggsarbeid"} hasBackFunction />
      {/*// TODO: add new view (OfferDetailsView removed)*/}
      <div>{"Additional-Work offer page"}</div>
    </>
  );
}
