import {
  FileApi,
  FileResponse,
  FolderApi,
  FolderResponse
} from "../../.generated/api";
import environment from "../../environment";
import { apiInstance } from "./ApiHttpBase";
import { createQuery } from "react-query-kit";

const folderApi = new FolderApi(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);
const fileApi = new FileApi(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export type FileAndFolderResponse = {
  files: FileResponse[];
  folders: FolderResponse[];
};

export const useGetRootFoldersOnResource = createQuery({
  queryKey: ["getRootFoldersOnResource"],
  fetcher: async (variables: { resourceId: string }) => {
    const response = await folderApi.getRootContent(variables.resourceId);
    return response.data;
  }
});

export const useGetAllFilesAndFoldersByResourceId = createQuery({
  queryKey: ["getAllFilesAndFoldersByResourceId"],
  fetcher: async (variables: { resourceId: string }) => {
    const [files, folders] = await Promise.all([
      fileApi.getFilesByResourceId(variables.resourceId),
      folderApi.getFoldersByResourceId(variables.resourceId)
    ]);

    return {
      files: files.data,
      folders: folders.data
    };
  }
});

export const useGetPublicFilesAndFoldersByResourceId = createQuery({
  queryKey: ["getPublicFilesAndFoldersByResourceId"],
  fetcher: async (variables: { resourceId: string; parentId?: string }) => {
    const [files, folders] = await Promise.all([
      fileApi.getPublicFiles(variables.resourceId, variables.parentId),
      folderApi.getPublicFolderContent(variables.resourceId, variables.parentId)
    ]);

    return {
      files: files.data,
      folders: folders.data
    };
  }
});

export async function getAllFilesAndFoldersByResourceId(
  resourceId: string
): Promise<FileAndFolderResponse> {
  const [files, folders] = await Promise.all([
    fileApi.getFilesByResourceId(resourceId),
    folderApi.getFoldersByResourceId(resourceId)
  ]);

  return {
    files: files.data,
    folders: folders.data
  };
}

export const useGetFilesAndFoldersByResourceId = createQuery({
  queryKey: ["getFilesAndFoldersByResourceId"],
  fetcher: async (variables: {
    parentId?: string;
    resourceId: string;
    disposition?: "INLINE" | "ATTACHMENT";
  }) => {
    if (variables.parentId) {
      const [files, folders] = await Promise.all([
        fileApi.getByFolder(
          variables.parentId,
          variables.resourceId,
          undefined,
          variables.disposition
        ),
        folderApi.getFolderContent(variables.parentId, variables.resourceId)
      ]);

      return {
        files: files.data,
        folders: folders.data
      };
    } else {
      const [files, folders] = await Promise.all([
        fileApi.getRootFiles(
          variables.resourceId,
          undefined,
          variables.disposition
        ),
        folderApi.getRootContent(variables.resourceId)
      ]);

      return {
        files: files.data,
        folders: folders.data
      };
    }
  }
});

export async function deleteFolder(
  id: string,
  resourceId: string
): Promise<void> {
  await folderApi.deleteFolder(id, resourceId);
}

export async function getFolderContent(
  parent: string,
  resourceId: string
): Promise<FolderResponse[]> {
  const response = await folderApi.getFolderContent(parent, resourceId);
  return response.data;
}

export async function createFolder(
  resourceId: string,
  name: string,
  parentId?: string
): Promise<FolderResponse> {
  const response = await folderApi.createFolder({
    resourceId: resourceId,
    parent: parentId,
    name: name
  });
  return response.data;
}

export async function updateFolder(
  id: string,
  resourceId: string,
  name: string,
  parent?: string
): Promise<FolderResponse> {
  const response = await folderApi.updateFolder(id, resourceId, {
    name: name,
    parent: parent
  });
  return response.data;
}

export interface FoldersInterface {
  [key: string]: {
    info: FolderResponse;
    child: GetFolderChildrenInterface;
  };
}

export interface GetFolderChildrenInterface {
  files: FileResponse[];
  folders: FoldersInterface;
}
