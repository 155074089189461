/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { PaginatedResponseTaskV2 } from '../model';
// @ts-ignore
import { RecurringTaskPostRequestV2 } from '../model';
// @ts-ignore
import { TaskPostRequestV2 } from '../model';
// @ts-ignore
import { TaskPutPhaseRequest } from '../model';
// @ts-ignore
import { TaskPutRequest } from '../model';
// @ts-ignore
import { TaskStatusRequestV2 } from '../model';
// @ts-ignore
import { TaskV2 } from '../model';
/**
 * TaskV2Api - axios parameter creator
 * @export
 */
export const TaskV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new task
         * @param {TaskPostRequestV2} taskPostRequestV2 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTask: async (taskPostRequestV2: TaskPostRequestV2, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskPostRequestV2' is not null or undefined
            assertParamExists('createTask', 'taskPostRequestV2', taskPostRequestV2)
            const localVarPath = `/v2/task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskPostRequestV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new task for BOB integration
         * @param {string} token 
         * @param {TaskPostRequestV2} taskPostRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTaskIntegration: async (token: string, taskPostRequestV2: TaskPostRequestV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('createTaskIntegration', 'token', token)
            // verify required parameter 'taskPostRequestV2' is not null or undefined
            assertParamExists('createTaskIntegration', 'taskPostRequestV2', taskPostRequestV2)
            const localVarPath = `/v2/integration/task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (token != null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskPostRequestV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete all chained future recurring tasks
         * @param {string} companyId 
         * @param {string} recurringTaskRelationId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecurringTaskChainV2: async (companyId: string, recurringTaskRelationId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteRecurringTaskChainV2', 'companyId', companyId)
            // verify required parameter 'recurringTaskRelationId' is not null or undefined
            assertParamExists('deleteRecurringTaskChainV2', 'recurringTaskRelationId', recurringTaskRelationId)
            const localVarPath = `/v2/company/{companyId}/task/recurring/{recurringTaskRelationId}/delete`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"recurringTaskRelationId"}}`, encodeURIComponent(String(recurringTaskRelationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete task
         * @param {string} taskId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTaskV2: async (taskId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('deleteTaskV2', 'taskId', taskId)
            const localVarPath = `/v2/task/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all tasks assigned to the user
         * @param {string} companyId 
         * @param {'Created' | 'Title' | 'ProjectTitle' | 'FromDateTime' | 'ToDateTime' | 'Status' | 'PhaseTitle'} sortField 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [query] 
         * @param {'active' | 'deleted'} [status] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserTasksV2: async (companyId: string, sortField: 'Created' | 'Title' | 'ProjectTitle' | 'FromDateTime' | 'ToDateTime' | 'Status' | 'PhaseTitle', page?: number, sortDirection?: 'ASC' | 'DESC', query?: string, status?: 'active' | 'deleted', authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllUserTasksV2', 'companyId', companyId)
            // verify required parameter 'sortField' is not null or undefined
            assertParamExists('getAllUserTasksV2', 'sortField', sortField)
            const localVarPath = `/v2/company/{companyId}/task/user`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all tasks for a company
         * @param {string} companyId 
         * @param {'Created' | 'Title' | 'ProjectTitle' | 'FromDateTime' | 'ToDateTime' | 'Status' | 'PhaseTitle'} sortField 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [query] 
         * @param {string} [projectId] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByCompanyId: async (companyId: string, sortField: 'Created' | 'Title' | 'ProjectTitle' | 'FromDateTime' | 'ToDateTime' | 'Status' | 'PhaseTitle', page?: number, sortDirection?: 'ASC' | 'DESC', query?: string, projectId?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getByCompanyId', 'companyId', companyId)
            // verify required parameter 'sortField' is not null or undefined
            assertParamExists('getByCompanyId', 'sortField', sortField)
            const localVarPath = `/v2/company/{companyId}/task`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all tasks for a project (paginated)
         * @param {string} projectId 
         * @param {'Created' | 'Title' | 'ProjectTitle' | 'FromDateTime' | 'ToDateTime' | 'Status' | 'PhaseTitle'} sortField 
         * @param {string} [projectPhaseId] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [query] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedTasksByProjectId: async (projectId: string, sortField: 'Created' | 'Title' | 'ProjectTitle' | 'FromDateTime' | 'ToDateTime' | 'Status' | 'PhaseTitle', projectPhaseId?: string, page?: number, sortDirection?: 'ASC' | 'DESC', query?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getPaginatedTasksByProjectId', 'projectId', projectId)
            // verify required parameter 'sortField' is not null or undefined
            assertParamExists('getPaginatedTasksByProjectId', 'sortField', sortField)
            const localVarPath = `/v2/task/project/{projectId}/paginated`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectPhaseId !== undefined) {
                localVarQueryParameter['projectPhaseId'] = projectPhaseId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all chained recurring tasks
         * @param {string} companyId 
         * @param {string} recurringTaskRelationId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecurringTaskChainV2: async (companyId: string, recurringTaskRelationId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getRecurringTaskChainV2', 'companyId', companyId)
            // verify required parameter 'recurringTaskRelationId' is not null or undefined
            assertParamExists('getRecurringTaskChainV2', 'recurringTaskRelationId', recurringTaskRelationId)
            const localVarPath = `/v2/company/{companyId}/task/recurring/{recurringTaskRelationId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"recurringTaskRelationId"}}`, encodeURIComponent(String(recurringTaskRelationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get task details
         * @param {string} taskId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskDetailsV2: async (taskId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('getTaskDetailsV2', 'taskId', taskId)
            const localVarPath = `/v2/task/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all tasks for a project
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasksByProjectId: async (projectId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTasksByProjectId', 'projectId', projectId)
            const localVarPath = `/v2/task/project/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new recurring task
         * @param {string} companyId 
         * @param {RecurringTaskPostRequestV2} recurringTaskPostRequestV2 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRecurringTaskV2: async (companyId: string, recurringTaskPostRequestV2: RecurringTaskPostRequestV2, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postRecurringTaskV2', 'companyId', companyId)
            // verify required parameter 'recurringTaskPostRequestV2' is not null or undefined
            assertParamExists('postRecurringTaskV2', 'recurringTaskPostRequestV2', recurringTaskPostRequestV2)
            const localVarPath = `/v2/company/{companyId}/task/recurring`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recurringTaskPostRequestV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a task
         * @param {string} taskId 
         * @param {TaskPutRequest} taskPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTask: async (taskId: string, taskPutRequest: TaskPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('updateTask', 'taskId', taskId)
            // verify required parameter 'taskPutRequest' is not null or undefined
            assertParamExists('updateTask', 'taskPutRequest', taskPutRequest)
            const localVarPath = `/v2/task/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update task phase
         * @param {string} taskId 
         * @param {TaskPutPhaseRequest} taskPutPhaseRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskPhaseV2: async (taskId: string, taskPutPhaseRequest: TaskPutPhaseRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('updateTaskPhaseV2', 'taskId', taskId)
            // verify required parameter 'taskPutPhaseRequest' is not null or undefined
            assertParamExists('updateTaskPhaseV2', 'taskPutPhaseRequest', taskPutPhaseRequest)
            const localVarPath = `/v2/task/{taskId}/phase`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskPutPhaseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update task status
         * @param {string} taskId 
         * @param {TaskStatusRequestV2} taskStatusRequestV2 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskProgressStatusV2: async (taskId: string, taskStatusRequestV2: TaskStatusRequestV2, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('updateTaskProgressStatusV2', 'taskId', taskId)
            // verify required parameter 'taskStatusRequestV2' is not null or undefined
            assertParamExists('updateTaskProgressStatusV2', 'taskStatusRequestV2', taskStatusRequestV2)
            const localVarPath = `/v2/task/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskStatusRequestV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskV2Api - functional programming interface
 * @export
 */
export const TaskV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaskV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new task
         * @param {TaskPostRequestV2} taskPostRequestV2 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTask(taskPostRequestV2: TaskPostRequestV2, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTask(taskPostRequestV2, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new task for BOB integration
         * @param {string} token 
         * @param {TaskPostRequestV2} taskPostRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTaskIntegration(token: string, taskPostRequestV2: TaskPostRequestV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTaskIntegration(token, taskPostRequestV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete all chained future recurring tasks
         * @param {string} companyId 
         * @param {string} recurringTaskRelationId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRecurringTaskChainV2(companyId: string, recurringTaskRelationId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRecurringTaskChainV2(companyId, recurringTaskRelationId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete task
         * @param {string} taskId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTaskV2(taskId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTaskV2(taskId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all tasks assigned to the user
         * @param {string} companyId 
         * @param {'Created' | 'Title' | 'ProjectTitle' | 'FromDateTime' | 'ToDateTime' | 'Status' | 'PhaseTitle'} sortField 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [query] 
         * @param {'active' | 'deleted'} [status] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUserTasksV2(companyId: string, sortField: 'Created' | 'Title' | 'ProjectTitle' | 'FromDateTime' | 'ToDateTime' | 'Status' | 'PhaseTitle', page?: number, sortDirection?: 'ASC' | 'DESC', query?: string, status?: 'active' | 'deleted', authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseTaskV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUserTasksV2(companyId, sortField, page, sortDirection, query, status, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all tasks for a company
         * @param {string} companyId 
         * @param {'Created' | 'Title' | 'ProjectTitle' | 'FromDateTime' | 'ToDateTime' | 'Status' | 'PhaseTitle'} sortField 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [query] 
         * @param {string} [projectId] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByCompanyId(companyId: string, sortField: 'Created' | 'Title' | 'ProjectTitle' | 'FromDateTime' | 'ToDateTime' | 'Status' | 'PhaseTitle', page?: number, sortDirection?: 'ASC' | 'DESC', query?: string, projectId?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseTaskV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByCompanyId(companyId, sortField, page, sortDirection, query, projectId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all tasks for a project (paginated)
         * @param {string} projectId 
         * @param {'Created' | 'Title' | 'ProjectTitle' | 'FromDateTime' | 'ToDateTime' | 'Status' | 'PhaseTitle'} sortField 
         * @param {string} [projectPhaseId] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [query] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedTasksByProjectId(projectId: string, sortField: 'Created' | 'Title' | 'ProjectTitle' | 'FromDateTime' | 'ToDateTime' | 'Status' | 'PhaseTitle', projectPhaseId?: string, page?: number, sortDirection?: 'ASC' | 'DESC', query?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseTaskV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginatedTasksByProjectId(projectId, sortField, projectPhaseId, page, sortDirection, query, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all chained recurring tasks
         * @param {string} companyId 
         * @param {string} recurringTaskRelationId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecurringTaskChainV2(companyId: string, recurringTaskRelationId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskV2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecurringTaskChainV2(companyId, recurringTaskRelationId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get task details
         * @param {string} taskId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskDetailsV2(taskId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskDetailsV2(taskId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all tasks for a project
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTasksByProjectId(projectId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskV2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTasksByProjectId(projectId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new recurring task
         * @param {string} companyId 
         * @param {RecurringTaskPostRequestV2} recurringTaskPostRequestV2 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRecurringTaskV2(companyId: string, recurringTaskPostRequestV2: RecurringTaskPostRequestV2, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRecurringTaskV2(companyId, recurringTaskPostRequestV2, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a task
         * @param {string} taskId 
         * @param {TaskPutRequest} taskPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTask(taskId: string, taskPutRequest: TaskPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTask(taskId, taskPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update task phase
         * @param {string} taskId 
         * @param {TaskPutPhaseRequest} taskPutPhaseRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTaskPhaseV2(taskId: string, taskPutPhaseRequest: TaskPutPhaseRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTaskPhaseV2(taskId, taskPutPhaseRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update task status
         * @param {string} taskId 
         * @param {TaskStatusRequestV2} taskStatusRequestV2 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTaskProgressStatusV2(taskId: string, taskStatusRequestV2: TaskStatusRequestV2, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTaskProgressStatusV2(taskId, taskStatusRequestV2, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaskV2Api - factory interface
 * @export
 */
export const TaskV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaskV2ApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new task
         * @param {TaskPostRequestV2} taskPostRequestV2 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTask(taskPostRequestV2: TaskPostRequestV2, authorization?: string, options?: any): AxiosPromise<TaskV2> {
            return localVarFp.createTask(taskPostRequestV2, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new task for BOB integration
         * @param {string} token 
         * @param {TaskPostRequestV2} taskPostRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTaskIntegration(token: string, taskPostRequestV2: TaskPostRequestV2, options?: any): AxiosPromise<TaskV2> {
            return localVarFp.createTaskIntegration(token, taskPostRequestV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete all chained future recurring tasks
         * @param {string} companyId 
         * @param {string} recurringTaskRelationId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecurringTaskChainV2(companyId: string, recurringTaskRelationId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteRecurringTaskChainV2(companyId, recurringTaskRelationId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete task
         * @param {string} taskId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTaskV2(taskId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteTaskV2(taskId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all tasks assigned to the user
         * @param {string} companyId 
         * @param {'Created' | 'Title' | 'ProjectTitle' | 'FromDateTime' | 'ToDateTime' | 'Status' | 'PhaseTitle'} sortField 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [query] 
         * @param {'active' | 'deleted'} [status] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserTasksV2(companyId: string, sortField: 'Created' | 'Title' | 'ProjectTitle' | 'FromDateTime' | 'ToDateTime' | 'Status' | 'PhaseTitle', page?: number, sortDirection?: 'ASC' | 'DESC', query?: string, status?: 'active' | 'deleted', authorization?: string, options?: any): AxiosPromise<PaginatedResponseTaskV2> {
            return localVarFp.getAllUserTasksV2(companyId, sortField, page, sortDirection, query, status, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all tasks for a company
         * @param {string} companyId 
         * @param {'Created' | 'Title' | 'ProjectTitle' | 'FromDateTime' | 'ToDateTime' | 'Status' | 'PhaseTitle'} sortField 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [query] 
         * @param {string} [projectId] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByCompanyId(companyId: string, sortField: 'Created' | 'Title' | 'ProjectTitle' | 'FromDateTime' | 'ToDateTime' | 'Status' | 'PhaseTitle', page?: number, sortDirection?: 'ASC' | 'DESC', query?: string, projectId?: string, authorization?: string, options?: any): AxiosPromise<PaginatedResponseTaskV2> {
            return localVarFp.getByCompanyId(companyId, sortField, page, sortDirection, query, projectId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all tasks for a project (paginated)
         * @param {string} projectId 
         * @param {'Created' | 'Title' | 'ProjectTitle' | 'FromDateTime' | 'ToDateTime' | 'Status' | 'PhaseTitle'} sortField 
         * @param {string} [projectPhaseId] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [query] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedTasksByProjectId(projectId: string, sortField: 'Created' | 'Title' | 'ProjectTitle' | 'FromDateTime' | 'ToDateTime' | 'Status' | 'PhaseTitle', projectPhaseId?: string, page?: number, sortDirection?: 'ASC' | 'DESC', query?: string, authorization?: string, options?: any): AxiosPromise<PaginatedResponseTaskV2> {
            return localVarFp.getPaginatedTasksByProjectId(projectId, sortField, projectPhaseId, page, sortDirection, query, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all chained recurring tasks
         * @param {string} companyId 
         * @param {string} recurringTaskRelationId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecurringTaskChainV2(companyId: string, recurringTaskRelationId: string, authorization?: string, options?: any): AxiosPromise<Array<TaskV2>> {
            return localVarFp.getRecurringTaskChainV2(companyId, recurringTaskRelationId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get task details
         * @param {string} taskId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskDetailsV2(taskId: string, authorization?: string, options?: any): AxiosPromise<TaskV2> {
            return localVarFp.getTaskDetailsV2(taskId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all tasks for a project
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasksByProjectId(projectId: string, authorization?: string, options?: any): AxiosPromise<Array<TaskV2>> {
            return localVarFp.getTasksByProjectId(projectId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new recurring task
         * @param {string} companyId 
         * @param {RecurringTaskPostRequestV2} recurringTaskPostRequestV2 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRecurringTaskV2(companyId: string, recurringTaskPostRequestV2: RecurringTaskPostRequestV2, authorization?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.postRecurringTaskV2(companyId, recurringTaskPostRequestV2, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a task
         * @param {string} taskId 
         * @param {TaskPutRequest} taskPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTask(taskId: string, taskPutRequest: TaskPutRequest, authorization?: string, options?: any): AxiosPromise<TaskV2> {
            return localVarFp.updateTask(taskId, taskPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update task phase
         * @param {string} taskId 
         * @param {TaskPutPhaseRequest} taskPutPhaseRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskPhaseV2(taskId: string, taskPutPhaseRequest: TaskPutPhaseRequest, authorization?: string, options?: any): AxiosPromise<TaskV2> {
            return localVarFp.updateTaskPhaseV2(taskId, taskPutPhaseRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update task status
         * @param {string} taskId 
         * @param {TaskStatusRequestV2} taskStatusRequestV2 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskProgressStatusV2(taskId: string, taskStatusRequestV2: TaskStatusRequestV2, authorization?: string, options?: any): AxiosPromise<TaskV2> {
            return localVarFp.updateTaskProgressStatusV2(taskId, taskStatusRequestV2, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaskV2Api - object-oriented interface
 * @export
 * @class TaskV2Api
 * @extends {BaseAPI}
 */
export class TaskV2Api extends BaseAPI {
    /**
     * 
     * @summary Create a new task
     * @param {TaskPostRequestV2} taskPostRequestV2 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskV2Api
     */
    public createTask(taskPostRequestV2: TaskPostRequestV2, authorization?: string, options?: AxiosRequestConfig) {
        return TaskV2ApiFp(this.configuration).createTask(taskPostRequestV2, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new task for BOB integration
     * @param {string} token 
     * @param {TaskPostRequestV2} taskPostRequestV2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskV2Api
     */
    public createTaskIntegration(token: string, taskPostRequestV2: TaskPostRequestV2, options?: AxiosRequestConfig) {
        return TaskV2ApiFp(this.configuration).createTaskIntegration(token, taskPostRequestV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete all chained future recurring tasks
     * @param {string} companyId 
     * @param {string} recurringTaskRelationId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskV2Api
     */
    public deleteRecurringTaskChainV2(companyId: string, recurringTaskRelationId: string, authorization?: string, options?: AxiosRequestConfig) {
        return TaskV2ApiFp(this.configuration).deleteRecurringTaskChainV2(companyId, recurringTaskRelationId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete task
     * @param {string} taskId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskV2Api
     */
    public deleteTaskV2(taskId: string, authorization?: string, options?: AxiosRequestConfig) {
        return TaskV2ApiFp(this.configuration).deleteTaskV2(taskId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all tasks assigned to the user
     * @param {string} companyId 
     * @param {'Created' | 'Title' | 'ProjectTitle' | 'FromDateTime' | 'ToDateTime' | 'Status' | 'PhaseTitle'} sortField 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {string} [query] 
     * @param {'active' | 'deleted'} [status] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskV2Api
     */
    public getAllUserTasksV2(companyId: string, sortField: 'Created' | 'Title' | 'ProjectTitle' | 'FromDateTime' | 'ToDateTime' | 'Status' | 'PhaseTitle', page?: number, sortDirection?: 'ASC' | 'DESC', query?: string, status?: 'active' | 'deleted', authorization?: string, options?: AxiosRequestConfig) {
        return TaskV2ApiFp(this.configuration).getAllUserTasksV2(companyId, sortField, page, sortDirection, query, status, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all tasks for a company
     * @param {string} companyId 
     * @param {'Created' | 'Title' | 'ProjectTitle' | 'FromDateTime' | 'ToDateTime' | 'Status' | 'PhaseTitle'} sortField 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {string} [query] 
     * @param {string} [projectId] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskV2Api
     */
    public getByCompanyId(companyId: string, sortField: 'Created' | 'Title' | 'ProjectTitle' | 'FromDateTime' | 'ToDateTime' | 'Status' | 'PhaseTitle', page?: number, sortDirection?: 'ASC' | 'DESC', query?: string, projectId?: string, authorization?: string, options?: AxiosRequestConfig) {
        return TaskV2ApiFp(this.configuration).getByCompanyId(companyId, sortField, page, sortDirection, query, projectId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all tasks for a project (paginated)
     * @param {string} projectId 
     * @param {'Created' | 'Title' | 'ProjectTitle' | 'FromDateTime' | 'ToDateTime' | 'Status' | 'PhaseTitle'} sortField 
     * @param {string} [projectPhaseId] 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {string} [query] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskV2Api
     */
    public getPaginatedTasksByProjectId(projectId: string, sortField: 'Created' | 'Title' | 'ProjectTitle' | 'FromDateTime' | 'ToDateTime' | 'Status' | 'PhaseTitle', projectPhaseId?: string, page?: number, sortDirection?: 'ASC' | 'DESC', query?: string, authorization?: string, options?: AxiosRequestConfig) {
        return TaskV2ApiFp(this.configuration).getPaginatedTasksByProjectId(projectId, sortField, projectPhaseId, page, sortDirection, query, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all chained recurring tasks
     * @param {string} companyId 
     * @param {string} recurringTaskRelationId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskV2Api
     */
    public getRecurringTaskChainV2(companyId: string, recurringTaskRelationId: string, authorization?: string, options?: AxiosRequestConfig) {
        return TaskV2ApiFp(this.configuration).getRecurringTaskChainV2(companyId, recurringTaskRelationId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get task details
     * @param {string} taskId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskV2Api
     */
    public getTaskDetailsV2(taskId: string, authorization?: string, options?: AxiosRequestConfig) {
        return TaskV2ApiFp(this.configuration).getTaskDetailsV2(taskId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all tasks for a project
     * @param {string} projectId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskV2Api
     */
    public getTasksByProjectId(projectId: string, authorization?: string, options?: AxiosRequestConfig) {
        return TaskV2ApiFp(this.configuration).getTasksByProjectId(projectId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new recurring task
     * @param {string} companyId 
     * @param {RecurringTaskPostRequestV2} recurringTaskPostRequestV2 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskV2Api
     */
    public postRecurringTaskV2(companyId: string, recurringTaskPostRequestV2: RecurringTaskPostRequestV2, authorization?: string, options?: AxiosRequestConfig) {
        return TaskV2ApiFp(this.configuration).postRecurringTaskV2(companyId, recurringTaskPostRequestV2, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a task
     * @param {string} taskId 
     * @param {TaskPutRequest} taskPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskV2Api
     */
    public updateTask(taskId: string, taskPutRequest: TaskPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return TaskV2ApiFp(this.configuration).updateTask(taskId, taskPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update task phase
     * @param {string} taskId 
     * @param {TaskPutPhaseRequest} taskPutPhaseRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskV2Api
     */
    public updateTaskPhaseV2(taskId: string, taskPutPhaseRequest: TaskPutPhaseRequest, authorization?: string, options?: AxiosRequestConfig) {
        return TaskV2ApiFp(this.configuration).updateTaskPhaseV2(taskId, taskPutPhaseRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update task status
     * @param {string} taskId 
     * @param {TaskStatusRequestV2} taskStatusRequestV2 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskV2Api
     */
    public updateTaskProgressStatusV2(taskId: string, taskStatusRequestV2: TaskStatusRequestV2, authorization?: string, options?: AxiosRequestConfig) {
        return TaskV2ApiFp(this.configuration).updateTaskProgressStatusV2(taskId, taskStatusRequestV2, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
