import {
  FilePostRequestFileResourceTypeEnum,
  LocationPostRequestOwnerResourceTypeEnum,
  OfferDetails,
  ProjectPostRequestActivityCreationStrategyEnum,
  SalesOpportunity,
  SalesOpportunityApi,
  SalesOpportunityPostRequest,
  SalesOpportunityPriorityEnum,
  SalesOpportunityPutRequest,
  SalesOpportunityStatusEnum
} from "../../.generated/api";
import environment from "../../environment";
import { createLocation } from "./LocationApi";
import { customerInstance } from "./CustomerHttpBase";
import { createCustomerRelation } from "./CustomerRelationApiV3";
import { createQuery } from "react-query-kit";
import { updateProjectEconomy } from "./ProjectEconomyApi";
import { createProjectV2 } from "./ProjectApiV2";
import { generateOfferPdf } from "../export/ExportApi";
import { mapOfferSubsections } from "../../utils/exportUtils";
import { createFolder } from "./FolderApi";
import { uploadFile } from "./FileApi";
import { fetchPublicCompanyInfo } from "../../utils/exportPdf";
import { QueryClient } from "@tanstack/react-query";
import { archiveOffer } from "./OfferApi";
import { createCompanyOrder, getCompanyOrderStatuses } from "./OrderApi";

export type SalesOpportunitySortFields =
  | "Title"
  | "Status"
  | "ExpectedCloseDate"
  | "EstimatedValue"
  | "Priority"
  | "Owner"
  | "Customer"
  | "Price"
  | "DeliveryType";

const salesOpportunityApi = new SalesOpportunityApi(
  undefined,
  environment.skwApiNewBaseUrl,
  customerInstance
);

export type SortDirection = "ASC" | "DESC";
export type SalesStatusFilter =
  | "IdentifiedSale"
  | "InProgress"
  | "OfferSent"
  | "Won"
  | "Lost";

type PageRequestParams<T extends string> = {
  page?: number;
  sortDirection?: SortDirection;
  sortField?: T;
  query?: string;
  pageSize?: number;
};

export const useGetSalesOpportunitiesByCompanyId = createQuery({
  queryKey: ["getSalesOpportunitiesByCompanyId"],
  fetcher: async (
    variables: PageRequestParams<SalesOpportunitySortFields> & {
      companyId: string;
      archived: boolean;
      status?: SalesStatusFilter;
      onlyUserAssigned?: boolean;
    }
  ) => {
    const response =
      await salesOpportunityApi.getPaginatedSalesOpportunityByCompanyId(
        variables.companyId,
        variables.page,
        variables.sortDirection,
        variables.sortField,
        variables.query,
        variables.pageSize,
        variables.archived,
        variables.status,
        variables.onlyUserAssigned
      );
    return response.data;
  }
});

export async function getSalesOpportunitiesByCompanyId(companyId: string) {
  const { data } =
    await salesOpportunityApi.getSalesOpportunityByCompanyId(companyId);

  return data;
}

export const useGetSalesOpportunityById = createQuery({
  queryKey: ["getSalesOpportunityById"],
  fetcher: async (variables: {
    companyId: string;
    salesOpportunityId: string;
  }) => {
    const response =
      await salesOpportunityApi.getSalesOpportunityByCompanyIdAndId(
        variables.companyId,
        variables.salesOpportunityId
      );

    return response.data;
  }
});

export const useGetSalesOpportunityByCustomerId = createQuery({
  queryKey: ["getSalesOpportunityByCustomerId"],
  fetcher: async (variables: { companyId: string; customerId: string }) => {
    const { data } = await salesOpportunityApi.getSalesOpportunityByCustomerId(
      variables.companyId,
      variables.customerId
    );

    return data;
  }
});

export async function getSalesOpportunityByCompanyIdAndId(
  companyId: string,
  id: string
) {
  const { data } =
    await salesOpportunityApi.getSalesOpportunityByCompanyIdAndId(
      companyId,
      id
    );

  return data;
}

export async function createSalesOpportunity(
  companyId: string,
  data: SalesOpportunityPostRequest
) {
  const response = await salesOpportunityApi.createSalesOpportunity(
    companyId,
    data
  );
  return response.data;
}

export async function updateSalesOpportunity(
  companyId: string,
  id: string,
  data: SalesOpportunityPutRequest
) {
  await salesOpportunityApi.updateSalesOpportunity(companyId, id, data);
}

export type SalesOpportunityToProjectDetails = {
  projectTitle: string;
  projectDescription?: string;
  projectNumberSeriesId?: string;
  fromDateTime: string;
  toDateTime: string;
};
export async function convertSalesOpportunityToProject(
  companyId: string,
  salesOpportunity: SalesOpportunity,
  queryClient: QueryClient,
  language: string,
  currency: string,
  details: SalesOpportunityToProjectDetails,
  offer?: OfferDetails
) {
  const { address, customer, id } = salesOpportunity;
  const company = await fetchPublicCompanyInfo(companyId, queryClient);

  const project = await createProjectV2({
    title: details.projectTitle,
    description: details.projectDescription,
    fromDateTime: details.fromDateTime,
    toDateTime: details.toDateTime,
    projectSeriesId: details.projectNumberSeriesId,
    salesOpportunityId: id,
    activityCreationStrategy:
      ProjectPostRequestActivityCreationStrategyEnum.Hours,
    companyId,
    contractValue: undefined,
    estimatedHours: undefined
  });

  if (offer) {
    await updateProjectEconomy(project.id, {
      estimatedRevenue: offer.totalValue,
      isHourly: false,
      sendHourNotification: false
    });
    const blob = await generateOfferPdf({
      title: offer.title,
      description: offer.description ?? "",
      companyLogoUrl: company.companyLogoUrl,
      frontPageImageUrl: company.reportLogoUrl,
      customer: {
        email: offer.customerEmail ?? "",
        phone: offer.customerPhone ?? "",
        contactPersonName: offer.customer?.primaryContact?.firstName ?? "",
        address: offer.customer?.primaryContact?.address ?? ""
      },
      subsections: mapOfferSubsections(offer.subsections),
      company: {
        companyName: company.name,
        phone: company.contactPhone,
        email: company.contactEmail,
        orgNumber: company.orgNumber,
        contactPersonName: company.contactPerson
      },
      attachments: offer.attachments?.map((a) => ({
        fileUrl: a.downloadUrl,
        fileType: a.fileType,
        name: a.name
      })),
      language: language,
      currency: currency
    });
    const pdfFile = new File(
      [blob],
      `${offer.title.replace(/\s+/g, "_")}(Tilbud).pdf`,
      {
        type: "application/pdf",
        lastModified: Date.now()
      }
    );

    const folder = await createFolder(project.id, "Kontrakter");
    await uploadFile(
      pdfFile,
      project.id,
      undefined,
      folder.id,
      FilePostRequestFileResourceTypeEnum.General
    );

    //await archiveOffer(companyId, offer.id);
  } else {
    await updateProjectEconomy(project.id, {
      estimatedRevenue: salesOpportunity.price,
      estimatedHours: salesOpportunity.estimatedHours,
      isHourly: salesOpportunity.priceType === "hourly",
      sendHourNotification: false
    });
  }

  if (address)
    await createLocation({
      streetAddress: address,
      ownerResourceId: project.id,
      ownerResourceType: LocationPostRequestOwnerResourceTypeEnum.Project
    });

  await createCustomerRelation(companyId, customer.id, project.id, "Project");

  await salesOpportunityApi.archiveSalesOpportunity(companyId, id);

  return project.id;
}

type SalesOpportunityToOrderDetails = {
  orderTitle: string;
  orderDescription?: string;
  date: string;
  duration: number;
};
export async function convertSalesOpportunityToOrder(
  companyId: string,
  salesOpportunity: SalesOpportunity,
  queryClient: QueryClient,
  language: string,
  currency: string,
  details: SalesOpportunityToOrderDetails,
  offer?: OfferDetails
) {
  const { id, customer, address, price } = salesOpportunity;
  const orderStatuses = await getCompanyOrderStatuses(companyId);
  const company = await fetchPublicCompanyInfo(companyId, queryClient);

  const status =
    orderStatuses.find((s) => s.title === "Ikke påbegynt") ??
    orderStatuses?.[0];

  const orderId = await createCompanyOrder(companyId, {
    companyId: companyId,
    title: details.orderTitle,
    description: details.orderDescription ?? "",
    date: details.date,
    duration: details.duration,
    address: address,
    price: offer?.totalValue ?? price,
    priceType: "Fixed",
    referencePersonName: customer.formattedCustomerName ?? "",
    referencePersonNumber: "",
    statusId: status.id,
    tagIds: [],
    salesOpportunityId: id
  });

  if (offer) {
    const blob = await generateOfferPdf({
      title: offer.title,
      description: offer.description ?? "",
      companyLogoUrl: company.companyLogoUrl,
      frontPageImageUrl: company.reportLogoUrl,
      customer: {
        email: offer.customerEmail ?? "",
        phone: offer.customerPhone ?? "",
        contactPersonName: offer.customer?.primaryContact?.firstName ?? "",
        address: offer.customer?.primaryContact?.address ?? ""
      },
      subsections: mapOfferSubsections(offer.subsections),
      company: {
        companyName: company.name,
        phone: company.contactPhone,
        email: company.contactEmail,
        orgNumber: company.orgNumber,
        contactPersonName: company.contactPerson
      },
      attachments: offer.attachments?.map((a) => ({
        fileUrl: a.downloadUrl,
        fileType: a.fileType,
        name: a.name
      })),
      language: language,
      currency: currency
    });
    const pdfFile = new File(
      [blob],
      `${offer.title.replace(/\s+/g, "_")}(Tilbud).pdf`,
      {
        type: "application/pdf",
        lastModified: Date.now()
      }
    );

    const folder = await createFolder(orderId[0], "Kontrakter");
    await uploadFile(
      pdfFile,
      orderId[0],
      undefined,
      folder.id,
      FilePostRequestFileResourceTypeEnum.General
    );

    await archiveOffer(companyId, offer.id);
  }

  await createCustomerRelation(companyId, customer.id, orderId[0], "Order");
  await archiveSalesOppertunity(companyId, id);

  return orderId[0];
}

export async function archiveSalesOppertunity(
  companyId: string,
  id: string,
  markAsWon?: boolean
) {
  await salesOpportunityApi.archiveSalesOpportunity(companyId, id, markAsWon);
}

export function convertStatus(status?: SalesOpportunityStatusEnum) {
  switch (status) {
    case "InProgress":
      return "inProgress";
    case "IdentifiedSale":
      return "identifiedSale";
    case "OfferSent":
      return "offerSent";
    case "Lost":
      return "lost";
    case "Won":
      return "won";
    default:
      return "-";
  }
}

export function convertPriority(priority?: SalesOpportunityPriorityEnum) {
  switch (priority) {
    case "High":
      return "high";
    case "Low":
      return "low";
    case "Medium":
      return "medium";
    default:
      return "-";
  }
}
