import {
  createFileRoute,
  useLoaderData,
  useRouter
} from "@tanstack/react-router";
import useDialog from "../../../../../../hooks/useDialog";
import { useToast } from "../../../../../../hooks/useToast";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import {
  updateCurrentProjectPhase,
  useGetProjectPhasesByProjectId
} from "../../../../../../service/api/PhasesApi";
import { ProjectPhaseEntity } from "../../../../../../.generated/api";
import CopyProjectDialog from "../../../../../../components/dialogs/CopyProjectDialog";
import GuestPageSettingsDialog from "../../../../../../components/guest/GuestPageSettingsDialog";
import PageLayout from "../../../../../../components/PageLayout";
import ButtonNew from "../../../../../../components/ds/ButtonNew";
import { deleteProject } from "../../../../../../service/api/ProjectApi";
import { Callout, Flex } from "@radix-ui/themes";
import NoteComponent from "../../../../../../components/NoteComponent";
import MessageView from "../../../../../../components/ds/MessageView";
import {
  deleteProjectReportLogo,
  getProjectReportLogoUploadUrl,
  useGetProjectReportLogo
} from "../../../../../../service/api/ProjectApiV2";
import Dialog from "../../../../../../components/ds/Dialog";
import InputWrapperNew from "../../../../../../components/ds/InputWrapperNew";
import axios from "axios";
import { useCompanyId } from "../../../../../../hooks/useCompanyId";
import FileUpload from "../../../../../../components/ds/FileUpload";
import ProjectPreview from "../../../../../../pages/projectOverview/ProjectPreview";
import ProjectSummary from "../../../../../../pages/projectOverview/ProjectSummary";
import ActionButtons from "../../../../../../pages/projectOverview/ActionButtons";
import ChildProjects from "../../../../../../pages/projectOverview/ChildProjects";
import Tooltip from "../../../../../../components/ds/Tooltip";
import RadixIcon from "../../../../../../components/ds/RadixIcon";
import ResourcesModuleOrder from "../../../../../../pages/taskDetails/taskUserResources/ResourcesModuleOrder";
import Icon from "../../../../../../components/ds/Icon";
import ResourceInbox from "../../-components/ResourceInbox";

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/_layout/overview"
)({
  component: ProjectOverviewPage
});

function ProjectOverviewPage() {
  const project = useLoaderData({
    from: "/_protected/dashboard/project/$projectId/_layout"
  });

  const router = useRouter();
  const copyProjectDialog = useDialog();
  const guestPageDialog = useDialog();
  const reportLogoDialog = useDialog();
  const { showErrorToast } = useToast();
  const { t } = useTranslation();
  const companyId = useCompanyId();
  const [selectedPhase, setSelectedPhase] = useState(project.projectPhase);
  const [reportLogoUploadProgress, setReportLogoUploadProgress] =
    useState<number>();

  useEffect(() => {
    setSelectedPhase(project.projectPhase);
  }, [project.projectPhase]);

  const phasesQuery = useGetProjectPhasesByProjectId({
    variables: { projectId: project.id }
  });

  const reportLogoQuery = useGetProjectReportLogo({
    variables: { companyId: companyId, projectId: project.id }
  });

  async function handlePhaseChange(phase: ProjectPhaseEntity) {
    setSelectedPhase(phase);
    if (phase.id !== project.projectPhase.id) {
      await updateCurrentProjectPhase(project.id, phase.id);
      await router.invalidate();
    }
  }

  async function uploadReportLogo(files: File[]) {
    const file = files[0];
    try {
      const response = await getProjectReportLogoUploadUrl(
        companyId,
        project.id,
        file.type
      );

      if (!response.data?.url) return;

      const instance = axios.create();

      const fileData = await file.arrayBuffer();
      await instance.put(response.data.url, fileData, {
        onUploadProgress: (event) => {
          setReportLogoUploadProgress((event.progress ?? 0) * 100);
        },
        headers: {
          "Content-Disposition": `filename=${file.name}`,
          "Content-Type": file.type
        }
      });

      reportLogoQuery.refetch();
    } catch (_) {
      showErrorToast("Feil ved opplasting av forsidebilde");
    }
  }

  async function onDeleteProjectReportLogo() {
    await deleteProjectReportLogo(companyId, project.id);
    await reportLogoQuery.refetch();
  }

  const isOverdue = project.to ? new Date(project.to) < new Date() : false;

  return (
    <>
      <CopyProjectDialog
        open={copyProjectDialog.isOpen}
        onClose={copyProjectDialog.onClose}
        project={project}
      />
      <GuestPageSettingsDialog
        open={guestPageDialog.isOpen}
        onClose={guestPageDialog.onClose}
        resourceId={project.id}
        resourceType={"project"}
        companyId={project.companyId}
        publicValue={project.isPublic}
        onSettingsSaved={() => router.invalidate()}
      />
      <Dialog
        open={reportLogoDialog.isOpen}
        onClose={reportLogoDialog.onClose}
        title={"Forsidebilde rapporter"}
        description={
          "Dette bildet vil inkluderes på forsiden av alle prosjektrapporter"
        }
      >
        {reportLogoQuery.data?.url && (
          <InputWrapperNew>
            <div className={"h-[250px] flex flex-col"}>
              <div className={"mb-2 grow relative"}>
                <img
                  alt={"project logo"}
                  src={reportLogoQuery.data.url}
                  className={
                    "absolute max-h-full max-w-full top-1/2 translate-y-[-50%]"
                  }
                />
              </div>
              <div>
                <ButtonNew
                  variant={"outline"}
                  icon={"upload"}
                  onClick={onDeleteProjectReportLogo}
                >
                  {t("uploadNewImage")}
                </ButtonNew>
              </div>
            </div>
          </InputWrapperNew>
        )}
        {!reportLogoQuery.data?.url && (
          <FileUpload
            label={t("reportImage")}
            accept={"image"}
            id={"fileUpload"}
            progress={reportLogoUploadProgress}
            onFilesSelected={uploadReportLogo}
          />
        )}
      </Dialog>
      <PageLayout
        previewOffset={"regularWithTabBar"}
        preview={
          <ProjectPreview
            project={project}
            selectedPhase={selectedPhase}
            handlePhaseChange={handlePhaseChange}
            deleteProject={deleteProject}
            copyProjectDialog={copyProjectDialog}
            guestPageDialog={guestPageDialog}
            reportLogoDialog={reportLogoDialog}
            phasesQuery={phasesQuery}
          />
        }
      >
        <Flex direction={"column"} gap={"4"}>
          {isOverdue && (
            <Callout.Root color={"yellow"}>
              <Callout.Icon>
                <Icon icon={"info"} />
              </Callout.Icon>
              <Callout.Text>{t("projectOverviewCallout")}</Callout.Text>
            </Callout.Root>
          )}

          <ProjectSummary project={project} />

          <ActionButtons project={project} />

          {/*<LastEvents />*/}

          <Flex gap={"4"}>
            <div className={"flex-1"}>
              <ResourcesModuleOrder
                resourceId={project.id}
                resourceType={"project"}
                defaultRoleName={"Prosjektdeltaker"}
                fromDate={project.from}
                toDate={project.to}
              />
            </div>

            <div className={"flex-1"}>
              <ResourceInbox resourceId={project.id} />
            </div>
          </Flex>

          <Flex gap={"4"}>
            <div className={"flex-1"}>
              <MessageView
                title={
                  <Flex gap={"3"} align={"center"}>
                    <span>Chat med prosjektdeltakere</span>
                    <Tooltip
                      content={"Tilgjengelig i adminportalen og mobilappen"}
                    >
                      <RadixIcon
                        icon={"info"}
                        className={"text-radix-gray-10 align-middle"}
                      />
                    </Tooltip>
                  </Flex>
                }
                resourceId={project.id}
                resourceType={"project"}
                maxHeight={300}
              />
            </div>

            <div className={"flex-1"}>
              <NoteComponent resourceId={project.id} resourceType={"project"} />
            </div>
          </Flex>

          <ChildProjects parentProject={project} />
        </Flex>
      </PageLayout>
    </>
  );
}
