import createClient from "openapi-fetch";
import { paths } from "../../.generated/export";
import environment from "../../environment";

function serializeOmitNull(body: unknown) {
  return JSON.stringify(body, (_, value) => {
    if (value === null) return undefined;
    return value;
  });
}

export const exportClient = createClient<paths>({
  baseUrl: environment.exportBaseUrl,
  bodySerializer: serializeOmitNull
});
