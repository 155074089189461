import { createFileRoute } from "@tanstack/react-router";
import React from "react";
import NewInvoicePage from "../../../../../pages/invoice/NewInvoicePage";

export const Route = createFileRoute(
  "/_protected/dashboard/economy/_layout/invoice"
)({
  component: InvoicePage
});

function InvoicePage() {
  return <NewInvoicePage />;
}
