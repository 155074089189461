/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { GetOrdersByIdsRequest } from '../model';
// @ts-ignore
import { Order } from '../model';
// @ts-ignore
import { OrderActiveStatusUpdateRequest } from '../model';
// @ts-ignore
import { OrderArchiveRequest } from '../model';
// @ts-ignore
import { OrderInspectionPutRequest } from '../model';
// @ts-ignore
import { OrderPostRequest } from '../model';
// @ts-ignore
import { OrderPutRequest } from '../model';
// @ts-ignore
import { OrderStatus } from '../model';
// @ts-ignore
import { OrderStatusPostRequest } from '../model';
// @ts-ignore
import { OrderStatusPutRequest } from '../model';
// @ts-ignore
import { OrderTag } from '../model';
// @ts-ignore
import { OrderTagPostRequest } from '../model';
// @ts-ignore
import { OrderTagPutRequest } from '../model';
// @ts-ignore
import { OrderUpdateDateRequest } from '../model';
// @ts-ignore
import { PaginatedResponseOrder } from '../model';
// @ts-ignore
import { PublicResourceInfo } from '../model';
/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive order
         * @param {OrderArchiveRequest} orderArchiveRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveOrder: async (orderArchiveRequest: OrderArchiveRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderArchiveRequest' is not null or undefined
            assertParamExists('archiveOrder', 'orderArchiveRequest', orderArchiveRequest)
            const localVarPath = `/v1/order/archive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderArchiveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete existing company order status
         * @param {string} companyId 
         * @param {string} statusId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyOrderStatus: async (companyId: string, statusId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteCompanyOrderStatus', 'companyId', companyId)
            // verify required parameter 'statusId' is not null or undefined
            assertParamExists('deleteCompanyOrderStatus', 'statusId', statusId)
            const localVarPath = `/v1/company/{companyId}/order/status/{statusId}/delete`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"statusId"}}`, encodeURIComponent(String(statusId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete existing company order tag
         * @param {string} companyId 
         * @param {string} tagId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyOrderTag: async (companyId: string, tagId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteCompanyOrderTag', 'companyId', companyId)
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('deleteCompanyOrderTag', 'tagId', tagId)
            const localVarPath = `/v1/company/{companyId}/order/tag/{tagId}/delete`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete recurring orders
         * @param {string} companyId 
         * @param {string} relationId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteRecurringOrders: async (companyId: string, relationId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteRecurringOrders', 'companyId', companyId)
            // verify required parameter 'relationId' is not null or undefined
            assertParamExists('deleteRecurringOrders', 'relationId', relationId)
            const localVarPath = `/v1/company/{companyId}/order/recurring/{relationId}/delete`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"relationId"}}`, encodeURIComponent(String(relationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get public orders by list of ids
         * @param {Array<string>} orderIds 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPublicOrderInfoByIds: async (orderIds: Array<string>, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderIds' is not null or undefined
            assertParamExists('getAllPublicOrderInfoByIds', 'orderIds', orderIds)
            const localVarPath = `/v1/public/order/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderIds) {
                localVarQueryParameter['orderIds'] = orderIds;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get archived orders
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArchivedOrders: async (companyId: string, page?: number, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getArchivedOrders', 'companyId', companyId)
            const localVarPath = `/v1/order/{companyId}/archived`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company order
         * @param {string} companyId 
         * @param {string} orderId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyOrder: async (companyId: string, orderId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyOrder', 'companyId', companyId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getCompanyOrder', 'orderId', orderId)
            const localVarPath = `/v1/order/{companyId}/{orderId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company order statuses
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyOrderStatuses: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyOrderStatuses', 'companyId', companyId)
            const localVarPath = `/v1/order/status/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company order tags
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyOrderTags: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyOrderTags', 'companyId', companyId)
            const localVarPath = `/v1/order/tag/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company orders
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyOrders: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyOrders', 'companyId', companyId)
            const localVarPath = `/v1/order/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company orders by recurring template id
         * @param {string} companyId 
         * @param {string} templateId 
         * @param {boolean} onlyPending 
         * @param {number} [page] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyOrdersByRecurringTemplateId: async (companyId: string, templateId: string, onlyPending: boolean, page?: number, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyOrdersByRecurringTemplateId', 'companyId', companyId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('getCompanyOrdersByRecurringTemplateId', 'templateId', templateId)
            // verify required parameter 'onlyPending' is not null or undefined
            assertParamExists('getCompanyOrdersByRecurringTemplateId', 'onlyPending', onlyPending)
            const localVarPath = `/v1/order/company/{companyId}/orders/recurring/{templateId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (onlyPending !== undefined) {
                localVarQueryParameter['onlyPending'] = onlyPending;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company orders on specific service contract
         * @param {string} companyId 
         * @param {string} serviceContractId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyOrdersOnServiceContract: async (companyId: string, serviceContractId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyOrdersOnServiceContract', 'companyId', companyId)
            // verify required parameter 'serviceContractId' is not null or undefined
            assertParamExists('getCompanyOrdersOnServiceContract', 'serviceContractId', serviceContractId)
            const localVarPath = `/v1/order/company/{companyId}/service contract/{serviceContractId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"serviceContractId"}}`, encodeURIComponent(String(serviceContractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get orders by IDs
         * @param {string} companyId 
         * @param {Array<string>} [orderIds] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersByIds: async (companyId: string, orderIds?: Array<string>, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getOrdersByIds', 'companyId', companyId)
            const localVarPath = `/v1/order/{companyId}/orders`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderIds) {
                localVarQueryParameter['orderIds'] = orderIds;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get orders by IDs
         * @param {string} companyId 
         * @param {GetOrdersByIdsRequest} getOrdersByIdsRequest 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersByIdsPost: async (companyId: string, getOrdersByIdsRequest: GetOrdersByIdsRequest, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getOrdersByIdsPost', 'companyId', companyId)
            // verify required parameter 'getOrdersByIdsRequest' is not null or undefined
            assertParamExists('getOrdersByIdsPost', 'getOrdersByIdsRequest', getOrdersByIdsRequest)
            const localVarPath = `/v1/order/{companyId}/orders`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getOrdersByIdsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get orders for user
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersForUser: async (companyId: string, authorization?: string, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getOrdersForUser', 'companyId', companyId)
            const localVarPath = `/v1/order/{companyId}/user`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all overdue company orders
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverdueOrdersByCompanyId: async (companyId: string, page?: number, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getOverdueOrdersByCompanyId', 'companyId', companyId)
            const localVarPath = `/v1/order/{companyId}/overdue`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get active company orders, paginated
         * @param {string} companyId 
         * @param {boolean} withoutDate 
         * @param {string} [authorization] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [search] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedActiveCompanyOrders: async (companyId: string, withoutDate: boolean, authorization?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', search?: string, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getPaginatedActiveCompanyOrders', 'companyId', companyId)
            // verify required parameter 'withoutDate' is not null or undefined
            assertParamExists('getPaginatedActiveCompanyOrders', 'withoutDate', withoutDate)
            const localVarPath = `/v1/{companyId}/orders/active/paginated`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withoutDate !== undefined) {
                localVarQueryParameter['withoutDate'] = withoutDate;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search active orders from list, paginated
         * @param {string} companyId 
         * @param {boolean} withoutDate 
         * @param {string} [authorization] 
         * @param {Array<string>} [orderIds] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [search] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedActiveOrdersByIds: async (companyId: string, withoutDate: boolean, authorization?: string, orderIds?: Array<string>, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', search?: string, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getPaginatedActiveOrdersByIds', 'companyId', companyId)
            // verify required parameter 'withoutDate' is not null or undefined
            assertParamExists('getPaginatedActiveOrdersByIds', 'withoutDate', withoutDate)
            const localVarPath = `/v1/{companyId}/orders/list/active/paginated`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderIds) {
                localVarQueryParameter['orderIds'] = orderIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withoutDate !== undefined) {
                localVarQueryParameter['withoutDate'] = withoutDate;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company orders paginated
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [authorization] 
         * @param {boolean} [completed] 
         * @param {boolean} [recurring] 
         * @param {string} [search] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [withoutDate] 
         * @param {boolean} [invoiced] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPaginatedCompanyOrders: async (companyId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', authorization?: string, completed?: boolean, recurring?: boolean, search?: string, fromDate?: string, toDate?: string, withoutDate?: boolean, invoiced?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getPaginatedCompanyOrders', 'companyId', companyId)
            const localVarPath = `/v1/order/{companyId}/paginated`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (completed !== undefined) {
                localVarQueryParameter['completed'] = completed;
            }

            if (recurring !== undefined) {
                localVarQueryParameter['recurring'] = recurring;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (withoutDate !== undefined) {
                localVarQueryParameter['withoutDate'] = withoutDate;
            }

            if (invoiced !== undefined) {
                localVarQueryParameter['invoiced'] = invoiced;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get completed company orders, paginated
         * @param {string} companyId 
         * @param {boolean} isInvoiced 
         * @param {string} [authorization] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [search] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [onlyForUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedCompletedCompanyOrders: async (companyId: string, isInvoiced: boolean, authorization?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', search?: string, fromDate?: string, toDate?: string, onlyForUser?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getPaginatedCompletedCompanyOrders', 'companyId', companyId)
            // verify required parameter 'isInvoiced' is not null or undefined
            assertParamExists('getPaginatedCompletedCompanyOrders', 'isInvoiced', isInvoiced)
            const localVarPath = `/v1/{companyId}/orders/completed/paginated`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (isInvoiced !== undefined) {
                localVarQueryParameter['isInvoiced'] = isInvoiced;
            }

            if (onlyForUser !== undefined) {
                localVarQueryParameter['onlyForUser'] = onlyForUser;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search completed orders from list, paginated
         * @param {string} companyId 
         * @param {boolean} isInvoiced 
         * @param {string} [authorization] 
         * @param {Array<string>} [orderIds] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [search] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedCompletedOrdersByIds: async (companyId: string, isInvoiced: boolean, authorization?: string, orderIds?: Array<string>, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', search?: string, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getPaginatedCompletedOrdersByIds', 'companyId', companyId)
            // verify required parameter 'isInvoiced' is not null or undefined
            assertParamExists('getPaginatedCompletedOrdersByIds', 'isInvoiced', isInvoiced)
            const localVarPath = `/v1/{companyId}/orders/list/completed/paginated`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderIds) {
                localVarQueryParameter['orderIds'] = orderIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (isInvoiced !== undefined) {
                localVarQueryParameter['isInvoiced'] = isInvoiced;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search completed orders on specific facility, paginated
         * @param {string} companyId 
         * @param {string} facilityId 
         * @param {boolean} withoutDate 
         * @param {string} [authorization] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [search] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedFacilityActiveOrders: async (companyId: string, facilityId: string, withoutDate: boolean, authorization?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', search?: string, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getPaginatedFacilityActiveOrders', 'companyId', companyId)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('getPaginatedFacilityActiveOrders', 'facilityId', facilityId)
            // verify required parameter 'withoutDate' is not null or undefined
            assertParamExists('getPaginatedFacilityActiveOrders', 'withoutDate', withoutDate)
            const localVarPath = `/v1/order/company/{companyId}/facility/{facilityId}/active/paginated`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withoutDate !== undefined) {
                localVarQueryParameter['withoutDate'] = withoutDate;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search completed orders on specific facility, paginated
         * @param {string} companyId 
         * @param {string} facilityId 
         * @param {boolean} isInvoiced 
         * @param {string} [authorization] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [search] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedFacilityCompletedOrders: async (companyId: string, facilityId: string, isInvoiced: boolean, authorization?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', search?: string, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getPaginatedFacilityCompletedOrders', 'companyId', companyId)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('getPaginatedFacilityCompletedOrders', 'facilityId', facilityId)
            // verify required parameter 'isInvoiced' is not null or undefined
            assertParamExists('getPaginatedFacilityCompletedOrders', 'isInvoiced', isInvoiced)
            const localVarPath = `/v1/order/company/{companyId}/facility/{facilityId}/completed/paginated`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (isInvoiced !== undefined) {
                localVarQueryParameter['isInvoiced'] = isInvoiced;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get orders for user (paginated)
         * @param {string} companyId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [query] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedOrdersForUser: async (companyId: string, from?: string, to?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', query?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getPaginatedOrdersForUser', 'companyId', companyId)
            const localVarPath = `/v1/order/{companyId}/user/paginated`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get public order by id
         * @param {string} orderId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicOrderInfoById: async (orderId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getPublicOrderInfoById', 'orderId', orderId)
            const localVarPath = `/v1/public/order/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all chained recurring orders
         * @param {string} companyId 
         * @param {string} recurringOrderRelationId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getRecurringOrderChain: async (companyId: string, recurringOrderRelationId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getRecurringOrderChain', 'companyId', companyId)
            // verify required parameter 'recurringOrderRelationId' is not null or undefined
            assertParamExists('getRecurringOrderChain', 'recurringOrderRelationId', recurringOrderRelationId)
            const localVarPath = `/v1/company/{companyId}/order/recurring/{recurringOrderRelationId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"recurringOrderRelationId"}}`, encodeURIComponent(String(recurringOrderRelationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get unassigned orders
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnassignedOrders: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getUnassignedOrders', 'companyId', companyId)
            const localVarPath = `/v1/order/{companyId}/unassigned`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get unassigned orders (paginated)
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnassignedOrdersPaginated: async (companyId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getUnassignedOrdersPaginated', 'companyId', companyId)
            const localVarPath = `/v1/order/{companyId}/unassigned/paginated`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new company order
         * @param {OrderPostRequest} orderPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyOrder: async (orderPostRequest: OrderPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderPostRequest' is not null or undefined
            assertParamExists('postCompanyOrder', 'orderPostRequest', orderPostRequest)
            const localVarPath = `/v1/order/post`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new company order status
         * @param {OrderStatusPostRequest} orderStatusPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyOrderStatus: async (orderStatusPostRequest: OrderStatusPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderStatusPostRequest' is not null or undefined
            assertParamExists('postCompanyOrderStatus', 'orderStatusPostRequest', orderStatusPostRequest)
            const localVarPath = `/v1/order/status/post`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderStatusPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new company order tag
         * @param {OrderTagPostRequest} orderTagPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyOrderTag: async (orderTagPostRequest: OrderTagPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderTagPostRequest' is not null or undefined
            assertParamExists('postCompanyOrderTag', 'orderTagPostRequest', orderTagPostRequest)
            const localVarPath = `/v1/order/tag/post`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderTagPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new recurring company order
         * @param {OrderPostRequest} orderPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postRecurringCompanyOrder: async (orderPostRequest: OrderPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderPostRequest' is not null or undefined
            assertParamExists('postRecurringCompanyOrder', 'orderPostRequest', orderPostRequest)
            const localVarPath = `/v1/order/post/recurring`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update existing company order
         * @param {OrderPutRequest} orderPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompanyOrder: async (orderPutRequest: OrderPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderPutRequest' is not null or undefined
            assertParamExists('putCompanyOrder', 'orderPutRequest', orderPutRequest)
            const localVarPath = `/v1/order/put`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update existing company order date
         * @param {OrderUpdateDateRequest} orderUpdateDateRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompanyOrderDate: async (orderUpdateDateRequest: OrderUpdateDateRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderUpdateDateRequest' is not null or undefined
            assertParamExists('putCompanyOrderDate', 'orderUpdateDateRequest', orderUpdateDateRequest)
            const localVarPath = `/v1/order/date/put`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderUpdateDateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update existing company order status
         * @param {OrderStatusPutRequest} orderStatusPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompanyOrderStatus: async (orderStatusPutRequest: OrderStatusPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderStatusPutRequest' is not null or undefined
            assertParamExists('putCompanyOrderStatus', 'orderStatusPutRequest', orderStatusPutRequest)
            const localVarPath = `/v1/order/status/put`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderStatusPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update existing company order tag
         * @param {OrderTagPutRequest} orderTagPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompanyOrderTag: async (orderTagPutRequest: OrderTagPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderTagPutRequest' is not null or undefined
            assertParamExists('putCompanyOrderTag', 'orderTagPutRequest', orderTagPutRequest)
            const localVarPath = `/v1/order/tag/put`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderTagPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restore order
         * @param {OrderArchiveRequest} orderArchiveRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreOrder: async (orderArchiveRequest: OrderArchiveRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderArchiveRequest' is not null or undefined
            assertParamExists('restoreOrder', 'orderArchiveRequest', orderArchiveRequest)
            const localVarPath = `/v1/order/restore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderArchiveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update order status
         * @param {OrderActiveStatusUpdateRequest} orderActiveStatusUpdateRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyOrderStatus: async (orderActiveStatusUpdateRequest: OrderActiveStatusUpdateRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderActiveStatusUpdateRequest' is not null or undefined
            assertParamExists('updateCompanyOrderStatus', 'orderActiveStatusUpdateRequest', orderActiveStatusUpdateRequest)
            const localVarPath = `/v1/order/update/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderActiveStatusUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set inspection ID for order
         * @param {string} orderId 
         * @param {OrderInspectionPutRequest} orderInspectionPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderInspectionId: async (orderId: string, orderInspectionPutRequest: OrderInspectionPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('updateOrderInspectionId', 'orderId', orderId)
            // verify required parameter 'orderInspectionPutRequest' is not null or undefined
            assertParamExists('updateOrderInspectionId', 'orderInspectionPutRequest', orderInspectionPutRequest)
            const localVarPath = `/v1/order/{orderId}/inspection`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderInspectionPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Archive order
         * @param {OrderArchiveRequest} orderArchiveRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveOrder(orderArchiveRequest: OrderArchiveRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveOrder(orderArchiveRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete existing company order status
         * @param {string} companyId 
         * @param {string} statusId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompanyOrderStatus(companyId: string, statusId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompanyOrderStatus(companyId, statusId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete existing company order tag
         * @param {string} companyId 
         * @param {string} tagId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompanyOrderTag(companyId: string, tagId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompanyOrderTag(companyId, tagId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete recurring orders
         * @param {string} companyId 
         * @param {string} relationId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deleteRecurringOrders(companyId: string, relationId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRecurringOrders(companyId, relationId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get public orders by list of ids
         * @param {Array<string>} orderIds 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPublicOrderInfoByIds(orderIds: Array<string>, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicResourceInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPublicOrderInfoByIds(orderIds, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get archived orders
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArchivedOrders(companyId: string, page?: number, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArchivedOrders(companyId, page, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company order
         * @param {string} companyId 
         * @param {string} orderId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyOrder(companyId: string, orderId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyOrder(companyId, orderId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company order statuses
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyOrderStatuses(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyOrderStatuses(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company order tags
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyOrderTags(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderTag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyOrderTags(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company orders
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyOrders(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Order>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyOrders(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company orders by recurring template id
         * @param {string} companyId 
         * @param {string} templateId 
         * @param {boolean} onlyPending 
         * @param {number} [page] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyOrdersByRecurringTemplateId(companyId: string, templateId: string, onlyPending: boolean, page?: number, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyOrdersByRecurringTemplateId(companyId, templateId, onlyPending, page, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company orders on specific service contract
         * @param {string} companyId 
         * @param {string} serviceContractId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyOrdersOnServiceContract(companyId: string, serviceContractId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Order>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyOrdersOnServiceContract(companyId, serviceContractId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get orders by IDs
         * @param {string} companyId 
         * @param {Array<string>} [orderIds] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrdersByIds(companyId: string, orderIds?: Array<string>, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Order>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersByIds(companyId, orderIds, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get orders by IDs
         * @param {string} companyId 
         * @param {GetOrdersByIdsRequest} getOrdersByIdsRequest 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrdersByIdsPost(companyId: string, getOrdersByIdsRequest: GetOrdersByIdsRequest, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersByIdsPost(companyId, getOrdersByIdsRequest, page, sortDirection, sortField, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get orders for user
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrdersForUser(companyId: string, authorization?: string, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Order>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersForUser(companyId, authorization, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all overdue company orders
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOverdueOrdersByCompanyId(companyId: string, page?: number, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOverdueOrdersByCompanyId(companyId, page, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get active company orders, paginated
         * @param {string} companyId 
         * @param {boolean} withoutDate 
         * @param {string} [authorization] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [search] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedActiveCompanyOrders(companyId: string, withoutDate: boolean, authorization?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', search?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginatedActiveCompanyOrders(companyId, withoutDate, authorization, page, sortDirection, sortField, search, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search active orders from list, paginated
         * @param {string} companyId 
         * @param {boolean} withoutDate 
         * @param {string} [authorization] 
         * @param {Array<string>} [orderIds] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [search] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedActiveOrdersByIds(companyId: string, withoutDate: boolean, authorization?: string, orderIds?: Array<string>, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', search?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginatedActiveOrdersByIds(companyId, withoutDate, authorization, orderIds, page, sortDirection, sortField, search, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company orders paginated
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [authorization] 
         * @param {boolean} [completed] 
         * @param {boolean} [recurring] 
         * @param {string} [search] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [withoutDate] 
         * @param {boolean} [invoiced] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getPaginatedCompanyOrders(companyId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', authorization?: string, completed?: boolean, recurring?: boolean, search?: string, fromDate?: string, toDate?: string, withoutDate?: boolean, invoiced?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginatedCompanyOrders(companyId, page, sortDirection, sortField, authorization, completed, recurring, search, fromDate, toDate, withoutDate, invoiced, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get completed company orders, paginated
         * @param {string} companyId 
         * @param {boolean} isInvoiced 
         * @param {string} [authorization] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [search] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [onlyForUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedCompletedCompanyOrders(companyId: string, isInvoiced: boolean, authorization?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', search?: string, fromDate?: string, toDate?: string, onlyForUser?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginatedCompletedCompanyOrders(companyId, isInvoiced, authorization, page, sortDirection, sortField, search, fromDate, toDate, onlyForUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search completed orders from list, paginated
         * @param {string} companyId 
         * @param {boolean} isInvoiced 
         * @param {string} [authorization] 
         * @param {Array<string>} [orderIds] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [search] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedCompletedOrdersByIds(companyId: string, isInvoiced: boolean, authorization?: string, orderIds?: Array<string>, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', search?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginatedCompletedOrdersByIds(companyId, isInvoiced, authorization, orderIds, page, sortDirection, sortField, search, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search completed orders on specific facility, paginated
         * @param {string} companyId 
         * @param {string} facilityId 
         * @param {boolean} withoutDate 
         * @param {string} [authorization] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [search] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedFacilityActiveOrders(companyId: string, facilityId: string, withoutDate: boolean, authorization?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', search?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginatedFacilityActiveOrders(companyId, facilityId, withoutDate, authorization, page, sortDirection, sortField, search, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search completed orders on specific facility, paginated
         * @param {string} companyId 
         * @param {string} facilityId 
         * @param {boolean} isInvoiced 
         * @param {string} [authorization] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [search] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedFacilityCompletedOrders(companyId: string, facilityId: string, isInvoiced: boolean, authorization?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', search?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginatedFacilityCompletedOrders(companyId, facilityId, isInvoiced, authorization, page, sortDirection, sortField, search, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get orders for user (paginated)
         * @param {string} companyId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [query] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedOrdersForUser(companyId: string, from?: string, to?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', query?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginatedOrdersForUser(companyId, from, to, page, sortDirection, sortField, query, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get public order by id
         * @param {string} orderId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicOrderInfoById(orderId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicResourceInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicOrderInfoById(orderId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all chained recurring orders
         * @param {string} companyId 
         * @param {string} recurringOrderRelationId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getRecurringOrderChain(companyId: string, recurringOrderRelationId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Order>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecurringOrderChain(companyId, recurringOrderRelationId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get unassigned orders
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnassignedOrders(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Order>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnassignedOrders(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get unassigned orders (paginated)
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnassignedOrdersPaginated(companyId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnassignedOrdersPaginated(companyId, page, sortDirection, sortField, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new company order
         * @param {OrderPostRequest} orderPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompanyOrder(orderPostRequest: OrderPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompanyOrder(orderPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new company order status
         * @param {OrderStatusPostRequest} orderStatusPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompanyOrderStatus(orderStatusPostRequest: OrderStatusPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompanyOrderStatus(orderStatusPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new company order tag
         * @param {OrderTagPostRequest} orderTagPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompanyOrderTag(orderTagPostRequest: OrderTagPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderTag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompanyOrderTag(orderTagPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new recurring company order
         * @param {OrderPostRequest} orderPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async postRecurringCompanyOrder(orderPostRequest: OrderPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRecurringCompanyOrder(orderPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update existing company order
         * @param {OrderPutRequest} orderPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCompanyOrder(orderPutRequest: OrderPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCompanyOrder(orderPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update existing company order date
         * @param {OrderUpdateDateRequest} orderUpdateDateRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCompanyOrderDate(orderUpdateDateRequest: OrderUpdateDateRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCompanyOrderDate(orderUpdateDateRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update existing company order status
         * @param {OrderStatusPutRequest} orderStatusPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCompanyOrderStatus(orderStatusPutRequest: OrderStatusPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCompanyOrderStatus(orderStatusPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update existing company order tag
         * @param {OrderTagPutRequest} orderTagPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCompanyOrderTag(orderTagPutRequest: OrderTagPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderTag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCompanyOrderTag(orderTagPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Restore order
         * @param {OrderArchiveRequest} orderArchiveRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreOrder(orderArchiveRequest: OrderArchiveRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreOrder(orderArchiveRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update order status
         * @param {OrderActiveStatusUpdateRequest} orderActiveStatusUpdateRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyOrderStatus(orderActiveStatusUpdateRequest: OrderActiveStatusUpdateRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyOrderStatus(orderActiveStatusUpdateRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set inspection ID for order
         * @param {string} orderId 
         * @param {OrderInspectionPutRequest} orderInspectionPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderInspectionId(orderId: string, orderInspectionPutRequest: OrderInspectionPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderInspectionId(orderId, orderInspectionPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderApiFp(configuration)
    return {
        /**
         * 
         * @summary Archive order
         * @param {OrderArchiveRequest} orderArchiveRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveOrder(orderArchiveRequest: OrderArchiveRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.archiveOrder(orderArchiveRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete existing company order status
         * @param {string} companyId 
         * @param {string} statusId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyOrderStatus(companyId: string, statusId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteCompanyOrderStatus(companyId, statusId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete existing company order tag
         * @param {string} companyId 
         * @param {string} tagId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyOrderTag(companyId: string, tagId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteCompanyOrderTag(companyId, tagId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete recurring orders
         * @param {string} companyId 
         * @param {string} relationId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteRecurringOrders(companyId: string, relationId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteRecurringOrders(companyId, relationId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get public orders by list of ids
         * @param {Array<string>} orderIds 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPublicOrderInfoByIds(orderIds: Array<string>, authorization?: string, options?: any): AxiosPromise<Array<PublicResourceInfo>> {
            return localVarFp.getAllPublicOrderInfoByIds(orderIds, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get archived orders
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArchivedOrders(companyId: string, page?: number, authorization?: string, options?: any): AxiosPromise<PaginatedResponseOrder> {
            return localVarFp.getArchivedOrders(companyId, page, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company order
         * @param {string} companyId 
         * @param {string} orderId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyOrder(companyId: string, orderId: string, authorization?: string, options?: any): AxiosPromise<Order> {
            return localVarFp.getCompanyOrder(companyId, orderId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company order statuses
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyOrderStatuses(companyId: string, authorization?: string, options?: any): AxiosPromise<Array<OrderStatus>> {
            return localVarFp.getCompanyOrderStatuses(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company order tags
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyOrderTags(companyId: string, authorization?: string, options?: any): AxiosPromise<Array<OrderTag>> {
            return localVarFp.getCompanyOrderTags(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company orders
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyOrders(companyId: string, authorization?: string, options?: any): AxiosPromise<Array<Order>> {
            return localVarFp.getCompanyOrders(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company orders by recurring template id
         * @param {string} companyId 
         * @param {string} templateId 
         * @param {boolean} onlyPending 
         * @param {number} [page] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyOrdersByRecurringTemplateId(companyId: string, templateId: string, onlyPending: boolean, page?: number, authorization?: string, options?: any): AxiosPromise<PaginatedResponseOrder> {
            return localVarFp.getCompanyOrdersByRecurringTemplateId(companyId, templateId, onlyPending, page, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company orders on specific service contract
         * @param {string} companyId 
         * @param {string} serviceContractId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyOrdersOnServiceContract(companyId: string, serviceContractId: string, authorization?: string, options?: any): AxiosPromise<Array<Order>> {
            return localVarFp.getCompanyOrdersOnServiceContract(companyId, serviceContractId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get orders by IDs
         * @param {string} companyId 
         * @param {Array<string>} [orderIds] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersByIds(companyId: string, orderIds?: Array<string>, authorization?: string, options?: any): AxiosPromise<Array<Order>> {
            return localVarFp.getOrdersByIds(companyId, orderIds, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get orders by IDs
         * @param {string} companyId 
         * @param {GetOrdersByIdsRequest} getOrdersByIdsRequest 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersByIdsPost(companyId: string, getOrdersByIdsRequest: GetOrdersByIdsRequest, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', authorization?: string, options?: any): AxiosPromise<PaginatedResponseOrder> {
            return localVarFp.getOrdersByIdsPost(companyId, getOrdersByIdsRequest, page, sortDirection, sortField, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get orders for user
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersForUser(companyId: string, authorization?: string, from?: string, to?: string, options?: any): AxiosPromise<Array<Order>> {
            return localVarFp.getOrdersForUser(companyId, authorization, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all overdue company orders
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverdueOrdersByCompanyId(companyId: string, page?: number, authorization?: string, options?: any): AxiosPromise<PaginatedResponseOrder> {
            return localVarFp.getOverdueOrdersByCompanyId(companyId, page, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get active company orders, paginated
         * @param {string} companyId 
         * @param {boolean} withoutDate 
         * @param {string} [authorization] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [search] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedActiveCompanyOrders(companyId: string, withoutDate: boolean, authorization?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', search?: string, fromDate?: string, toDate?: string, options?: any): AxiosPromise<PaginatedResponseOrder> {
            return localVarFp.getPaginatedActiveCompanyOrders(companyId, withoutDate, authorization, page, sortDirection, sortField, search, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search active orders from list, paginated
         * @param {string} companyId 
         * @param {boolean} withoutDate 
         * @param {string} [authorization] 
         * @param {Array<string>} [orderIds] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [search] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedActiveOrdersByIds(companyId: string, withoutDate: boolean, authorization?: string, orderIds?: Array<string>, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', search?: string, fromDate?: string, toDate?: string, options?: any): AxiosPromise<PaginatedResponseOrder> {
            return localVarFp.getPaginatedActiveOrdersByIds(companyId, withoutDate, authorization, orderIds, page, sortDirection, sortField, search, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company orders paginated
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [authorization] 
         * @param {boolean} [completed] 
         * @param {boolean} [recurring] 
         * @param {string} [search] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [withoutDate] 
         * @param {boolean} [invoiced] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPaginatedCompanyOrders(companyId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', authorization?: string, completed?: boolean, recurring?: boolean, search?: string, fromDate?: string, toDate?: string, withoutDate?: boolean, invoiced?: boolean, options?: any): AxiosPromise<PaginatedResponseOrder> {
            return localVarFp.getPaginatedCompanyOrders(companyId, page, sortDirection, sortField, authorization, completed, recurring, search, fromDate, toDate, withoutDate, invoiced, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get completed company orders, paginated
         * @param {string} companyId 
         * @param {boolean} isInvoiced 
         * @param {string} [authorization] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [search] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [onlyForUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedCompletedCompanyOrders(companyId: string, isInvoiced: boolean, authorization?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', search?: string, fromDate?: string, toDate?: string, onlyForUser?: boolean, options?: any): AxiosPromise<PaginatedResponseOrder> {
            return localVarFp.getPaginatedCompletedCompanyOrders(companyId, isInvoiced, authorization, page, sortDirection, sortField, search, fromDate, toDate, onlyForUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search completed orders from list, paginated
         * @param {string} companyId 
         * @param {boolean} isInvoiced 
         * @param {string} [authorization] 
         * @param {Array<string>} [orderIds] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [search] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedCompletedOrdersByIds(companyId: string, isInvoiced: boolean, authorization?: string, orderIds?: Array<string>, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', search?: string, fromDate?: string, toDate?: string, options?: any): AxiosPromise<PaginatedResponseOrder> {
            return localVarFp.getPaginatedCompletedOrdersByIds(companyId, isInvoiced, authorization, orderIds, page, sortDirection, sortField, search, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search completed orders on specific facility, paginated
         * @param {string} companyId 
         * @param {string} facilityId 
         * @param {boolean} withoutDate 
         * @param {string} [authorization] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [search] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedFacilityActiveOrders(companyId: string, facilityId: string, withoutDate: boolean, authorization?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', search?: string, fromDate?: string, toDate?: string, options?: any): AxiosPromise<PaginatedResponseOrder> {
            return localVarFp.getPaginatedFacilityActiveOrders(companyId, facilityId, withoutDate, authorization, page, sortDirection, sortField, search, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search completed orders on specific facility, paginated
         * @param {string} companyId 
         * @param {string} facilityId 
         * @param {boolean} isInvoiced 
         * @param {string} [authorization] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [search] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedFacilityCompletedOrders(companyId: string, facilityId: string, isInvoiced: boolean, authorization?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', search?: string, fromDate?: string, toDate?: string, options?: any): AxiosPromise<PaginatedResponseOrder> {
            return localVarFp.getPaginatedFacilityCompletedOrders(companyId, facilityId, isInvoiced, authorization, page, sortDirection, sortField, search, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get orders for user (paginated)
         * @param {string} companyId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [query] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedOrdersForUser(companyId: string, from?: string, to?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', query?: string, authorization?: string, options?: any): AxiosPromise<PaginatedResponseOrder> {
            return localVarFp.getPaginatedOrdersForUser(companyId, from, to, page, sortDirection, sortField, query, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get public order by id
         * @param {string} orderId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicOrderInfoById(orderId: string, authorization?: string, options?: any): AxiosPromise<PublicResourceInfo> {
            return localVarFp.getPublicOrderInfoById(orderId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all chained recurring orders
         * @param {string} companyId 
         * @param {string} recurringOrderRelationId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getRecurringOrderChain(companyId: string, recurringOrderRelationId: string, authorization?: string, options?: any): AxiosPromise<Array<Order>> {
            return localVarFp.getRecurringOrderChain(companyId, recurringOrderRelationId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get unassigned orders
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnassignedOrders(companyId: string, authorization?: string, options?: any): AxiosPromise<Array<Order>> {
            return localVarFp.getUnassignedOrders(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get unassigned orders (paginated)
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnassignedOrdersPaginated(companyId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', authorization?: string, options?: any): AxiosPromise<PaginatedResponseOrder> {
            return localVarFp.getUnassignedOrdersPaginated(companyId, page, sortDirection, sortField, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new company order
         * @param {OrderPostRequest} orderPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyOrder(orderPostRequest: OrderPostRequest, authorization?: string, options?: any): AxiosPromise<Order> {
            return localVarFp.postCompanyOrder(orderPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new company order status
         * @param {OrderStatusPostRequest} orderStatusPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyOrderStatus(orderStatusPostRequest: OrderStatusPostRequest, authorization?: string, options?: any): AxiosPromise<OrderStatus> {
            return localVarFp.postCompanyOrderStatus(orderStatusPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new company order tag
         * @param {OrderTagPostRequest} orderTagPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyOrderTag(orderTagPostRequest: OrderTagPostRequest, authorization?: string, options?: any): AxiosPromise<OrderTag> {
            return localVarFp.postCompanyOrderTag(orderTagPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new recurring company order
         * @param {OrderPostRequest} orderPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postRecurringCompanyOrder(orderPostRequest: OrderPostRequest, authorization?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.postRecurringCompanyOrder(orderPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update existing company order
         * @param {OrderPutRequest} orderPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompanyOrder(orderPutRequest: OrderPutRequest, authorization?: string, options?: any): AxiosPromise<Order> {
            return localVarFp.putCompanyOrder(orderPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update existing company order date
         * @param {OrderUpdateDateRequest} orderUpdateDateRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompanyOrderDate(orderUpdateDateRequest: OrderUpdateDateRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.putCompanyOrderDate(orderUpdateDateRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update existing company order status
         * @param {OrderStatusPutRequest} orderStatusPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompanyOrderStatus(orderStatusPutRequest: OrderStatusPutRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.putCompanyOrderStatus(orderStatusPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update existing company order tag
         * @param {OrderTagPutRequest} orderTagPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompanyOrderTag(orderTagPutRequest: OrderTagPutRequest, authorization?: string, options?: any): AxiosPromise<OrderTag> {
            return localVarFp.putCompanyOrderTag(orderTagPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Restore order
         * @param {OrderArchiveRequest} orderArchiveRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreOrder(orderArchiveRequest: OrderArchiveRequest, authorization?: string, options?: any): AxiosPromise<string> {
            return localVarFp.restoreOrder(orderArchiveRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update order status
         * @param {OrderActiveStatusUpdateRequest} orderActiveStatusUpdateRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyOrderStatus(orderActiveStatusUpdateRequest: OrderActiveStatusUpdateRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.updateCompanyOrderStatus(orderActiveStatusUpdateRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set inspection ID for order
         * @param {string} orderId 
         * @param {OrderInspectionPutRequest} orderInspectionPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderInspectionId(orderId: string, orderInspectionPutRequest: OrderInspectionPutRequest, authorization?: string, options?: any): AxiosPromise<Order> {
            return localVarFp.updateOrderInspectionId(orderId, orderInspectionPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * 
     * @summary Archive order
     * @param {OrderArchiveRequest} orderArchiveRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public archiveOrder(orderArchiveRequest: OrderArchiveRequest, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).archiveOrder(orderArchiveRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete existing company order status
     * @param {string} companyId 
     * @param {string} statusId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public deleteCompanyOrderStatus(companyId: string, statusId: string, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).deleteCompanyOrderStatus(companyId, statusId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete existing company order tag
     * @param {string} companyId 
     * @param {string} tagId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public deleteCompanyOrderTag(companyId: string, tagId: string, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).deleteCompanyOrderTag(companyId, tagId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete recurring orders
     * @param {string} companyId 
     * @param {string} relationId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public deleteRecurringOrders(companyId: string, relationId: string, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).deleteRecurringOrders(companyId, relationId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get public orders by list of ids
     * @param {Array<string>} orderIds 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getAllPublicOrderInfoByIds(orderIds: Array<string>, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getAllPublicOrderInfoByIds(orderIds, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get archived orders
     * @param {string} companyId 
     * @param {number} [page] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getArchivedOrders(companyId: string, page?: number, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getArchivedOrders(companyId, page, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company order
     * @param {string} companyId 
     * @param {string} orderId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getCompanyOrder(companyId: string, orderId: string, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getCompanyOrder(companyId, orderId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company order statuses
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getCompanyOrderStatuses(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getCompanyOrderStatuses(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company order tags
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getCompanyOrderTags(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getCompanyOrderTags(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company orders
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getCompanyOrders(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getCompanyOrders(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company orders by recurring template id
     * @param {string} companyId 
     * @param {string} templateId 
     * @param {boolean} onlyPending 
     * @param {number} [page] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getCompanyOrdersByRecurringTemplateId(companyId: string, templateId: string, onlyPending: boolean, page?: number, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getCompanyOrdersByRecurringTemplateId(companyId, templateId, onlyPending, page, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company orders on specific service contract
     * @param {string} companyId 
     * @param {string} serviceContractId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getCompanyOrdersOnServiceContract(companyId: string, serviceContractId: string, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getCompanyOrdersOnServiceContract(companyId, serviceContractId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get orders by IDs
     * @param {string} companyId 
     * @param {Array<string>} [orderIds] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrdersByIds(companyId: string, orderIds?: Array<string>, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getOrdersByIds(companyId, orderIds, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get orders by IDs
     * @param {string} companyId 
     * @param {GetOrdersByIdsRequest} getOrdersByIdsRequest 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrdersByIdsPost(companyId: string, getOrdersByIdsRequest: GetOrdersByIdsRequest, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getOrdersByIdsPost(companyId, getOrdersByIdsRequest, page, sortDirection, sortField, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get orders for user
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrdersForUser(companyId: string, authorization?: string, from?: string, to?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getOrdersForUser(companyId, authorization, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all overdue company orders
     * @param {string} companyId 
     * @param {number} [page] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOverdueOrdersByCompanyId(companyId: string, page?: number, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getOverdueOrdersByCompanyId(companyId, page, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get active company orders, paginated
     * @param {string} companyId 
     * @param {boolean} withoutDate 
     * @param {string} [authorization] 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
     * @param {string} [search] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getPaginatedActiveCompanyOrders(companyId: string, withoutDate: boolean, authorization?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', search?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getPaginatedActiveCompanyOrders(companyId, withoutDate, authorization, page, sortDirection, sortField, search, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search active orders from list, paginated
     * @param {string} companyId 
     * @param {boolean} withoutDate 
     * @param {string} [authorization] 
     * @param {Array<string>} [orderIds] 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
     * @param {string} [search] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getPaginatedActiveOrdersByIds(companyId: string, withoutDate: boolean, authorization?: string, orderIds?: Array<string>, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', search?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getPaginatedActiveOrdersByIds(companyId, withoutDate, authorization, orderIds, page, sortDirection, sortField, search, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company orders paginated
     * @param {string} companyId 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
     * @param {string} [authorization] 
     * @param {boolean} [completed] 
     * @param {boolean} [recurring] 
     * @param {string} [search] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {boolean} [withoutDate] 
     * @param {boolean} [invoiced] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getPaginatedCompanyOrders(companyId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', authorization?: string, completed?: boolean, recurring?: boolean, search?: string, fromDate?: string, toDate?: string, withoutDate?: boolean, invoiced?: boolean, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getPaginatedCompanyOrders(companyId, page, sortDirection, sortField, authorization, completed, recurring, search, fromDate, toDate, withoutDate, invoiced, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get completed company orders, paginated
     * @param {string} companyId 
     * @param {boolean} isInvoiced 
     * @param {string} [authorization] 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
     * @param {string} [search] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {boolean} [onlyForUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getPaginatedCompletedCompanyOrders(companyId: string, isInvoiced: boolean, authorization?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', search?: string, fromDate?: string, toDate?: string, onlyForUser?: boolean, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getPaginatedCompletedCompanyOrders(companyId, isInvoiced, authorization, page, sortDirection, sortField, search, fromDate, toDate, onlyForUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search completed orders from list, paginated
     * @param {string} companyId 
     * @param {boolean} isInvoiced 
     * @param {string} [authorization] 
     * @param {Array<string>} [orderIds] 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
     * @param {string} [search] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getPaginatedCompletedOrdersByIds(companyId: string, isInvoiced: boolean, authorization?: string, orderIds?: Array<string>, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', search?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getPaginatedCompletedOrdersByIds(companyId, isInvoiced, authorization, orderIds, page, sortDirection, sortField, search, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search completed orders on specific facility, paginated
     * @param {string} companyId 
     * @param {string} facilityId 
     * @param {boolean} withoutDate 
     * @param {string} [authorization] 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
     * @param {string} [search] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getPaginatedFacilityActiveOrders(companyId: string, facilityId: string, withoutDate: boolean, authorization?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', search?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getPaginatedFacilityActiveOrders(companyId, facilityId, withoutDate, authorization, page, sortDirection, sortField, search, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search completed orders on specific facility, paginated
     * @param {string} companyId 
     * @param {string} facilityId 
     * @param {boolean} isInvoiced 
     * @param {string} [authorization] 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
     * @param {string} [search] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getPaginatedFacilityCompletedOrders(companyId: string, facilityId: string, isInvoiced: boolean, authorization?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', search?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getPaginatedFacilityCompletedOrders(companyId, facilityId, isInvoiced, authorization, page, sortDirection, sortField, search, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get orders for user (paginated)
     * @param {string} companyId 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
     * @param {string} [query] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getPaginatedOrdersForUser(companyId: string, from?: string, to?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', query?: string, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getPaginatedOrdersForUser(companyId, from, to, page, sortDirection, sortField, query, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get public order by id
     * @param {string} orderId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getPublicOrderInfoById(orderId: string, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getPublicOrderInfoById(orderId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all chained recurring orders
     * @param {string} companyId 
     * @param {string} recurringOrderRelationId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getRecurringOrderChain(companyId: string, recurringOrderRelationId: string, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getRecurringOrderChain(companyId, recurringOrderRelationId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get unassigned orders
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getUnassignedOrders(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getUnassignedOrders(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get unassigned orders (paginated)
     * @param {string} companyId 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType'} [sortField] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getUnassignedOrdersPaginated(companyId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OrderNumber' | 'Date' | 'Status' | 'Tags' | 'ReferencePerson' | 'CreatedAt' | 'PriceType', authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getUnassignedOrdersPaginated(companyId, page, sortDirection, sortField, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new company order
     * @param {OrderPostRequest} orderPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public postCompanyOrder(orderPostRequest: OrderPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).postCompanyOrder(orderPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new company order status
     * @param {OrderStatusPostRequest} orderStatusPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public postCompanyOrderStatus(orderStatusPostRequest: OrderStatusPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).postCompanyOrderStatus(orderStatusPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new company order tag
     * @param {OrderTagPostRequest} orderTagPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public postCompanyOrderTag(orderTagPostRequest: OrderTagPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).postCompanyOrderTag(orderTagPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new recurring company order
     * @param {OrderPostRequest} orderPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public postRecurringCompanyOrder(orderPostRequest: OrderPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).postRecurringCompanyOrder(orderPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update existing company order
     * @param {OrderPutRequest} orderPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public putCompanyOrder(orderPutRequest: OrderPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).putCompanyOrder(orderPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update existing company order date
     * @param {OrderUpdateDateRequest} orderUpdateDateRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public putCompanyOrderDate(orderUpdateDateRequest: OrderUpdateDateRequest, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).putCompanyOrderDate(orderUpdateDateRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update existing company order status
     * @param {OrderStatusPutRequest} orderStatusPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public putCompanyOrderStatus(orderStatusPutRequest: OrderStatusPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).putCompanyOrderStatus(orderStatusPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update existing company order tag
     * @param {OrderTagPutRequest} orderTagPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public putCompanyOrderTag(orderTagPutRequest: OrderTagPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).putCompanyOrderTag(orderTagPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Restore order
     * @param {OrderArchiveRequest} orderArchiveRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public restoreOrder(orderArchiveRequest: OrderArchiveRequest, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).restoreOrder(orderArchiveRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update order status
     * @param {OrderActiveStatusUpdateRequest} orderActiveStatusUpdateRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public updateCompanyOrderStatus(orderActiveStatusUpdateRequest: OrderActiveStatusUpdateRequest, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).updateCompanyOrderStatus(orderActiveStatusUpdateRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set inspection ID for order
     * @param {string} orderId 
     * @param {OrderInspectionPutRequest} orderInspectionPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public updateOrderInspectionId(orderId: string, orderInspectionPutRequest: OrderInspectionPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).updateOrderInspectionId(orderId, orderInspectionPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
