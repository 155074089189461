import { createFileRoute } from "@tanstack/react-router";
import { UpsertEstimation } from "./-components/UpsertEstimation";

export const Route = createFileRoute(
  "/_protected/dashboard/products/estimations/new"
)({
  component: NewEstimationPage
});

function NewEstimationPage() {
  return <UpsertEstimation />;
}
