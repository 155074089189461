import {
  createFileRoute,
  Link,
  useLoaderData,
  useNavigate,
  useRouter
} from "@tanstack/react-router";
import Card from "../../../../../../../components/ds/Card";
import IconButton from "../../../../../../../components/ds/buttons/IconButton";
import IconWithText from "../../../../../../../components/IconWithText";
import { formatFullNumericDate } from "../../../../../../../utils/DateTimeFormatter";
import DeadlineComponent from "../../../../../../../pages/sales/salesOppertunity/details/DeadlineComponent";
import NoteComponent from "../../../../../../../components/NoteComponent";
import { Trans, useTranslation } from "react-i18next";
import { formatCurrency } from "../../../../../../../utils/currencyFormatter";
import {
  archiveSalesOppertunity,
  updateSalesOpportunity
} from "../../../../../../../service/api/SalesOpportunityApi";
import { useCompanyId } from "../../../../../../../hooks/useCompanyId";
import { useToast } from "../../../../../../../hooks/useToast";
import useDialog from "../../../../../../../hooks/useDialog";
import { useGetCustomColumnsByCompanyId } from "../../../../../../../service/api/CustomColumnApi";
import { useRef } from "react";
import DropDown from "../../../../../../../components/ds/Dropdown/Dropdown";
import { getDsColorFromSalesOpportunityStatus } from "../../../../../../../utils/ConvertToDSColor";
import {
  getStatusLabel,
  SALES_OPPORTUNITY_STATUS_OPTIONS
} from "../../../../../../../pages/sales/utils";
import Icon from "../../../../../../../components/ds/Icon";
import DropdownItem from "../../../../../../../components/ds/Dropdown/DropdownItem";
import classNames from "classnames";
import Statusline from "../../../../../../../pages/sales/salesOppertunity/details/StatusLine";
import ConfirmationDialog from "../../../../../../../components/dialogs/ConfirmationDialog";
import WonModal from "../../../../../../../pages/sales/salesOppertunity/details/WonModal";
import ConvertToResourceDialog from "../../../-components/ConvertToResourceDialog";

export const Route = createFileRoute(
  "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/overview"
)({
  component: SalesOpportunityOverviewPage
});

function SalesOpportunityOverviewPage() {
  const { t } = useTranslation();

  const salesOpportunity = useLoaderData({
    from: "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout"
  });

  const navigate = useNavigate();
  const router = useRouter();
  const companyId = useCompanyId();
  const { showSuccessToast, showErrorToast } = useToast();
  const {
    isOpen: isArchiveOpen,
    onClose: onArchiveClose,
    onOpen: onArchiveOpen
  } = useDialog();
  const convertToProjectDialog = useDialog();
  const convertDialog = useDialog();
  const economyData = useRef<
    | {
        isHourly?: boolean;
        estimatedValue?: string;
        estimatedHours?: string;
      }
    | undefined
  >(undefined);

  const {
    isOpen: isWonDialogOpen,
    onClose: onWonDialogClose,
    onOpen: onWonDialogOpen
  } = useDialog();

  const customColumnsQuery = useGetCustomColumnsByCompanyId({
    variables: { companyId }
  });
  const customColumns = customColumnsQuery.data ?? [];

  const {
    startDate,
    endDate,
    ownerId,
    owner,
    description,
    address,
    status,
    customer,
    priority,
    leadSource,
    expectedCloseDate,
    title,
    id,
    archived,
    deliveryType,
    price,
    priceType,
    estimatedHours,
    customColumnOne,
    customColumnTwo
  } = salesOpportunity;

  economyData.current = {
    estimatedValue: salesOpportunity.price?.toString(),
    estimatedHours: salesOpportunity.estimatedHours?.toString(),
    isHourly: salesOpportunity.priceType === "hourly"
  };

  let priceText = formatCurrency(price);
  if (priceType === "hourly") {
    const total = price * estimatedHours;
    priceText = formatCurrency(total);

    priceText =
      formatCurrency(total) +
      ` (${estimatedHours} ${t(" timer à ")} ${formatCurrency(
        price
      )} ${t(" per hour")})`;
  }

  return (
    <div className={"p-5"}>
      <ConvertToResourceDialog
        open={convertDialog.isOpen}
        onClose={convertDialog.onClose}
        salesOpportunity={salesOpportunity}
      />
      <ConfirmationDialog
        title={t("Arkiver salgsmulighet")}
        message={t("Er du sikker på at du vil arkivere salgsmuligheten?")}
        confirmText={t("Arkiver")}
        onCancel={onArchiveClose}
        open={isArchiveOpen}
        onConfirm={() => {
          archiveSalesOppertunity(companyId, salesOpportunity.id, false)
            .then(() => {
              onArchiveClose();
              navigate({
                to: "/dashboard/sales",
                search: {
                  tab: "salesOpportunities"
                }
              });
              showSuccessToast(t("Salgsmulighet arkivert"));
            })
            .catch(() => {
              showErrorToast(t("Kunne ikke arkivere salgsmulighet"));
            });
        }}
      />
      <WonModal
        salesOpportunity={salesOpportunity}
        isOpen={isWonDialogOpen}
        onClose={onWonDialogClose}
        onConvertToProject={convertToProjectDialog.onOpen}
        refetch={router.invalidate}
      />
      <div className={"flex items-center justify-between mb-5"}>
        <Statusline status={status} />
        <div className={"flex items-center gap-2"}>
          {!archived && (
            <div className={"flex min-w-[200px] justify-end"}>
              <DropDown
                button={
                  <button
                    className={classNames(
                      "px-4 py-3 text-rg rounded-2 gap-2 flex items-center",
                      getDsColorFromSalesOpportunityStatus(status)
                    )}
                  >
                    <Trans>
                      {
                        SALES_OPPORTUNITY_STATUS_OPTIONS.find(
                          ({ value }) => value === status
                        )?.label
                      }
                    </Trans>
                    <Icon icon={"expand_more"} />
                  </button>
                }
              >
                {SALES_OPPORTUNITY_STATUS_OPTIONS.map((item, index) => (
                  <DropdownItem
                    key={index}
                    onClick={async () => {
                      await updateSalesOpportunity(companyId, id, {
                        status: item.value,
                        owner: ownerId,
                        priority,
                        title,
                        address,
                        deliveryTypeId: deliveryType?.id,
                        description,
                        startDate,
                        endDate,
                        leadSource,
                        expectedCloseDate,
                        price,
                        priceType,
                        estimatedHours,
                        customColumnOne,
                        customColumnTwo
                      });

                      if (item.value === "Won") onWonDialogOpen();

                      await router.invalidate();
                    }}
                    label={t(getStatusLabel(item.value))}
                  />
                ))}
              </DropDown>
            </div>
          )}
          {!archived && (
            <DropDown
              button={
                <IconButton icon="more_vert" size={"4"} variant={"surface"} />
              }
            >
              <DropdownItem onClick={onArchiveOpen} label={t("Arkiver")} />
              <DropdownItem
                onClick={convertDialog.onOpen}
                label={t("convert")}
              />
            </DropDown>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 3xl:grid-cols-3">
          {/* Details card */}
          <Card
            isFullHeight
            title={t("details")}
            trailing={
              !archived && (
                <IconButton asChild icon={"edit"} variant={"soft"}>
                  <Link
                    to={
                      "/dashboard/sales/sales-opportunity/$salesOpportunityId/update"
                    }
                    params={{
                      salesOpportunityId: salesOpportunity.id
                    }}
                  />
                </IconButton>
              )
            }
          >
            <div className="flex flex-col gap-3">
              {owner && <IconWithText icon={"assignment_ind"} text={owner} />}
              {description && (
                <IconWithText icon={"notes"} text={description} />
              )}
              {leadSource && <IconWithText icon={"link"} text={leadSource} />}
              {expectedCloseDate && (
                <IconWithText
                  icon={"calendar_month"}
                  text={formatFullNumericDate(new Date(expectedCloseDate))}
                />
              )}
              {address && <IconWithText icon={"location_on"} text={address} />}
              {startDate && endDate && (
                <IconWithText
                  icon={"timer"}
                  text={`${formatFullNumericDate(new Date(startDate))} -
                        ${formatFullNumericDate(new Date(endDate))}`}
                />
              )}
              {priceText && <IconWithText icon={"payments"} text={priceText} />}

              {customColumns.map((column, index) => {
                const value = index === 0 ? customColumnOne : customColumnTwo;

                if (!value) return;

                return (
                  <div key={index} className="flex items-center gap-2">
                    <IconWithText
                      icon={"short_text"}
                      text={`${column.title}: `}
                    />
                    <p>{value}</p>
                  </div>
                );
              })}
            </div>
          </Card>
          <DeadlineComponent
            resourceId={salesOpportunity.id}
            canAdd={!salesOpportunity.archived}
          />
          <Card>
            <div className="grid grid-cols-2 gap-3">
              {customer && (
                <div className={"flex flex-col gap-3"}>
                  <h3 className={"font-bold"}>{t("customer") + ":"}</h3>
                  {customer.formattedCustomerName !== "" && (
                    <IconWithText
                      icon={
                        customer.type === "Corporate" ? "apartment" : "person"
                      }
                      text={customer.formattedCustomerName}
                    />
                  )}
                  <IconWithText
                    icon={"person"}
                    text={customer.firstName + " " + customer.lastName}
                  />
                  {customer.mobilePhone && (
                    <IconWithText icon={"phone"} text={customer.mobilePhone} />
                  )}
                  {customer.workPhone && (
                    <IconWithText icon={"phone"} text={customer.workPhone} />
                  )}
                  {customer.email && (
                    <IconWithText icon={"email"} text={customer.email} />
                  )}
                </div>
              )}
              {customer && (
                <div className={"flex flex-col gap-3"}>
                  <h3 className={"font-bold"}>{t("contactPerson") + ":"}</h3>
                  {salesOpportunity.contactPerson && (
                    <IconWithText
                      icon={"person"}
                      text={
                        salesOpportunity.contactPerson.firstName +
                        " " +
                        salesOpportunity.contactPerson.lastName
                      }
                    />
                  )}
                  {salesOpportunity.contactPerson && (
                    <IconWithText
                      icon={"phone"}
                      text={salesOpportunity.contactPerson.mobilePhone}
                    />
                  )}
                  {salesOpportunity.contactPerson && (
                    <IconWithText
                      icon={"email"}
                      text={salesOpportunity.contactPerson.email}
                    />
                  )}
                </div>
              )}
            </div>
          </Card>
        </div>
        <NoteComponent
          resourceId={salesOpportunity.id}
          resourceType={"salesOpportunity"}
        />
      </div>
    </div>
  );
}
