/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface OfferCheckbox
 */
export interface OfferCheckbox {
    /**
     * 
     * @type {string}
     * @memberof OfferCheckbox
     */
    'type': OfferCheckboxTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OfferCheckbox
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OfferCheckbox
     */
    'title': string;
    /**
     * 
     * @type {boolean}
     * @memberof OfferCheckbox
     */
    'customerChecked': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfferCheckbox
     */
    'required': boolean;
    /**
     * 
     * @type {number}
     * @memberof OfferCheckbox
     */
    'orderIndex': number;
}

export const OfferCheckboxTypeEnum = {
    ProductGroup: 'ProductGroup',
    Checkbox: 'Checkbox',
    TextField: 'TextField',
    Description: 'Description',
    Product: 'Product'
} as const;

export type OfferCheckboxTypeEnum = typeof OfferCheckboxTypeEnum[keyof typeof OfferCheckboxTypeEnum];


