import { createFileRoute } from "@tanstack/react-router";
import { UpsertOfferPage } from "../../../../../pages/sales/salesOppertunity/details/offer/UpsertOfferPage";

export const Route = createFileRoute("/_protected/dashboard/sales/offer/new")({
  component: CreateOfferPage
});

function CreateOfferPage() {
  return <UpsertOfferPage />;
}
