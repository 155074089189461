import {
  createFileRoute,
  Link,
  useLoaderData,
  useNavigate,
  useRouter
} from "@tanstack/react-router";
import PageLayout from "../../../../../../components/PageLayout";
import CardNew from "../../../../../../components/ds/CardNew";
import DetailsCard from "../../../overview/-components/DetailsCard";
import { useTranslation } from "react-i18next";
import ResourcesModuleOrder from "../../../../../../pages/taskDetails/taskUserResources/ResourcesModuleOrder";
import NoteComponent from "../../../../../../components/NoteComponent";
import React from "react";
import PreviewSubtitle from "../../../../../../components/ds/preview/PreviewSubtitle";
import ButtonNew from "../../../../../../components/ds/ButtonNew";
import { useGetCustomerByResourceId } from "../../../../../../service/api/CustomerApiV3";
import DataListItem from "../../../../../../components/ds/datalist/DataListItem";
import DataList from "../../../../../../components/ds/datalist/DataList";
import { formatHoursAndMinutes } from "../../../../../../utils/DateTimeFormatter";
import { addHours } from "date-fns";
import { formatCurrency } from "../../../../../../utils/currencyFormatter";
import Badge from "../../../../../../components/ds/Badge";
import { DropdownMenu, ScrollArea } from "@radix-ui/themes";
import { useGetOrderStatusesByCompanyId } from "../../../../../../service/api/OrderStatusApi";
import { useCompanyId } from "../../../../../../hooks/useCompanyId";
import { OrderStatus } from "../../../../../../.generated/api";
import {
  deleteOrder,
  updateOrderActiveStatus,
  updateOrderDate
} from "../../../../../../service/api/OrderApi";
import { useToast } from "../../../../../../hooks/useToast";
import useDialog from "../../../../../../hooks/useDialog";
import GuestPageSettingsDialog from "../../../../../../components/guest/GuestPageSettingsDialog";
import DeleteBlock from "../../../../../../components/buttons/DeleteBlock";
import { useGetDeviationsByResourceId } from "../../../../../../service/api/DeviationApi";
import { useGetTimeEntriesByResourceId } from "../../../../../../service/api/TimeEntryApi";
import { useGetChecklistByResourceId } from "../../../../../../service/api/ChecklistApiV2";
import { getFormattedHoursAndMinutesOnTimeSubmits } from "../../../../../../service/api/utils";
import { ApiError } from "../../../../../../service/ErrorHandler";
import DatePickerNew from "../../../../../../components/ds/DatePicker/DatePickerNew";
import ResourceInbox from "../../../project/-components/ResourceInbox";
import MarkdownViewer from "../../../../../../components/MarkdownViewer";
import Dialog from "../../../../../../components/ds/Dialog";

export const Route = createFileRoute(
  "/_protected/dashboard/orders/$orderId/_layout/overview"
)({
  component: OrderOverviewPage
});

function OrderOverviewPage() {
  const { t } = useTranslation();
  const companyId = useCompanyId();
  const router = useRouter();
  const { onClose, onOpen, isOpen } = useDialog();
  const descriptionDialog = useDialog();
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToast();
  const order = useLoaderData({
    from: "/_protected/dashboard/orders/$orderId/_layout"
  });

  const deviationQuery = useGetDeviationsByResourceId({
    variables: { resourceId: order.id }
  });

  const timeQuery = useGetTimeEntriesByResourceId({
    variables: { resourceId: order.id, companyId: companyId }
  });

  const customerQuery = useGetCustomerByResourceId({
    variables: { resourceId: order.id, companyId: order.companyId }
  });

  const orderStatusQuery = useGetOrderStatusesByCompanyId({
    variables: { companyId }
  });

  const checklistQuery = useGetChecklistByResourceId({
    variables: { resourceId: order.id }
  });

  const deviations = deviationQuery.data ?? [];
  const timeEntries = timeQuery.data ?? [];
  const checklists = checklistQuery.data ?? [];
  const completedChecklists = checklists.filter((c) => c.completed).length;

  const openDeviations = deviations.filter(
    (d) => d.deviationStatus === "open"
  ).length;

  const timeEntriesForApproval = timeEntries.filter((t) => !t.approved);

  const statuses = orderStatusQuery.data ?? [];
  const customer = customerQuery.data;
  order.tags.sort((a, b) => a.title.localeCompare(b.title));

  const startDate = order.date ? new Date(order.date) : undefined;
  const endDate = startDate ? addHours(startDate, order.duration) : undefined;

  const timeString =
    startDate && endDate
      ? `${formatHoursAndMinutes(startDate)} - ${formatHoursAndMinutes(endDate)} - (${order.duration} ${t("hours").toLowerCase()})`
      : `${order.duration} ${t("hours").toLowerCase()}`;

  async function handleStatusChange(status: OrderStatus) {
    if (status.id === order.status.id) return;

    try {
      await updateOrderActiveStatus({
        companyId,
        orderId: order.id,
        statusId: status.id
      });

      showSuccessToast(t("orderStatusUpdated"));
      await router.invalidate();
    } catch (e) {
      const apiError = e as ApiError;
      if (apiError?.errorCode === "ChecklistRequired") {
        showErrorToast(t("allChecklistsMustBeCompletedToMarkAsCompleted"));
      } else {
        showErrorToast(t("anErrorOccurred"));
      }
    }
  }

  async function handleDateChange(date: Date) {
    try {
      await updateOrderDate({
        date: date.toISOString(),
        id: order.id,
        companyId: companyId,
        duration: order.duration
      });
      await router.invalidate();
    } catch (_) {
      showErrorToast(t("anErrorOccurred"));
    }
  }

  async function handleDurationChange(duration: number) {
    if (!order.date) return;
    try {
      await updateOrderDate({
        date: order.date,
        id: order.id,
        companyId: companyId,
        duration: duration
      });
      await router.invalidate();
    } catch (_) {
      showErrorToast(t("anErrorOccurred"));
    }
  }

  return (
    <>
      <GuestPageSettingsDialog
        open={isOpen}
        onClose={onClose}
        resourceId={order.id}
        resourceType={"order"}
        companyId={companyId}
        publicValue={order.isPublic}
        onSettingsSaved={router.invalidate}
      />
      <Dialog
        open={descriptionDialog.isOpen}
        onClose={descriptionDialog.onClose}
        maxWidth={"xl"}
        title={t("description")}
      >
        <ScrollArea className={"max-h-[600px]"}>
          <MarkdownViewer>{order.description}</MarkdownViewer>
        </ScrollArea>
      </Dialog>

      <PageLayout
        previewOffset={"regularWithTabBar"}
        preview={
          <>
            <PreviewSubtitle title={t("quickActions")} withSeparator={false} />
            <div className={"grid grid-cols-2 gap-2"}>
              <ButtonNew
                icon={"contract"}
                variant={"soft"}
                color={"gray"}
                asChild
              >
                <Link
                  to={"/dashboard/economy/invoice/new"}
                  search={{
                    orderId: order.id
                  }}
                >
                  {t("sendInvoice")}
                </Link>
              </ButtonNew>
              <DeleteBlock
                title={t("archiveOrder")}
                confirmationText={t("archiveOrderConfirmationText")}
                render={(onClick) => {
                  return (
                    <ButtonNew
                      icon={"contract"}
                      variant={"soft"}
                      color={"gray"}
                      onClick={onClick}
                    >
                      {t("archiveOrder")}
                    </ButtonNew>
                  );
                }}
                deleteFunction={() =>
                  deleteOrder({ companyId, orderId: order.id })
                }
                onDeleted={() => {
                  showSuccessToast(t("orderArchived"));
                  navigate({
                    to: "/dashboard/orders"
                  });
                }}
              />

              <ButtonNew
                color={"gray"}
                icon={"settings"}
                variant={"soft"}
                className={"col-span-2"}
                onClick={onOpen}
              >
                {t("customerPortalSettings")}
              </ButtonNew>
              <ButtonNew
                color={"gray"}
                icon={"open_in_new"}
                variant={"soft"}
                disabled={!order.isPublic}
                className={"col-span-2"}
                asChild
              >
                <Link
                  target={"_blank"}
                  to={"/guest/order/$orderId"}
                  params={{ orderId: order.id }}
                >
                  {t("customerPortal")}
                </Link>
              </ButtonNew>
              {order.salesOpportunityId && (
                <ButtonNew
                  color={"gray"}
                  icon={"open_in_new"}
                  variant={"soft"}
                  className={"col-span-2"}
                  asChild
                >
                  <Link
                    to={
                      "/dashboard/sales/sales-opportunity/$salesOpportunityId/overview"
                    }
                    params={{ salesOpportunityId: order.salesOpportunityId }}
                  >
                    {t("showArchivedSalesOpportunity")}
                  </Link>
                </ButtonNew>
              )}
            </div>

            <PreviewSubtitle
              title={t("orderDetails")}
              trailing={
                <ButtonNew icon={"edit"} asChild variant={"soft"} size={"1"}>
                  <Link
                    to={"/dashboard/orders/$orderId/update"}
                    params={{ orderId: order.id }}
                  >
                    {t("edit")}
                  </Link>
                </ButtonNew>
              }
            />
            <DataList>
              <DataListItem
                label={t("status")}
                value={
                  <DropdownMenu.Root>
                    <DropdownMenu.Trigger>
                      <ButtonNew
                        size={"1"}
                        variant={"soft"}
                        color={"gray"}
                        trailingIcon={"keyboard_arrow_down"}
                      >
                        {order.status.title}
                      </ButtonNew>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content>
                      {statuses.map((status) => (
                        <DropdownMenu.Item
                          key={status.id}
                          onClick={() => handleStatusChange(status)}
                        >
                          {status.title}
                        </DropdownMenu.Item>
                      ))}
                    </DropdownMenu.Content>
                  </DropdownMenu.Root>
                }
              />
              <DataListItem
                label={t("date")}
                value={
                  <DatePickerNew
                    withTime
                    type={"single"}
                    value={order.date ? new Date(order.date) : undefined}
                    onChange={handleDateChange}
                    trigger={(ref, vale) => (
                      <ButtonNew
                        ref={ref}
                        size={"1"}
                        variant={"soft"}
                        color={"gray"}
                        trailingIcon={"keyboard_arrow_down"}
                      >
                        {vale ?? t("noDateSelected")}
                      </ButtonNew>
                    )}
                  />
                }
              />
              <DataListItem
                label={t("duration")}
                value={
                  <DropdownMenu.Root>
                    <DropdownMenu.Trigger disabled={!order.date}>
                      <ButtonNew
                        size={"1"}
                        variant={"soft"}
                        color={"gray"}
                        trailingIcon={"keyboard_arrow_down"}
                      >
                        {timeString}
                      </ButtonNew>
                    </DropdownMenu.Trigger>

                    <DropdownMenu.Content>
                      {Array.from(Array(12).keys()).map((i) => (
                        <DropdownMenu.Item
                          key={i}
                          onClick={() => handleDurationChange(i + 1)}
                        >
                          {i + 1} {t("hours").toLowerCase()}
                        </DropdownMenu.Item>
                      ))}
                    </DropdownMenu.Content>
                  </DropdownMenu.Root>
                }
              />
              <DataListItem
                label={t("price")}
                value={`${formatCurrency(order.price)} (${t(order.priceType).toLowerCase()})`}
              />
              <DataListItem
                label={t("tags")}
                value={
                  <div className={"flex gap-2 flex-wrap"}>
                    {order.tags.map((tag) => (
                      <Badge key={tag.id} color={"blue"}>
                        {tag.title}
                      </Badge>
                    ))}
                  </div>
                }
              />
              <DataListItem label={t("address")} value={order.address} />
              <DataListItem
                label={t("description")}
                value={
                  <ButtonNew
                    size={"1"}
                    variant={"soft"}
                    icon={"visibility"}
                    onClick={descriptionDialog.onOpen}
                  >
                    {t("showDescription")}
                  </ButtonNew>
                }
              />
            </DataList>
            <PreviewSubtitle title={t("customer")} />
            {customer && (
              <DataList>
                <DataListItem
                  label={t("customer")}
                  value={customer.formattedCustomerName}
                />
                {customer.type === "Corporate" && (
                  <DataListItem
                    label={t("contactPerson")}
                    value={`${customer.primaryContact?.firstName} ${customer.primaryContact?.lastName}`}
                  />
                )}
                <DataListItem
                  label={t("phone")}
                  value={customer.primaryContact?.mobilePhone}
                />
                <DataListItem
                  label={t("email")}
                  value={customer.primaryContact?.email}
                />
              </DataList>
            )}
          </>
        }
      >
        <div className={"grid grid-cols-2 gap-5"}>
          <CardNew inset className={"col-span-2"}>
            <div className={"grid grid-cols-3 divide-x divide-radix-gray-6"}>
              <DetailsCard
                withBorder={false}
                title={t("openDeviations")}
                loading={false}
                subtitle={`${deviations.length} ${t("total")}`}
                number={openDeviations.toString()}
              />
              <DetailsCard
                withBorder={false}
                title={t("hoursForApproval")}
                loading={false}
                number={getFormattedHoursAndMinutesOnTimeSubmits(
                  timeEntriesForApproval
                )}
                subtitle={`${getFormattedHoursAndMinutesOnTimeSubmits(timeEntries)} ${t("total")}`}
              />
              <DetailsCard
                withBorder={false}
                title={t("completedChecklists")}
                loading={false}
                subtitle={`${checklists.length} ${t("total")}`}
                number={completedChecklists.toString()}
              />
            </div>
          </CardNew>
          <ResourcesModuleOrder
            defaultRoleName={"Ordredeltaker"}
            resourceId={order.id}
            toDate={order.date}
            fromDate={order.date}
            resourceType={"order"}
          />
          <NoteComponent resourceId={order.id} resourceType={"order"} />
          <ResourceInbox resourceId={order.id} />
        </div>
      </PageLayout>
    </>
  );
}
