import { useState } from "react";
import { createFileRoute, Link } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { Link as RadixLink, Text } from "@radix-ui/themes";
import { useCompanyId } from "../../../../../../hooks/useCompanyId";
import { useGetInboundEmailConfig } from "../../../../../../service/api/CompanyApi";
import {
  MailSortField,
  useGetEmailsByCompanyId
} from "../../../../../../service/api/MailApi";
import PageLayout from "../../../../../../components/PageLayout";
import CardNew from "../../../../../../components/ds/CardNew";
import { TextFieldNew } from "../../../../../../components/ds/TextFieldNew";
import PaginationNew from "../../../../../../components/ds/PaginationNew";
import { searchBarSize } from "../../../../../../components/ds/sizes";
import Table from "../../../../../../components/ds/RadixTable/Table";
import TableHeader from "../../../../../../components/ds/RadixTable/TableHeader";
import TableRow from "../../../../../../components/ds/RadixTable/TableRow";
import TableHeaderCell from "../../../../../../components/ds/RadixTable/TableHeaderCell";
import TableBody from "../../../../../../components/ds/RadixTable/TableBody";
import TableCell from "../../../../../../components/ds/RadixTable/TableCell";
import { formatFullNumericDateString } from "../../../../../../utils/DateTimeFormatter";
import Badge from "../../../../../../components/ds/Badge";
import ButtonNew from "../../../../../../components/ds/ButtonNew";
import EmailPreviewDrawer from "./-components/EmailPreviewDrawer";
import { useSort } from "../../../../../../hooks/useSort";
import { keepPreviousData } from "@tanstack/react-query";
import Select from "../../../../../../components/ds/NewSelect";
import { useAppStore } from "../../../../../../state/app_store";

export const Route = createFileRoute(
  "/_protected/dashboard/customers/_layout/inbox/"
)({
  component: InboxPage
});

function InboxPage() {
  const { t } = useTranslation();
  const companyId = useCompanyId();
  const [open, setOpen] = useState(false);
  const [selectedEmailId, setSelectedEmailId] = useState<string>();
  const [filter, setFilter] = useAppStore((s) => [
    s.inboundEmailFilter,
    s.setInboundEmailFilter
  ]);

  const user = useAppStore((a) => a.user);

  const {
    page,
    setPage,
    query,
    debouncedQuery,
    setQuery,
    sortDirection,
    sortField,
    registerHeader
  } = useSort<MailSortField>("CreatedAt", "mailSort");

  const inboundEmailConfigQuery = useGetInboundEmailConfig({
    variables: { companyId }
  });

  const hasEmailConfig =
    inboundEmailConfigQuery.data !== undefined &&
    inboundEmailConfigQuery.data?.email !== undefined;

  const emailQuery = useGetEmailsByCompanyId({
    placeholderData: keepPreviousData,
    variables: {
      companyId,
      page: page,
      query: filter === "mine" ? user?.email : "" + debouncedQuery,
      sortDirection,
      sortField
    }
  });

  const emails = emailQuery.data?.data ?? [];
  const selectedEmail = emails.find((email) => email.id === selectedEmailId);

  return (
    <PageLayout>
      {selectedEmail && (
        <EmailPreviewDrawer
          open={open}
          key={selectedEmail.id}
          onClose={() => setOpen(false)}
          email={selectedEmail}
          onUpdated={() => emailQuery.refetch()}
        />
      )}
      <CardNew
        inset
        title={t("inbox")}
        headerContent={
          <>
            <TextFieldNew
              icon={"search"}
              placeholder={t("searchForEmail")}
              className={searchBarSize}
              size={"2"}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <Select
              size={"2"}
              value={filter}
              onChange={(value) => setFilter(value)}
              options={[
                { label: t("all"), value: "all" },
                { label: t("mine"), value: "mine" }
              ]}
            />
            <Text className={"self-center"} size={"1"} color={"gray"}>
              {t("inboundEmailMaxFileSize")}
            </Text>
          </>
        }
        footerContent={
          <PaginationNew
            page={page}
            totalPages={emailQuery.data?.totalPages}
            setPage={setPage}
          />
        }
      >
        <Table variant="ghost">
          <TableHeader>
            <TableRow>
              <TableHeaderCell sortFn={registerHeader("Subject")}>
                {t("subject")}
              </TableHeaderCell>
              <TableHeaderCell sortFn={registerHeader("Read")}>
                {t("read")}
              </TableHeaderCell>
              <TableHeaderCell sortFn={registerHeader("CreatedAt")}>
                {t("date")}
              </TableHeaderCell>
              <TableHeaderCell sortFn={registerHeader("FromName")}>
                {t("sender")}
              </TableHeaderCell>
              <TableHeaderCell>{t("customer")}</TableHeaderCell>
              <TableHeaderCell>{t("projectOrOrder")}</TableHeaderCell>
              <TableHeaderCell>{t("attachments")}</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {hasEmailConfig && emails.length === 0 && (
              <TableRow>
                <TableCell>{t("noEmailsFound")}</TableCell>
              </TableRow>
            )}
            {!hasEmailConfig && (
              <TableRow>
                <TableCell colSpan={8}>
                  <Text>
                    {t("inboundSettingsText1")}{" "}
                    <RadixLink asChild>
                      <Link to={"/dashboard/settings/company"}>
                        {t("settings").toLowerCase()}
                      </Link>
                    </RadixLink>{" "}
                    {t("inboundSettingsText2")}
                  </Text>
                </TableCell>
              </TableRow>
            )}
            {emails.map((email) => (
              <TableRow key={email.id}>
                <TableCell>
                  <ButtonNew
                    highContrast
                    variant={"ghost"}
                    className={"group-hover/tr:underline"}
                    onClick={() => {
                      setSelectedEmailId(email.id);
                      setOpen(true);
                    }}
                  >
                    {email.subject}
                  </ButtonNew>
                </TableCell>
                <TableCell py={"0"} className={"align-middle"}>
                  {!email.read && <Badge color={"amber"}>{t("unread")}</Badge>}
                </TableCell>
                <TableCell>
                  {formatFullNumericDateString(email.createdAt)}
                </TableCell>
                <TableCell>
                  {email.fromName} ({email.fromEmail})
                </TableCell>
                <TableCell>{email.customerName}</TableCell>
                <TableCell>{email.resourceName}</TableCell>

                <TableCell py={"0"} className={"align-middle"}>
                  <Badge color="blue">
                    {email.attachments} {t("attachments")}
                  </Badge>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardNew>
    </PageLayout>
  );
}
