import { productsInstance } from "./ProductsModuleHttpBase";

import {
  ProductPriceRequest,
  ProductRequest,
  ProductApi
} from "../../.generated/api";
import environment from "../../environment";
import { createQuery } from "react-query-kit";
import { SortDirection } from "../../hooks/useSort";

export type ProductSortField = Parameters<
  typeof productApi.getPaginatedProductsByCompanyId
>[6];

export type ProductFilterType = Parameters<
  typeof productApi.getPaginatedProductsByCompanyId
>[8];

const productApi = new ProductApi(
  undefined,
  environment.skwApiNewBaseUrl,
  productsInstance
);

export const useGetPaginatedProductsByCompanyId = createQuery({
  queryKey: ["getPaginatedProductsByCompanyId"],
  fetcher: async (variables: {
    companyId: string;
    page?: number;
    sortField?: ProductSortField;
    sortDirection?: SortDirection;
    query?: string;
    archived?: boolean;
    categoryIds?: string;
    filter?: ProductFilterType;
  }) => {
    const response = await productApi.getPaginatedProductsByCompanyId(
      variables.companyId,
      undefined,
      variables.categoryIds ? variables.categoryIds.split(",") : undefined,
      variables.archived,
      variables.page,
      variables.sortDirection,
      variables.sortField,
      variables.query,
      variables.filter
    );

    return response.data;
  }
});

export const useGetProductById = createQuery({
  queryKey: ["getProductById"],
  fetcher: async (variables: { companyId: string; productId: string }) => {
    const response = await productApi.getProductById(
      variables.companyId,
      variables.productId
    );
    return response.data.body!;
  }
});

export async function createProduct(
  companyId: string,
  products: ProductRequest
) {
  return await productApi.createProduct(companyId, products);
}

export async function deleteProduct(companyId: string, request: string) {
  await productApi.deleteProduct(companyId, request);
}

export async function updateProduct(
  companyId: string,
  productId: string,
  product: ProductRequest
) {
  await productApi.updateProduct(companyId, productId, product);
}

export async function updateProductPrices(
  companyId: string,
  productPrice: ProductPriceRequest
) {
  await productApi.updateProductPrices(companyId, productPrice);
}
