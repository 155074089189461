import React, { useEffect } from "react";
import t, { ToastBar, Toaster, useToasterStore } from "react-hot-toast";
import { Callout, Spinner } from "@radix-ui/themes";
import RadixIcon from "../ds/RadixIcon";

function getToastColor(type: string) {
  if (type === "success") return "green";
  if (type === "loading") return "gray";
  if (type === "error") return "red";
}

type CustomToasterProps = {
  id: "drawer" | "regular";
};

export default function CustomToast({ id }: CustomToasterProps) {
  const { toasts } = useToasterStore();

  useEffect(() => {
    toasts
      .filter((tt) => tt.visible)
      .filter((_, i) => i >= 1)
      .forEach((tt) => t.dismiss(tt.id));
  }, [toasts]);

  return (
    <Toaster position={"bottom-center"} toasterId={id}>
      {(toast) => {
        return (
          <ToastBar
            position={"bottom-center"}
            toast={toast}
            style={{
              backgroundColor: "var(--color-background)",
              margin: "0px",
              padding: "0px"
            }}
          >
            {() => (
              <Callout.Root color={getToastColor(toast.type)} size={"1"}>
                <Callout.Icon>
                  {toast.type === "loading" && <Spinner />}
                  {toast.type === "success" && (
                    <RadixIcon icon={"check_circle"} />
                  )}
                  {toast.type === "error" && <RadixIcon icon={"error"} />}
                </Callout.Icon>
                <Callout.Text>{toast.message as string}</Callout.Text>
              </Callout.Root>
            )}
          </ToastBar>
        );
      }}
    </Toaster>
  );
}
