import { createFileRoute } from "@tanstack/react-router";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import { useToast } from "../../../../../hooks/useToast";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  deleteCompanyLogo,
  deleteCompanyReportLogo,
  getLogoUploadUrl,
  getReportLogoUploadUrl,
  updateCompanyMenu,
  useGetCompanyDetails,
  useGetCompanyLogo,
  useGetCompanyReportLogo
} from "../../../../../service/api/CompanyApi";
import axios from "axios";
import FormContainer from "../../../../../components/ds/layout/FormContainer";
import ContactSection from "./-components/ContactSection";
import FormSection from "../../../../../components/ds/layout/FormSection";
import CardNew from "../../../../../components/ds/CardNew";
import InputWrapperNew from "../../../../../components/ds/InputWrapperNew";
import ButtonNew from "../../../../../components/ds/ButtonNew";
import FileUpload from "../../../../../components/ds/FileUpload";
import EconomySection from "./-components/EconomySection";
import PageLayout from "../../../../../components/PageLayout";
import { useAppStore } from "../../../../../state/app_store";
import { refetchCompany } from "../../../../../hooks/useCompanyResolver";
import { useQueryClient } from "@tanstack/react-query";
import { Text } from "@radix-ui/themes";
import SwitchNew from "../../../../../components/ds/SwitchNew";
import { useGetTimekeepingCompanySettings } from "../../../../../service/api/CompanySettingsApi";
import InboundEmailSection from "./-components/InboundEmailSection";

type MenuItemSettings = {
  orders: boolean;
  projects: boolean;
  tasks: boolean;
  resourcePlanner: boolean;
  sales: boolean;
  products: boolean;
  timekeeping: boolean;
};

export const Route = createFileRoute(
  "/_protected/dashboard/settings/_layout/company"
)({
  component: CompanySettingsPage
});

function CompanySettingsPage() {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();
  const company = useAppStore((s) => s.selectedCompany);
  const { showErrorToast } = useToast();
  const [uploadProgress, setUploadProgress] = useState<number>();
  const [reportUploadProgress, setReportUploadProgress] = useState<number>();
  const { t } = useTranslation();

  const logoQuery = useGetCompanyLogo({ variables: { companyId } });

  const reportLogoQuery = useGetCompanyReportLogo({ variables: { companyId } });

  const companyQuery = useGetCompanyDetails({
    variables: { companyId }
  });

  const companySettingsQuery = useGetTimekeepingCompanySettings({
    variables: { companyId }
  });

  async function onDeleteCompanyLogo() {
    await deleteCompanyLogo(companyId);
    await logoQuery.refetch();
  }

  async function onDeleteCompanyReportLogo() {
    await deleteCompanyReportLogo(companyId);
    await reportLogoQuery.refetch();
  }

  const [menuItems, setMenuItems] = useState<MenuItemSettings>({
    orders: company?.features.orders ?? false,
    projects: company?.features.projects ?? false,
    tasks: company?.features.tasks ?? false,
    resourcePlanner: company?.features.resourcePlanner ?? false,
    sales: company?.features.sales ?? false,
    products: company?.features.products ?? false,
    timekeeping: company?.features.timekeeping ?? false
  });

  async function handleUpdate(key: keyof MenuItemSettings) {
    const updated = {
      ...menuItems,
      [key]: !menuItems[key]
    };

    setMenuItems(updated);
    await updateCompanyMenu(companyId, updated);
    await refetchCompany(queryClient);
  }

  async function uploadReportLogo(files: File[]) {
    const file = files[0];
    try {
      const response = await getReportLogoUploadUrl(companyId, file.type);

      const instance = axios.create();

      const fileData = await file.arrayBuffer();
      await instance.put(response.data.url, fileData, {
        onUploadProgress: (event) => {
          setReportUploadProgress((event.progress ?? 0) * 100);
        },
        headers: {
          "Content-Disposition": `filename=${file.name}`,
          "Content-Type": file.type
        }
      });

      setUploadProgress(undefined);
      reportLogoQuery.refetch();
    } catch (_) {
      showErrorToast("Feil ved opplasting av logo");
    }
  }

  async function uploadCompanyLogo(files: File[]) {
    const file = files[0];
    try {
      const response = await getLogoUploadUrl(companyId, file.type);

      const instance = axios.create();

      const fileData = await file.arrayBuffer();
      await instance.put(response.data.url, fileData, {
        onUploadProgress: (event) => {
          setUploadProgress((event.progress ?? 0) * 100);
        },
        headers: {
          "Content-Disposition": `filename=${file.name}`,
          "Content-Type": file.type
        }
      });

      setUploadProgress(undefined);
      logoQuery.refetch();
    } catch (_) {
      showErrorToast("Feil ved opplasting av logo");
    }
  }

  return (
    <PageLayout>
      <FormContainer>
        <ContactSection
          key={`contact-section-${companyQuery.dataUpdatedAt}`}
          onUpdated={companyQuery.refetch}
          companyData={companyQuery.data}
        />
        <FormSection
          title={t("logo")}
          description={t("logoSectionDescription")}
          withSeparator
          content={
            <CardNew>
              {logoQuery.data?.url && (
                <InputWrapperNew label={t("companyLogo")}>
                  <div className={"h-36 flex flex-col"}>
                    <div className={"mb-2 grow relative"}>
                      <img
                        alt={"company logo"}
                        src={logoQuery.data.url}
                        className={
                          "absolute max-h-full max-w-[250px] top-1/2 translate-y-[-50%]"
                        }
                      />
                    </div>
                    <div>
                      <ButtonNew
                        variant={"outline"}
                        icon={"upload"}
                        onClick={onDeleteCompanyLogo}
                      >
                        {t("uploadNewImage")}
                      </ButtonNew>
                    </div>
                  </div>
                </InputWrapperNew>
              )}
              {!logoQuery.data?.url && (
                <FileUpload
                  label={t("Last opp logo")}
                  accept={"image"}
                  progress={uploadProgress}
                  onFilesSelected={uploadCompanyLogo}
                />
              )}
              {reportLogoQuery.data?.url && (
                <InputWrapperNew label={t("reportImage")}>
                  <div className={"h-[300px] flex flex-col"}>
                    <div className={"mb-2 grow relative"}>
                      <img
                        alt={"company logo"}
                        src={reportLogoQuery.data.url}
                        className={
                          "absolute max-h-full max-w-[250px] top-1/2 translate-y-[-50%]"
                        }
                      />
                    </div>
                    <div>
                      <ButtonNew
                        variant={"outline"}
                        icon={"upload"}
                        onClick={onDeleteCompanyReportLogo}
                      >
                        {t("uploadNewImage")}
                      </ButtonNew>
                    </div>
                  </div>
                </InputWrapperNew>
              )}
              {!reportLogoQuery.data?.url && (
                <FileUpload
                  label={t("reportImage")}
                  accept={"image"}
                  id={"fileUpload"}
                  progress={reportUploadProgress}
                  onFilesSelected={uploadReportLogo}
                />
              )}
            </CardNew>
          }
        />
        <InboundEmailSection />
        <FormSection
          withSeparator
          title={t("navigationMenu")}
          description={t("navigationMenuText")}
          content={
            <CardNew>
              <div className={"flex flex-col gap-4"}>
                <Text as={"p"} weight={"bold"}>
                  {t("adminportalAndApp")}
                </Text>
                <SwitchNew
                  size={"1"}
                  label={t("projects")}
                  onClick={() => handleUpdate("projects")}
                  checked={menuItems.projects}
                />
                <SwitchNew
                  size={"1"}
                  label={t("workOrders")}
                  onClick={() => handleUpdate("orders")}
                  checked={menuItems.orders}
                />
                <Text as={"p"} weight={"bold"}>
                  {t("adminportal")}
                </Text>
                <SwitchNew
                  size={"1"}
                  label={t("resourcePlanner")}
                  onClick={() => handleUpdate("resourcePlanner")}
                  checked={menuItems.resourcePlanner}
                />
                <SwitchNew
                  size={"1"}
                  label={t("sales")}
                  onClick={() => handleUpdate("sales")}
                  checked={menuItems.sales}
                />
                <SwitchNew
                  size={"1"}
                  label={t("products")}
                  onClick={() => handleUpdate("products")}
                  checked={menuItems.products}
                />
                <Text as={"p"} weight={"bold"}>
                  {t("app")}
                </Text>
                <SwitchNew
                  size={"1"}
                  label={t("tasks")}
                  onClick={() => handleUpdate("tasks")}
                  checked={menuItems.tasks}
                />
                <SwitchNew
                  size={"1"}
                  label={t("timekeeping")}
                  onClick={() => handleUpdate("timekeeping")}
                  checked={menuItems.timekeeping}
                />
              </div>
            </CardNew>
          }
        />
        <EconomySection
          key={companyQuery.dataUpdatedAt + companySettingsQuery.dataUpdatedAt}
          companyData={companyQuery.data}
          settingsData={companySettingsQuery.data}
          onUpdated={() => {
            companyQuery.refetch();
            companySettingsQuery.refetch();
          }}
        />
      </FormContainer>
    </PageLayout>
  );
}
