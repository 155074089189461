import * as XLSX from "xlsx";
import {
  OfferCheckboxTypeEnum,
  OfferSubsection,
  ProjectV2,
  SalesOpportunity,
  TimeEntry
} from "../.generated/api";
import { saveAsExcel } from "../pages/timeInvoice/utils";
import { getProjectsByIds } from "../service/api/ProjectApiV2";
import { useGetSalesOpportunitiesByCompanyId } from "../service/api/SalesOpportunityApi";
import { getUserById, useGetUsersOnResource } from "../service/api/UserApi";
import {
  formatFullNumericDate,
  formatFullNumericDateString
} from "./DateTimeFormatter";
import { useQueryClient } from "@tanstack/react-query";
import { useGetCustomerByResourceId } from "../service/api/CustomerApiV3";
import { useGetProjectEconomy } from "../service/api/ProjectEconomyApi";
import { formatCurrency } from "./currencyFormatter";
import {
  isOfferCheckbox,
  isOfferDescription,
  isOfferProducts,
  isOfferTextField
} from "../pages/sales/salesOppertunity/details/offer/-components/schema";

export type ExportType = "excel" | "csv" | "tripletex" | "poweroffice";

type ExportFormat = {
  rawDate: string;
  data: {
    EmployeeNumber?: number;
    Name: string;
    SalaryType?: string;
    Date: string;
    ProjectNumber?: string;
    ProjectName?: string;
    ActivityNumber: number;
    ActivityName: string;
    PayItemCode?: string;
    Value: number;
    Description?: string;
    RawDate: string;
  };
};

export async function exportEmployeeTimeEntries(
  companyId: string,
  entries: TimeEntry[],
  exportType: ExportType,
  groupEntries: boolean
) {
  const formattedEntries = await formatTimeEntries(entries, companyId);
  if (exportType === "excel") {
    generateExcel(formattedEntries);
  } else if (exportType === "csv") {
    generateCsv(formattedEntries);
  } else if (exportType === "tripletex") {
    generateTripletex(formattedEntries, groupEntries);
  } else {
    generatePowerOffice(formattedEntries);
  }
}

export async function exportSalesOpportunities(
  companyId: string,
  queryClient: ReturnType<typeof useQueryClient>
) {
  const openSalesOpportunities = await queryClient.fetchQuery(
    useGetSalesOpportunitiesByCompanyId.getOptions({
      companyId,
      archived: false,
      page: -1,
      sortField: "Title"
    })
  );

  const archivedSalesOpportunities = await queryClient.fetchQuery(
    useGetSalesOpportunitiesByCompanyId.getOptions({
      companyId,
      archived: true,
      page: -1,
      sortField: "Title"
    })
  );

  const formattedOpenSalesOpportunities = formatSalesOpportunities(
    openSalesOpportunities.data
  );
  const formattedArchivedSalesOpportunities = formatSalesOpportunities(
    archivedSalesOpportunities.data
  );

  generateSalesOpportunityExcel(
    formattedOpenSalesOpportunities,
    formattedArchivedSalesOpportunities
  );
}

function generateSalesOpportunityExcel(
  openSalesOpportunities: object[],
  archivedSalesOpportunities: object[]
) {
  const openSalesOpportunitiesWorksheet = XLSX.utils.json_to_sheet(
    openSalesOpportunities
  );
  const archivedSalesOpportunitiesWorksheet = XLSX.utils.json_to_sheet(
    archivedSalesOpportunities
  );

  const workbook = {
    Sheets: {
      "Åpne salgsmuligheter": openSalesOpportunitiesWorksheet,
      "Arkiverte salgsmuligheter": archivedSalesOpportunitiesWorksheet
    },
    SheetNames: ["Åpne salgsmuligheter", "Arkiverte salgsmuligheter"]
  };

  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array"
  });

  saveAsExcel(excelBuffer, "Salgsmuligheter");
}

function timeToDecimal(timeStr: string) {
  const [hours, minutes] = timeStr.split(":").map(Number);
  return hours + minutes / 60;
}

export async function exportProjects(
  projects: ProjectV2[],
  queryClient: ReturnType<typeof useQueryClient>
) {
  const data = await Promise.all(
    projects.map(async (project) => {
      const users = await queryClient.fetchQuery(
        useGetUsersOnResource.getOptions({
          resourceId: project.id,
          resourceType: "project"
        })
      );

      const projectManagers =
        users
          .filter((user) => user.role?.name === "Prosjektleder")
          .map((user) => user.user.name) ?? [];

      const customer = await queryClient.fetchQuery(
        useGetCustomerByResourceId.getOptions({
          companyId: project.companyId,
          resourceId: project.id
        })
      );

      const economy = await queryClient.fetchQuery(
        useGetProjectEconomy.getOptions({
          projectId: project.id
        })
      );

      return {
        Prosjektnummer: project.prefixedProjectNumber,
        Opprettet: formatFullNumericDateString(project.created),
        Tittel: project.title,
        Kunde: customer?.formattedCustomerName,
        Prosjektleder: projectManagers.join(", "),
        Start: formatFullNumericDateString(project.from),
        Slutt: formatFullNumericDateString(project.to),
        Fase: project.projectPhase.title,
        Kontraktsverdi: formatCurrency(economy?.estimatedContractValue)
      };
    })
  );

  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Prosjekter");
  const buffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array"
  });
  saveAsExcel(buffer, "Prosjekter");
}

function generateTripletex(data: ExportFormat[], grouped: boolean) {
  let processedData: ExportFormat[] = [];
  const filtered = data.filter((e) => e.data.Value > 0);

  if (grouped) {
    const grouped = Object.groupBy(filtered, (entry) => {
      const date = new Date(entry.rawDate);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      return `${year}${month}${entry.data.EmployeeNumber}${entry.data.PayItemCode}${entry.data.ProjectNumber}`;
    });

    processedData = Object.values(grouped)
      .map((entries) => {
        return entries?.reduce(
          (acc, entry) => {
            acc.data.Description = "";
            acc.data.Value += entry.data.Value;
            return acc;
          },
          { ...entries[0] }
        );
      })
      .filter((e) => e !== undefined);
  } else {
    processedData = filtered;
  }

  const content = processedData.map((entry) => {
    const date = new Date(entry.rawDate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    return {
      År: year,
      Måned: month,
      Ansattnr: entry.data.EmployeeNumber,
      Lønnsartnummer: entry.data.PayItemCode,
      Kommentar: entry.data.Description,
      Antall: entry.data.Value,
      Sats: "",
      Prosjektnummer: entry.data.ProjectNumber,
      Avdelingsnummer: ""
    };
  });

  const worksheet = XLSX.utils.json_to_sheet(content);
  const workbook = {
    Sheets: { Lønnsgrunnlag: worksheet },
    SheetNames: ["Lønnsgrunnlag"]
  };

  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array"
  });

  saveAsExcel(excelBuffer, "Lønnsgrunnlag Tripletex");
}

function generatePowerOffice(data: ExportFormat[]) {
  const workbook = XLSX.utils.book_new();
  const sheet = XLSX.utils.aoa_to_sheet([["[SalaryBasis]"]]);

  const content = data.map((entry) => ({
    EmployeeNo: entry.data.EmployeeNumber,
    DepartmentCode: "",
    ProjectCode: entry.data.ProjectNumber,
    PayItemCode: entry.data.PayItemCode,
    Rate: "",
    Amount: "",
    Quantity: entry.data.Value,
    Comment: entry.data.Description,
    PersonType: ""
  }));

  const jsonSheet = XLSX.utils.json_to_sheet(content, { origin: "A2" });
  Object.assign(sheet, jsonSheet);

  XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");
  const buffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  saveAsExcel(buffer, "Lønnsgrunnlag PowerOffice");
}

function generateExcel(data: ExportFormat[]) {
  const worksheet = XLSX.utils.json_to_sheet(data.map((entry) => entry.data));
  const workbook = {
    Sheets: { "Eksporterte timer": worksheet },
    SheetNames: ["Eksporterte timer"]
  };

  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array"
  });

  saveAsExcel(excelBuffer, "Timer");
}

function generateCsv(data: ExportFormat[]) {
  let csvContent = "data:text/csv;charset=utf-8,";
  const headers = Object.keys(data[0]);
  const csvHeaders = headers.join(",");
  csvContent += csvHeaders + "\r\n";

  data.forEach((timeSubmit) => {
    const row = Object.values(timeSubmit.data).join(",");
    csvContent += row + "\r\n";
  });

  const encodedUri = encodeURI(csvContent);
  window.open(encodedUri);
}

async function formatTimeEntries(
  entries: TimeEntry[],
  companyId: string
): Promise<ExportFormat[]> {
  const userIds = new Set(entries.map((e) => e.userId));
  const projectEntries = entries.filter((e) => e.resourceType === "Project");
  const projectIds = new Set(projectEntries.map((e) => e.resourceId));

  const users = await Promise.all(
    [...userIds].map((id) => getUserById(id, companyId))
  );

  const projects = await getProjectsByIds(companyId, [...projectIds]);

  return entries.map((entry) => {
    const user = users.find((u) => u.id === entry.userId);
    const project = projects.find((p) => p.id === entry.resourceId);
    const connection = user?.companyConnections?.find(
      (c) => c.companyId === companyId
    );

    return {
      rawDate: entry.date,
      data: {
        EmployeeNumber: connection?.employeeNumber,
        Name: user?.name ?? "-",
        SalaryType: connection?.salaryType,
        Date: formatFullNumericDateString(entry.date),
        ProjectNumber: project?.prefixedProjectNumber,
        ProjectName: project?.title,
        ActivityNumber: entry.activityNumber,
        ActivityName: entry.activityName,
        PayItemCode: entry.salaryTypeId,
        Value: timeToDecimal(entry.value),
        Description: entry.description,
        RawDate: entry.date
      }
    };
  });
}

function formatSalesOpportunities(salesOpportunities: SalesOpportunity[]) {
  const formattedSalesOpportunities = salesOpportunities.map(
    ({
      title,
      description,
      createdAt,
      owner,
      archived,
      leadSource,
      address,
      startDate,
      endDate,
      expectedCloseDate,
      status,
      priority,
      priceType,
      price,
      estimatedHours,
      customColumnOne,
      customColumnTwo,
      customer,
      deliveryType
    }) => {
      const formattedCreatedAt = createdAt
        ? formatFullNumericDate(new Date(createdAt))
        : null;
      const formattedStartDate = startDate
        ? formatFullNumericDate(new Date(startDate))
        : null;
      const formattedEndDate = endDate
        ? formatFullNumericDate(new Date(endDate))
        : null;
      const formattedExpectedCloseDate = expectedCloseDate
        ? formatFullNumericDate(new Date(expectedCloseDate))
        : null;

      return {
        Title: title,
        Description: description,
        CreatedAt: formattedCreatedAt,
        Owner: owner,
        Archived: archived,
        LeadSource: leadSource,
        Address: address,
        StartDate: formattedStartDate,
        EndDate: formattedEndDate,
        ExpectedCloseDate: formattedExpectedCloseDate,
        Status: status,
        Priority: priority,
        PriceType: priceType,
        Price: price,
        EstimatedHours: estimatedHours,
        CustomerNumber: customer.customerNumber,
        Customer: customer.formattedCustomerName,
        CustomerAddress: customer.address,
        DeliveryType: deliveryType?.name,
        CustomColumnOne: customColumnOne,
        CustomColumnTwo: customColumnTwo
      };
    }
  );

  return formattedSalesOpportunities;
}

export function mapOfferSubsections(subsection: OfferSubsection[]) {
  return subsection.map((section) => {
    if (isOfferTextField(section)) {
      return {
        type: OfferCheckboxTypeEnum.TextField,
        id: section.id,
        title: section.title,
        customerResponse: section.customerResponse,
        required: section.required
      };
    } else if (isOfferCheckbox(section)) {
      return {
        type: OfferCheckboxTypeEnum.Checkbox,
        id: section.id,
        title: section.title,
        customerChecked: section.customerChecked,
        required: section.required
      };
    } else if (isOfferDescription(section)) {
      return {
        type: OfferCheckboxTypeEnum.Description,
        id: section.id,
        title: section.title,
        description: section.description
      };
    } else if (isOfferProducts(section)) {
      return {
        type: OfferCheckboxTypeEnum.ProductGroup,
        id: section.id,
        title: section.title,
        isOption: section.isOption,
        isChosen: section.isChosen,
        products: section.products.map((product) => {
          return {
            title: product.title,
            description: product.description,
            count: product.count,
            unitName: product.unitName,
            price: product.price,
            discount: product.rebate,
            mva: product.mva
          };
        })
      };
    } else {
      throw new Error(`Unsupported subsection type: ${section}`);
    }
  });
}
