import { createFileRoute, Link } from "@tanstack/react-router";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import { useSort } from "../../../../../hooks/useSort";
import { keepPreviousData } from "@tanstack/react-query";
import { useGetCustomersByCompanyId } from "../../../../../service/api/CustomerApiV3";
import { useTranslation } from "react-i18next";
import { TextFieldNew } from "../../../../../components/ds/TextFieldNew";
import Table from "../../../../../components/ds/RadixTable/Table";
import TableHeader from "../../../../../components/ds/RadixTable/TableHeader";
import TableHeaderCell from "../../../../../components/ds/RadixTable/TableHeaderCell";
import TableRow from "../../../../../components/ds/RadixTable/TableRow";
import TableBody from "../../../../../components/ds/RadixTable/TableBody";
import TableCell from "../../../../../components/ds/RadixTable/TableCell";
import { formatFullNumericDateString } from "../../../../../utils/DateTimeFormatter";
import React from "react";
import CardNew from "../../../../../components/ds/CardNew";
import ButtonNew from "../../../../../components/ds/ButtonNew";
import PageLayout from "../../../../../components/PageLayout";
import PaginationNew from "../../../../../components/ds/PaginationNew";
import { searchBarSize } from "../../../../../components/ds/sizes";

export const Route = createFileRoute(
  "/_protected/dashboard/customers/_layout/customers"
)({
  component: CustomerPage
});

type customerSort =
  | "CustomerNumber"
  | "Type"
  | "CompanyName"
  | "OrgNumber"
  | "ExternalReference";

function CustomerPage() {
  const companyId = useCompanyId();
  const { t } = useTranslation();

  const {
    page,
    setPage,
    debouncedQuery,
    setQuery,
    query,
    sortField,
    sortDirection,
    registerHeader
  } = useSort<customerSort>("CustomerNumber", "customerTable", (field) =>
    Object.values([
      "CustomerNumber",
      "Type",
      "CompanyName",
      "OrgNumber",
      "ExternalReference"
    ]).includes(field)
  );

  const customerQuery = useGetCustomersByCompanyId({
    placeholderData: keepPreviousData,
    variables: {
      companyId,
      page,
      sortField,
      sortDirection,
      query: debouncedQuery
    }
  });

  const customers = customerQuery.data?.data ?? [];

  return (
    <PageLayout>
      <CardNew
        inset
        title={t("allCustomers")}
        trailing={
          <ButtonNew icon={"add"} asChild>
            <Link to={"/dashboard/customers/new"}>{t("createCustomer")}</Link>
          </ButtonNew>
        }
        headerContent={
          <TextFieldNew
            size={"2"}
            className={searchBarSize}
            name="search"
            placeholder={t("searchForCustomer")}
            icon="search"
            onChange={(e) => setQuery(e.target.value)}
            value={query}
          />
        }
        footerContent={
          <PaginationNew
            page={page}
            totalPages={customerQuery.data?.totalPages ?? 0}
            setPage={setPage}
          />
        }
      >
        <Table variant={"ghost"}>
          <TableHeader>
            <TableRow>
              <TableHeaderCell sortFn={registerHeader("CustomerNumber")}>
                #
              </TableHeaderCell>
              <TableHeaderCell sortFn={registerHeader("CompanyName")}>
                {t("name")}
              </TableHeaderCell>
              <TableHeaderCell>{t("Org.Nr.")}</TableHeaderCell>
              <TableHeaderCell>{t("type")}</TableHeaderCell>
              <TableHeaderCell>{t("phone")}</TableHeaderCell>
              <TableHeaderCell>{t("email")}</TableHeaderCell>
              <TableHeaderCell>{t("address")}</TableHeaderCell>
              <TableHeaderCell>{t("created")}</TableHeaderCell>
              <TableHeaderCell>{t("customerPortal")}</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {customers.map((customer) => {
              return (
                <TableRow key={customer.id}>
                  <TableCell>
                    <ButtonNew
                      asChild
                      highContrast
                      variant={"ghost"}
                      className={"group-hover/tr:underline"}
                    >
                      <Link
                        className={"group-hover/tr:underline"}
                        to={"/dashboard/customers/$customerId"}
                        params={{
                          customerId: customer.id
                        }}
                      >
                        #{customer.customerNumber}
                      </Link>
                    </ButtonNew>
                  </TableCell>
                  <TableCell>{customer.formattedCustomerName}</TableCell>
                  <TableCell>{customer.orgNumber}</TableCell>
                  <TableCell>
                    {customer.type === "Private" ? t("private") : t("company")}
                  </TableCell>
                  <TableCell>{customer.primaryContact?.mobilePhone}</TableCell>
                  <TableCell>{customer.primaryContact?.email}</TableCell>
                  <TableCell>{customer.formattedAddress}</TableCell>
                  <TableCell>
                    {customer.created &&
                      formatFullNumericDateString(customer.created)}
                  </TableCell>
                  <TableCell py={"0"} className={"align-middle"}>
                    <ButtonNew
                      asChild
                      size={"1"}
                      variant={"soft"}
                      trailingIcon={"open_in_new"}
                    >
                      <Link
                        to={"/guest/$customerId"}
                        target={"_blank"}
                        params={{
                          customerId: customer.id
                        }}
                      >
                        {t("customerPortal")}
                      </Link>
                    </ButtonNew>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardNew>
    </PageLayout>
  );
}
