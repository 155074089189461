import { createFileRoute, useSearch } from "@tanstack/react-router";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import useDialog from "../../../../../hooks/useDialog";
import { useAppStore } from "../../../../../state/app_store";
import { useQuery } from "@tanstack/react-query";
import { useGetDeliveryTypesByCompanyId } from "../../../../../service/api/DeliveryTypeApi";
import { useGetCustomerById } from "../../../../../service/api/CustomerApiV3";
import { useEffect } from "react";
import { userV2Queries } from "../../../../../service/api/UserV2Api";
import {
  getPriorityLabel,
  getStatusLabel,
  SALES_OPPORTUNITY_PRIORITY_OPTIONS,
  SALES_OPPORTUNITY_STATUS_OPTIONS
} from "../../../../../pages/sales/utils";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  convertPriority,
  convertStatus,
  createSalesOpportunity,
  updateSalesOpportunity
} from "../../../../../service/api/SalesOpportunityApi";
import { AppRouter } from "../../../../../router/AppRouter";
import {
  formatFullNumericDate,
  formatToFromDateWithDayDifference
} from "../../../../../utils/DateTimeFormatter";
import FormPageLayout from "../../../../../components/layouts/FormPageLayout";
import { t } from "i18next";
import { formatCurrency } from "../../../../../utils/currencyFormatter";
import TextField from "../../../../../components/ds/TextField";
import EmbeddedCustomerForm from "../../../../../components/EmbeddedCustomerForm";
import Select from "../../../../../components/ds/Select";
import ControlledDatePickerOLD from "../../../../../components/ds/DatePicker/ControlledDatePickerOLD";
import {
  CustomerV3,
  SalesOpportunity,
  SalesOpportunityPostRequestStatusEnum,
  SalesOpportunityPriceTypeEnum,
  SalesOpportunityPriorityEnum
} from "../../../../../.generated/api";
import { UpsertDeliveryTypeModal } from "../../../../../pages/sales/UpsertDeliveryTypeModal";
import * as Yup from "yup";
import { DetailsField } from "../../../../../components/DetailsField";
import { z } from "zod";

type UpsertSalesOpportunityPageProps = {
  salesOpportunity?: SalesOpportunity;
  customerId?: string;
};

const salesOpportunityFormSchema = Yup.object().shape({
  title: Yup.string().required(t("fieldRequired")),
  status: Yup.string().required(t("Status er påkrevd")),
  priority: Yup.string().required(t("Prioritet er påkrevd")),
  owner: Yup.string().required(t("Eier er påkrevd")),
  customer: Yup.object().required(t("Kunde er påkrevd")),
  deliveryTypeId: Yup.string().required(t("Leveransetype er påkrevd")),
  price: Yup.number().when("priceType", {
    is: "fixed",
    then: Yup.number()
      .required(t("fieldRequired"))
      .typeError(t("fieldMustBeNumber"))
  }),
  estimatedHours: Yup.number().when("priceType", {
    is: "hourly",
    then: Yup.number()
      .required(t("Antall timer er påkrevd"))
      .typeError(t("Timer må være et tall"))
  })
});

export type SalesOpportunityFormInputs = {
  title: string;
  description: string;
  deliveryTypeId: undefined | string;
  owner: string;
  leadSource: string;
  address: string;
  startDate: Date | undefined;
  endDate: Date | undefined;
  status: SalesOpportunityPostRequestStatusEnum | undefined;
  priority: SalesOpportunityPriorityEnum | undefined;
  expectedCloseDate: Date | undefined;
  customer?: CustomerV3;
  priceType: SalesOpportunityPriceTypeEnum;
  price: number;
  estimatedHours: number;
};

const createSalesRouteSchema = z.object({
  customerId: z.string()
});

export const Route = createFileRoute("/_protected/dashboard/sales/new/")({
  validateSearch: createSalesRouteSchema,
  component: CreateSalesOpportunityPage
});

function CreateSalesOpportunityPage() {
  const { customerId } = useSearch({
    from: "/_protected/dashboard/sales/new/"
  });
  return <UpsertSalesOpportunityPage customerId={customerId} />;
}

//TODO: Switch out with new dialog
function UpsertSalesOpportunityPage({
  salesOpportunity,
  customerId
}: UpsertSalesOpportunityPageProps) {
  const companyId = useCompanyId();
  const { isOpen, onClose, onOpen } = useDialog();
  const user = useAppStore(({ user }) => user);

  const deliveryTypesQuery = useGetDeliveryTypesByCompanyId({
    variables: { companyId }
  });

  const deliveryTypes = deliveryTypesQuery.data ?? [];
  const deliveryTypeOptions = [
    { label: "Rediger leveransetyper", value: "edit" },
    ...deliveryTypes.map(({ name, id }) => ({
      label: name,
      value: id
    }))
  ];

  const customerQuery = useGetCustomerById({
    variables: { customerId: customerId ?? "", companyId },
    enabled: customerId !== undefined
  });

  useEffect(() => {
    if (customerQuery.data && !salesOpportunity) {
      setValue("customer", customerQuery.data);
    }
  }, [customerQuery.data]);

  const userQuery = useQuery(userV2Queries(companyId).getByCompanyId());
  const users = userQuery.data ?? [];

  const ownerOptions = users
    .filter(({ name }) => name)
    .map(({ name }) => ({
      label: name as string,
      value: name as string
    }));

  const defaultValues: SalesOpportunityFormInputs = {
    title: salesOpportunity?.title ?? "",
    description: salesOpportunity?.description ?? "",
    deliveryTypeId: salesOpportunity?.deliveryType?.id ?? "",
    price: salesOpportunity?.price ?? 0,
    priceType: salesOpportunity?.priceType || "fixed",
    estimatedHours: salesOpportunity?.estimatedHours || 0,
    owner: salesOpportunity?.ownerId ?? user?.id ?? "",
    leadSource: salesOpportunity?.leadSource ?? "",
    address: salesOpportunity?.address ?? "",
    startDate: salesOpportunity?.startDate
      ? new Date(salesOpportunity.startDate)
      : undefined,
    endDate: salesOpportunity?.endDate
      ? new Date(salesOpportunity.endDate)
      : undefined,
    status:
      salesOpportunity?.status ?? SALES_OPPORTUNITY_STATUS_OPTIONS[0].value,
    priority:
      salesOpportunity?.priority ?? SALES_OPPORTUNITY_PRIORITY_OPTIONS[1].value,
    expectedCloseDate: salesOpportunity?.expectedCloseDate
      ? new Date(salesOpportunity.expectedCloseDate)
      : undefined,
    customer: salesOpportunity?.customer
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(salesOpportunityFormSchema),
    defaultValues
  });

  async function onFormSubmit(data: SalesOpportunityFormInputs) {
    const { title, customer, owner, priority, status } = data;

    if (!title || !status || !priority || !owner || !customer) return;
    const formattedData = {
      ...data,
      startDate: data.startDate?.toISOString(),
      endDate: data.endDate?.toISOString(),
      expectedCloseDate: data.expectedCloseDate?.toISOString(),
      status,
      priority,
      customerId: customer?.id
    };

    if (!salesOpportunity)
      await createSalesOpportunity(companyId, formattedData);
    else {
      await updateSalesOpportunity(
        companyId,
        salesOpportunity.id,
        formattedData
      );
    }

    AppRouter.history.back();
  }

  const {
    customer,
    description,
    title,
    priority,
    startDate,
    status,
    endDate,
    expectedCloseDate,
    deliveryTypeId,
    owner,
    address,
    price,
    leadSource,
    priceType
  } = watch();

  const selectedDeliveryType = deliveryTypes.find(
    ({ id }) => id === deliveryTypeId
  );

  const fromToDate = formatToFromDateWithDayDifference(startDate, endDate);

  const expectedCloseDateViewValue = expectedCloseDate
    ? formatFullNumericDate(expectedCloseDate)
    : undefined;

  return (
    <>
      <FormPageLayout
        onSubmit={handleSubmit(onFormSubmit)}
        pageTitle={
          salesOpportunity
            ? t("editSalesOpportunity")
            : t("createSalesOpportunity")
        }
        formTitle={""}
        submitButtonText={salesOpportunity ? t("update") : t("create")}
        submitButtonIcon={salesOpportunity ? undefined : "add"}
        summary={
          <div className="flex flex-col gap-4">
            <div>
              <p className="text-base font-bold pb-2">{t("details")}</p>
              <DetailsField icon={"title"} data={title} />
              <DetailsField icon={"notes"} data={description} />
              <DetailsField
                icon={"category"}
                data={selectedDeliveryType?.name}
              />
              <DetailsField icon={"payments"} data={formatCurrency(price)} />
              <DetailsField icon={"person"} data={owner} />
              <DetailsField icon={"arrow_circle_right"} data={leadSource} />
              <DetailsField icon={"location_on"} data={address} />
              <DetailsField icon={"calendar_today"} data={fromToDate} />
              <DetailsField icon={"circle"} data={t(convertStatus(status))} />
              <DetailsField
                icon={"priority"}
                data={t(convertPriority(priority))}
              />
              <DetailsField
                icon={"schedule"}
                data={expectedCloseDateViewValue}
              />
            </div>

            <div>
              <p className="text-base font-bold pb-2">{t("customer")}</p>
              <DetailsField
                icon={"apartment"}
                data={customer?.formattedCustomerName}
              />
              <DetailsField icon={"123"} data={customer?.orgNumber} />
              <DetailsField
                icon={"location_on"}
                data={customer?.primaryAddress?.address}
              />
              <DetailsField
                icon={"stairs"}
                data={customer?.primaryAddress?.apartmentNumber}
              />
              <DetailsField
                icon={"phone"}
                data={customer?.primaryContact?.mobilePhone}
              />
              <DetailsField
                icon={"email"}
                data={customer?.primaryContact?.email}
              />
              <DetailsField
                icon={"apartment"}
                data={customer?.primaryAddress?.apartmentNumber}
              />
            </div>
          </div>
        }
        summaryTitle={t("preview")}
        isSubmitting={isSubmitting}
      >
        <div className={"grid grid-cols-1 gap-4"}>
          <TextField
            label={t("title")}
            name={"title"}
            placeholder={t("title")}
            register={register("title")}
            message={errors.title?.message as string}
            messageType="error"
          />
        </div>
        <div className={"grid grid-cols-1 gap-4"}>
          <TextField
            isMultiline
            label={t("description")}
            placeholder={t("description")}
            name={"description"}
            register={register("description")}
            message={errors.description?.message as string}
          />
        </div>

        {!salesOpportunity && !customerId && (
          <Controller
            control={control}
            name={"customer"}
            render={({ field: { onChange, value } }) => (
              <EmbeddedCustomerForm
                onChange={onChange}
                customer={value}
                message={errors.customer?.message}
              />
            )}
          />
        )}

        <div className={"grid grid-cols-2 gap-4 mb-6"}>
          <TextField
            label={t("source")}
            name={"leadSource"}
            placeholder="https://mittanbud.no/1234"
            register={register("leadSource")}
            message={errors.leadSource?.message as string}
          />
          <Controller
            name={"owner"}
            control={control}
            render={() => (
              <Select
                name={"owner"}
                label={t("owner")}
                message={errors.owner?.message as string}
                messageType="error"
                placeholder={"Ola Nordmann"}
                value={owner}
                onChange={(selectedOption) => {
                  if (!selectedOption) return;
                  setValue("owner", selectedOption);
                }}
                options={ownerOptions}
              />
            )}
          />
          <Controller
            name={"deliveryTypeId"}
            control={control}
            render={() => (
              <Select
                name={"deliveryTypeId"}
                label={t("deliveryType")}
                placeholder={t("selectDeliveryType")}
                message={errors.deliveryTypeId?.message as string}
                messageType="error"
                value={deliveryTypeId}
                onChange={(selectedOption) => {
                  if (selectedOption === "edit") {
                    onOpen();
                    return;
                  }

                  setValue("deliveryTypeId", selectedOption);
                }}
                options={deliveryTypeOptions}
              />
            )}
          />
        </div>
        <TextField
          label={t("location")}
          name={"address"}
          placeholder={t("Eventyrveien 34, 0665 Oslo")}
          register={register("address")}
          message={errors.address?.message as string}
        />

        <div className={"grid grid-cols-2 gap-4 mb-6"}>
          <ControlledDatePickerOLD
            control={control}
            name={"startDate"}
            label={t("expectedStartDate")}
            helperText={errors.startDate?.message as string}
          />
          <ControlledDatePickerOLD
            control={control}
            name={"endDate"}
            label={t("expectedEndDate")}
            helperText={errors.endDate?.message as string}
          />
          <Controller
            name={"status"}
            control={control}
            render={() => (
              <Select
                name={"status"}
                label={t("status")}
                message={errors.status?.message as string}
                messageType="error"
                placeholder={t("Velg...")}
                value={status}
                onChange={(selectedOption) => {
                  if (selectedOption) setValue("status", selectedOption);
                }}
                //options={SALES_OPPORTUNITY_STATUS_OPTIONS}
                options={SALES_OPPORTUNITY_STATUS_OPTIONS.map((option) => ({
                  ...option,
                  label: t(getStatusLabel(option.value))
                }))}
              />
            )}
          />
          <Controller
            name={"priority"}
            control={control}
            render={() => (
              <Select
                name={"priority"}
                label={t("priority")}
                message={errors.priority?.message as string}
                messageType="error"
                placeholder={t("Velg...")}
                value={priority}
                onChange={(selectedOption) => {
                  if (selectedOption) setValue("priority", selectedOption);
                }}
                options={SALES_OPPORTUNITY_PRIORITY_OPTIONS.map((option) => ({
                  ...option,
                  label: t(getPriorityLabel(option.value))
                }))}
              />
            )}
          />
        </div>
        <ControlledDatePickerOLD
          control={control}
          name={"expectedCloseDate"}
          //initialMonth={expectedCloseDate}
          label={t("expectedCloseDate")}
          helperText={errors.expectedCloseDate?.message as string}
        />

        {/*<InputWrapper
          name={"priceType"}
          label={t("priceType")}
          isBottomPadding={false}
        >
          <RadioButton
            label={t("fixedPrice")}
            register={register("priceType")}
            value={OfferPriceTypeEnum.Fixed}
          />
          <RadioButton
            label={t("hourly")}
            register={register("priceType")}
            value={OfferPriceTypeEnum.Hourly}
          />
        </InputWrapper>*/}

        {priceType === "hourly" ? (
          <div className={"flex flex-col sm:flex-row gap-0 sm:gap-4"}>
            <TextField
              name={"hours"}
              placeholder={t("hourlyRate")}
              label={t("hourlyRateExclVat")}
              register={register("price")}
              message={errors.price?.message}
            />
            <TextField
              name={"hours"}
              placeholder={t("estimatedAmountOfHours")}
              label={t("estimatedAmountOfHours")}
              register={register("estimatedHours")}
              message={errors.estimatedHours?.message}
            />
          </div>
        ) : (
          <div className={"flex"}>
            <TextField
              type="number"
              name={"price"}
              label={t("priceExclVat")}
              placeholder={t("enterFixedPrice")}
              register={register("price")}
              message={errors.price?.message}
            />
            <div className={"basis-full hidden sm:block"} />
          </div>
        )}
      </FormPageLayout>
      <UpsertDeliveryTypeModal
        isOpen={isOpen}
        onClose={onClose}
        refetch={deliveryTypesQuery.refetch}
        deliveryTypes={deliveryTypes}
      />
    </>
  );
}
