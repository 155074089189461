import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import { UpsertOfferPage } from "../../../../../pages/sales/salesOppertunity/details/offer/UpsertOfferPage";
import { useGetProjectById } from "../../../../../service/api/ProjectApiV2";

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/offer/new"
)({
  preloadGcTime: 0,
  preloadStaleTime: 0,
  loader: ({ context: { queryClient }, params: { projectId } }) => {
    return queryClient.fetchQuery({
      ...useGetProjectById.getOptions({ projectId })
    });
  },
  component: CreateOfferPage
});

function CreateOfferPage() {
  const project = useLoaderData({
    from: "/_protected/dashboard/project/$projectId/offer/new"
  });
  return <UpsertOfferPage project={project} />;
}
