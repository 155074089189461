import { getApps, initializeApp } from "firebase/app";
import React, { Suspense } from "react";
import CustomToast from "./components/common/CustomToast";
import { setDefaultOptions } from "date-fns";
import { nb } from "date-fns/locale";
import { QueryClientProvider } from "@tanstack/react-query";
import { AppRouter } from "./router/AppRouter";
import { RouterProvider } from "@tanstack/react-router";
import environment from "./environment";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Theme } from "@radix-ui/themes";
import useTheme from "./hooks/useTheme";
import { z } from "zod";
import { t } from "i18next";
import { queryClient } from "./router/QueryClient";

z.setErrorMap((err, ctx) => {
  switch (err.code) {
    case "invalid_type":
      if (err.expected === "string") {
        return { message: t("fieldRequired") };
      }
      if (err.expected === "number") {
        return { message: t("mustBeANumber") };
      }
      break;
    case "too_small":
      if (err.type === "string") {
        return { message: t("fieldRequired") };
      }
      if (err.type === "array") {
        return { message: t("atLeastOneElementMustBeAdded") };
      }
      break;
    case "invalid_string":
      if (err.validation === "email") {
        return { message: t("invalidEmail") };
      }
  }

  return { message: ctx.defaultError };
});

const TanStackRouterDevtools =
  import.meta.env.MODE === "prod"
    ? () => null
    : React.lazy(() =>
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools
        }))
      );

export default function App() {
  useTheme();
  setDefaultOptions({ locale: nb });

  if (!getApps().length) {
    initializeApp(environment.firebaseConfig);
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Theme
        id={"radixThemeElement"}
        accentColor={"teal"}
        grayColor={"sage"}
        hasBackground={false}
        panelBackground={"solid"}
      >
        <ReactQueryDevtools />
        <CustomToast id={"regular"} />
        <Suspense>
          <TanStackRouterDevtools router={AppRouter} />
        </Suspense>

        <I18nextProvider i18n={i18n}></I18nextProvider>
        <RouterProvider router={AppRouter} />
      </Theme>
    </QueryClientProvider>
  );
}
