import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import { UpsertEstimation } from "../-components/UpsertEstimation";
import { useGetEstimationOfferById } from "../../../../../../service/api/EstimationOfferApi";

export const Route = createFileRoute(
  "/_protected/dashboard/products/estimations/$estimationId/update"
)({
  preloadGcTime: 0,
  preloadStaleTime: 0,

  loader: async ({
    context: { queryClient, companyId },
    params: { estimationId }
  }) => {
    return await queryClient.fetchQuery({
      ...useGetEstimationOfferById.getOptions({ companyId, estimationId })
    });
  },
  component: UpdateEstimationPage
});

function UpdateEstimationPage() {
  const estimation = useLoaderData({
    from: "/_protected/dashboard/products/estimations/$estimationId/update"
  });

  return <UpsertEstimation estimation={estimation} />;
}
