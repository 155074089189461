import React, { useEffect } from "react";
import { Table as RadixTable } from "@radix-ui/themes";
import classNames from "classnames";

export default function Table({ ...props }: RadixTable.RootProps) {
  const tableRootRef = React.useRef<HTMLDivElement>(null);

  // This is a workaround to make the table use "border-collapse: separate"
  const borderSeparate = props.className?.includes("border-separate");
  useEffect(() => {
    if (tableRootRef.current) {
      const tableElement = tableRootRef.current.querySelector("table");
      if (tableElement) {
        if (borderSeparate) {
          tableElement.style.borderCollapse = "separate";
        } else {
          tableElement.style.borderCollapse = "";
        }
      }
    }
  }, [borderSeparate]);

  return (
    <RadixTable.Root
      ref={tableRootRef}
      variant={"surface"}
      className={classNames(props.className, {
        "no-bottom-border-last-row [&_thead]:bg-radix-gray-a2":
          props.variant === "ghost"
      })}
      {...props}
    ></RadixTable.Root>
  );
}
