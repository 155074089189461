import {
  createFileRoute,
  Outlet,
  useLoaderData,
  useNavigate
} from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import NewPageTitle from "../../../../../../components/common/NewPageTitle";
import TabNav from "../../../../../../components/TabNav";
import { TabLink } from "../../../../../../components/TabLink";
import { useGetSalesOpportunityById } from "../../../../../../service/api/SalesOpportunityApi";

export const Route = createFileRoute(
  "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout"
)({
  loader: async ({
    context: { queryClient, companyId },
    params: { salesOpportunityId }
  }) => {
    return await queryClient.fetchQuery(
      useGetSalesOpportunityById.getOptions({ companyId, salesOpportunityId })
    );
  },
  component: SalesOpportunityLayout
});

function SalesOpportunityLayout() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const salesOpportunity = useLoaderData({
    from: "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout"
  });
  const salesOpportunityId = salesOpportunity.id;

  return (
    <>
      <NewPageTitle
        title={salesOpportunity.title}
        onBackClick={() =>
          navigate({
            to: "/dashboard/sales/sales-opportunity"
          })
        }
      />
      <TabNav>
        <TabLink
          to={"/dashboard/sales/sales-opportunity/$salesOpportunityId/overview"}
          params={{ salesOpportunityId }}
        >
          {t("overview")}
        </TabLink>
        <TabLink
          to={
            "/dashboard/sales/sales-opportunity/$salesOpportunityId/documentation"
          }
          params={{ salesOpportunityId }}
        >
          {t("documentation")}
        </TabLink>
        <TabLink
          to={
            "/dashboard/sales/sales-opportunity/$salesOpportunityId/inspection"
          }
          params={{ salesOpportunityId }}
        >
          {t("inspections")}
        </TabLink>
        <TabLink
          to={"/dashboard/sales/sales-opportunity/$salesOpportunityId/offer"}
          params={{ salesOpportunityId }}
        >
          {t("offers")}
        </TabLink>
      </TabNav>
      <Outlet />
    </>
  );
}
