import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import { useGetOfferById } from "../../../../../../service/api/OfferApi";
import { UpsertOfferPage } from "../../../../../../pages/sales/salesOppertunity/details/offer/UpsertOfferPage";

export const Route = createFileRoute(
  "/_protected/dashboard/sales/offer/$offerId/update"
)({
  preloadGcTime: 0,
  preloadStaleTime: 0,
  loader: ({ context: { queryClient, companyId }, params: { offerId } }) => {
    return queryClient.fetchQuery({
      ...useGetOfferById.getOptions({ offerId, companyId })
    });
  },
  component: UpdateOfferPage
});

function UpdateOfferPage() {
  const offer = useLoaderData({
    from: "/_protected/dashboard/sales/offer/$offerId/update"
  });

  return <UpsertOfferPage offer={offer} />;
}
