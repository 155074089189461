import {
  createFileRoute,
  useLoaderData,
  useSearch
} from "@tanstack/react-router";
import {
  offerSearchSchema,
  useGetPaginatedOffersByCompanyId
} from "../../../../../service/api/OfferApi";
import OfferList from "./-components/OfferList";
import { useAppStore } from "../../../../../state/app_store";

export const Route = createFileRoute(
  "/_protected/dashboard/sales/_layout/offer"
)({
  validateSearch: offerSearchSchema,
  loaderDeps: ({ search }) => search,
  loader: async ({
    context: { queryClient, companyId },
    deps: { status, ownerId, page, sortDirection, sortField, query }
  }) => {
    return await queryClient.fetchQuery({
      ...useGetPaginatedOffersByCompanyId.getOptions({
        companyId,
        status,
        ownerId,
        page,
        sortDirection,
        sortField,
        query,
        pageSize: useAppStore.getState().pageSize
      })
    });
  },
  component: OfferPage
});

function OfferPage() {
  const offers = useLoaderData({
    from: "/_protected/dashboard/sales/_layout/offer"
  });

  const searchParams = useSearch({
    from: "/_protected/dashboard/sales/_layout/offer"
  });

  return <OfferList data={offers} offerSearchParams={searchParams} />;
}
