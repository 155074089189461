/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { EstimationOffer } from '../model';
// @ts-ignore
import { EstimationOfferPostRequest } from '../model';
// @ts-ignore
import { EstimationOfferPutRequest } from '../model';
// @ts-ignore
import { PaginatedResponseEstimationOfferListItem } from '../model';
/**
 * EstimationOfferApi - axios parameter creator
 * @export
 */
export const EstimationOfferApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new estimation
         * @param {string} companyId 
         * @param {EstimationOfferPostRequest} estimationOfferPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEstimationOffer: async (companyId: string, estimationOfferPostRequest: EstimationOfferPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createEstimationOffer', 'companyId', companyId)
            // verify required parameter 'estimationOfferPostRequest' is not null or undefined
            assertParamExists('createEstimationOffer', 'estimationOfferPostRequest', estimationOfferPostRequest)
            const localVarPath = `/v1/company/{companyId}/estimationOffer`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(estimationOfferPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete estimation
         * @param {string} companyId 
         * @param {string} estimationId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEstimationOffer: async (companyId: string, estimationId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteEstimationOffer', 'companyId', companyId)
            // verify required parameter 'estimationId' is not null or undefined
            assertParamExists('deleteEstimationOffer', 'estimationId', estimationId)
            const localVarPath = `/v1/company/{companyId}/estimationOffer/{estimationId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"estimationId"}}`, encodeURIComponent(String(estimationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get kalkyle by id
         * @param {string} companyId 
         * @param {string} estimationOfferId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstimationOfferById: async (companyId: string, estimationOfferId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getEstimationOfferById', 'companyId', companyId)
            // verify required parameter 'estimationOfferId' is not null or undefined
            assertParamExists('getEstimationOfferById', 'estimationOfferId', estimationOfferId)
            const localVarPath = `/v1/company/{companyId}/estimationOffer/{estimationOfferId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"estimationOfferId"}}`, encodeURIComponent(String(estimationOfferId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get bundles for company (paginated)
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Name'} [sortField] 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedEstimationOffersByCompanyId: async (companyId: string, authorization?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Name', query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getPaginatedEstimationOffersByCompanyId', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/estimationOffer/paginated`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update estimation
         * @param {string} companyId 
         * @param {string} estimationOfferId 
         * @param {EstimationOfferPutRequest} estimationOfferPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEstimationOffer: async (companyId: string, estimationOfferId: string, estimationOfferPutRequest: EstimationOfferPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateEstimationOffer', 'companyId', companyId)
            // verify required parameter 'estimationOfferId' is not null or undefined
            assertParamExists('updateEstimationOffer', 'estimationOfferId', estimationOfferId)
            // verify required parameter 'estimationOfferPutRequest' is not null or undefined
            assertParamExists('updateEstimationOffer', 'estimationOfferPutRequest', estimationOfferPutRequest)
            const localVarPath = `/v1/company/{companyId}/estimationOffer/{estimationOfferId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"estimationOfferId"}}`, encodeURIComponent(String(estimationOfferId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(estimationOfferPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EstimationOfferApi - functional programming interface
 * @export
 */
export const EstimationOfferApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EstimationOfferApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new estimation
         * @param {string} companyId 
         * @param {EstimationOfferPostRequest} estimationOfferPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEstimationOffer(companyId: string, estimationOfferPostRequest: EstimationOfferPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstimationOffer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEstimationOffer(companyId, estimationOfferPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete estimation
         * @param {string} companyId 
         * @param {string} estimationId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEstimationOffer(companyId: string, estimationId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEstimationOffer(companyId, estimationId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get kalkyle by id
         * @param {string} companyId 
         * @param {string} estimationOfferId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEstimationOfferById(companyId: string, estimationOfferId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstimationOffer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEstimationOfferById(companyId, estimationOfferId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get bundles for company (paginated)
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Name'} [sortField] 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedEstimationOffersByCompanyId(companyId: string, authorization?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Name', query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseEstimationOfferListItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginatedEstimationOffersByCompanyId(companyId, authorization, page, sortDirection, sortField, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update estimation
         * @param {string} companyId 
         * @param {string} estimationOfferId 
         * @param {EstimationOfferPutRequest} estimationOfferPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEstimationOffer(companyId: string, estimationOfferId: string, estimationOfferPutRequest: EstimationOfferPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstimationOffer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEstimationOffer(companyId, estimationOfferId, estimationOfferPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EstimationOfferApi - factory interface
 * @export
 */
export const EstimationOfferApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EstimationOfferApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new estimation
         * @param {string} companyId 
         * @param {EstimationOfferPostRequest} estimationOfferPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEstimationOffer(companyId: string, estimationOfferPostRequest: EstimationOfferPostRequest, authorization?: string, options?: any): AxiosPromise<EstimationOffer> {
            return localVarFp.createEstimationOffer(companyId, estimationOfferPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete estimation
         * @param {string} companyId 
         * @param {string} estimationId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEstimationOffer(companyId: string, estimationId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteEstimationOffer(companyId, estimationId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get kalkyle by id
         * @param {string} companyId 
         * @param {string} estimationOfferId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstimationOfferById(companyId: string, estimationOfferId: string, authorization?: string, options?: any): AxiosPromise<EstimationOffer> {
            return localVarFp.getEstimationOfferById(companyId, estimationOfferId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get bundles for company (paginated)
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Name'} [sortField] 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedEstimationOffersByCompanyId(companyId: string, authorization?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Name', query?: string, options?: any): AxiosPromise<PaginatedResponseEstimationOfferListItem> {
            return localVarFp.getPaginatedEstimationOffersByCompanyId(companyId, authorization, page, sortDirection, sortField, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update estimation
         * @param {string} companyId 
         * @param {string} estimationOfferId 
         * @param {EstimationOfferPutRequest} estimationOfferPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEstimationOffer(companyId: string, estimationOfferId: string, estimationOfferPutRequest: EstimationOfferPutRequest, authorization?: string, options?: any): AxiosPromise<EstimationOffer> {
            return localVarFp.updateEstimationOffer(companyId, estimationOfferId, estimationOfferPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EstimationOfferApi - object-oriented interface
 * @export
 * @class EstimationOfferApi
 * @extends {BaseAPI}
 */
export class EstimationOfferApi extends BaseAPI {
    /**
     * 
     * @summary Create new estimation
     * @param {string} companyId 
     * @param {EstimationOfferPostRequest} estimationOfferPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimationOfferApi
     */
    public createEstimationOffer(companyId: string, estimationOfferPostRequest: EstimationOfferPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return EstimationOfferApiFp(this.configuration).createEstimationOffer(companyId, estimationOfferPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete estimation
     * @param {string} companyId 
     * @param {string} estimationId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimationOfferApi
     */
    public deleteEstimationOffer(companyId: string, estimationId: string, authorization?: string, options?: AxiosRequestConfig) {
        return EstimationOfferApiFp(this.configuration).deleteEstimationOffer(companyId, estimationId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get kalkyle by id
     * @param {string} companyId 
     * @param {string} estimationOfferId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimationOfferApi
     */
    public getEstimationOfferById(companyId: string, estimationOfferId: string, authorization?: string, options?: AxiosRequestConfig) {
        return EstimationOfferApiFp(this.configuration).getEstimationOfferById(companyId, estimationOfferId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get bundles for company (paginated)
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'Name'} [sortField] 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimationOfferApi
     */
    public getPaginatedEstimationOffersByCompanyId(companyId: string, authorization?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Name', query?: string, options?: AxiosRequestConfig) {
        return EstimationOfferApiFp(this.configuration).getPaginatedEstimationOffersByCompanyId(companyId, authorization, page, sortDirection, sortField, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update estimation
     * @param {string} companyId 
     * @param {string} estimationOfferId 
     * @param {EstimationOfferPutRequest} estimationOfferPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimationOfferApi
     */
    public updateEstimationOffer(companyId: string, estimationOfferId: string, estimationOfferPutRequest: EstimationOfferPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return EstimationOfferApiFp(this.configuration).updateEstimationOffer(companyId, estimationOfferId, estimationOfferPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
