import { createFileRoute, useParams, useSearch } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import AddSingleProduct from "../../../../../../pages/projectDetails/projectProducts/components/AddSingleProduct";
import { z } from "zod";
import NewPageTitle from "../../../../../../components/common/NewPageTitle";

const addProductsToProjectSchema = z.object({
  taskId: z.string().optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/products/add"
)({
  validateSearch: addProductsToProjectSchema,
  component: AddProductToProject
});

function AddProductToProject() {
  const { projectId } = useParams({
    from: "/_protected/dashboard/project/$projectId/products/add"
  });
  const { taskId } = useSearch({
    from: "/_protected/dashboard/project/$projectId/products/add"
  });
  const { t } = useTranslation();

  return (
    <>
      <NewPageTitle title={t("addFromProductCatalog")} />

      <AddSingleProduct
        resourceId={projectId}
        taskId={taskId}
        resourceType="project"
      />
    </>
  );
}
