/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface FilePostRequest
 */
export interface FilePostRequest {
    /**
     * 
     * @type {string}
     * @memberof FilePostRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FilePostRequest
     */
    'parent'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilePostRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FilePostRequest
     */
    'fileType': string;
    /**
     * 
     * @type {string}
     * @memberof FilePostRequest
     */
    'fileResourceType'?: FilePostRequestFileResourceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FilePostRequest
     */
    'description'?: string;
}

export const FilePostRequestFileResourceTypeEnum = {
    General: 'general',
    ChecklistReference: 'checklist_reference',
    ChecklistUserUpload: 'checklist_user_upload'
} as const;

export type FilePostRequestFileResourceTypeEnum = typeof FilePostRequestFileResourceTypeEnum[keyof typeof FilePostRequestFileResourceTypeEnum];


