/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Company } from '../model';
// @ts-ignore
import { CompanyFreeTrialOrderRequest } from '../model';
// @ts-ignore
import { CompanyLogoDownloadResponse } from '../model';
// @ts-ignore
import { CompanyLogoUploadResponse } from '../model';
// @ts-ignore
import { CompanyMenuItemPutRequest } from '../model';
// @ts-ignore
import { CompanyName } from '../model';
// @ts-ignore
import { CompanyPostRequest } from '../model';
// @ts-ignore
import { CompanyPublicInfo } from '../model';
// @ts-ignore
import { CompanyPutRequest } from '../model';
// @ts-ignore
import { CompanySubscriptionResponse } from '../model';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { InboundEmailConfig } from '../model';
// @ts-ignore
import { InboundEmailRequest } from '../model';
/**
 * CompanyControllerApi - axios parameter creator
 * @export
 */
export const CompanyControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete existing company logo
         * @param {string} companyId companyId UUID
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyLogo: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteCompanyLogo', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/logo`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete existing company report logo
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyReportLogo: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteCompanyReportLogo', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/reportLogo`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInboundEmailConfig: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteInboundEmailConfig', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/inbound-email`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company info
         * @param {string} companyId companyId UUID
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompany', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company info. Used by the customer portal
         * @param {string} companyId companyId UUID
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyById: async (companyId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyById', 'companyId', companyId)
            const localVarPath = `/v1/customerportal/company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY != null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company logo upload URL
         * @param {string} contentType 
         * @param {string} companyId companyId UUID
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyLogoUploadUrl: async (contentType: string, companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentType' is not null or undefined
            assertParamExists('getCompanyLogoUploadUrl', 'contentType', contentType)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyLogoUploadUrl', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/logoUploadUrl`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentType !== undefined) {
                localVarQueryParameter['contentType'] = contentType;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company logo download URL
         * @param {string} companyId companyId UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyLogoUrl: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyLogoUrl', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/logo`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company name
         * @param {string} companyId companyId UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyNameById: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyNameById', 'companyId', companyId)
            const localVarPath = `/v1/customerportal/company/{companyId}/name`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company public info
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyPublicInfo: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyPublicInfo', 'companyId', companyId)
            const localVarPath = `/v1/open/company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company report logo upload URL
         * @param {string} contentType 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyReportLogoUploadUrl: async (contentType: string, companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentType' is not null or undefined
            assertParamExists('getCompanyReportLogoUploadUrl', 'contentType', contentType)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyReportLogoUploadUrl', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/reportLogoUploadUrl`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentType !== undefined) {
                localVarQueryParameter['contentType'] = contentType;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company report logo download URL
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyReportLogoUrl: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyReportLogoUrl', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/reportLogo`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company free trial status
         * @param {string} companyId companyId UUID
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanySubscriptionStatus: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanySubscriptionStatus', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/subscription`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboundEmailSettings: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getInboundEmailSettings', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/inbound-email`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invert company restricted phase access
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invertCompanyRestrictedPhaseAccess: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('invertCompanyRestrictedPhaseAccess', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/invertCompanyRestrictedAccess`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new company
         * @param {CompanyPostRequest} companyPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompany: async (companyPostRequest: CompanyPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyPostRequest' is not null or undefined
            assertParamExists('postCompany', 'companyPostRequest', companyPostRequest)
            const localVarPath = `/v1/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update company
         * @param {string} companyId 
         * @param {CompanyPutRequest} companyPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompany: async (companyId: string, companyPutRequest: CompanyPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('putCompany', 'companyId', companyId)
            // verify required parameter 'companyPutRequest' is not null or undefined
            assertParamExists('putCompany', 'companyPutRequest', companyPutRequest)
            const localVarPath = `/v1/company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update company on free trial order
         * @param {string} companyId 
         * @param {CompanyFreeTrialOrderRequest} companyFreeTrialOrderRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompanyOnFreeTrialOrder: async (companyId: string, companyFreeTrialOrderRequest: CompanyFreeTrialOrderRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('putCompanyOnFreeTrialOrder', 'companyId', companyId)
            // verify required parameter 'companyFreeTrialOrderRequest' is not null or undefined
            assertParamExists('putCompanyOnFreeTrialOrder', 'companyFreeTrialOrderRequest', companyFreeTrialOrderRequest)
            const localVarPath = `/v1/company/{companyId}/freeTrial`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyFreeTrialOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update company menu items
         * @param {string} companyId 
         * @param {CompanyMenuItemPutRequest} companyMenuItemPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyMenuItems: async (companyId: string, companyMenuItemPutRequest: CompanyMenuItemPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateCompanyMenuItems', 'companyId', companyId)
            // verify required parameter 'companyMenuItemPutRequest' is not null or undefined
            assertParamExists('updateCompanyMenuItems', 'companyMenuItemPutRequest', companyMenuItemPutRequest)
            const localVarPath = `/v1/company/{companyId}/menuItems`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyMenuItemPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {InboundEmailRequest} inboundEmailRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertInboundEmailConfig: async (companyId: string, inboundEmailRequest: InboundEmailRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('upsertInboundEmailConfig', 'companyId', companyId)
            // verify required parameter 'inboundEmailRequest' is not null or undefined
            assertParamExists('upsertInboundEmailConfig', 'inboundEmailRequest', inboundEmailRequest)
            const localVarPath = `/v1/company/{companyId}/inbound-email`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inboundEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyControllerApi - functional programming interface
 * @export
 */
export const CompanyControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete existing company logo
         * @param {string} companyId companyId UUID
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompanyLogo(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompanyLogo(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete existing company report logo
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompanyReportLogo(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompanyReportLogo(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInboundEmailConfig(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInboundEmailConfig(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company info
         * @param {string} companyId companyId UUID
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompany(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompany(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company info. Used by the customer portal
         * @param {string} companyId companyId UUID
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyById(companyId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyById(companyId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company logo upload URL
         * @param {string} contentType 
         * @param {string} companyId companyId UUID
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyLogoUploadUrl(contentType: string, companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyLogoUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyLogoUploadUrl(contentType, companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company logo download URL
         * @param {string} companyId companyId UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyLogoUrl(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyLogoDownloadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyLogoUrl(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company name
         * @param {string} companyId companyId UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyNameById(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyName>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyNameById(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company public info
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyPublicInfo(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyPublicInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyPublicInfo(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company report logo upload URL
         * @param {string} contentType 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyReportLogoUploadUrl(contentType: string, companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyLogoUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyReportLogoUploadUrl(contentType, companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company report logo download URL
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyReportLogoUrl(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyLogoDownloadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyReportLogoUrl(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company free trial status
         * @param {string} companyId companyId UUID
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanySubscriptionStatus(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanySubscriptionStatus(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInboundEmailSettings(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboundEmailConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInboundEmailSettings(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Invert company restricted phase access
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invertCompanyRestrictedPhaseAccess(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invertCompanyRestrictedPhaseAccess(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new company
         * @param {CompanyPostRequest} companyPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompany(companyPostRequest: CompanyPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompany(companyPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update company
         * @param {string} companyId 
         * @param {CompanyPutRequest} companyPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCompany(companyId: string, companyPutRequest: CompanyPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCompany(companyId, companyPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update company on free trial order
         * @param {string} companyId 
         * @param {CompanyFreeTrialOrderRequest} companyFreeTrialOrderRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCompanyOnFreeTrialOrder(companyId: string, companyFreeTrialOrderRequest: CompanyFreeTrialOrderRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCompanyOnFreeTrialOrder(companyId, companyFreeTrialOrderRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update company menu items
         * @param {string} companyId 
         * @param {CompanyMenuItemPutRequest} companyMenuItemPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyMenuItems(companyId: string, companyMenuItemPutRequest: CompanyMenuItemPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyMenuItems(companyId, companyMenuItemPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {InboundEmailRequest} inboundEmailRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertInboundEmailConfig(companyId: string, inboundEmailRequest: InboundEmailRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboundEmailConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertInboundEmailConfig(companyId, inboundEmailRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyControllerApi - factory interface
 * @export
 */
export const CompanyControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete existing company logo
         * @param {string} companyId companyId UUID
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyLogo(companyId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteCompanyLogo(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete existing company report logo
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyReportLogo(companyId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteCompanyReportLogo(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInboundEmailConfig(companyId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteInboundEmailConfig(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company info
         * @param {string} companyId companyId UUID
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany(companyId: string, authorization?: string, options?: any): AxiosPromise<Company> {
            return localVarFp.getCompany(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company info. Used by the customer portal
         * @param {string} companyId companyId UUID
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyById(companyId: string, xAPIKEY?: string, options?: any): AxiosPromise<Company> {
            return localVarFp.getCompanyById(companyId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company logo upload URL
         * @param {string} contentType 
         * @param {string} companyId companyId UUID
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyLogoUploadUrl(contentType: string, companyId: string, authorization?: string, options?: any): AxiosPromise<CompanyLogoUploadResponse> {
            return localVarFp.getCompanyLogoUploadUrl(contentType, companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company logo download URL
         * @param {string} companyId companyId UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyLogoUrl(companyId: string, options?: any): AxiosPromise<CompanyLogoDownloadResponse> {
            return localVarFp.getCompanyLogoUrl(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company name
         * @param {string} companyId companyId UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyNameById(companyId: string, options?: any): AxiosPromise<CompanyName> {
            return localVarFp.getCompanyNameById(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company public info
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyPublicInfo(companyId: string, authorization?: string, options?: any): AxiosPromise<CompanyPublicInfo> {
            return localVarFp.getCompanyPublicInfo(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company report logo upload URL
         * @param {string} contentType 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyReportLogoUploadUrl(contentType: string, companyId: string, authorization?: string, options?: any): AxiosPromise<CompanyLogoUploadResponse> {
            return localVarFp.getCompanyReportLogoUploadUrl(contentType, companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company report logo download URL
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyReportLogoUrl(companyId: string, options?: any): AxiosPromise<CompanyLogoDownloadResponse> {
            return localVarFp.getCompanyReportLogoUrl(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company free trial status
         * @param {string} companyId companyId UUID
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanySubscriptionStatus(companyId: string, authorization?: string, options?: any): AxiosPromise<CompanySubscriptionResponse> {
            return localVarFp.getCompanySubscriptionStatus(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboundEmailSettings(companyId: string, authorization?: string, options?: any): AxiosPromise<InboundEmailConfig> {
            return localVarFp.getInboundEmailSettings(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Invert company restricted phase access
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invertCompanyRestrictedPhaseAccess(companyId: string, authorization?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.invertCompanyRestrictedPhaseAccess(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new company
         * @param {CompanyPostRequest} companyPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompany(companyPostRequest: CompanyPostRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.postCompany(companyPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update company
         * @param {string} companyId 
         * @param {CompanyPutRequest} companyPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompany(companyId: string, companyPutRequest: CompanyPutRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.putCompany(companyId, companyPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update company on free trial order
         * @param {string} companyId 
         * @param {CompanyFreeTrialOrderRequest} companyFreeTrialOrderRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompanyOnFreeTrialOrder(companyId: string, companyFreeTrialOrderRequest: CompanyFreeTrialOrderRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.putCompanyOnFreeTrialOrder(companyId, companyFreeTrialOrderRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update company menu items
         * @param {string} companyId 
         * @param {CompanyMenuItemPutRequest} companyMenuItemPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyMenuItems(companyId: string, companyMenuItemPutRequest: CompanyMenuItemPutRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.updateCompanyMenuItems(companyId, companyMenuItemPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {InboundEmailRequest} inboundEmailRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertInboundEmailConfig(companyId: string, inboundEmailRequest: InboundEmailRequest, authorization?: string, options?: any): AxiosPromise<InboundEmailConfig> {
            return localVarFp.upsertInboundEmailConfig(companyId, inboundEmailRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyControllerApi - object-oriented interface
 * @export
 * @class CompanyControllerApi
 * @extends {BaseAPI}
 */
export class CompanyControllerApi extends BaseAPI {
    /**
     * 
     * @summary Delete existing company logo
     * @param {string} companyId companyId UUID
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public deleteCompanyLogo(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).deleteCompanyLogo(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete existing company report logo
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public deleteCompanyReportLogo(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).deleteCompanyReportLogo(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public deleteInboundEmailConfig(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).deleteInboundEmailConfig(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company info
     * @param {string} companyId companyId UUID
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getCompany(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).getCompany(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company info. Used by the customer portal
     * @param {string} companyId companyId UUID
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getCompanyById(companyId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).getCompanyById(companyId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company logo upload URL
     * @param {string} contentType 
     * @param {string} companyId companyId UUID
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getCompanyLogoUploadUrl(contentType: string, companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).getCompanyLogoUploadUrl(contentType, companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company logo download URL
     * @param {string} companyId companyId UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getCompanyLogoUrl(companyId: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).getCompanyLogoUrl(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company name
     * @param {string} companyId companyId UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getCompanyNameById(companyId: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).getCompanyNameById(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company public info
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getCompanyPublicInfo(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).getCompanyPublicInfo(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company report logo upload URL
     * @param {string} contentType 
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getCompanyReportLogoUploadUrl(contentType: string, companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).getCompanyReportLogoUploadUrl(contentType, companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company report logo download URL
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getCompanyReportLogoUrl(companyId: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).getCompanyReportLogoUrl(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company free trial status
     * @param {string} companyId companyId UUID
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getCompanySubscriptionStatus(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).getCompanySubscriptionStatus(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getInboundEmailSettings(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).getInboundEmailSettings(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Invert company restricted phase access
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public invertCompanyRestrictedPhaseAccess(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).invertCompanyRestrictedPhaseAccess(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new company
     * @param {CompanyPostRequest} companyPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public postCompany(companyPostRequest: CompanyPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).postCompany(companyPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update company
     * @param {string} companyId 
     * @param {CompanyPutRequest} companyPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public putCompany(companyId: string, companyPutRequest: CompanyPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).putCompany(companyId, companyPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update company on free trial order
     * @param {string} companyId 
     * @param {CompanyFreeTrialOrderRequest} companyFreeTrialOrderRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public putCompanyOnFreeTrialOrder(companyId: string, companyFreeTrialOrderRequest: CompanyFreeTrialOrderRequest, authorization?: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).putCompanyOnFreeTrialOrder(companyId, companyFreeTrialOrderRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update company menu items
     * @param {string} companyId 
     * @param {CompanyMenuItemPutRequest} companyMenuItemPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public updateCompanyMenuItems(companyId: string, companyMenuItemPutRequest: CompanyMenuItemPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).updateCompanyMenuItems(companyId, companyMenuItemPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {InboundEmailRequest} inboundEmailRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public upsertInboundEmailConfig(companyId: string, inboundEmailRequest: InboundEmailRequest, authorization?: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).upsertInboundEmailConfig(companyId, inboundEmailRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
