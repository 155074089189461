import {
  createFileRoute,
  Link,
  useLoaderData,
  useNavigate,
  useRouter
} from "@tanstack/react-router";
import useDialog from "../../../../../../hooks/useDialog";
import { useToast } from "../../../../../../hooks/useToast";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import {
  updateCurrentProjectPhase,
  useGetProjectPhasesByProjectId
} from "../../../../../../service/api/PhasesApi";
import { ProjectPhaseEntity } from "../../../../../../.generated/api";
import CopyProjectDialog from "../../../../../../components/dialogs/CopyProjectDialog";
import GuestPageSettingsDialog from "../../../../../../components/guest/GuestPageSettingsDialog";
import PageLayout from "../../../../../../components/PageLayout";
import PreviewSubtitle from "../../../../../../components/ds/preview/PreviewSubtitle";
import ButtonNew from "../../../../../../components/ds/ButtonNew";
import DeleteBlock from "../../../../../../components/buttons/DeleteBlock";
import { deleteProject } from "../../../../../../service/api/ProjectApi";
import { RadioCards } from "@radix-ui/themes";
import classNames from "classnames";
import ProjectStatus from "../../../../../../pages/projectOverview/ProjectStatus";
import Card from "../../../../../../components/ds/Card";
import IconButton from "../../../../../../components/ds/buttons/IconButton";
import IconWithText from "../../../../../../components/IconWithText";
import { dateTimeFormatter } from "../../../../../../utils/DateTimeFormatter";
import LocationComponent from "../../../../../../components/common/location/LocationComponent";
import DropDown from "../../../../../../components/ds/Dropdown/Dropdown";
import Button from "../../../../../../components/ds/Button";
import DropdownItem from "../../../../../../components/ds/Dropdown/DropdownItem";
import DeadlineComponent from "../../../../../../pages/sales/salesOppertunity/details/DeadlineComponent";
import CustomerComponent from "../../../../../../components/common/CustomerComponent";
import NoteComponent from "../../../../../../components/NoteComponent";
import MessageView from "../../../../../../components/ds/MessageView";
import ResourcesModuleOrder from "../../../../../../pages/taskDetails/taskUserResources/ResourcesModuleOrder";
import {
  deleteProjectReportLogo,
  getProjectReportLogoUploadUrl,
  useGetProjectById,
  useGetProjectReportLogo
} from "../../../../../../service/api/ProjectApiV2";
import Dialog from "../../../../../../components/ds/Dialog";
import InputWrapperNew from "../../../../../../components/ds/InputWrapperNew";
import axios from "axios";
import { useCompanyId } from "../../../../../../hooks/useCompanyId";
import FileUpload from "../../../../../../components/ds/FileUpload";

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/_layout/overview-old"
)({
  component: ProjectOverviewPage
});

function ProjectOverviewPage() {
  const project = useLoaderData({
    from: "/_protected/dashboard/project/$projectId/_layout"
  });

  const router = useRouter();
  const copyProjectDialog = useDialog();
  const guestPageDialog = useDialog();
  const reportLogoDialog = useDialog();
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToast();
  const { t } = useTranslation();
  const companyId = useCompanyId();
  const [selectedPhase, setSelectedPhase] = useState(project.projectPhase);
  const [reportLogoUploadProgress, setReportLogoUploadProgress] =
    useState<number>();

  const parentProjectQuery = useGetProjectById({
    variables: { projectId: project.parentProject ?? "" },
    enabled: project.parentProject !== undefined
  });
  const parentProject = parentProjectQuery.data;

  useEffect(() => {
    setSelectedPhase(project.projectPhase);
  }, [project.projectPhase]);

  const phasesQuery = useGetProjectPhasesByProjectId({
    variables: { projectId: project.id }
  });

  const reportLogoQuery = useGetProjectReportLogo({
    variables: { companyId: companyId, projectId: project.id }
  });

  async function handlePhaseChange(phase: ProjectPhaseEntity) {
    setSelectedPhase(phase);
    if (phase.id !== project.projectPhase.id) {
      await updateCurrentProjectPhase(project.id, phase.id);
      await router.invalidate();
    }
  }

  async function uploadReportLogo(files: File[]) {
    const file = files[0];
    try {
      const response = await getProjectReportLogoUploadUrl(
        companyId,
        project.id,
        file.type
      );

      if (!response.data?.url) return;

      const instance = axios.create();

      const fileData = await file.arrayBuffer();
      await instance.put(response.data.url, fileData, {
        onUploadProgress: (event) => {
          setReportLogoUploadProgress((event.progress ?? 0) * 100);
        },
        headers: {
          "Content-Disposition": `filename=${file.name}`,
          "Content-Type": file.type
        }
      });

      reportLogoQuery.refetch();
    } catch (_) {
      showErrorToast("Feil ved opplasting av forsidebilde");
    }
  }

  async function onDeleteProjectReportLogo() {
    await deleteProjectReportLogo(companyId, project.id);
    await reportLogoQuery.refetch();
  }

  return (
    <>
      <CopyProjectDialog
        open={copyProjectDialog.isOpen}
        onClose={copyProjectDialog.onClose}
        project={project}
      />
      <GuestPageSettingsDialog
        open={guestPageDialog.isOpen}
        onClose={guestPageDialog.onClose}
        resourceId={project.id}
        resourceType={"project"}
        companyId={project.companyId}
        publicValue={project.isPublic}
        onSettingsSaved={() => router.invalidate()}
      />
      <Dialog
        open={reportLogoDialog.isOpen}
        onClose={reportLogoDialog.onClose}
        title={"Forsidebilde rapporter"}
        description={
          "Dette bildet vil inkluderes på forsiden av alle prosjektrapporter"
        }
      >
        {reportLogoQuery.data?.url && (
          <InputWrapperNew>
            <div className={"h-[250px] flex flex-col"}>
              <div className={"mb-2 grow relative"}>
                <img
                  alt={"project logo"}
                  src={reportLogoQuery.data.url}
                  className={
                    "absolute max-h-full max-w-full top-1/2 translate-y-[-50%]"
                  }
                />
              </div>
              <div>
                <ButtonNew
                  variant={"outline"}
                  icon={"upload"}
                  onClick={onDeleteProjectReportLogo}
                >
                  {t("uploadNewImage")}
                </ButtonNew>
              </div>
            </div>
          </InputWrapperNew>
        )}
        {!reportLogoQuery.data?.url && (
          <FileUpload
            label={t("reportImage")}
            accept={"image"}
            id={"fileUpload"}
            progress={reportLogoUploadProgress}
            onFilesSelected={uploadReportLogo}
          />
        )}
      </Dialog>
      <PageLayout
        previewOffset={"regularWithTabBar"}
        preview={
          <>
            <PreviewSubtitle title={t("quickActions")} withSeparator={false} />
            <div className={"flex flex-col gap-2"}>
              <ButtonNew
                icon={"copy_all"}
                variant={"soft"}
                color={"gray"}
                onClick={copyProjectDialog.onOpen}
              >
                {t("copyProject")}
              </ButtonNew>
              <ButtonNew
                icon={"note_add"}
                variant={"soft"}
                color={"gray"}
                asChild
              >
                <Link
                  to={"/dashboard/economy/invoice/new"}
                  search={{
                    projectId: project.id
                  }}
                >
                  {t("createInvoice")}
                </Link>
              </ButtonNew>
              {project.isPublic && (
                <ButtonNew
                  asChild
                  icon={"open_in_new"}
                  variant={"soft"}
                  color={"green"}
                >
                  <Link
                    to={"/guest/project/$projectId"}
                    params={{ projectId: project.id }}
                    target={"_blank"}
                  >
                    {t("customerPortal")}
                  </Link>
                </ButtonNew>
              )}

              <ButtonNew
                icon={"settings"}
                variant={"soft"}
                color={"gray"}
                onClick={guestPageDialog.onOpen}
              >
                {t("customerPortalSettings")}
              </ButtonNew>
              <ButtonNew
                icon={"image"}
                variant={"soft"}
                color={"gray"}
                onClick={reportLogoDialog.onOpen}
              >
                {"Forsidebilde rapporter"}
              </ButtonNew>
              <DeleteBlock
                title={t("archiveProject")}
                confirmationText={t("archiveProjectConfirmation")}
                deleteFunction={async () => {
                  await deleteProject(project.id);
                }}
                onDeleted={async () => {
                  showSuccessToast(t("Prosjekt arkivert"));
                  await navigate({
                    to: "/dashboard/project/overview"
                  });
                }}
                render={(onClick) => (
                  <ButtonNew
                    icon={"archive"}
                    variant={"soft"}
                    color={"gray"}
                    className={"w-full"}
                    onClick={onClick}
                  >
                    {t("archiveProject")}
                  </ButtonNew>
                )}
              />
            </div>
            <PreviewSubtitle
              title={t("projectPhase")}
              trailing={
                <ButtonNew icon={"edit"} size={"1"} variant={"soft"} asChild>
                  <Link
                    to={"/dashboard/project/$projectId/phases"}
                    params={{
                      projectId: project.id
                    }}
                  >
                    {t("edit")}
                  </Link>
                </ButtonNew>
              }
            />
            <RadioCards.Root
              size={"1"}
              className={"gap-2"}
              value={selectedPhase.id}
            >
              {phasesQuery.data?.map((phase, index) => (
                <RadioCards.Item
                  onClick={() => handlePhaseChange(phase)}
                  key={phase.id}
                  value={phase.id}
                  className={classNames("py-2 justify-start", {
                    "after:bg-radix-gray-a3 text-radix-gray-a9":
                      selectedPhase.sequence - 1 > index
                  })}
                >
                  {`${index + 1}. ${phase.title}`}
                </RadioCards.Item>
              ))}
            </RadioCards.Root>
          </>
        }
      >
        <div
          className={
            "grid grid-flow-row-dense grid-cols-1 gap-8 sm:grid-cols-2 3xl:grid-cols-3"
          }
        >
          <div className="flex flex-col gap-8">
            <div className="block sm:hidden">
              <ProjectStatus projectId={project.id} />
            </div>
            <div className={""}>
              <Card isFullHeight>
                <div className={"mb-6 mt-1 flex items-center justify-between"}>
                  <h3 className="font-bold">{t("projectDetails")}</h3>
                  <IconButton icon="edit" variant={"surface"} asChild>
                    <Link
                      to={"/dashboard/project/$projectId/update"}
                      params={{ projectId: project.id }}
                    />
                  </IconButton>
                </div>
                <div className={"mb-3 flex flex-col gap-3"}>
                  {parentProject && (
                    <IconWithText icon={"layers"}>
                      <p>{t("Underprosjekt av:")}</p>
                      <Link
                        className={"underline text-sm"}
                        to={"/dashboard/project/$projectId/overview"}
                        params={{ projectId: parentProject.id }}
                      >
                        {parentProject.title}
                      </Link>
                    </IconWithText>
                  )}
                  <IconWithText
                    icon={"event"}
                    text={
                      dateTimeFormatter(project.from) +
                      `${" - "}` +
                      dateTimeFormatter(project.to)
                    }
                  />
                  <IconWithText icon={"notes"} text={project.description} />
                </div>
                <LocationComponent
                  resourceId={project.id}
                  resourceType={"project"}
                />
                {project.salesOpportunityId && (
                  <div className={"mb-3 mt-6 flex flex-col gap-3"}>
                    <div className={"flex flex-row items-center gap-3"}>
                      <p className={"text-center text-rg font-bold"}>
                        {t("Salg:")}
                      </p>
                      <DropDown
                        button={
                          <Button icon={"add"} theme={"white"} isSmall>
                            {t("Nytt tilbud")}
                          </Button>
                        }
                      >
                        <Link
                          to={
                            "/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/new"
                          }
                          params={{
                            salesOpportunityId: project.salesOpportunityId
                          }}
                        >
                          <DropdownItem label={t("Bygg tilbud")} />
                        </Link>
                      </DropDown>
                    </div>
                    <div>
                      <Link
                        to={
                          "/dashboard/sales/sales-opportunity/$salesOpportunityId"
                        }
                        search={{
                          tab: "overview"
                        }}
                        params={{
                          salesOpportunityId: project.salesOpportunityId
                        }}
                      >
                        <Button icon="arrow_right" isSmall theme="white">
                          {t("Vis arkivert salgsmulighet")}
                        </Button>
                      </Link>
                    </div>
                  </div>
                )}
              </Card>
            </div>
            <div className={"h-full"}>
              <DeadlineComponent resourceId={project.id} canAdd={true} />
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <div className="hidden sm:block 3xl:hidden">
              <ProjectStatus projectId={project.id} />
            </div>
            <div className={""}>
              <CustomerComponent
                resourceId={project.id}
                companyId={project.companyId}
                resourceType={"Project"}
              />
            </div>
            <div>
              <ResourcesModuleOrder
                resourceId={project.id}
                resourceType={"project"}
                defaultRoleName={"Prosjektdeltaker"}
                fromDate={project.from}
                toDate={project.to}
              />
            </div>
          </div>

          <div className="flex flex-col gap-8 sm:col-span-2 3xl:col-span-1">
            <div className="hidden 3xl:block">
              <ProjectStatus projectId={project.id} />
            </div>

            <div>
              <NoteComponent resourceId={project.id} resourceType={"project"} />
            </div>
            <div className="h-full">
              <MessageView
                title={"Chat"}
                resourceId={project.id}
                resourceType={"project"}
              />
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  );
}
