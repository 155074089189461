/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { Offer } from '../model';
// @ts-ignore
import { OfferDetails } from '../model';
// @ts-ignore
import { OfferFileResponse } from '../model';
// @ts-ignore
import { OfferPostRequest } from '../model';
// @ts-ignore
import { OfferPutRequest } from '../model';
// @ts-ignore
import { OfferSendPostRequest } from '../model';
// @ts-ignore
import { OfferStatusRequest } from '../model';
// @ts-ignore
import { OfferTemplate } from '../model';
// @ts-ignore
import { OfferTemplatePostRequest } from '../model';
// @ts-ignore
import { PaginatedResponseOffer } from '../model';
// @ts-ignore
import { RespondToOfferRequest } from '../model';
// @ts-ignore
import { SignedUrlRequest } from '../model';
// @ts-ignore
import { SignedUrlResponse } from '../model';
/**
 * OfferApi - axios parameter creator
 * @export
 */
export const OfferApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive offer
         * @param {string} companyId 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveOffer: async (companyId: string, id: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('archiveOffer', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('archiveOffer', 'id', id)
            const localVarPath = `/v1/company/{companyId}/offer/{id}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} fileId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileUrl: async (companyId: string, fileId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createFileUrl', 'companyId', companyId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('createFileUrl', 'fileId', fileId)
            const localVarPath = `/v1/company/{companyId}/offer/file/{fileId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new offer
         * @param {string} companyId 
         * @param {OfferPostRequest} offerPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffer: async (companyId: string, offerPostRequest: OfferPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createOffer', 'companyId', companyId)
            // verify required parameter 'offerPostRequest' is not null or undefined
            assertParamExists('createOffer', 'offerPostRequest', offerPostRequest)
            const localVarPath = `/v1/company/{companyId}/offer`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offerPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {OfferTemplatePostRequest} offerTemplatePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrReplaceTemplate: async (companyId: string, offerTemplatePostRequest: OfferTemplatePostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createOrReplaceTemplate', 'companyId', companyId)
            // verify required parameter 'offerTemplatePostRequest' is not null or undefined
            assertParamExists('createOrReplaceTemplate', 'offerTemplatePostRequest', offerTemplatePostRequest)
            const localVarPath = `/v1/company/{companyId}/offer_template`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offerTemplatePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete offer
         * @param {string} companyId 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOffer: async (companyId: string, id: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteOffer', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOffer', 'id', id)
            const localVarPath = `/v1/company/{companyId}/offer/{id}/delete`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delte offer template
         * @param {string} companyId 
         * @param {string} templateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOfferTemplate: async (companyId: string, templateId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteOfferTemplate', 'companyId', companyId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('deleteOfferTemplate', 'templateId', templateId)
            const localVarPath = `/v1/company/{companyId}/offer/template/{templateId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get offer by id
         * @param {string} companyId 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferById: async (companyId: string, id: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getOfferById', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOfferById', 'id', id)
            const localVarPath = `/v1/company/{companyId}/offer/{id}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get offer by path URI
         * @param {string} pathUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferByPathUri: async (pathUri: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pathUri' is not null or undefined
            assertParamExists('getOfferByPathUri', 'pathUri', pathUri)
            const localVarPath = `/v1/unauthenticated/offer/path_uri/{pathUri}`
                .replace(`{${"pathUri"}}`, encodeURIComponent(String(pathUri)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get offer file URL by id
         * @param {string} companyId 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferFileByOfferId: async (companyId: string, id: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getOfferFileByOfferId', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOfferFileByOfferId', 'id', id)
            const localVarPath = `/v1/company/{companyId}/offer/{id}/file`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferTemplateById: async (companyId: string, id: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getOfferTemplateById', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOfferTemplateById', 'id', id)
            const localVarPath = `/v1/company/{companyId}/offer_template/{id}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferTemplatesByCompanyId: async (companyId: string, authorization?: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getOfferTemplatesByCompanyId', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/offer_template`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get offer upload URL
         * @param {string} companyId 
         * @param {SignedUrlRequest} signedUrlRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferUploadUrl: async (companyId: string, signedUrlRequest: SignedUrlRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getOfferUploadUrl', 'companyId', companyId)
            // verify required parameter 'signedUrlRequest' is not null or undefined
            assertParamExists('getOfferUploadUrl', 'signedUrlRequest', signedUrlRequest)
            const localVarPath = `/v1/company/{companyId}/offer/file/upload`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signedUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get offers by company id
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffersByCompanyId: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getOffersByCompanyId', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/offer`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get offers by customer id
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffersByCustomerId: async (companyId: string, customerId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getOffersByCustomerId', 'companyId', companyId)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getOffersByCustomerId', 'customerId', customerId)
            const localVarPath = `/v1/company/{companyId}/offer/customer/{customerId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get offers (change orders) by project id
         * @param {string} companyId 
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffersByProjectId: async (companyId: string, projectId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getOffersByProjectId', 'companyId', companyId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getOffersByProjectId', 'projectId', projectId)
            const localVarPath = `/v1/company/{companyId}/project{projectId}/offer`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get offers by sales opportunity id
         * @param {string} companyId 
         * @param {string} salesOpportunityId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffersBySalesOpportunityId: async (companyId: string, salesOpportunityId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getOffersBySalesOpportunityId', 'companyId', companyId)
            // verify required parameter 'salesOpportunityId' is not null or undefined
            assertParamExists('getOffersBySalesOpportunityId', 'salesOpportunityId', salesOpportunityId)
            const localVarPath = `/v1/company/{companyId}/offer/sales-opportunity/{salesOpportunityId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"salesOpportunityId"}}`, encodeURIComponent(String(salesOpportunityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get paginated offers by company id
         * @param {string} companyId 
         * @param {string} [salesOpportunityId] 
         * @param {'created' | 'sent' | 'accepted' | 'declined' | 'expired' | 'pending_price' | 'deleted'} [status] 
         * @param {string} [ownerId] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OfferNumber' | 'UpdatedAt' | 'SentAt' | 'CreatedAt' | 'Status'} [sortField] 
         * @param {string} [query] 
         * @param {number} [pageSize] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedOffersByCompanyId: async (companyId: string, salesOpportunityId?: string, status?: 'created' | 'sent' | 'accepted' | 'declined' | 'expired' | 'pending_price' | 'deleted', ownerId?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OfferNumber' | 'UpdatedAt' | 'SentAt' | 'CreatedAt' | 'Status', query?: string, pageSize?: number, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getPaginatedOffersByCompanyId', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/offer/paginated`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salesOpportunityId !== undefined) {
                localVarQueryParameter['salesOpportunityId'] = salesOpportunityId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (ownerId !== undefined) {
                localVarQueryParameter['ownerId'] = ownerId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove offer sales opportunity relation
         * @param {string} companyId 
         * @param {string} offerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOfferSalesOpportunity: async (companyId: string, offerId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('removeOfferSalesOpportunity', 'companyId', companyId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('removeOfferSalesOpportunity', 'offerId', offerId)
            const localVarPath = `/v1/company/{companyId}/offer/{offerId}/salesOpportunity/remove`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Customer accepts or declines offer
         * @param {string} pathUri 
         * @param {RespondToOfferRequest} respondToOfferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        respondToOffer: async (pathUri: string, respondToOfferRequest: RespondToOfferRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pathUri' is not null or undefined
            assertParamExists('respondToOffer', 'pathUri', pathUri)
            // verify required parameter 'respondToOfferRequest' is not null or undefined
            assertParamExists('respondToOffer', 'respondToOfferRequest', respondToOfferRequest)
            const localVarPath = `/v1/unauthenticated/offer/path_uri/{pathUri}`
                .replace(`{${"pathUri"}}`, encodeURIComponent(String(pathUri)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(respondToOfferRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send offer
         * @param {string} companyId 
         * @param {string} id 
         * @param {OfferSendPostRequest} offerSendPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendOffer: async (companyId: string, id: string, offerSendPostRequest: OfferSendPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('sendOffer', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendOffer', 'id', id)
            // verify required parameter 'offerSendPostRequest' is not null or undefined
            assertParamExists('sendOffer', 'offerSendPostRequest', offerSendPostRequest)
            const localVarPath = `/v1/company/{companyId}/offer/{id}/send`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offerSendPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update offer
         * @param {string} companyId 
         * @param {string} id 
         * @param {OfferPutRequest} offerPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOffer: async (companyId: string, id: string, offerPutRequest: OfferPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateOffer', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOffer', 'id', id)
            // verify required parameter 'offerPutRequest' is not null or undefined
            assertParamExists('updateOffer', 'offerPutRequest', offerPutRequest)
            const localVarPath = `/v1/company/{companyId}/offer/{id}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offerPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update offer relation to sales opportunity
         * @param {string} companyId 
         * @param {string} offerId 
         * @param {string} salesOpportunityId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOfferSalesOpportunity: async (companyId: string, offerId: string, salesOpportunityId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateOfferSalesOpportunity', 'companyId', companyId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('updateOfferSalesOpportunity', 'offerId', offerId)
            // verify required parameter 'salesOpportunityId' is not null or undefined
            assertParamExists('updateOfferSalesOpportunity', 'salesOpportunityId', salesOpportunityId)
            const localVarPath = `/v1/company/{companyId}/offer/{offerId}/salesOpportunity/{salesOpportunityId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)))
                .replace(`{${"salesOpportunityId"}}`, encodeURIComponent(String(salesOpportunityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update offer status
         * @param {string} companyId 
         * @param {string} offerId 
         * @param {OfferStatusRequest} offerStatusRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOfferStatus: async (companyId: string, offerId: string, offerStatusRequest: OfferStatusRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateOfferStatus', 'companyId', companyId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('updateOfferStatus', 'offerId', offerId)
            // verify required parameter 'offerStatusRequest' is not null or undefined
            assertParamExists('updateOfferStatus', 'offerStatusRequest', offerStatusRequest)
            const localVarPath = `/v1/company/{companyId}/offer/{offerId}/status`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offerStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferApi - functional programming interface
 * @export
 */
export const OfferApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfferApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Archive offer
         * @param {string} companyId 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveOffer(companyId: string, id: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveOffer(companyId, id, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} fileId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFileUrl(companyId: string, fileId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFileUrl(companyId, fileId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new offer
         * @param {string} companyId 
         * @param {OfferPostRequest} offerPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOffer(companyId: string, offerPostRequest: OfferPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOffer(companyId, offerPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {OfferTemplatePostRequest} offerTemplatePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrReplaceTemplate(companyId: string, offerTemplatePostRequest: OfferTemplatePostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrReplaceTemplate(companyId, offerTemplatePostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete offer
         * @param {string} companyId 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOffer(companyId: string, id: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOffer(companyId, id, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delte offer template
         * @param {string} companyId 
         * @param {string} templateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOfferTemplate(companyId: string, templateId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOfferTemplate(companyId, templateId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get offer by id
         * @param {string} companyId 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfferById(companyId: string, id: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfferById(companyId, id, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get offer by path URI
         * @param {string} pathUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfferByPathUri(pathUri: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfferByPathUri(pathUri, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get offer file URL by id
         * @param {string} companyId 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfferFileByOfferId(companyId: string, id: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfferFileByOfferId(companyId, id, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfferTemplateById(companyId: string, id: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfferTemplateById(companyId, id, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfferTemplatesByCompanyId(companyId: string, authorization?: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OfferTemplate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfferTemplatesByCompanyId(companyId, authorization, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get offer upload URL
         * @param {string} companyId 
         * @param {SignedUrlRequest} signedUrlRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfferUploadUrl(companyId: string, signedUrlRequest: SignedUrlRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfferUploadUrl(companyId, signedUrlRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get offers by company id
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOffersByCompanyId(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Offer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOffersByCompanyId(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get offers by customer id
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOffersByCustomerId(companyId: string, customerId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Offer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOffersByCustomerId(companyId, customerId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get offers (change orders) by project id
         * @param {string} companyId 
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOffersByProjectId(companyId: string, projectId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Offer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOffersByProjectId(companyId, projectId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get offers by sales opportunity id
         * @param {string} companyId 
         * @param {string} salesOpportunityId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOffersBySalesOpportunityId(companyId: string, salesOpportunityId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Offer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOffersBySalesOpportunityId(companyId, salesOpportunityId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get paginated offers by company id
         * @param {string} companyId 
         * @param {string} [salesOpportunityId] 
         * @param {'created' | 'sent' | 'accepted' | 'declined' | 'expired' | 'pending_price' | 'deleted'} [status] 
         * @param {string} [ownerId] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OfferNumber' | 'UpdatedAt' | 'SentAt' | 'CreatedAt' | 'Status'} [sortField] 
         * @param {string} [query] 
         * @param {number} [pageSize] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedOffersByCompanyId(companyId: string, salesOpportunityId?: string, status?: 'created' | 'sent' | 'accepted' | 'declined' | 'expired' | 'pending_price' | 'deleted', ownerId?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OfferNumber' | 'UpdatedAt' | 'SentAt' | 'CreatedAt' | 'Status', query?: string, pageSize?: number, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseOffer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginatedOffersByCompanyId(companyId, salesOpportunityId, status, ownerId, page, sortDirection, sortField, query, pageSize, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove offer sales opportunity relation
         * @param {string} companyId 
         * @param {string} offerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeOfferSalesOpportunity(companyId: string, offerId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeOfferSalesOpportunity(companyId, offerId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Customer accepts or declines offer
         * @param {string} pathUri 
         * @param {RespondToOfferRequest} respondToOfferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async respondToOffer(pathUri: string, respondToOfferRequest: RespondToOfferRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.respondToOffer(pathUri, respondToOfferRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send offer
         * @param {string} companyId 
         * @param {string} id 
         * @param {OfferSendPostRequest} offerSendPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendOffer(companyId: string, id: string, offerSendPostRequest: OfferSendPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendOffer(companyId, id, offerSendPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update offer
         * @param {string} companyId 
         * @param {string} id 
         * @param {OfferPutRequest} offerPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOffer(companyId: string, id: string, offerPutRequest: OfferPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOffer(companyId, id, offerPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update offer relation to sales opportunity
         * @param {string} companyId 
         * @param {string} offerId 
         * @param {string} salesOpportunityId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOfferSalesOpportunity(companyId: string, offerId: string, salesOpportunityId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOfferSalesOpportunity(companyId, offerId, salesOpportunityId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update offer status
         * @param {string} companyId 
         * @param {string} offerId 
         * @param {OfferStatusRequest} offerStatusRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOfferStatus(companyId: string, offerId: string, offerStatusRequest: OfferStatusRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOfferStatus(companyId, offerId, offerStatusRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfferApi - factory interface
 * @export
 */
export const OfferApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfferApiFp(configuration)
    return {
        /**
         * 
         * @summary Archive offer
         * @param {string} companyId 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveOffer(companyId: string, id: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.archiveOffer(companyId, id, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} fileId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileUrl(companyId: string, fileId: string, authorization?: string, options?: any): AxiosPromise<string> {
            return localVarFp.createFileUrl(companyId, fileId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new offer
         * @param {string} companyId 
         * @param {OfferPostRequest} offerPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffer(companyId: string, offerPostRequest: OfferPostRequest, authorization?: string, options?: any): AxiosPromise<Offer> {
            return localVarFp.createOffer(companyId, offerPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {OfferTemplatePostRequest} offerTemplatePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrReplaceTemplate(companyId: string, offerTemplatePostRequest: OfferTemplatePostRequest, authorization?: string, options?: any): AxiosPromise<OfferTemplate> {
            return localVarFp.createOrReplaceTemplate(companyId, offerTemplatePostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete offer
         * @param {string} companyId 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOffer(companyId: string, id: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteOffer(companyId, id, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delte offer template
         * @param {string} companyId 
         * @param {string} templateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOfferTemplate(companyId: string, templateId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteOfferTemplate(companyId, templateId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get offer by id
         * @param {string} companyId 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferById(companyId: string, id: string, authorization?: string, options?: any): AxiosPromise<OfferDetails> {
            return localVarFp.getOfferById(companyId, id, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get offer by path URI
         * @param {string} pathUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferByPathUri(pathUri: string, options?: any): AxiosPromise<OfferDetails> {
            return localVarFp.getOfferByPathUri(pathUri, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get offer file URL by id
         * @param {string} companyId 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferFileByOfferId(companyId: string, id: string, authorization?: string, options?: any): AxiosPromise<OfferFileResponse> {
            return localVarFp.getOfferFileByOfferId(companyId, id, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferTemplateById(companyId: string, id: string, authorization?: string, options?: any): AxiosPromise<OfferTemplate> {
            return localVarFp.getOfferTemplateById(companyId, id, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferTemplatesByCompanyId(companyId: string, authorization?: string, lang?: string, options?: any): AxiosPromise<Array<OfferTemplate>> {
            return localVarFp.getOfferTemplatesByCompanyId(companyId, authorization, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get offer upload URL
         * @param {string} companyId 
         * @param {SignedUrlRequest} signedUrlRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferUploadUrl(companyId: string, signedUrlRequest: SignedUrlRequest, authorization?: string, options?: any): AxiosPromise<SignedUrlResponse> {
            return localVarFp.getOfferUploadUrl(companyId, signedUrlRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get offers by company id
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffersByCompanyId(companyId: string, authorization?: string, options?: any): AxiosPromise<Array<Offer>> {
            return localVarFp.getOffersByCompanyId(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get offers by customer id
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffersByCustomerId(companyId: string, customerId: string, authorization?: string, options?: any): AxiosPromise<Array<Offer>> {
            return localVarFp.getOffersByCustomerId(companyId, customerId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get offers (change orders) by project id
         * @param {string} companyId 
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffersByProjectId(companyId: string, projectId: string, authorization?: string, options?: any): AxiosPromise<Array<Offer>> {
            return localVarFp.getOffersByProjectId(companyId, projectId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get offers by sales opportunity id
         * @param {string} companyId 
         * @param {string} salesOpportunityId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffersBySalesOpportunityId(companyId: string, salesOpportunityId: string, authorization?: string, options?: any): AxiosPromise<Array<Offer>> {
            return localVarFp.getOffersBySalesOpportunityId(companyId, salesOpportunityId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get paginated offers by company id
         * @param {string} companyId 
         * @param {string} [salesOpportunityId] 
         * @param {'created' | 'sent' | 'accepted' | 'declined' | 'expired' | 'pending_price' | 'deleted'} [status] 
         * @param {string} [ownerId] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title' | 'OfferNumber' | 'UpdatedAt' | 'SentAt' | 'CreatedAt' | 'Status'} [sortField] 
         * @param {string} [query] 
         * @param {number} [pageSize] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedOffersByCompanyId(companyId: string, salesOpportunityId?: string, status?: 'created' | 'sent' | 'accepted' | 'declined' | 'expired' | 'pending_price' | 'deleted', ownerId?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OfferNumber' | 'UpdatedAt' | 'SentAt' | 'CreatedAt' | 'Status', query?: string, pageSize?: number, authorization?: string, options?: any): AxiosPromise<PaginatedResponseOffer> {
            return localVarFp.getPaginatedOffersByCompanyId(companyId, salesOpportunityId, status, ownerId, page, sortDirection, sortField, query, pageSize, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove offer sales opportunity relation
         * @param {string} companyId 
         * @param {string} offerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOfferSalesOpportunity(companyId: string, offerId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.removeOfferSalesOpportunity(companyId, offerId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Customer accepts or declines offer
         * @param {string} pathUri 
         * @param {RespondToOfferRequest} respondToOfferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        respondToOffer(pathUri: string, respondToOfferRequest: RespondToOfferRequest, options?: any): AxiosPromise<object> {
            return localVarFp.respondToOffer(pathUri, respondToOfferRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send offer
         * @param {string} companyId 
         * @param {string} id 
         * @param {OfferSendPostRequest} offerSendPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendOffer(companyId: string, id: string, offerSendPostRequest: OfferSendPostRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.sendOffer(companyId, id, offerSendPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update offer
         * @param {string} companyId 
         * @param {string} id 
         * @param {OfferPutRequest} offerPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOffer(companyId: string, id: string, offerPutRequest: OfferPutRequest, authorization?: string, options?: any): AxiosPromise<Offer> {
            return localVarFp.updateOffer(companyId, id, offerPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update offer relation to sales opportunity
         * @param {string} companyId 
         * @param {string} offerId 
         * @param {string} salesOpportunityId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOfferSalesOpportunity(companyId: string, offerId: string, salesOpportunityId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.updateOfferSalesOpportunity(companyId, offerId, salesOpportunityId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update offer status
         * @param {string} companyId 
         * @param {string} offerId 
         * @param {OfferStatusRequest} offerStatusRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOfferStatus(companyId: string, offerId: string, offerStatusRequest: OfferStatusRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.updateOfferStatus(companyId, offerId, offerStatusRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfferApi - object-oriented interface
 * @export
 * @class OfferApi
 * @extends {BaseAPI}
 */
export class OfferApi extends BaseAPI {
    /**
     * 
     * @summary Archive offer
     * @param {string} companyId 
     * @param {string} id 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public archiveOffer(companyId: string, id: string, authorization?: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).archiveOffer(companyId, id, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} fileId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public createFileUrl(companyId: string, fileId: string, authorization?: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).createFileUrl(companyId, fileId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new offer
     * @param {string} companyId 
     * @param {OfferPostRequest} offerPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public createOffer(companyId: string, offerPostRequest: OfferPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).createOffer(companyId, offerPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {OfferTemplatePostRequest} offerTemplatePostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public createOrReplaceTemplate(companyId: string, offerTemplatePostRequest: OfferTemplatePostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).createOrReplaceTemplate(companyId, offerTemplatePostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete offer
     * @param {string} companyId 
     * @param {string} id 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public deleteOffer(companyId: string, id: string, authorization?: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).deleteOffer(companyId, id, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delte offer template
     * @param {string} companyId 
     * @param {string} templateId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public deleteOfferTemplate(companyId: string, templateId: string, authorization?: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).deleteOfferTemplate(companyId, templateId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get offer by id
     * @param {string} companyId 
     * @param {string} id 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public getOfferById(companyId: string, id: string, authorization?: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).getOfferById(companyId, id, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get offer by path URI
     * @param {string} pathUri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public getOfferByPathUri(pathUri: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).getOfferByPathUri(pathUri, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get offer file URL by id
     * @param {string} companyId 
     * @param {string} id 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public getOfferFileByOfferId(companyId: string, id: string, authorization?: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).getOfferFileByOfferId(companyId, id, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} id 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public getOfferTemplateById(companyId: string, id: string, authorization?: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).getOfferTemplateById(companyId, id, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public getOfferTemplatesByCompanyId(companyId: string, authorization?: string, lang?: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).getOfferTemplatesByCompanyId(companyId, authorization, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get offer upload URL
     * @param {string} companyId 
     * @param {SignedUrlRequest} signedUrlRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public getOfferUploadUrl(companyId: string, signedUrlRequest: SignedUrlRequest, authorization?: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).getOfferUploadUrl(companyId, signedUrlRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get offers by company id
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public getOffersByCompanyId(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).getOffersByCompanyId(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get offers by customer id
     * @param {string} companyId 
     * @param {string} customerId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public getOffersByCustomerId(companyId: string, customerId: string, authorization?: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).getOffersByCustomerId(companyId, customerId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get offers (change orders) by project id
     * @param {string} companyId 
     * @param {string} projectId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public getOffersByProjectId(companyId: string, projectId: string, authorization?: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).getOffersByProjectId(companyId, projectId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get offers by sales opportunity id
     * @param {string} companyId 
     * @param {string} salesOpportunityId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public getOffersBySalesOpportunityId(companyId: string, salesOpportunityId: string, authorization?: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).getOffersBySalesOpportunityId(companyId, salesOpportunityId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get paginated offers by company id
     * @param {string} companyId 
     * @param {string} [salesOpportunityId] 
     * @param {'created' | 'sent' | 'accepted' | 'declined' | 'expired' | 'pending_price' | 'deleted'} [status] 
     * @param {string} [ownerId] 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'Title' | 'OfferNumber' | 'UpdatedAt' | 'SentAt' | 'CreatedAt' | 'Status'} [sortField] 
     * @param {string} [query] 
     * @param {number} [pageSize] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public getPaginatedOffersByCompanyId(companyId: string, salesOpportunityId?: string, status?: 'created' | 'sent' | 'accepted' | 'declined' | 'expired' | 'pending_price' | 'deleted', ownerId?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title' | 'OfferNumber' | 'UpdatedAt' | 'SentAt' | 'CreatedAt' | 'Status', query?: string, pageSize?: number, authorization?: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).getPaginatedOffersByCompanyId(companyId, salesOpportunityId, status, ownerId, page, sortDirection, sortField, query, pageSize, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove offer sales opportunity relation
     * @param {string} companyId 
     * @param {string} offerId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public removeOfferSalesOpportunity(companyId: string, offerId: string, authorization?: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).removeOfferSalesOpportunity(companyId, offerId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Customer accepts or declines offer
     * @param {string} pathUri 
     * @param {RespondToOfferRequest} respondToOfferRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public respondToOffer(pathUri: string, respondToOfferRequest: RespondToOfferRequest, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).respondToOffer(pathUri, respondToOfferRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send offer
     * @param {string} companyId 
     * @param {string} id 
     * @param {OfferSendPostRequest} offerSendPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public sendOffer(companyId: string, id: string, offerSendPostRequest: OfferSendPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).sendOffer(companyId, id, offerSendPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update offer
     * @param {string} companyId 
     * @param {string} id 
     * @param {OfferPutRequest} offerPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public updateOffer(companyId: string, id: string, offerPutRequest: OfferPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).updateOffer(companyId, id, offerPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update offer relation to sales opportunity
     * @param {string} companyId 
     * @param {string} offerId 
     * @param {string} salesOpportunityId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public updateOfferSalesOpportunity(companyId: string, offerId: string, salesOpportunityId: string, authorization?: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).updateOfferSalesOpportunity(companyId, offerId, salesOpportunityId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update offer status
     * @param {string} companyId 
     * @param {string} offerId 
     * @param {OfferStatusRequest} offerStatusRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public updateOfferStatus(companyId: string, offerId: string, offerStatusRequest: OfferStatusRequest, authorization?: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).updateOfferStatus(companyId, offerId, offerStatusRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
