import { createFileRoute } from "@tanstack/react-router";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import { useOfferPreviewStore } from "../../../../../state/app_store";
import {
  useGetCompanyLogo,
  useGetCompanyPublicInfo
} from "../../../../../service/api/CompanyApi";
import {
  OfferCheckbox,
  OfferDescription,
  OfferDetails,
  OfferProduct,
  OfferProductGroup,
  OfferTextField
} from "../../../../../.generated/api";
import DetailsPage from "../../../../_unprotected/offer/$pathUri/details/-components/DetailsPage";

export const Route = createFileRoute(
  "/_protected/dashboard/sales/offer/preview"
)({
  component: RouteComponent
});

function RouteComponent() {
  const companyId = useCompanyId();
  const offer = useOfferPreviewStore((s) => s.offer);

  const company = useGetCompanyPublicInfo({ variables: { companyId } });

  const companyLogo = useGetCompanyLogo({ variables: { companyId } });

  const expiryDate = offer?.expiryDate
    ? offer.expiryDate.toString()
    : new Date().toString();

  const offerDetails: OfferDetails = {
    companyId: "",
    id: "",
    companyInformation: "",
    pathUri: "",
    updatedAt: new Date().toString(),
    number: 0,
    status: "created",
    title: offer?.title ?? "",
    description: offer?.description,
    expirationDate: expiryDate,
    archived: false,
    totalValue: 0,
    attachments: offer?.attachments?.map((a) => ({
      fileId: "",
      downloadUrl: "",
      name: a.filename,
      fileType: a.fileType ?? "",
      createdAt: new Date().toISOString()
    })),
    subsections:
      offer?.sections?.map((section) => {
        if (section.type === "textField") {
          return {
            id: "",
            type: "TextField",
            required: section.required,
            title: section.title,
            orderIndex: 0
          } satisfies OfferTextField;
        } else if (section.type === "checkbox") {
          return {
            id: "",
            type: "Checkbox",
            title: section.title,
            customerChecked: false,
            orderIndex: 0,
            required: section.required
          } satisfies OfferCheckbox;
        } else if (section.type === "description") {
          return {
            id: "",
            type: "Description",
            title: section.title,
            description: section.description,
            orderIndex: 0
          } satisfies OfferDescription;
        } else if (section.type === "products") {
          return {
            id: "",
            type: "ProductGroup",
            title: section.title,
            isOption: section.isOption,
            orderIndex: 0,
            isChosen: false,
            products: section.products.map((product) => {
              return {
                type: "Product",
                id: 0,
                productId: product.productId ?? undefined,
                title: product.title,
                description: product.description ?? undefined,
                mva: +product.vat,
                count: product.quantity,
                price: product.price,
                rebate: product.discount
              } satisfies OfferProduct;
            })
          } satisfies OfferProductGroup;
        } else {
          throw new Error("Unsupported section type OUTSIDE");
        }
      }) ?? [],
    customer: undefined,
    history: [],
    companyDetails: {
      companyId: "",
      companyName: company?.data?.name ?? "",
      companyLogoUrl: companyLogo?.data?.url,
      contactEmail: company?.data?.contactEmail ?? "",
      contactName: company?.data?.contactPerson ?? "",
      contactPhone: company?.data?.contactPhone ?? ""
    }
  };

  return <DetailsPage isPreview={true} offer={offerDetails} />;
}
