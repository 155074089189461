import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import DetailsPage from "./-components/DetailsPage";
import { useGetOfferByPathUri } from "../../../../../service/api/OfferApi";

export const Route = createFileRoute("/_unprotected/offer/$pathUri/details/")({
  loader: ({ context: { queryClient }, params: { pathUri } }) => {
    return queryClient.fetchQuery({
      ...useGetOfferByPathUri.getOptions({ pathUri: pathUri })
    });
  },
  component: RouteComponent
});

function RouteComponent() {
  const offer = useLoaderData({
    from: "/_unprotected/offer/$pathUri/details/"
  });

  return <DetailsPage offer={offer} isPreview={false} />;
}
