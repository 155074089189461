import { createFileRoute } from "@tanstack/react-router";
import React from "react";
import { t } from "i18next";
import CenteredPageLayout from "../../../../../components/ds/CenteredPageLayout";
import PageLayout from "../../../../../components/PageLayout";
import CardNew from "../../../../../components/ds/CardNew";
import ButtonNew from "../../../../../components/ds/ButtonNew";
import { Text } from "@radix-ui/themes";

type VideoCardProps = {
  title: string;
  subtitle: string;
  link: string;
};

export const Route = createFileRoute(
  "/_protected/dashboard/settings/_layout/video-guide"
)({
  component: VideoGuideSettingsPage
});

function VideoCard({ link, subtitle, title }: VideoCardProps) {
  return (
    <CardNew>
      <div className={"flex justify-between items-center gap-4"}>
        <div className={"flex flex-col gap-1"}>
          <Text as={"p"} weight={"bold"}>
            {title}
          </Text>
          <Text as={"p"} color={"gray"} className={"max-w-[400px]"}>
            {subtitle}
          </Text>
        </div>
        <ButtonNew asChild variant={"soft"} trailingIcon={"open_in_new"}>
          <a href={link} target={"_blank"} rel="noreferrer">
            Se video
          </a>
        </ButtonNew>
      </div>
    </CardNew>
  );
}

function VideoGuideSettingsPage() {
  return (
    <PageLayout>
      <CenteredPageLayout>
        <CardNew title={t("skyworkerVideoGuide")}>
          <div className={"flex flex-col gap-4"}>
            <VideoCard
              title={t("Velkommen til Skyworker!")}
              subtitle={"Intro til Skyworker"}
              link={
                "https://www.youtube.com/embed/DEHn8zAAJJo?si=pfQru6WUOmoiMI2h"
              }
            />
            <VideoCard
              title={t("customers")}
              subtitle={t("Jobb med kunder.")}
              link={
                "https://www.youtube.com/embed/h51hCN5DwCQ?si=_VZEAvcEAedRc4bn"
              }
            />
            <VideoCard
              title={t("sales")}
              subtitle={t(
                "Lær hvordan du kan jobbe med salg og digitale tilbud."
              )}
              link={
                "https://www.youtube.com/embed/hlYiIpYPBak?si=zT23njTixM09Lp2p"
              }
            />
            <VideoCard
              title={t("projects")}
              subtitle={t("Alt du trenger å vite om Skyworker prosjektmodul.")}
              link={
                "https://www.youtube.com/embed/b9CCUURtaKs?si=DSJOJBeJMUWTSP2M"
              }
            />
            <VideoCard
              title={t("workOrders")}
              subtitle={t("Arbeidsordre - perfekt for mindre oppdrag.")}
              link={
                "https://www.youtube.com/embed/dtR_vwoCbhc?si=tuU_gD88KA8_iP2c"
              }
            />
            <VideoCard
              title={t("resources")}
              subtitle={t("Inviter dine ansatte.")}
              link={
                "https://www.youtube.com/embed/9AihpJFCyVM?si=SfIuUCGI5VGLNjN9"
              }
            />
            <VideoCard
              title={t("economy")}
              subtitle={t("Jobb med faktura og timelister.")}
              link={
                "https://www.youtube.com/embed/XTEP3a0kxo8?si=XlWGwggviLR0qQks"
              }
            />
            <VideoCard
              title={t("products")}
              subtitle={t("Bygg din egen produktdatabase i Skyworker.")}
              link={
                "https://www.youtube.com/embed/FLCzgHqb3Qg?si=tIXXZQbKcVR6FOEf"
              }
            />
            <VideoCard
              title={t("ehsAndInternalRoutines")}
              subtitle={t("Bruk HMS til lovpålagte rutiner.")}
              link={
                "https://www.youtube.com/embed/-vyrCckWVTs?si=zeXOyU9rabz_IYR_"
              }
            />
            <VideoCard
              title={t("app")}
              subtitle={t(
                "Lær hvordan dine ansatte kan bruke Skyworker effektivt fra vår mobil-applikasjon."
              )}
              link={
                "https://www.youtube.com/embed/kwAqVP_LWlk?si=FbPh7TxXJ0KEfOv7"
              }
            />
          </div>
        </CardNew>
      </CenteredPageLayout>
    </PageLayout>
  );
}
