import {
  createFileRoute,
  useNavigate,
  useSearch
} from "@tanstack/react-router";
import { productTabs } from "../../../../router/Routes";
import CategoryView from "../../../../pages/products/CategoryView";
import UnitView from "../../../../pages/products/UnitView";
import PriceAdjustmentsView from "../../../../pages/products/PriceAdjustmentsView";
import ProductTab from "./-components/ProductTab";
import EstimationTab from "./-components/EstimationTab";
import { useTranslation } from "react-i18next";
import NewPageTitle from "../../../../components/common/NewPageTitle";
import TypedTabNew from "../../../../components/ds/TypedTabNew";
import { z } from "zod";

const productTabSchema = z.object({
  tab: productTabs.catch("products").optional()
});

export const Route = createFileRoute("/_protected/dashboard/products/")({
  validateSearch: productTabSchema,
  component: ProductPage
});

function ProductPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { tab } = useSearch({ from: "/_protected/dashboard/products/" });

  return (
    <>
      <NewPageTitle title={t("products")} />
      <TypedTabNew
        tabs={productTabs.options}
        getLabel={(tab) => t(tab)}
        selected={tab}
        onChange={async (tab) => {
          await navigate({
            to: "/dashboard/products",
            search: {
              tab: tab
            }
          });
        }}
      >
        <ProductTab />
        {/* <CompositeProductTab /> */}
        {/* <ProductBundleTab /> */}
        <CategoryView />
        <UnitView />
        <PriceAdjustmentsView />
        <EstimationTab />
      </TypedTabNew>
    </>
  );
}
