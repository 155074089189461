import { saveAs } from "file-saver";
import { SelectedProjectType } from "../../providers/SelectedProjectsProvider";
import { TS } from "../../service/api/TimeSubmitApi";
import { intervalToDuration } from "date-fns";

export const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
export const EXCEL_EXTENSION = ".xlsx";

export function getAllTimeSubmits(selectedProjects: SelectedProjectType[]) {
  const allSelectedTimeSubmits = selectedProjects.flatMap(({ timeSubmits }) =>
    timeSubmits.map((timeSubmit) => timeSubmit)
  );

  return allSelectedTimeSubmits;
}

export function separateTimeTravelEntries(timeSubmits: TS[]) {
  const { timeEntries, travelEntries } = timeSubmits.reduce(
    (accumulator, timeSubmit) => {
      const { timeEntries, travelEntries } = accumulator;

      if (timeSubmit.timeEntry.valueType === "Hours")
        timeEntries.push(timeSubmit);
      else travelEntries.push(timeSubmit);

      return accumulator;
    },
    {
      timeEntries: [] as TS[],
      travelEntries: [] as TS[]
    }
  );

  return { timeEntries, travelEntries };
}

export function fileFormatTimeSubmits(timeSubmits: TS[]) {
  const formattedTimeSubmits = timeSubmits.map(
    ({
      timeEntry: {
        date,
        description,
        value,
        salaryTypeId,
        activityName,
        activityNumber
      },
      project: { title: project, projectNumber },
      user: { name: user, employeeNumber, salaryType }
    }) => ({
      EmployeeNumber: employeeNumber,
      Name: user ?? "",
      SalaryType: salaryType,
      Date: date,
      ProjectNumber: projectNumber,
      ProjectName: project,
      ActivityNumber: activityNumber,
      ActivityName: activityName,
      PayItemCode: salaryTypeId,
      Value: value,
      Description: description ?? ""
    })
  );

  return formattedTimeSubmits;
}

export function extractAndFileFormatProducts(
  selectedProjects: SelectedProjectType[]
) {
  const allSelectedProducts = selectedProjects.flatMap(({ products }) =>
    products.flatMap(({ product, entries }) =>
      entries.map(({ comment, quantity, updated_time, updated_by }) => ({
        product: product.name,
        comment,
        quantity,
        updated_time,
        updated_by
      }))
    )
  );

  return allSelectedProducts;
}

export function saveAsExcel(buffer: ArrayBuffer, fileName: string) {
  const excelData = new Blob([buffer], { type: EXCEL_TYPE });
  saveAs(
    excelData,
    fileName + "_eksportert_" + new Date().getTime() + EXCEL_EXTENSION
  );
}

export function convertStatusToVisual(status: string) {
  const lowerCaseStatus = status.toLowerCase();

  if (lowerCaseStatus === "submitted") return "Registrert";
  else if (lowerCaseStatus === "approved") return "Godkjent";
  else if (lowerCaseStatus === "invoiced") return "Fakturert";
  else return status;
}

function zeroPad(val?: number) {
  return String(val).padStart(2, "0");
}

export function validateInput(hours?: number, minutes?: number) {
  try {
    const hoursToSeconds = (hours ?? 0) * 3600;
    const minutesToSeconds = (minutes ?? 0) * 60;
    const interval = intervalToDuration({
      start: 0,
      end: (hoursToSeconds + minutesToSeconds) * 1000
    });

    return `${zeroPad(interval.hours ?? 0)}:${zeroPad(interval.minutes ?? 0)}`;
  } catch (_) {
    return undefined;
  }
}
