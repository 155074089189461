import { useTranslation } from "react-i18next";
import { createFileRoute, Outlet } from "@tanstack/react-router";
import NewPageTitle from "../../../../components/common/NewPageTitle";
import TabNav from "../../../../components/TabNav";
import { TabLink } from "../../../../components/TabLink";

export const Route = createFileRoute("/_protected/dashboard/customers/_layout")(
  {
    component: CustomersAndSalesLayoutPage
  }
);

function CustomersAndSalesLayoutPage() {
  const { t } = useTranslation();
  return (
    <>
      <NewPageTitle title={t("customers")} />
      <TabNav>
        <TabLink to={"/dashboard/customers/customers"}>
          {t("customers")}
        </TabLink>
        <TabLink to={"/dashboard/customers/inbox"}>{t("inbox")}</TabLink>
      </TabNav>
      <Outlet />
    </>
  );
}
