/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CompanyUser } from './company-user';
// May contain unused imports in some cases
// @ts-ignore
import { CustomerV3 } from './customer-v3';
// May contain unused imports in some cases
// @ts-ignore
import { OfferHistory } from './offer-history';

/**
 * 
 * @export
 * @interface Offer
 */
export interface Offer {
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    'number': number;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'salesOpportunityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'companyId': string;
    /**
     * 
     * @type {CustomerV3}
     * @memberof Offer
     */
    'customer'?: CustomerV3;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'pathUri': string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'status': OfferStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'expirationDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof Offer
     */
    'archived': boolean;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    'totalValue': number;
    /**
     * 
     * @type {Array<OfferHistory>}
     * @memberof Offer
     */
    'history': Array<OfferHistory>;
    /**
     * 
     * @type {CompanyUser}
     * @memberof Offer
     */
    'owner'?: CompanyUser;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'contactEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'contactPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'customerEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'customerPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'responseMessage'?: string;
}

export const OfferStatusEnum = {
    Created: 'created',
    Sent: 'sent',
    Accepted: 'accepted',
    Declined: 'declined',
    Expired: 'expired',
    PendingPrice: 'pending_price',
    Deleted: 'deleted'
} as const;

export type OfferStatusEnum = typeof OfferStatusEnum[keyof typeof OfferStatusEnum];


