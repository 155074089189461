import {
  ChecklistItemPutRequest,
  EhsItemPostRequest,
  EhsItemPutRequest,
  EhsTemplatePostRequest,
  EhsTemplatePutRequest,
  EhsV2Api
} from "../../.generated/api";
import { apiInstance } from "./ApiHttpBase";
import environment from "../../environment";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { ChecklistFormInputs } from "./ChecklistApiV2";
import i18n from "i18next";
import { createQuery } from "react-query-kit";

type EhsSortField = "Title";
type EhsType =
  | "BasicEHS"
  | "SafetyInspection"
  | "SHA"
  | "SJA"
  | "KU"
  | "FinalInspection";

const ehsApiV2 = new EhsV2Api(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export interface EhsFormInputs extends ChecklistFormInputs {
  title: string;
  description: string;
  files?: File[];
  writingUserIds?: string[];
  approvalUserIds?: string[];
  isDefault?: boolean;
}

export const useGetEhsTemplateById = createQuery({
  queryKey: ["getEhsTemplateById"],
  fetcher: async (variables: { companyId: string; templateId: string }) => {
    const response = await ehsApiV2.getEhsTemplateById(
      variables.companyId,
      variables.templateId
    );
    return response.data;
  }
});

export const useGetEhsById = createQuery({
  queryKey: ["getEhsById"],
  fetcher: async (variables: { companyId: string; ehsId: string }) => {
    const response = await ehsApiV2.getEhsItemById(
      variables.companyId,
      variables.ehsId
    );
    return response.data;
  }
});

export const useGetEhsItemsByResourceId = createQuery({
  queryKey: ["getEhsItemsByResourceId"],
  fetcher: async (variables: {
    companyId: string;
    resourceId: string;
    page?: number;
    sortDirection?: "ASC" | "DESC";
    sortField?: EhsSortField;
    query?: string;
    type?: EhsType;
  }) => {
    const response = await ehsApiV2.getAllEhsItemsByResource(
      variables.companyId,
      variables.resourceId,
      variables.page,
      variables.sortDirection,
      variables.sortField,
      variables.query,
      undefined,
      "active",
      variables.type
    );
    return response.data;
  }
});

export const useGetEhsTemplatesByCompanyId = createQuery({
  queryKey: ["getEhsTemplatesByCompanyId"],
  fetcher: async (variables: { companyId: string }) => {
    const response = await ehsApiV2.getCompanyEhsTemplates(variables.companyId);
    return response.data;
  }
});

export const ehsQueries = createQueryKeys("ehs", {
  getEhsTemplatesForCompany: (companyId: string) => ({
    queryKey: ["companyEhsTemplates", companyId],
    queryFn: async () => {
      const response = await ehsApiV2.getCompanyEhsTemplates(companyId);
      return response.data;
    }
  }),

  getPublicByProjectId: (projectId: string) => ({
    queryKey: ["getByProjectId", projectId],
    queryFn: async () => {
      const response =
        await ehsApiV2.getAllPublicEhsItemsByResourceId(projectId);
      return response.data;
    }
  })
});

export async function deleteEhsItem(
  companyId: string,
  ehsItemId: string
): Promise<void> {
  await ehsApiV2.deleteEhsItem(companyId, ehsItemId);
}

export async function createEhsItem(
  companyId: string,
  request: EhsItemPostRequest
) {
  const response = await ehsApiV2.createEhsItem(companyId, request);
  return response.data;
}

export async function updateEhsItem(
  companyId: string,
  ehsItemId: string,
  request: EhsItemPutRequest
): Promise<void> {
  await ehsApiV2.updateEhsItem(companyId, ehsItemId, request);
}

export async function updateEhsItemInputValueData(
  companyId: string,
  ehsItemId: string,
  request: Array<ChecklistItemPutRequest>,
  done?: boolean
): Promise<void> {
  await ehsApiV2.updateEHSItemInfo(
    companyId,
    ehsItemId,
    done ?? false,
    request
  );
}

export async function updateEhsTemplate(
  companyId: string,
  ehsItemId: string,
  request: EhsTemplatePutRequest
): Promise<void> {
  await ehsApiV2.updateEhsTemplate(companyId, ehsItemId, request);
}

export async function createEhsTemplate(
  companyId: string,
  request: EhsTemplatePostRequest
) {
  const response = await ehsApiV2.createEhsTemplate(companyId, request);
  return response.data;
}

export async function deleteEhsTemplate(
  companyId: string,
  ehsTemplateId: string
) {
  await ehsApiV2.deleteEhsTemplate(companyId, ehsTemplateId);
}

export async function getAllEhsItemsForResource(
  companyId: string,
  resourceId: string,
  sortDirection?: "ASC" | "DESC",
  sortField?: "Title",
  query?: string,
  status?: "active" | "deleted",
  ehsType?:
    | "BasicEHS"
    | "SafetyInspection"
    | "SHA"
    | "SJA"
    | "KU"
    | "FinalInspection"
) {
  // Temp until a (or if) endpoint for fetching all is made
  const firstPage = await ehsApiV2.getAllEhsItemsByResource(
    companyId,
    resourceId,
    0,
    sortDirection ?? "DESC",
    sortField ?? "Title",
    query ?? "",
    undefined,
    status ?? "active",
    ehsType
  );
  const numberOfPages = firstPage.data.totalPages;
  const requests = Array(numberOfPages - 1).map((_, index) =>
    ehsApiV2.getAllEhsItemsByResource(companyId, resourceId, index + 1)
  );
  const restPages = await Promise.all(requests);
  return restPages
    .map((page) => page.data.data)
    .reduce((acc, page) => acc.concat(page), firstPage.data.data);
}

export function convertEhsTitle(title: string) {
  switch (title) {
    case "Vernerunde":
      return i18n.t("safetyInspection");
    case "SJA (Sikker jobbanalyse)":
      return i18n.t("sja");
    case "Standard HMS-rutine":
      return i18n.t("ehsDocument");
    case "SHA (Sikkerhet, helse og arbeidsmiljø)":
      return i18n.t("sha");
    default:
      return title;
  }
}
