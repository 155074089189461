import { createFileRoute, useSearch } from "@tanstack/react-router";
import { UpsertProductPage } from "../../../../../pages/products/UpsertProductPage";
import { z } from "zod";

const createProductSchema = z.object({
  isComposite: z.boolean().optional()
});

export const Route = createFileRoute("/_protected/dashboard/products/new/")({
  validateSearch: createProductSchema,

  component: CreateProductPage
});

function CreateProductPage() {
  const { isComposite } = useSearch({
    from: "/_protected/dashboard/products/new/"
  });
  return <UpsertProductPage isComposite={isComposite} />;
}
