import {
  createFileRoute,
  useNavigate,
  useParams
} from "@tanstack/react-router";
import React, { useState } from "react";
import {
  EhsItem,
  EhsItemEhsTypeEnum
} from "../../../../../../../.generated/api";
import { useCompanyId } from "../../../../../../../hooks/useCompanyId";
import useDialog from "../../../../../../../hooks/useDialog";
import { useTranslation } from "react-i18next";
import {
  determinEhsBadgeColor,
  determinEhsBadgeText,
  ehsTypeString
} from "../../../../../../../pages/ehsDetails/utils";
import { useSort } from "../../../../../../../hooks/useSort";
import {
  useGetEhsItemsByResourceId,
  useGetEhsTemplatesByCompanyId
} from "../../../../../../../service/api/EhsApi";
import { keepPreviousData } from "@tanstack/react-query";
import PageLayout from "../../../../../../../components/PageLayout";
import EhsPreviewDrawer from "./-components/EhsPreviewDrawer";
import CardNew from "../../../../../../../components/ds/CardNew";
import { DropdownMenu, Flex } from "@radix-ui/themes";
import ButtonNew from "../../../../../../../components/ds/ButtonNew";
import { TextFieldNew } from "../../../../../../../components/ds/TextFieldNew";
import { searchBarSize } from "../../../../../../../components/ds/sizes";
import NewSelect from "../../../../../../../components/ds/NewSelect";
import PaginationNew from "../../../../../../../components/ds/PaginationNew";
import Table from "../../../../../../../components/ds/RadixTable/Table";
import TableHeader from "../../../../../../../components/ds/RadixTable/TableHeader";
import TableRow from "../../../../../../../components/ds/RadixTable/TableRow";
import TableHeaderCell from "../../../../../../../components/ds/RadixTable/TableHeaderCell";
import TableBody from "../../../../../../../components/ds/RadixTable/TableBody";
import TableCell from "../../../../../../../components/ds/RadixTable/TableCell";
import Badge from "../../../../../../../components/ds/Badge";
import {
  formatFullNumericDateString,
  formatTime
} from "../../../../../../../utils/DateTimeFormatter";
import { useToast } from "../../../../../../../hooks/useToast";

export type EhsSortField = "Title";

type FilteredEhsTypeEnum = Exclude<EhsItemEhsTypeEnum, "BasicEHS">;
type EhsTypeOptionValueType = FilteredEhsTypeEnum | "all";
type EhsFilterOptionType = { value: EhsTypeOptionValueType; label: string };

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/_layout/ehs/"
)({
  component: ProjectEhsPage
});

function ProjectEhsPage() {
  const { projectId } = useParams({
    from: "/_protected/dashboard/project/$projectId/_layout"
  });
  const navigate = useNavigate();
  const [selectedEhsType, setSelectedEhsType] = useState<FilteredEhsTypeEnum>();
  const [selectedEhsItem, setSelectedEhsItem] = useState<EhsItem>();
  const { showSuccessToast } = useToast();
  const companyId = useCompanyId();
  const { onClose, onOpen, isOpen } = useDialog();
  const { t } = useTranslation();

  const ehsTypeOptions: EhsFilterOptionType[] = [
    {
      value: "all",
      label: t("all")
    },
    {
      value: EhsItemEhsTypeEnum.FinalInspection,
      label: t(ehsTypeString(EhsItemEhsTypeEnum.FinalInspection))
    },
    {
      value: EhsItemEhsTypeEnum.SafetyInspection,
      label: t(ehsTypeString(EhsItemEhsTypeEnum.SafetyInspection))
    },
    {
      value: EhsItemEhsTypeEnum.Sha,
      label: t(ehsTypeString(EhsItemEhsTypeEnum.Sha))
    },
    {
      value: EhsItemEhsTypeEnum.Sja,
      label: t(ehsTypeString(EhsItemEhsTypeEnum.Sja))
    },
    {
      value: EhsItemEhsTypeEnum.Ku,
      label: t(ehsTypeString(EhsItemEhsTypeEnum.Ku))
    }
  ];

  const {
    page,
    setPage,
    query,
    setQuery,
    sortDirection,
    sortField,
    registerHeader,
    debouncedQuery
  } = useSort<EhsSortField>("Title", "ehsSort");

  const ehsQuery = useGetEhsItemsByResourceId({
    placeholderData: keepPreviousData,
    variables: {
      companyId,
      resourceId: projectId,
      query: debouncedQuery,
      sortField,
      sortDirection,
      page,
      type: selectedEhsType
    }
  });

  const ehsTemplateQuery = useGetEhsTemplatesByCompanyId({
    variables: { companyId }
  });

  const templates = ehsTemplateQuery?.data ?? [];
  const ehsItems = ehsQuery.data?.data ?? [];

  const createFromTemplate = async (templateId: string) => {
    await navigate({
      to: "/dashboard/ehs/$templateId/new",
      search: { resourceId: projectId, resourceType: "project" },
      params: { templateId }
    });
  };

  async function handleUpdate() {
    const items = await ehsQuery.refetch();
    const selected = items.data?.data.find(
      (item) => item.ehsItemId === selectedEhsItem?.ehsItemId
    );
    setSelectedEhsItem(selected);
  }

  return (
    <PageLayout>
      {selectedEhsItem && (
        <EhsPreviewDrawer
          open={isOpen}
          onClose={onClose}
          ehsItem={selectedEhsItem}
          onUpdated={handleUpdate}
          onDeleted={async () => {
            onClose();
            showSuccessToast(t("ehsItemDeleted"));
            await ehsQuery.refetch();
          }}
        />
      )}
      <CardNew
        inset
        title={t("ehs")}
        trailing={
          <DropdownMenu.Root>
            <DropdownMenu.Trigger>
              <ButtonNew
                size={"1"}
                variant={"soft"}
                color={"gray"}
                trailingIcon={"keyboard_arrow_down"}
              >
                {t("newFromTemplate")}
              </ButtonNew>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
              {templates.map((template) => (
                <DropdownMenu.Item
                  key={template.id}
                  onClick={() => createFromTemplate(template.id)}
                >
                  {template.title}
                </DropdownMenu.Item>
              ))}
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        }
        headerContent={
          <Flex align={"center"} gap={"2"}>
            <TextFieldNew
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              className={searchBarSize}
              size={"2"}
              placeholder={t("searchForName")}
              icon={"search"}
            />
            <NewSelect
              options={ehsTypeOptions}
              value={selectedEhsType}
              onChange={(value) => {
                if (value === "all") {
                  setSelectedEhsType(undefined);
                } else {
                  setSelectedEhsType(value);
                }
              }}
              placeholder={t("selectType")}
              size={"2"}
            />
          </Flex>
        }
        footerContent={
          <PaginationNew
            page={page}
            totalPages={ehsQuery.data?.totalPages}
            setPage={setPage}
          />
        }
      >
        <Table variant={"ghost"}>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>#</TableHeaderCell>
              <TableHeaderCell sortFn={registerHeader("Title")}>
                {t("name")}
              </TableHeaderCell>
              <TableHeaderCell>{t("type")}</TableHeaderCell>
              <TableHeaderCell>{t("status")}</TableHeaderCell>
              <TableHeaderCell>{t("lastUpdated")}</TableHeaderCell>
            </TableRow>
          </TableHeader>

          <TableBody>
            {ehsItems.length === 0 && (
              <TableRow>
                <TableCell>{t("noEhsItemsFound")}</TableCell>
              </TableRow>
            )}

            {ehsItems.map((ehsItem) => {
              return (
                <TableRow key={ehsItem.ehsItemId}>
                  <TableCell>
                    <ButtonNew
                      variant={"ghost"}
                      highContrast
                      className={"group-hover/tr:underline"}
                      color={"gray"}
                      onClick={() => {
                        setSelectedEhsItem(ehsItem);
                        onOpen();
                      }}
                    >
                      #{ehsItem.ehsNumber}
                    </ButtonNew>
                  </TableCell>
                  <TableCell>{ehsItem.title}</TableCell>
                  <TableCell>{t(ehsTypeString(ehsItem.ehsType))}</TableCell>
                  <TableCell>
                    <Badge color={determinEhsBadgeColor(ehsItem)}>
                      {determinEhsBadgeText(ehsItem)}
                    </Badge>
                  </TableCell>
                  <TableCell>
                    {formatFullNumericDateString(ehsItem.updatedAt)}{" "}
                    {formatTime(ehsItem.updatedAt)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardNew>
    </PageLayout>
  );
}
