import saveAs from "file-saver";
import {
  ChecklistItem,
  ChecklistV2,
  CmFileInfo,
  CustomerV3,
  Deviation,
  EhsItem,
  FileResponse,
  InspectionDetails,
  OfferSubsection,
  Order,
  ProductConsumption,
  ProductConsumptionOrder,
  ProjectV2
} from "../.generated/api";
import {
  useGetCompanyDetails,
  useGetCompanyLogo,
  useGetCompanyPublicInfo,
  useGetCompanyReportLogo
} from "../service/api/CompanyApi";
import { getFilesByResourceId } from "../service/api/FileApi";
import {
  ChecklistItems,
  CrewMembers,
  generateChecklistPdf,
  generateCrewListPdf,
  generateDeviationPdf,
  generateEhsItemPdf,
  generateFinalProjectReportPdf,
  generateInspectionPdf,
  generateOfferPdf,
  generateWorkDocumentationPdf
} from "../service/export/ExportApi";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import {
  useGetProjectById,
  useGetProjectReportLogo
} from "../service/api/ProjectApiV2";
import { useGetTaskById } from "../service/api/TaskApiV2";
import { orderQueries } from "../service/api/OrderApi";
import {
  useGetCustomerById,
  useGetCustomerByResourceId
} from "../service/api/CustomerApiV3";
import { useGetDeviationById } from "../service/api/DeviationApi";
import i18n from "../i18n";
import { useGetLocationByResourceId } from "../service/api/LocationApi";
import { useGetUsersOnResource } from "../service/api/UserApi";
import { mapOfferSubsections } from "./exportUtils";

export async function exportFinalReportPdf({
  resourceId,
  companyId,
  queryClient,
  resourceType,
  selectedFiles,
  selectedChecklists,
  selectedDeviations,
  selectedEhsItems,
  reportDescription,
  products,
  currency
}: {
  resourceId: string;
  companyId: string;
  reportDescription: string;
  queryClient: ReturnType<typeof useQueryClient>;
  resourceType: "project" | "order";
  currency: string;
  selectedChecklists: ChecklistV2[];
  selectedDeviations: Deviation[];
  selectedFiles: FileResponse[];
  selectedEhsItems: EhsItem[];
  products: ProductConsumptionOrder[] | ProductConsumption[];
}) {
  const { logo, company } = await fetchCompanyInformation(
    companyId,
    queryClient
  );
  const { reportLogo } = await fetchReportLogo(
    companyId,
    queryClient,
    resourceId
  );

  const { contactPhone, contactPerson, contactEmail } =
    await fetchContactPerson(resourceType, resourceId, companyId, queryClient);

  const customer = await fetchCustomerInfo(queryClient, companyId, resourceId);
  const resource = await fetchResourceInfo(
    resourceId,
    companyId,
    resourceType,
    queryClient
  );

  const formattedChecklists = await Promise.all(
    selectedChecklists.map(async (checklist) => {
      const items = await formatChecklistItems(checklist.items, queryClient);
      return {
        title: checklist.title,
        language: i18n.language,
        description: checklist.description,
        resourceType: mapChecklistResourceType(checklist.resourceType),
        items: items
      };
    })
  );

  const formattedEhsItems = await Promise.all(
    selectedEhsItems.map(async (ehsItem) => {
      const items = await formatChecklistItems(
        ehsItem.checklist?.items ?? [],
        queryClient
      );

      return {
        title: ehsItem.title,
        type: ehsItem.ehsType,
        language: i18n.language,
        currency: currency,
        description: ehsItem.description,
        resourceType: ehsItem.resourceType,
        deadline: ehsItem.deadline,
        signedBy: ehsItem.signaturesV2?.[0]?.user?.name,
        ehsChecklistItems: items
      };
    })
  );

  const formattedDeviations = await Promise.all(
    selectedDeviations.map(async (deviation) => {
      const files = await fetchResourceFiles(deviation.deviationId);

      return {
        title: deviation.title,
        number: deviation.deviationNumber.toString(),
        resourceType: deviation.resourceType,
        status: deviation.deviationStatus,
        type: deviation.deviationType,
        deadline: deviation.deadline,
        preventiveMeasures: deviation.preventiveMeasures,
        description: deviation.description,
        cost: deviation?.cost ? +deviation.cost : undefined,
        hours: deviation.hours,
        files: files,
        language: i18n.language,
        currency: currency,
        assignee: deviation.assignee?.name,
        priority: convertDeviationPriority(deviation.priority)
      };
    })
  );

  const formattedProducts = products?.map((product) => {
    return {
      productName: product.product.name,
      quantity: product.total_quantity,
      unit: product.product.unit.name,
      item_number: product.product.item_number
    };
  });

  const formattedFiles = selectedFiles.map((file) => ({
    name: file.name,
    fileType: file.fileType,
    fileUrl: file.fileUrl ?? ""
  }));

  const blob = await generateFinalProjectReportPdf({
    resource: resource,
    resourceType: resourceType,
    companyLogoUrl: logo.url,
    customer: customer,
    reportDescription: reportDescription,
    language: i18n.language,
    ehsItems: formattedEhsItems,
    files: formattedFiles,
    checklists: formattedChecklists,
    deviations: formattedDeviations,
    products: formattedProducts,
    frontPageImageUrl: reportLogo.url,
    company: {
      companyName: company.companyName,
      phone: contactPhone,
      email: contactEmail,
      orgNumber: company.orgNr,
      contactPersonName: contactPerson
    }
  });

  saveAs(blob, `Sluttrapport - (${resource.resourceName}).pdf`);
  return blob;
}

export async function exportDeviationPdf({
  deviation,
  companyId,
  queryClient,
  language,
  currency,
  saveFile = true
}: {
  deviation: Deviation;
  companyId: string;
  queryClient: ReturnType<typeof useQueryClient>;
  language: string;
  currency: string;
  saveFile?: boolean;
}) {
  const { logo, company } = await fetchCompanyInformation(
    companyId,
    queryClient
  );

  const { reportLogo } = await fetchReportLogo(
    companyId,
    queryClient,
    deviation.resourceId
  );

  const { contactPhone, contactPerson, contactEmail } =
    await fetchContactPerson(
      deviation.resourceType,
      deviation.resourceId,
      companyId,
      queryClient
    );

  const files = await fetchResourceFiles(deviation.deviationId);
  const resourceInfo = await fetchResourceInfo(
    deviation.resourceId,
    companyId,
    deviation.resourceType,
    queryClient
  );
  const customerInfo = await fetchCustomerInfo(
    queryClient,
    companyId,
    deviation.resourceId
  );

  const pdfBlob = await generateDeviationPdf({
    number: deviation.deviationNumber.toString(),
    title: deviation.title,
    cost: deviation.cost ? +deviation.cost : 0,
    deadline: deviation.deadline,
    preventiveMeasures: deviation.preventiveMeasures,
    description: deviation.description,
    hours: deviation.hours,
    status: deviation.deviationStatus,
    files: files,
    companyLogoUrl: logo?.url,
    language: language,
    currency: currency,
    type: deviation.deviationType,
    customer: customerInfo,
    resource: resourceInfo,
    resourceType: deviation.resourceType,
    priority: convertDeviationPriority(deviation.priority),
    frontPageImageUrl: reportLogo.url,
    assignee: deviation.assignee?.name,
    company: {
      companyName: company.companyName,
      phone: contactPhone,
      email: contactEmail,
      orgNumber: company.orgNr,
      contactPersonName: contactPerson
    }
  });

  if (saveFile) {
    saveAs(pdfBlob, `Avvik - (${deviation.title}).pdf`);
  }
  return pdfBlob;
}

export async function exportInspectionPdf({
  inspection,
  companyId,
  language,
  queryClient
}: {
  inspection: InspectionDetails;
  companyId: string;
  language: string;
  queryClient: ReturnType<typeof useQueryClient>;
}) {
  const { company, logo } = await fetchCompanyInformation(
    companyId,
    queryClient
  );
  const customerData = await queryClient.ensureQueryData({
    ...useGetCustomerById.getOptions({
      customerId: inspection.customer.id,
      companyId
    })
  });

  const blob = await generateInspectionPdf({
    title: inspection.name ?? "",
    //TODO: Check which field is correct
    message: inspection.message,
    language: language,
    companyLogoUrl: logo.url,
    status: inspection.status,
    companyInformation: company.companyName,
    customer: mapCustomerData(customerData),
    stepsInformation: inspection.steps.map((step) => ({
      name: step.name,
      description: step.description,
      type: step.type,
      response: step.response,
      stepNumber: step.stepNumber,
      files: step.files.map((file) => ({
        name: file.name,
        fileUrl: file.downloadUrl,
        fileType: file.fileType
      }))
    }))
  });

  saveAs(blob, `Befaring - (${inspection.name}).pdf`);
}

export async function exportCrewListPdf({
  crewMembers,
  fromDate,
  toDate,
  title,
  companyId,
  language,
  queryClient
}: {
  crewMembers: CrewMembers;
  fromDate?: Date;
  toDate?: Date;
  title: string;
  companyId: string;
  language: string;
  queryClient: QueryClient;
}) {
  const { logo } = await fetchCompanyInformation(companyId, queryClient);

  const blob = await generateCrewListPdf({
    projectTitle: title,
    language: language,
    companyLogoUrl: logo.url,
    fromDate: fromDate?.toISOString(),
    toDate: toDate?.toISOString(),
    crewMembers: crewMembers
  });

  saveAs(blob, `Mannskapsliste - (${title}).pdf`);
}

export async function exportOfferPdf({
  title,
  language,
  description,
  currency,
  subsections,
  customerName,
  customerEmail,
  customerPhone,
  customerAddress,
  companyId,
  attachments,
  queryClient
}: {
  title: string;
  language: string;
  description: string;
  currency: string;
  subsections: OfferSubsection[];
  customerName: string;
  customerPhone: string;
  customerEmail: string;
  customerAddress: string;
  companyId: string;
  attachments: CmFileInfo[];
  queryClient: QueryClient;
}) {
  const company = await fetchPublicCompanyInfo(companyId, queryClient);

  const blob = await generateOfferPdf({
    title: title,
    language: language,
    description: description,
    currency: currency,
    companyLogoUrl: company.companyLogoUrl,
    frontPageImageUrl: company.reportLogoUrl,
    customer: {
      email: customerEmail,
      phone: customerPhone,
      contactPersonName: customerName,
      address: customerAddress
    },
    subsections: mapOfferSubsections(subsections),
    company: {
      companyName: company.name,
      phone: company.contactPhone,
      email: company.contactEmail,
      orgNumber: company.orgNumber,
      contactPersonName: company.contactPerson
    },
    attachments: attachments.map((a) => ({
      fileUrl: a.downloadUrl,
      fileType: a.fileType,
      name: a.name
    }))
  });

  saveAs(blob, `Tilbud - (${title}).pdf`);
}

export type ExportResourceType = "project" | "order" | "task";

export async function exportChecklistPdf({
  checklist,
  companyId,
  resourceType,
  queryClient,
  language,
  saveFile = true,
  withTimeStamps = true
}: {
  checklist: ChecklistV2;
  companyId: string;
  resourceType: ExportResourceType;
  queryClient: ReturnType<typeof useQueryClient>;
  language: string;
  saveFile?: boolean;
  withTimeStamps?: boolean;
}) {
  const resourceId =
    checklist.resourceType === "Task"
      ? (checklist.parentId ?? "")
      : checklist.resourceId;
  const { logo, company } = await fetchCompanyInformation(
    companyId,
    queryClient
  );

  const customerInfo = await fetchCustomerInfo(
    queryClient,
    companyId,
    resourceId
  );

  const resourceInfo = await fetchResourceInfo(
    checklist.resourceType === "Task"
      ? (checklist.parentId ?? checklist.resourceId)
      : resourceId,
    companyId,
    resourceType,
    queryClient
  );

  const { contactPhone, contactPerson, contactEmail } =
    await fetchContactPerson(
      resourceType,
      checklist.resourceId,
      companyId,
      queryClient
    );

  const formattedItems = await formatChecklistItems(
    checklist.items,
    queryClient
  );

  const { reportLogo } = await fetchReportLogo(
    companyId,
    queryClient,
    checklist.resourceId
  );

  const blob = await generateChecklistPdf({
    resourceType: resourceType,
    companyLogoUrl: logo?.url,
    title: checklist.title,
    description: checklist.description,
    items: formattedItems,
    language: language,
    customer: customerInfo,
    frontPageImageUrl: reportLogo.url,
    resource: resourceInfo,
    company: {
      companyName: company.companyName,
      phone: contactPhone,
      email: contactEmail,
      orgNumber: company.orgNr,
      contactPersonName: contactPerson
    },
    withTimeStamps: withTimeStamps
  });

  if (saveFile) {
    saveAs(blob, `Sjekkliste - (${checklist.title}).pdf`);
  }
  return blob;
}

export async function exportWorkDocumentationPdf({
  title,
  description,
  images,
  project,
  order,
  companyId,
  language,
  resourceId,
  queryClient
}: {
  title: string;
  description: string;
  images?: string[];
  project?: ProjectV2;
  order?: Order;
  companyId: string;
  language: string;
  resourceId: string;
  queryClient: ReturnType<typeof useQueryClient>;
}) {
  try {
    const { company, logo } = await fetchCompanyInformation(
      companyId,
      queryClient
    );
    const customer = await fetchCustomerInfo(
      queryClient,
      companyId,
      resourceId
    );

    const blob = await generateWorkDocumentationPdf({
      title: title,
      description: description,
      images: images,
      language: language,
      companyLogoUrl: logo.url,
      companyName: company.companyName,
      customerName: customer?.companyName ?? customer.contactPersonName,
      projectNumber: project?.prefixedProjectNumber,
      orderNumber: order?.orderNumber?.toString(),
      projectTitle: project?.title,
      orderTitle: order?.title
    });

    return new File([blob], `Arbeidssokumentasjon - (${title}).pdf`);
  } catch (_) {}
}

export async function exportEhsItemPdf({
  ehsItem,
  companyId,
  queryClient,
  language,
  saveFile = true,
  withTimeStamps = true
}: {
  ehsItem: EhsItem;
  companyId: string;
  queryClient: ReturnType<typeof useQueryClient>;
  language: string;
  saveFile?: boolean;
  withTimeStamps?: boolean;
}) {
  const { logo } = await fetchCompanyInformation(companyId, queryClient);
  const resourceInfo = await fetchResourceInfo(
    ehsItem.resourceId,
    companyId,
    //TODO: Project ok?
    "project",
    queryClient
  );

  const customerInfo = await fetchCustomerInfo(
    queryClient,
    companyId,
    ehsItem.resourceId
  );

  const formattedItems = await formatChecklistItems(
    ehsItem.checklist?.items ?? [],
    queryClient
  );

  const { reportLogo } = await fetchReportLogo(
    companyId,
    queryClient,
    ehsItem.resourceId
  );

  const pdfBlob = await generateEhsItemPdf({
    title: ehsItem.title,
    type: ehsItem.ehsType,
    resource: resourceInfo,
    description: ehsItem.description,
    resourceType: "project",
    customer: customerInfo,
    language: language,
    companyLogoUrl: logo.url,
    deadline: ehsItem.deadline,
    signedBy: ehsItem.signaturesV2?.[0]?.user?.name,
    ehsChecklistItems: formattedItems,
    frontPageImageUrl: reportLogo.url,
    withTimeStamps: withTimeStamps
  });

  if (saveFile) {
    saveAs(pdfBlob, `HMS - (${ehsItem.title}).pdf`);
  }
  return pdfBlob;
}

async function fetchCompanyInformation(
  companyId: string,
  queryClient: QueryClient
) {
  const logo = await queryClient.ensureQueryData(
    useGetCompanyLogo.getOptions({ companyId })
  );
  const company = await queryClient.ensureQueryData(
    useGetCompanyDetails.getOptions({ companyId })
  );
  return { logo, company /*frontPageImage*/ };
}

export async function fetchPublicCompanyInfo(
  companyId: string,
  queryClient: QueryClient
) {
  return await queryClient.ensureQueryData(
    useGetCompanyPublicInfo.getOptions({ companyId })
  );
}

async function fetchReportLogo(
  companyId: string,
  queryClient: QueryClient,
  resourceId?: string
) {
  let reportLogo;
  if (resourceId) {
    reportLogo = await queryClient.ensureQueryData(
      useGetProjectReportLogo.getOptions({
        companyId: companyId,
        projectId: resourceId
      })
    );
  }

  if (!resourceId || !reportLogo?.url) {
    reportLogo = await queryClient.ensureQueryData(
      useGetCompanyReportLogo.getOptions({ companyId: companyId })
    );
  }

  return { reportLogo };
}

async function fetchResourceFiles(resourceId: string) {
  const files = await getFilesByResourceId(resourceId);

  return files.map((file) => ({
    name: file.name,
    fileType: file.fileType,
    fileUrl: file.fileUrl ?? ""
  }));
}

async function fetchContactPerson(
  resourceType: "project" | "order" | "task",
  resourceId: string,
  companyId: string,
  queryClient: QueryClient
) {
  if (resourceType === "project") {
    const users = await queryClient.ensureQueryData(
      useGetUsersOnResource.getOptions({ resourceId, resourceType })
    );
    const projectAdmin = users.find((u) => u.role?.name === "Prosjektleder");
    if (projectAdmin)
      return {
        contactPerson: projectAdmin.user.name,
        contactEmail: projectAdmin.user.email,
        contactPhone: projectAdmin.user.phoneNumber
      };
  }

  const company = await queryClient.ensureQueryData(
    useGetCompanyDetails.getOptions({ companyId })
  );
  return {
    contactPerson: company.contactPerson,
    contactEmail: company.contactEmail,
    contactPhone: company.contactPhone
  };
}

async function fetchResourceInfo(
  resourceId: string,
  companyId: string,
  resourceType: "order" | "task" | "project",
  queryClient: ReturnType<typeof useQueryClient>
) {
  if (resourceType === "project") {
    const project = await queryClient.ensureQueryData({
      ...useGetProjectById.getOptions({ projectId: resourceId })
    });

    const location = await queryClient.ensureQueryData({
      ...useGetLocationByResourceId.getOptions({ resourceId }),
      staleTime: 0
    });

    return {
      resourceName: project.title,
      resourceNumber: project.prefixedProjectNumber,
      dateFrom: project.from,
      dateTo: project.to,
      address: location.formattedAddress
    };
  } else if (resourceType === "order") {
    const order = await queryClient.ensureQueryData(
      orderQueries.getById(companyId, resourceId)
    );

    return {
      resourceName: order.title,
      resourceNumber: order.orderNumber.toString(),
      dateFrom: order.date,
      dateTo: order.date,
      address: order.address
    };
  } else {
    const task = await queryClient.ensureQueryData(
      useGetTaskById.getOptions({ taskId: resourceId })
    );

    return {
      resourceName: task.title,
      //resourceNumber: task.orderNumber,
      dateFrom: task.from,
      dateTo: task.to
      //address: order.address
    };
  }
}

async function fetchCustomerInfo(
  queryClient: ReturnType<typeof useQueryClient>,
  companyId: string,
  resourceId: string
) {
  const customer = await queryClient.ensureQueryData(
    useGetCustomerByResourceId.getOptions({ companyId, resourceId })
  );

  return mapCustomerData(customer!);
}

function mapCustomerData(customer: CustomerV3) {
  return {
    companyName: customer?.companyName,
    phone: customer?.primaryContact?.mobilePhone,
    email: customer?.primaryContact?.email,
    address: customer?.formattedAddress,
    contactPersonName:
      `${customer?.primaryContact?.firstName ?? ""} ${customer?.primaryContact?.lastName ?? ""}`.trim() ||
      "-"
  };
}

export async function formatChecklistItems(
  items: ChecklistItem[],
  queryClient: ReturnType<typeof useQueryClient>
): Promise<ChecklistItems> {
  return await Promise.all(
    items.map(async (item) => {
      const files = await fetchResourceFiles(item.id);

      let deviationData;
      if (item.deviationId) {
        const deviation = await queryClient.ensureQueryData({
          ...useGetDeviationById.getOptions({ deviationId: item.deviationId })
        });

        deviationData = {
          title: deviation.title,
          number: deviation.deviationNumber.toString()
        };
      }

      //TODO: Add handling of multiple type in PDF
      const type = item.type === "multiple" ? "select" : item.type;

      return {
        title: item.title,
        type: type,
        files: files ?? [],
        description: item.description,
        index: item.index,
        value: item.value,
        comment: item.comment,
        completedAt: item.completedAt,
        completedBy: item.completedBy,
        deviation: deviationData
      };
    })
  );
}

function mapChecklistResourceType(
  resourceType: "Order" | "EhsInspection" | "Project" | "Task" | "Equipment"
): "order" | "task" | "project" {
  switch (resourceType) {
    case "Order":
      return "order";
    case "Task":
      return "task";
    case "Equipment":
    case "Project":
    case "EhsInspection":
      return "project";
  }
}

function convertDeviationPriority(
  priority?: "Low" | "Medium" | "High"
): "low" | "medium" | "high" | undefined {
  switch (priority) {
    case "Low":
      return "low";
    case "Medium":
      return "medium";
    case "High":
      return "high";
    default:
      return undefined;
  }
}
