/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CompanyProjectPhaseEntity } from '../model';
// @ts-ignore
import { CompanyProjectPhasePostAllRequest } from '../model';
// @ts-ignore
import { CompanyProjectPhasePostRequest } from '../model';
// @ts-ignore
import { CompanyProjectPhasePutRequest } from '../model';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { ProjectPhaseEntity } from '../model';
// @ts-ignore
import { ProjectPhasePutAllRequest } from '../model';
/**
 * PhaseApi - axios parameter creator
 * @export
 */
export const PhaseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a company project phases
         * @param {string} companyId 
         * @param {string} phaseId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyProjectPhase: async (companyId: string, phaseId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteCompanyProjectPhase', 'companyId', companyId)
            // verify required parameter 'phaseId' is not null or undefined
            assertParamExists('deleteCompanyProjectPhase', 'phaseId', phaseId)
            const localVarPath = `/v1/companyProjectPhase/{companyId}/phase/{phaseId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"phaseId"}}`, encodeURIComponent(String(phaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company project phases
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyProjectPhases: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyProjectPhases', 'companyId', companyId)
            const localVarPath = `/v1/companyProjectPhase/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get project phases
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectPhases: async (projectId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectPhases', 'projectId', projectId)
            const localVarPath = `/v1/projectPhase/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new company project phase
         * @param {CompanyProjectPhasePostRequest} companyProjectPhasePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyProjectPhase: async (companyProjectPhasePostRequest: CompanyProjectPhasePostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyProjectPhasePostRequest' is not null or undefined
            assertParamExists('postCompanyProjectPhase', 'companyProjectPhasePostRequest', companyProjectPhasePostRequest)
            const localVarPath = `/v1/companyProjectPhase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyProjectPhasePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update all company project phases
         * @param {string} companyId 
         * @param {CompanyProjectPhasePostAllRequest} companyProjectPhasePostAllRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllCompanyProjectPhases: async (companyId: string, companyProjectPhasePostAllRequest: CompanyProjectPhasePostAllRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateAllCompanyProjectPhases', 'companyId', companyId)
            // verify required parameter 'companyProjectPhasePostAllRequest' is not null or undefined
            assertParamExists('updateAllCompanyProjectPhases', 'companyProjectPhasePostAllRequest', companyProjectPhasePostAllRequest)
            const localVarPath = `/v1/companyProjectPhase/updateAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyProjectPhasePostAllRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update all project phases
         * @param {string} projectId 
         * @param {ProjectPhasePutAllRequest} projectPhasePutAllRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllProjectPhases: async (projectId: string, projectPhasePutAllRequest: ProjectPhasePutAllRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateAllProjectPhases', 'projectId', projectId)
            // verify required parameter 'projectPhasePutAllRequest' is not null or undefined
            assertParamExists('updateAllProjectPhases', 'projectPhasePutAllRequest', projectPhasePutAllRequest)
            const localVarPath = `/v1/projectPhase/all/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectPhasePutAllRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a company project phase
         * @param {CompanyProjectPhasePutRequest} companyProjectPhasePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyProjectPhase: async (companyProjectPhasePutRequest: CompanyProjectPhasePutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyProjectPhasePutRequest' is not null or undefined
            assertParamExists('updateCompanyProjectPhase', 'companyProjectPhasePutRequest', companyProjectPhasePutRequest)
            const localVarPath = `/v1/companyProjectPhase/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyProjectPhasePutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update current project phase
         * @param {string} projectId 
         * @param {string} phaseId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentProjectPhase: async (projectId: string, phaseId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateCurrentProjectPhase', 'projectId', projectId)
            // verify required parameter 'phaseId' is not null or undefined
            assertParamExists('updateCurrentProjectPhase', 'phaseId', phaseId)
            const localVarPath = `/v1/projectPhase/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (phaseId !== undefined) {
                localVarQueryParameter['phaseId'] = phaseId;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PhaseApi - functional programming interface
 * @export
 */
export const PhaseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PhaseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a company project phases
         * @param {string} companyId 
         * @param {string} phaseId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompanyProjectPhase(companyId: string, phaseId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompanyProjectPhase(companyId, phaseId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company project phases
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyProjectPhases(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyProjectPhaseEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyProjectPhases(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get project phases
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectPhases(projectId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectPhaseEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectPhases(projectId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new company project phase
         * @param {CompanyProjectPhasePostRequest} companyProjectPhasePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompanyProjectPhase(companyProjectPhasePostRequest: CompanyProjectPhasePostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompanyProjectPhase(companyProjectPhasePostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update all company project phases
         * @param {string} companyId 
         * @param {CompanyProjectPhasePostAllRequest} companyProjectPhasePostAllRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAllCompanyProjectPhases(companyId: string, companyProjectPhasePostAllRequest: CompanyProjectPhasePostAllRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAllCompanyProjectPhases(companyId, companyProjectPhasePostAllRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update all project phases
         * @param {string} projectId 
         * @param {ProjectPhasePutAllRequest} projectPhasePutAllRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAllProjectPhases(projectId: string, projectPhasePutAllRequest: ProjectPhasePutAllRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAllProjectPhases(projectId, projectPhasePutAllRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a company project phase
         * @param {CompanyProjectPhasePutRequest} companyProjectPhasePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyProjectPhase(companyProjectPhasePutRequest: CompanyProjectPhasePutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyProjectPhase(companyProjectPhasePutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update current project phase
         * @param {string} projectId 
         * @param {string} phaseId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCurrentProjectPhase(projectId: string, phaseId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCurrentProjectPhase(projectId, phaseId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PhaseApi - factory interface
 * @export
 */
export const PhaseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PhaseApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a company project phases
         * @param {string} companyId 
         * @param {string} phaseId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyProjectPhase(companyId: string, phaseId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteCompanyProjectPhase(companyId, phaseId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company project phases
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyProjectPhases(companyId: string, authorization?: string, options?: any): AxiosPromise<Array<CompanyProjectPhaseEntity>> {
            return localVarFp.getCompanyProjectPhases(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get project phases
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectPhases(projectId: string, authorization?: string, options?: any): AxiosPromise<Array<ProjectPhaseEntity>> {
            return localVarFp.getProjectPhases(projectId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new company project phase
         * @param {CompanyProjectPhasePostRequest} companyProjectPhasePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyProjectPhase(companyProjectPhasePostRequest: CompanyProjectPhasePostRequest, authorization?: string, options?: any): AxiosPromise<string> {
            return localVarFp.postCompanyProjectPhase(companyProjectPhasePostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update all company project phases
         * @param {string} companyId 
         * @param {CompanyProjectPhasePostAllRequest} companyProjectPhasePostAllRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllCompanyProjectPhases(companyId: string, companyProjectPhasePostAllRequest: CompanyProjectPhasePostAllRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.updateAllCompanyProjectPhases(companyId, companyProjectPhasePostAllRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update all project phases
         * @param {string} projectId 
         * @param {ProjectPhasePutAllRequest} projectPhasePutAllRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllProjectPhases(projectId: string, projectPhasePutAllRequest: ProjectPhasePutAllRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.updateAllProjectPhases(projectId, projectPhasePutAllRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a company project phase
         * @param {CompanyProjectPhasePutRequest} companyProjectPhasePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyProjectPhase(companyProjectPhasePutRequest: CompanyProjectPhasePutRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.updateCompanyProjectPhase(companyProjectPhasePutRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update current project phase
         * @param {string} projectId 
         * @param {string} phaseId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentProjectPhase(projectId: string, phaseId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.updateCurrentProjectPhase(projectId, phaseId, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PhaseApi - object-oriented interface
 * @export
 * @class PhaseApi
 * @extends {BaseAPI}
 */
export class PhaseApi extends BaseAPI {
    /**
     * 
     * @summary Delete a company project phases
     * @param {string} companyId 
     * @param {string} phaseId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhaseApi
     */
    public deleteCompanyProjectPhase(companyId: string, phaseId: string, authorization?: string, options?: AxiosRequestConfig) {
        return PhaseApiFp(this.configuration).deleteCompanyProjectPhase(companyId, phaseId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company project phases
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhaseApi
     */
    public getCompanyProjectPhases(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return PhaseApiFp(this.configuration).getCompanyProjectPhases(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get project phases
     * @param {string} projectId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhaseApi
     */
    public getProjectPhases(projectId: string, authorization?: string, options?: AxiosRequestConfig) {
        return PhaseApiFp(this.configuration).getProjectPhases(projectId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new company project phase
     * @param {CompanyProjectPhasePostRequest} companyProjectPhasePostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhaseApi
     */
    public postCompanyProjectPhase(companyProjectPhasePostRequest: CompanyProjectPhasePostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return PhaseApiFp(this.configuration).postCompanyProjectPhase(companyProjectPhasePostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update all company project phases
     * @param {string} companyId 
     * @param {CompanyProjectPhasePostAllRequest} companyProjectPhasePostAllRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhaseApi
     */
    public updateAllCompanyProjectPhases(companyId: string, companyProjectPhasePostAllRequest: CompanyProjectPhasePostAllRequest, authorization?: string, options?: AxiosRequestConfig) {
        return PhaseApiFp(this.configuration).updateAllCompanyProjectPhases(companyId, companyProjectPhasePostAllRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update all project phases
     * @param {string} projectId 
     * @param {ProjectPhasePutAllRequest} projectPhasePutAllRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhaseApi
     */
    public updateAllProjectPhases(projectId: string, projectPhasePutAllRequest: ProjectPhasePutAllRequest, authorization?: string, options?: AxiosRequestConfig) {
        return PhaseApiFp(this.configuration).updateAllProjectPhases(projectId, projectPhasePutAllRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a company project phase
     * @param {CompanyProjectPhasePutRequest} companyProjectPhasePutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhaseApi
     */
    public updateCompanyProjectPhase(companyProjectPhasePutRequest: CompanyProjectPhasePutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return PhaseApiFp(this.configuration).updateCompanyProjectPhase(companyProjectPhasePutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update current project phase
     * @param {string} projectId 
     * @param {string} phaseId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhaseApi
     */
    public updateCurrentProjectPhase(projectId: string, phaseId: string, authorization?: string, options?: AxiosRequestConfig) {
        return PhaseApiFp(this.configuration).updateCurrentProjectPhase(projectId, phaseId, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
