/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { PaginatedResponseTimeEntry } from '../model';
// @ts-ignore
import { Summary } from '../model';
// @ts-ignore
import { TimeApprovalRequest } from '../model';
// @ts-ignore
import { TimeEntry } from '../model';
// @ts-ignore
import { TimeEntryPostRequest } from '../model';
// @ts-ignore
import { TimeEntryPutRequest } from '../model';
// @ts-ignore
import { TimeEntryTaskListGetRequest } from '../model';
// @ts-ignore
import { TimeInvoiceRequest } from '../model';
/**
 * TimeEntriesApi - axios parameter creator
 * @export
 */
export const TimeEntriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Automatically approve hours
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoApproveHours: async (authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/timeentry/auto-approve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete time entry on user
         * @param {string} id 
         * @param {string} userId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTimeEntryOnUser: async (id: string, userId: string, companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTimeEntryOnUser', 'id', id)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteTimeEntryOnUser', 'userId', userId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteTimeEntryOnUser', 'companyId', companyId)
            const localVarPath = `/v2/company/{companyId}/timeentry/{id}/user/{userId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete time entry for user
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserTimeEntry: async (id: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserTimeEntry', 'id', id)
            const localVarPath = `/v2/timeentry/{id}/user`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get time entries for company
         * @param {string} companyId 
         * @param {'All' | 'Project' | 'Company' | 'Order'} type 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {'Submitted' | 'Approved' | 'Quarantined' | 'Invoiced'} [status] 
         * @param {boolean} [needApproval] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyTimeEntries: async (companyId: string, type: 'All' | 'Project' | 'Company' | 'Order', dateFrom?: string, dateTo?: string, status?: 'Submitted' | 'Approved' | 'Quarantined' | 'Invoiced', needApproval?: boolean, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyTimeEntries', 'companyId', companyId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getCompanyTimeEntries', 'type', type)
            const localVarPath = `/v2/company/{companyId}/timeEntry`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (needApproval !== undefined) {
                localVarQueryParameter['needApproval'] = needApproval;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get time entries for approval on company
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'userId' | 'date'} [sortField] 
         * @param {string} [query] 
         * @param {number} [pageSize] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyTimeEntriesForApproval: async (companyId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'userId' | 'date', query?: string, pageSize?: number, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyTimeEntriesForApproval', 'companyId', companyId)
            const localVarPath = `/v2/company/{companyId}/timeEntry/unapproved`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get paginated time entries for company
         * @param {string} companyId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {'approved' | 'notApproved' | 'invoiced' | 'notInvoiced' | 'transferred' | 'notTransferred'} [approved] 
         * @param {Array<string>} [userIds] 
         * @param {string} [resourceId] 
         * @param {boolean} [isProject] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'userId' | 'date' | 'isSynced' | 'isInvoiced' | 'activity' | 'resource'} [sortField] 
         * @param {string} [query] 
         * @param {number} [pageSize] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyTimeEntriesPaginated: async (companyId: string, from?: string, to?: string, approved?: 'approved' | 'notApproved' | 'invoiced' | 'notInvoiced' | 'transferred' | 'notTransferred', userIds?: Array<string>, resourceId?: string, isProject?: boolean, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'userId' | 'date' | 'isSynced' | 'isInvoiced' | 'activity' | 'resource', query?: string, pageSize?: number, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyTimeEntriesPaginated', 'companyId', companyId)
            const localVarPath = `/v2/company/{companyId}/time-entry`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (approved !== undefined) {
                localVarQueryParameter['approved'] = approved;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }

            if (resourceId !== undefined) {
                localVarQueryParameter['resourceId'] = resourceId;
            }

            if (isProject !== undefined) {
                localVarQueryParameter['isProject'] = isProject;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get time entries by resource ID
         * @param {string} companyId 
         * @param {string} resourceId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {'Submitted' | 'Approved' | 'Quarantined' | 'Invoiced'} [status] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeEntriesByResourceId: async (companyId: string, resourceId: string, dateFrom?: string, dateTo?: string, status?: 'Submitted' | 'Approved' | 'Quarantined' | 'Invoiced', authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTimeEntriesByResourceId', 'companyId', companyId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getTimeEntriesByResourceId', 'resourceId', resourceId)
            const localVarPath = `/v2/company/{companyId}/resource/{resourceId}/timeEntry`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {TimeEntryTaskListGetRequest} timeEntryTaskListGetRequest 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeEntriesByTaskIds: async (companyId: string, timeEntryTaskListGetRequest: TimeEntryTaskListGetRequest, dateFrom?: string, dateTo?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTimeEntriesByTaskIds', 'companyId', companyId)
            // verify required parameter 'timeEntryTaskListGetRequest' is not null or undefined
            assertParamExists('getTimeEntriesByTaskIds', 'timeEntryTaskListGetRequest', timeEntryTaskListGetRequest)
            const localVarPath = `/v2/company/{companyId}/timeEntry/tasks`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timeEntryTaskListGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get time entries for user
         * @param {string} companyId 
         * @param {string} userId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeEntriesOnUserV2: async (companyId: string, userId: string, dateFrom: string, dateTo: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTimeEntriesOnUserV2', 'companyId', companyId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getTimeEntriesOnUserV2', 'userId', userId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getTimeEntriesOnUserV2', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('getTimeEntriesOnUserV2', 'dateTo', dateTo)
            const localVarPath = `/v2/v2/company/{companyId}/timeEntry/user/{userId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get total time submitted for project
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalTimeSubmitted: async (projectId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTotalTimeSubmitted', 'projectId', projectId)
            const localVarPath = `/v2/project/{projectId}/timeentry/total`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get time entries for logged in user
         * @param {string} companyId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserTimeEntries: async (companyId: string, dateFrom: string, dateTo: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getUserTimeEntries', 'companyId', companyId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getUserTimeEntries', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('getUserTimeEntries', 'dateTo', dateTo)
            const localVarPath = `/v2/company/{companyId}/timeEntry/user`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new time entry on user
         * @param {string} companyId 
         * @param {string} userId 
         * @param {TimeEntryPostRequest} timeEntryPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTimeEntryOnUser: async (companyId: string, userId: string, timeEntryPostRequest: TimeEntryPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postTimeEntryOnUser', 'companyId', companyId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('postTimeEntryOnUser', 'userId', userId)
            // verify required parameter 'timeEntryPostRequest' is not null or undefined
            assertParamExists('postTimeEntryOnUser', 'timeEntryPostRequest', timeEntryPostRequest)
            const localVarPath = `/v2/company/{companyId}/timeentry/user/{userId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timeEntryPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new time entry for user
         * @param {string} companyId 
         * @param {TimeEntryPostRequest} timeEntryPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserTimeEntry: async (companyId: string, timeEntryPostRequest: TimeEntryPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postUserTimeEntry', 'companyId', companyId)
            // verify required parameter 'timeEntryPostRequest' is not null or undefined
            assertParamExists('postUserTimeEntry', 'timeEntryPostRequest', timeEntryPostRequest)
            const localVarPath = `/v2/company/{companyId}/timeentry/user`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timeEntryPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new time entry for user on resource (task or order)
         * @param {string} companyId 
         * @param {TimeEntryPostRequest} timeEntryPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserTimeEntryOnResource: async (companyId: string, timeEntryPostRequest: TimeEntryPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postUserTimeEntryOnResource', 'companyId', companyId)
            // verify required parameter 'timeEntryPostRequest' is not null or undefined
            assertParamExists('postUserTimeEntryOnResource', 'timeEntryPostRequest', timeEntryPostRequest)
            const localVarPath = `/v2/company/{companyId}/resource/timeentry`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timeEntryPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync hours
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncHours: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('syncHours', 'companyId', companyId)
            const localVarPath = `/v2/company/{companyId}/sync`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update time entry
         * @param {string} companyId 
         * @param {string} id 
         * @param {TimeEntryPutRequest} timeEntryPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeEntry: async (companyId: string, id: string, timeEntryPutRequest: TimeEntryPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateTimeEntry', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTimeEntry', 'id', id)
            // verify required parameter 'timeEntryPutRequest' is not null or undefined
            assertParamExists('updateTimeEntry', 'timeEntryPutRequest', timeEntryPutRequest)
            const localVarPath = `/v2/company/{companyId}/timeentry/{id}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timeEntryPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set time entry approvals
         * @param {string} companyId 
         * @param {TimeApprovalRequest} timeApprovalRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeEntryApproval: async (companyId: string, timeApprovalRequest: TimeApprovalRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateTimeEntryApproval', 'companyId', companyId)
            // verify required parameter 'timeApprovalRequest' is not null or undefined
            assertParamExists('updateTimeEntryApproval', 'timeApprovalRequest', timeApprovalRequest)
            const localVarPath = `/v2/company/{companyId}/timeentry/approval`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timeApprovalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark time entries as invoiced
         * @param {string} companyId 
         * @param {TimeInvoiceRequest} timeInvoiceRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeEntryInvoiced: async (companyId: string, timeInvoiceRequest: TimeInvoiceRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateTimeEntryInvoiced', 'companyId', companyId)
            // verify required parameter 'timeInvoiceRequest' is not null or undefined
            assertParamExists('updateTimeEntryInvoiced', 'timeInvoiceRequest', timeInvoiceRequest)
            const localVarPath = `/v2/company/{companyId}/timeentry/invoice`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timeInvoiceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update time entry on user
         * @param {string} companyId 
         * @param {string} id 
         * @param {string} userId 
         * @param {TimeEntryPutRequest} timeEntryPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeEntryOnUser: async (companyId: string, id: string, userId: string, timeEntryPutRequest: TimeEntryPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateTimeEntryOnUser', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTimeEntryOnUser', 'id', id)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateTimeEntryOnUser', 'userId', userId)
            // verify required parameter 'timeEntryPutRequest' is not null or undefined
            assertParamExists('updateTimeEntryOnUser', 'timeEntryPutRequest', timeEntryPutRequest)
            const localVarPath = `/v2/company/{companyId}/timeentry/{id}/user/{userId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timeEntryPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update time entry for user
         * @param {string} companyId 
         * @param {string} id 
         * @param {TimeEntryPutRequest} timeEntryPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserTimeEntry: async (companyId: string, id: string, timeEntryPutRequest: TimeEntryPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateUserTimeEntry', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserTimeEntry', 'id', id)
            // verify required parameter 'timeEntryPutRequest' is not null or undefined
            assertParamExists('updateUserTimeEntry', 'timeEntryPutRequest', timeEntryPutRequest)
            const localVarPath = `/v2/company/{companyId}/timeentry/{id}/user`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timeEntryPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimeEntriesApi - functional programming interface
 * @export
 */
export const TimeEntriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TimeEntriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Automatically approve hours
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoApproveHours(authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoApproveHours(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete time entry on user
         * @param {string} id 
         * @param {string} userId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTimeEntryOnUser(id: string, userId: string, companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTimeEntryOnUser(id, userId, companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete time entry for user
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserTimeEntry(id: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserTimeEntry(id, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get time entries for company
         * @param {string} companyId 
         * @param {'All' | 'Project' | 'Company' | 'Order'} type 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {'Submitted' | 'Approved' | 'Quarantined' | 'Invoiced'} [status] 
         * @param {boolean} [needApproval] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyTimeEntries(companyId: string, type: 'All' | 'Project' | 'Company' | 'Order', dateFrom?: string, dateTo?: string, status?: 'Submitted' | 'Approved' | 'Quarantined' | 'Invoiced', needApproval?: boolean, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyTimeEntries(companyId, type, dateFrom, dateTo, status, needApproval, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get time entries for approval on company
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'userId' | 'date'} [sortField] 
         * @param {string} [query] 
         * @param {number} [pageSize] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyTimeEntriesForApproval(companyId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'userId' | 'date', query?: string, pageSize?: number, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseTimeEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyTimeEntriesForApproval(companyId, page, sortDirection, sortField, query, pageSize, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get paginated time entries for company
         * @param {string} companyId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {'approved' | 'notApproved' | 'invoiced' | 'notInvoiced' | 'transferred' | 'notTransferred'} [approved] 
         * @param {Array<string>} [userIds] 
         * @param {string} [resourceId] 
         * @param {boolean} [isProject] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'userId' | 'date' | 'isSynced' | 'isInvoiced' | 'activity' | 'resource'} [sortField] 
         * @param {string} [query] 
         * @param {number} [pageSize] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyTimeEntriesPaginated(companyId: string, from?: string, to?: string, approved?: 'approved' | 'notApproved' | 'invoiced' | 'notInvoiced' | 'transferred' | 'notTransferred', userIds?: Array<string>, resourceId?: string, isProject?: boolean, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'userId' | 'date' | 'isSynced' | 'isInvoiced' | 'activity' | 'resource', query?: string, pageSize?: number, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseTimeEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyTimeEntriesPaginated(companyId, from, to, approved, userIds, resourceId, isProject, page, sortDirection, sortField, query, pageSize, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get time entries by resource ID
         * @param {string} companyId 
         * @param {string} resourceId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {'Submitted' | 'Approved' | 'Quarantined' | 'Invoiced'} [status] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimeEntriesByResourceId(companyId: string, resourceId: string, dateFrom?: string, dateTo?: string, status?: 'Submitted' | 'Approved' | 'Quarantined' | 'Invoiced', authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimeEntriesByResourceId(companyId, resourceId, dateFrom, dateTo, status, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {TimeEntryTaskListGetRequest} timeEntryTaskListGetRequest 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimeEntriesByTaskIds(companyId: string, timeEntryTaskListGetRequest: TimeEntryTaskListGetRequest, dateFrom?: string, dateTo?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimeEntriesByTaskIds(companyId, timeEntryTaskListGetRequest, dateFrom, dateTo, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get time entries for user
         * @param {string} companyId 
         * @param {string} userId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimeEntriesOnUserV2(companyId: string, userId: string, dateFrom: string, dateTo: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimeEntriesOnUserV2(companyId, userId, dateFrom, dateTo, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get total time submitted for project
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalTimeSubmitted(projectId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Summary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalTimeSubmitted(projectId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get time entries for logged in user
         * @param {string} companyId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getUserTimeEntries(companyId: string, dateFrom: string, dateTo: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserTimeEntries(companyId, dateFrom, dateTo, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new time entry on user
         * @param {string} companyId 
         * @param {string} userId 
         * @param {TimeEntryPostRequest} timeEntryPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTimeEntryOnUser(companyId: string, userId: string, timeEntryPostRequest: TimeEntryPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTimeEntryOnUser(companyId, userId, timeEntryPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new time entry for user
         * @param {string} companyId 
         * @param {TimeEntryPostRequest} timeEntryPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserTimeEntry(companyId: string, timeEntryPostRequest: TimeEntryPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserTimeEntry(companyId, timeEntryPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new time entry for user on resource (task or order)
         * @param {string} companyId 
         * @param {TimeEntryPostRequest} timeEntryPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserTimeEntryOnResource(companyId: string, timeEntryPostRequest: TimeEntryPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserTimeEntryOnResource(companyId, timeEntryPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sync hours
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncHours(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncHours(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update time entry
         * @param {string} companyId 
         * @param {string} id 
         * @param {TimeEntryPutRequest} timeEntryPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTimeEntry(companyId: string, id: string, timeEntryPutRequest: TimeEntryPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTimeEntry(companyId, id, timeEntryPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set time entry approvals
         * @param {string} companyId 
         * @param {TimeApprovalRequest} timeApprovalRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTimeEntryApproval(companyId: string, timeApprovalRequest: TimeApprovalRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTimeEntryApproval(companyId, timeApprovalRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark time entries as invoiced
         * @param {string} companyId 
         * @param {TimeInvoiceRequest} timeInvoiceRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTimeEntryInvoiced(companyId: string, timeInvoiceRequest: TimeInvoiceRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTimeEntryInvoiced(companyId, timeInvoiceRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update time entry on user
         * @param {string} companyId 
         * @param {string} id 
         * @param {string} userId 
         * @param {TimeEntryPutRequest} timeEntryPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTimeEntryOnUser(companyId: string, id: string, userId: string, timeEntryPutRequest: TimeEntryPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTimeEntryOnUser(companyId, id, userId, timeEntryPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update time entry for user
         * @param {string} companyId 
         * @param {string} id 
         * @param {TimeEntryPutRequest} timeEntryPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserTimeEntry(companyId: string, id: string, timeEntryPutRequest: TimeEntryPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserTimeEntry(companyId, id, timeEntryPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TimeEntriesApi - factory interface
 * @export
 */
export const TimeEntriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TimeEntriesApiFp(configuration)
    return {
        /**
         * 
         * @summary Automatically approve hours
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoApproveHours(authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.autoApproveHours(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete time entry on user
         * @param {string} id 
         * @param {string} userId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTimeEntryOnUser(id: string, userId: string, companyId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteTimeEntryOnUser(id, userId, companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete time entry for user
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserTimeEntry(id: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteUserTimeEntry(id, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get time entries for company
         * @param {string} companyId 
         * @param {'All' | 'Project' | 'Company' | 'Order'} type 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {'Submitted' | 'Approved' | 'Quarantined' | 'Invoiced'} [status] 
         * @param {boolean} [needApproval] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyTimeEntries(companyId: string, type: 'All' | 'Project' | 'Company' | 'Order', dateFrom?: string, dateTo?: string, status?: 'Submitted' | 'Approved' | 'Quarantined' | 'Invoiced', needApproval?: boolean, authorization?: string, options?: any): AxiosPromise<Array<TimeEntry>> {
            return localVarFp.getCompanyTimeEntries(companyId, type, dateFrom, dateTo, status, needApproval, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get time entries for approval on company
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'userId' | 'date'} [sortField] 
         * @param {string} [query] 
         * @param {number} [pageSize] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyTimeEntriesForApproval(companyId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'userId' | 'date', query?: string, pageSize?: number, authorization?: string, options?: any): AxiosPromise<PaginatedResponseTimeEntry> {
            return localVarFp.getCompanyTimeEntriesForApproval(companyId, page, sortDirection, sortField, query, pageSize, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get paginated time entries for company
         * @param {string} companyId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {'approved' | 'notApproved' | 'invoiced' | 'notInvoiced' | 'transferred' | 'notTransferred'} [approved] 
         * @param {Array<string>} [userIds] 
         * @param {string} [resourceId] 
         * @param {boolean} [isProject] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'userId' | 'date' | 'isSynced' | 'isInvoiced' | 'activity' | 'resource'} [sortField] 
         * @param {string} [query] 
         * @param {number} [pageSize] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyTimeEntriesPaginated(companyId: string, from?: string, to?: string, approved?: 'approved' | 'notApproved' | 'invoiced' | 'notInvoiced' | 'transferred' | 'notTransferred', userIds?: Array<string>, resourceId?: string, isProject?: boolean, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'userId' | 'date' | 'isSynced' | 'isInvoiced' | 'activity' | 'resource', query?: string, pageSize?: number, authorization?: string, options?: any): AxiosPromise<PaginatedResponseTimeEntry> {
            return localVarFp.getCompanyTimeEntriesPaginated(companyId, from, to, approved, userIds, resourceId, isProject, page, sortDirection, sortField, query, pageSize, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get time entries by resource ID
         * @param {string} companyId 
         * @param {string} resourceId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {'Submitted' | 'Approved' | 'Quarantined' | 'Invoiced'} [status] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeEntriesByResourceId(companyId: string, resourceId: string, dateFrom?: string, dateTo?: string, status?: 'Submitted' | 'Approved' | 'Quarantined' | 'Invoiced', authorization?: string, options?: any): AxiosPromise<Array<TimeEntry>> {
            return localVarFp.getTimeEntriesByResourceId(companyId, resourceId, dateFrom, dateTo, status, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {TimeEntryTaskListGetRequest} timeEntryTaskListGetRequest 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeEntriesByTaskIds(companyId: string, timeEntryTaskListGetRequest: TimeEntryTaskListGetRequest, dateFrom?: string, dateTo?: string, authorization?: string, options?: any): AxiosPromise<Array<TimeEntry>> {
            return localVarFp.getTimeEntriesByTaskIds(companyId, timeEntryTaskListGetRequest, dateFrom, dateTo, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get time entries for user
         * @param {string} companyId 
         * @param {string} userId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeEntriesOnUserV2(companyId: string, userId: string, dateFrom: string, dateTo: string, authorization?: string, options?: any): AxiosPromise<Array<TimeEntry>> {
            return localVarFp.getTimeEntriesOnUserV2(companyId, userId, dateFrom, dateTo, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get total time submitted for project
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalTimeSubmitted(projectId: string, authorization?: string, options?: any): AxiosPromise<Summary> {
            return localVarFp.getTotalTimeSubmitted(projectId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get time entries for logged in user
         * @param {string} companyId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserTimeEntries(companyId: string, dateFrom: string, dateTo: string, authorization?: string, options?: any): AxiosPromise<Array<TimeEntry>> {
            return localVarFp.getUserTimeEntries(companyId, dateFrom, dateTo, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new time entry on user
         * @param {string} companyId 
         * @param {string} userId 
         * @param {TimeEntryPostRequest} timeEntryPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTimeEntryOnUser(companyId: string, userId: string, timeEntryPostRequest: TimeEntryPostRequest, authorization?: string, options?: any): AxiosPromise<TimeEntry> {
            return localVarFp.postTimeEntryOnUser(companyId, userId, timeEntryPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new time entry for user
         * @param {string} companyId 
         * @param {TimeEntryPostRequest} timeEntryPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserTimeEntry(companyId: string, timeEntryPostRequest: TimeEntryPostRequest, authorization?: string, options?: any): AxiosPromise<TimeEntry> {
            return localVarFp.postUserTimeEntry(companyId, timeEntryPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new time entry for user on resource (task or order)
         * @param {string} companyId 
         * @param {TimeEntryPostRequest} timeEntryPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserTimeEntryOnResource(companyId: string, timeEntryPostRequest: TimeEntryPostRequest, authorization?: string, options?: any): AxiosPromise<TimeEntry> {
            return localVarFp.postUserTimeEntryOnResource(companyId, timeEntryPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync hours
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncHours(companyId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.syncHours(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update time entry
         * @param {string} companyId 
         * @param {string} id 
         * @param {TimeEntryPutRequest} timeEntryPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeEntry(companyId: string, id: string, timeEntryPutRequest: TimeEntryPutRequest, authorization?: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateTimeEntry(companyId, id, timeEntryPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set time entry approvals
         * @param {string} companyId 
         * @param {TimeApprovalRequest} timeApprovalRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeEntryApproval(companyId: string, timeApprovalRequest: TimeApprovalRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.updateTimeEntryApproval(companyId, timeApprovalRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark time entries as invoiced
         * @param {string} companyId 
         * @param {TimeInvoiceRequest} timeInvoiceRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeEntryInvoiced(companyId: string, timeInvoiceRequest: TimeInvoiceRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.updateTimeEntryInvoiced(companyId, timeInvoiceRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update time entry on user
         * @param {string} companyId 
         * @param {string} id 
         * @param {string} userId 
         * @param {TimeEntryPutRequest} timeEntryPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeEntryOnUser(companyId: string, id: string, userId: string, timeEntryPutRequest: TimeEntryPutRequest, authorization?: string, options?: any): AxiosPromise<TimeEntry> {
            return localVarFp.updateTimeEntryOnUser(companyId, id, userId, timeEntryPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update time entry for user
         * @param {string} companyId 
         * @param {string} id 
         * @param {TimeEntryPutRequest} timeEntryPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserTimeEntry(companyId: string, id: string, timeEntryPutRequest: TimeEntryPutRequest, authorization?: string, options?: any): AxiosPromise<TimeEntry> {
            return localVarFp.updateUserTimeEntry(companyId, id, timeEntryPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TimeEntriesApi - object-oriented interface
 * @export
 * @class TimeEntriesApi
 * @extends {BaseAPI}
 */
export class TimeEntriesApi extends BaseAPI {
    /**
     * 
     * @summary Automatically approve hours
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntriesApi
     */
    public autoApproveHours(authorization?: string, options?: AxiosRequestConfig) {
        return TimeEntriesApiFp(this.configuration).autoApproveHours(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete time entry on user
     * @param {string} id 
     * @param {string} userId 
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntriesApi
     */
    public deleteTimeEntryOnUser(id: string, userId: string, companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return TimeEntriesApiFp(this.configuration).deleteTimeEntryOnUser(id, userId, companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete time entry for user
     * @param {string} id 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntriesApi
     */
    public deleteUserTimeEntry(id: string, authorization?: string, options?: AxiosRequestConfig) {
        return TimeEntriesApiFp(this.configuration).deleteUserTimeEntry(id, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get time entries for company
     * @param {string} companyId 
     * @param {'All' | 'Project' | 'Company' | 'Order'} type 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {'Submitted' | 'Approved' | 'Quarantined' | 'Invoiced'} [status] 
     * @param {boolean} [needApproval] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntriesApi
     */
    public getCompanyTimeEntries(companyId: string, type: 'All' | 'Project' | 'Company' | 'Order', dateFrom?: string, dateTo?: string, status?: 'Submitted' | 'Approved' | 'Quarantined' | 'Invoiced', needApproval?: boolean, authorization?: string, options?: AxiosRequestConfig) {
        return TimeEntriesApiFp(this.configuration).getCompanyTimeEntries(companyId, type, dateFrom, dateTo, status, needApproval, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get time entries for approval on company
     * @param {string} companyId 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'userId' | 'date'} [sortField] 
     * @param {string} [query] 
     * @param {number} [pageSize] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntriesApi
     */
    public getCompanyTimeEntriesForApproval(companyId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'userId' | 'date', query?: string, pageSize?: number, authorization?: string, options?: AxiosRequestConfig) {
        return TimeEntriesApiFp(this.configuration).getCompanyTimeEntriesForApproval(companyId, page, sortDirection, sortField, query, pageSize, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get paginated time entries for company
     * @param {string} companyId 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {'approved' | 'notApproved' | 'invoiced' | 'notInvoiced' | 'transferred' | 'notTransferred'} [approved] 
     * @param {Array<string>} [userIds] 
     * @param {string} [resourceId] 
     * @param {boolean} [isProject] 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'userId' | 'date' | 'isSynced' | 'isInvoiced' | 'activity' | 'resource'} [sortField] 
     * @param {string} [query] 
     * @param {number} [pageSize] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntriesApi
     */
    public getCompanyTimeEntriesPaginated(companyId: string, from?: string, to?: string, approved?: 'approved' | 'notApproved' | 'invoiced' | 'notInvoiced' | 'transferred' | 'notTransferred', userIds?: Array<string>, resourceId?: string, isProject?: boolean, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'userId' | 'date' | 'isSynced' | 'isInvoiced' | 'activity' | 'resource', query?: string, pageSize?: number, authorization?: string, options?: AxiosRequestConfig) {
        return TimeEntriesApiFp(this.configuration).getCompanyTimeEntriesPaginated(companyId, from, to, approved, userIds, resourceId, isProject, page, sortDirection, sortField, query, pageSize, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get time entries by resource ID
     * @param {string} companyId 
     * @param {string} resourceId 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {'Submitted' | 'Approved' | 'Quarantined' | 'Invoiced'} [status] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntriesApi
     */
    public getTimeEntriesByResourceId(companyId: string, resourceId: string, dateFrom?: string, dateTo?: string, status?: 'Submitted' | 'Approved' | 'Quarantined' | 'Invoiced', authorization?: string, options?: AxiosRequestConfig) {
        return TimeEntriesApiFp(this.configuration).getTimeEntriesByResourceId(companyId, resourceId, dateFrom, dateTo, status, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {TimeEntryTaskListGetRequest} timeEntryTaskListGetRequest 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntriesApi
     */
    public getTimeEntriesByTaskIds(companyId: string, timeEntryTaskListGetRequest: TimeEntryTaskListGetRequest, dateFrom?: string, dateTo?: string, authorization?: string, options?: AxiosRequestConfig) {
        return TimeEntriesApiFp(this.configuration).getTimeEntriesByTaskIds(companyId, timeEntryTaskListGetRequest, dateFrom, dateTo, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get time entries for user
     * @param {string} companyId 
     * @param {string} userId 
     * @param {string} dateFrom 
     * @param {string} dateTo 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntriesApi
     */
    public getTimeEntriesOnUserV2(companyId: string, userId: string, dateFrom: string, dateTo: string, authorization?: string, options?: AxiosRequestConfig) {
        return TimeEntriesApiFp(this.configuration).getTimeEntriesOnUserV2(companyId, userId, dateFrom, dateTo, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get total time submitted for project
     * @param {string} projectId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntriesApi
     */
    public getTotalTimeSubmitted(projectId: string, authorization?: string, options?: AxiosRequestConfig) {
        return TimeEntriesApiFp(this.configuration).getTotalTimeSubmitted(projectId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get time entries for logged in user
     * @param {string} companyId 
     * @param {string} dateFrom 
     * @param {string} dateTo 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof TimeEntriesApi
     */
    public getUserTimeEntries(companyId: string, dateFrom: string, dateTo: string, authorization?: string, options?: AxiosRequestConfig) {
        return TimeEntriesApiFp(this.configuration).getUserTimeEntries(companyId, dateFrom, dateTo, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new time entry on user
     * @param {string} companyId 
     * @param {string} userId 
     * @param {TimeEntryPostRequest} timeEntryPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntriesApi
     */
    public postTimeEntryOnUser(companyId: string, userId: string, timeEntryPostRequest: TimeEntryPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return TimeEntriesApiFp(this.configuration).postTimeEntryOnUser(companyId, userId, timeEntryPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new time entry for user
     * @param {string} companyId 
     * @param {TimeEntryPostRequest} timeEntryPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntriesApi
     */
    public postUserTimeEntry(companyId: string, timeEntryPostRequest: TimeEntryPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return TimeEntriesApiFp(this.configuration).postUserTimeEntry(companyId, timeEntryPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new time entry for user on resource (task or order)
     * @param {string} companyId 
     * @param {TimeEntryPostRequest} timeEntryPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntriesApi
     */
    public postUserTimeEntryOnResource(companyId: string, timeEntryPostRequest: TimeEntryPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return TimeEntriesApiFp(this.configuration).postUserTimeEntryOnResource(companyId, timeEntryPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync hours
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntriesApi
     */
    public syncHours(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return TimeEntriesApiFp(this.configuration).syncHours(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update time entry
     * @param {string} companyId 
     * @param {string} id 
     * @param {TimeEntryPutRequest} timeEntryPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntriesApi
     */
    public updateTimeEntry(companyId: string, id: string, timeEntryPutRequest: TimeEntryPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return TimeEntriesApiFp(this.configuration).updateTimeEntry(companyId, id, timeEntryPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set time entry approvals
     * @param {string} companyId 
     * @param {TimeApprovalRequest} timeApprovalRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntriesApi
     */
    public updateTimeEntryApproval(companyId: string, timeApprovalRequest: TimeApprovalRequest, authorization?: string, options?: AxiosRequestConfig) {
        return TimeEntriesApiFp(this.configuration).updateTimeEntryApproval(companyId, timeApprovalRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark time entries as invoiced
     * @param {string} companyId 
     * @param {TimeInvoiceRequest} timeInvoiceRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntriesApi
     */
    public updateTimeEntryInvoiced(companyId: string, timeInvoiceRequest: TimeInvoiceRequest, authorization?: string, options?: AxiosRequestConfig) {
        return TimeEntriesApiFp(this.configuration).updateTimeEntryInvoiced(companyId, timeInvoiceRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update time entry on user
     * @param {string} companyId 
     * @param {string} id 
     * @param {string} userId 
     * @param {TimeEntryPutRequest} timeEntryPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntriesApi
     */
    public updateTimeEntryOnUser(companyId: string, id: string, userId: string, timeEntryPutRequest: TimeEntryPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return TimeEntriesApiFp(this.configuration).updateTimeEntryOnUser(companyId, id, userId, timeEntryPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update time entry for user
     * @param {string} companyId 
     * @param {string} id 
     * @param {TimeEntryPutRequest} timeEntryPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntriesApi
     */
    public updateUserTimeEntry(companyId: string, id: string, timeEntryPutRequest: TimeEntryPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return TimeEntriesApiFp(this.configuration).updateUserTimeEntry(companyId, id, timeEntryPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
