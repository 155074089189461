/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { PaginatedResponseProduct } from '../model';
// @ts-ignore
import { ProductPriceRequest } from '../model';
// @ts-ignore
import { ProductRequest } from '../model';
// @ts-ignore
import { ResponseDTOListProduct } from '../model';
// @ts-ignore
import { ResponseDTOPaginatedProduct } from '../model';
// @ts-ignore
import { ResponseDTOProduct } from '../model';
// @ts-ignore
import { ResponseDTOString } from '../model';
// @ts-ignore
import { ResponseDTOUUID } from '../model';
/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create product
         * @param {string} companyId 
         * @param {ProductRequest} productRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct: async (companyId: string, productRequest: ProductRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createProduct', 'companyId', companyId)
            // verify required parameter 'productRequest' is not null or undefined
            assertParamExists('createProduct', 'productRequest', productRequest)
            const localVarPath = `/v1/company/{companyId}/products`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete inventory
         * @param {string} companyId 
         * @param {string} productId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInventory: async (companyId: string, productId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteInventory', 'companyId', companyId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('deleteInventory', 'productId', productId)
            const localVarPath = `/v1/company/{companyId}/inventory/{productId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete/Archive product
         * @param {string} companyId 
         * @param {string} productId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct: async (companyId: string, productId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteProduct', 'companyId', companyId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('deleteProduct', 'productId', productId)
            const localVarPath = `/v1/company/{companyId}/products/{productId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all products
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {Array<string>} [categoryIds] 
         * @param {boolean} [archived] 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyProducts: async (companyId: string, authorization?: string, categoryIds?: Array<string>, archived?: boolean, search?: string, limit?: number, page?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllCompanyProducts', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/products`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (categoryIds) {
                localVarQueryParameter['category_ids'] = categoryIds;
            }

            if (archived !== undefined) {
                localVarQueryParameter['archived'] = archived;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get products for company (paginated)
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {Array<string>} [categoryIds] 
         * @param {boolean} [archived] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Name' | 'Supplier' | 'ItemNumber' | 'Unit'} [sortField] 
         * @param {string} [query] 
         * @param {'All' | 'Composite' | 'Simple'} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedProductsByCompanyId: async (companyId: string, authorization?: string, categoryIds?: Array<string>, archived?: boolean, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Name' | 'Supplier' | 'ItemNumber' | 'Unit', query?: string, filter?: 'All' | 'Composite' | 'Simple', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getPaginatedProductsByCompanyId', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/products/paginated`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (categoryIds) {
                localVarQueryParameter['category_ids'] = categoryIds;
            }

            if (archived !== undefined) {
                localVarQueryParameter['archived'] = archived;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get product by ID
         * @param {string} companyId 
         * @param {string} productId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById: async (companyId: string, productId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getProductById', 'companyId', companyId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductById', 'productId', productId)
            const localVarPath = `/v1/company/{companyId}/products/{productId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get products by EAN number
         * @param {string} companyId 
         * @param {string} eanNumber 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsByEanNumber: async (companyId: string, eanNumber: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getProductsByEanNumber', 'companyId', companyId)
            // verify required parameter 'eanNumber' is not null or undefined
            assertParamExists('getProductsByEanNumber', 'eanNumber', eanNumber)
            const localVarPath = `/v1/company/{companyId}/products/ean_number/{eanNumber}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"eanNumber"}}`, encodeURIComponent(String(eanNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unarchive product
         * @param {string} companyId 
         * @param {string} productId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveProduct: async (companyId: string, productId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('unarchiveProduct', 'companyId', companyId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('unarchiveProduct', 'productId', productId)
            const localVarPath = `/v1/company/{companyId}/products/{productId}/unarchive`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a product
         * @param {string} companyId 
         * @param {string} productId 
         * @param {ProductRequest} productRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct: async (companyId: string, productId: string, productRequest: ProductRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateProduct', 'companyId', companyId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('updateProduct', 'productId', productId)
            // verify required parameter 'productRequest' is not null or undefined
            assertParamExists('updateProduct', 'productRequest', productRequest)
            const localVarPath = `/v1/company/{companyId}/products/{productId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a product
         * @param {string} companyId 
         * @param {ProductPriceRequest} productPriceRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductPrices: async (companyId: string, productPriceRequest: ProductPriceRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateProductPrices', 'companyId', companyId)
            // verify required parameter 'productPriceRequest' is not null or undefined
            assertParamExists('updateProductPrices', 'productPriceRequest', productPriceRequest)
            const localVarPath = `/v1/company/{companyId}/products/price/update`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productPriceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create product
         * @param {string} companyId 
         * @param {ProductRequest} productRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProduct(companyId: string, productRequest: ProductRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOUUID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProduct(companyId, productRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete inventory
         * @param {string} companyId 
         * @param {string} productId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInventory(companyId: string, productId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInventory(companyId, productId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete/Archive product
         * @param {string} companyId 
         * @param {string} productId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProduct(companyId: string, productId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProduct(companyId, productId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all products
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {Array<string>} [categoryIds] 
         * @param {boolean} [archived] 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCompanyProducts(companyId: string, authorization?: string, categoryIds?: Array<string>, archived?: boolean, search?: string, limit?: number, page?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOPaginatedProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCompanyProducts(companyId, authorization, categoryIds, archived, search, limit, page, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get products for company (paginated)
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {Array<string>} [categoryIds] 
         * @param {boolean} [archived] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Name' | 'Supplier' | 'ItemNumber' | 'Unit'} [sortField] 
         * @param {string} [query] 
         * @param {'All' | 'Composite' | 'Simple'} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedProductsByCompanyId(companyId: string, authorization?: string, categoryIds?: Array<string>, archived?: boolean, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Name' | 'Supplier' | 'ItemNumber' | 'Unit', query?: string, filter?: 'All' | 'Composite' | 'Simple', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginatedProductsByCompanyId(companyId, authorization, categoryIds, archived, page, sortDirection, sortField, query, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get product by ID
         * @param {string} companyId 
         * @param {string} productId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductById(companyId: string, productId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductById(companyId, productId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get products by EAN number
         * @param {string} companyId 
         * @param {string} eanNumber 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsByEanNumber(companyId: string, eanNumber: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOListProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsByEanNumber(companyId, eanNumber, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unarchive product
         * @param {string} companyId 
         * @param {string} productId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unarchiveProduct(companyId: string, productId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unarchiveProduct(companyId, productId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a product
         * @param {string} companyId 
         * @param {string} productId 
         * @param {ProductRequest} productRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProduct(companyId: string, productId: string, productRequest: ProductRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProduct(companyId, productId, productRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a product
         * @param {string} companyId 
         * @param {ProductPriceRequest} productPriceRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProductPrices(companyId: string, productPriceRequest: ProductPriceRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProductPrices(companyId, productPriceRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 
         * @summary Create product
         * @param {string} companyId 
         * @param {ProductRequest} productRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(companyId: string, productRequest: ProductRequest, authorization?: string, options?: any): AxiosPromise<ResponseDTOUUID> {
            return localVarFp.createProduct(companyId, productRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete inventory
         * @param {string} companyId 
         * @param {string} productId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInventory(companyId: string, productId: string, authorization?: string, options?: any): AxiosPromise<ResponseDTOString> {
            return localVarFp.deleteInventory(companyId, productId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete/Archive product
         * @param {string} companyId 
         * @param {string} productId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct(companyId: string, productId: string, authorization?: string, options?: any): AxiosPromise<ResponseDTOString> {
            return localVarFp.deleteProduct(companyId, productId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all products
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {Array<string>} [categoryIds] 
         * @param {boolean} [archived] 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyProducts(companyId: string, authorization?: string, categoryIds?: Array<string>, archived?: boolean, search?: string, limit?: number, page?: number, sort?: string, options?: any): AxiosPromise<ResponseDTOPaginatedProduct> {
            return localVarFp.getAllCompanyProducts(companyId, authorization, categoryIds, archived, search, limit, page, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get products for company (paginated)
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {Array<string>} [categoryIds] 
         * @param {boolean} [archived] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Name' | 'Supplier' | 'ItemNumber' | 'Unit'} [sortField] 
         * @param {string} [query] 
         * @param {'All' | 'Composite' | 'Simple'} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedProductsByCompanyId(companyId: string, authorization?: string, categoryIds?: Array<string>, archived?: boolean, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Name' | 'Supplier' | 'ItemNumber' | 'Unit', query?: string, filter?: 'All' | 'Composite' | 'Simple', options?: any): AxiosPromise<PaginatedResponseProduct> {
            return localVarFp.getPaginatedProductsByCompanyId(companyId, authorization, categoryIds, archived, page, sortDirection, sortField, query, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get product by ID
         * @param {string} companyId 
         * @param {string} productId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById(companyId: string, productId: string, authorization?: string, options?: any): AxiosPromise<ResponseDTOProduct> {
            return localVarFp.getProductById(companyId, productId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get products by EAN number
         * @param {string} companyId 
         * @param {string} eanNumber 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsByEanNumber(companyId: string, eanNumber: string, authorization?: string, options?: any): AxiosPromise<ResponseDTOListProduct> {
            return localVarFp.getProductsByEanNumber(companyId, eanNumber, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unarchive product
         * @param {string} companyId 
         * @param {string} productId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveProduct(companyId: string, productId: string, authorization?: string, options?: any): AxiosPromise<ResponseDTOString> {
            return localVarFp.unarchiveProduct(companyId, productId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a product
         * @param {string} companyId 
         * @param {string} productId 
         * @param {ProductRequest} productRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct(companyId: string, productId: string, productRequest: ProductRequest, authorization?: string, options?: any): AxiosPromise<ResponseDTOString> {
            return localVarFp.updateProduct(companyId, productId, productRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a product
         * @param {string} companyId 
         * @param {ProductPriceRequest} productPriceRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductPrices(companyId: string, productPriceRequest: ProductPriceRequest, authorization?: string, options?: any): AxiosPromise<ResponseDTOString> {
            return localVarFp.updateProductPrices(companyId, productPriceRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @summary Create product
     * @param {string} companyId 
     * @param {ProductRequest} productRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public createProduct(companyId: string, productRequest: ProductRequest, authorization?: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).createProduct(companyId, productRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete inventory
     * @param {string} companyId 
     * @param {string} productId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public deleteInventory(companyId: string, productId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).deleteInventory(companyId, productId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete/Archive product
     * @param {string} companyId 
     * @param {string} productId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public deleteProduct(companyId: string, productId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).deleteProduct(companyId, productId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all products
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {Array<string>} [categoryIds] 
     * @param {boolean} [archived] 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getAllCompanyProducts(companyId: string, authorization?: string, categoryIds?: Array<string>, archived?: boolean, search?: string, limit?: number, page?: number, sort?: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getAllCompanyProducts(companyId, authorization, categoryIds, archived, search, limit, page, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get products for company (paginated)
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {Array<string>} [categoryIds] 
     * @param {boolean} [archived] 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'Name' | 'Supplier' | 'ItemNumber' | 'Unit'} [sortField] 
     * @param {string} [query] 
     * @param {'All' | 'Composite' | 'Simple'} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getPaginatedProductsByCompanyId(companyId: string, authorization?: string, categoryIds?: Array<string>, archived?: boolean, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Name' | 'Supplier' | 'ItemNumber' | 'Unit', query?: string, filter?: 'All' | 'Composite' | 'Simple', options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getPaginatedProductsByCompanyId(companyId, authorization, categoryIds, archived, page, sortDirection, sortField, query, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get product by ID
     * @param {string} companyId 
     * @param {string} productId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProductById(companyId: string, productId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getProductById(companyId, productId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get products by EAN number
     * @param {string} companyId 
     * @param {string} eanNumber 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProductsByEanNumber(companyId: string, eanNumber: string, authorization?: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getProductsByEanNumber(companyId, eanNumber, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unarchive product
     * @param {string} companyId 
     * @param {string} productId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public unarchiveProduct(companyId: string, productId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).unarchiveProduct(companyId, productId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a product
     * @param {string} companyId 
     * @param {string} productId 
     * @param {ProductRequest} productRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public updateProduct(companyId: string, productId: string, productRequest: ProductRequest, authorization?: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).updateProduct(companyId, productId, productRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a product
     * @param {string} companyId 
     * @param {ProductPriceRequest} productPriceRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public updateProductPrices(companyId: string, productPriceRequest: ProductPriceRequest, authorization?: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).updateProductPrices(companyId, productPriceRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
