import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import { useGetOfferByPathUri } from "../../../../../service/api/OfferApi";
import DetailsPage from "../../../../_unprotected/offer/$pathUri/details/-components/DetailsPage";

export const Route = createFileRoute(
  "/_protected/dashboard/sales/offer/$pathUri/preview"
)({
  loader: ({ context: { queryClient }, params: { pathUri } }) => {
    return queryClient.fetchQuery({
      ...useGetOfferByPathUri.getOptions({ pathUri: pathUri })
    });
  },
  component: OfferPreviewPage
});

function OfferPreviewPage() {
  const offer = useLoaderData({
    from: "/_protected/dashboard/sales/offer/$pathUri/preview"
  });

  return <DetailsPage offer={offer} isPreview={true} />;
}
