import { persist } from "zustand/middleware";
import { CompanyNameCurrencyEnum, User } from "../.generated/api";
import { createWithEqualityFn as create } from "zustand/traditional";
import { OfferFormData } from "../pages/sales/salesOppertunity/details/offer/-components/schema";
import { shared } from "use-broadcast-ts";

export type CompanyInfo = {
  name: string;
  id: string;
  orgNumber: string;
  contactPerson: string;
  email?: string;
  phone?: string;
  freeTrialDaysRemaining: number;
  subscription?: "FREE_TRIAL" | "PAYED" | "EXPIRED";
  features: {
    sales: boolean;
    projects: boolean;
    orders: boolean;
    products: boolean;
    tasks: boolean;
    resourcePlanner: boolean;
    timekeeping: boolean;
  };
};

export type Theme = "dark" | "light";

type AppStore = {
  selectedCompany?: CompanyInfo;
  setSelectedCompany: (company: CompanyInfo) => void;
  pageSize: number;
  setPageSize: (size: number) => void;
  theme: Theme;
  setTheme: (theme: Theme) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  user: User;
  setUser: (user: User) => void;
  currency: CompanyNameCurrencyEnum;
  inboundEmailFilter: "all" | "mine";
  setInboundEmailFilter: (filter: "all" | "mine") => void;
  setCurrency: (currency: CompanyNameCurrencyEnum) => void;
};

type OfferPreviewStore = {
  offer?: Partial<OfferFormData>;
  set: (o: Partial<OfferFormData>) => void;
};

export const useOfferPreviewStore = create<OfferPreviewStore>(
  shared((set) => ({
    offer: undefined,
    set: (o) => set({ offer: o })
  }))
);

export const useAppStore = create<AppStore>()(
  persist(
    (set) => ({
      setSelectedCompany: (company) => {
        set(() => ({ selectedCompany: company }));
      },
      setCurrency: (currency) => {
        set(() => ({ currency }));
      },
      setIsLoading: (isLoading) => set(() => ({ isLoading })),
      setUser: (user) => set(() => ({ user: user })),
      setTheme: (theme) => set(() => ({ theme: theme })),
      setPageSize: (size) => set(() => ({ pageSize: size })),
      setInboundEmailFilter: (filter) =>
        set(() => ({ inboundEmailFilter: filter })),
      pageSize: 15,
      projectFilter: "all",
      inboundEmailFilter: "all",
      theme: "light",
      selectedProjectView: "list",
      isLoading: false,
      menuState: "open",
      currency: "NOK",
      user: {
        id: "",
        email: "",
        loginOption: "google",
        status: "active"
      }
    }),
    {
      name: "company",
      partialize: (s) => ({
        selectedCompany: s.selectedCompany,
        inboundEmailFilter: s.inboundEmailFilter,
        pageSize: s.pageSize,
        currency: s.currency,
        user: s.user
      })
    }
  )
);
