import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export type ToastType = "drawer" | "regular";

export function useToast(type: ToastType = "regular") {
  const { t } = useTranslation();
  return {
    showSuccessToast: (message: string) => {
      toast.success(message, { toasterId: type });
    },
    showErrorToast: (message: string) => {
      toast.error(message, { toasterId: type });
    },
    showLoadingToast: (
      promise: Promise<unknown>,
      loadingText: string,
      successText: string
    ) => {
      toast.promise(
        promise,
        {
          loading: loadingText,
          error: t("anErrorOccurred"),
          success: successText
        },
        { toasterId: type }
      );
    }
  };
}
