/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CloudMessagingTokenPutRequest } from '../model';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { ProfilePutRequest } from '../model';
// @ts-ignore
import { User } from '../model';
// @ts-ignore
import { UserPermissions } from '../model';
// @ts-ignore
import { UserWithRole } from '../model';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete user profile
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserAccount: async (authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete user company connection
         * @param {string} otherUserId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserCompanyConnection: async (otherUserId: string, companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'otherUserId' is not null or undefined
            assertParamExists('deleteUserCompanyConnection', 'otherUserId', otherUserId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteUserCompanyConnection', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/user/{otherUserId}`
                .replace(`{${"otherUserId"}}`, encodeURIComponent(String(otherUserId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all users in company
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsersInCompany: async (companyId: string, authorization?: string, status?: 'active' | 'deleted', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllUsersInCompany', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/user`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all users for a specific resource
         * @param {string} resourceId 
         * @param {'task' | 'project' | 'order' | 'company'} resourceType 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsersOnResource: async (resourceId: string, resourceType: 'task' | 'project' | 'order' | 'company', authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getAllUsersOnResource', 'resourceId', resourceId)
            // verify required parameter 'resourceType' is not null or undefined
            assertParamExists('getAllUsersOnResource', 'resourceType', resourceType)
            const localVarPath = `/v1/users/{resourceType}/{resourceId}`
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)))
                .replace(`{${"resourceType"}}`, encodeURIComponent(String(resourceType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get information about currently logged in user
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInformationAboutCaller: async (authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user information
         * @param {string} otherUserId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInformation: async (otherUserId: string, companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'otherUserId' is not null or undefined
            assertParamExists('getUserInformation', 'otherUserId', otherUserId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getUserInformation', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/user/{otherUserId}`
                .replace(`{${"otherUserId"}}`, encodeURIComponent(String(otherUserId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check which of the requested permissions user has
         * @param {string} resourceId 
         * @param {'task' | 'project' | 'company' | 'deviation' | 'checklist' | 'checklistItem' | 'documentation' | 'documentationConnection' | 'location' | 'ehs' | 'internalRoutine' | 'order' | 'orderTemplate' | 'facility' | 'salesOpportunity' | 'customer'} resourceType 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersPermissionsOnResource: async (resourceId: string, resourceType: 'task' | 'project' | 'company' | 'deviation' | 'checklist' | 'checklistItem' | 'documentation' | 'documentationConnection' | 'location' | 'ehs' | 'internalRoutine' | 'order' | 'orderTemplate' | 'facility' | 'salesOpportunity' | 'customer', authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getUsersPermissionsOnResource', 'resourceId', resourceId)
            // verify required parameter 'resourceType' is not null or undefined
            assertParamExists('getUsersPermissionsOnResource', 'resourceType', resourceType)
            const localVarPath = `/v1/permission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (resourceId !== undefined) {
                localVarQueryParameter['resourceId'] = resourceId;
            }

            if (resourceType !== undefined) {
                localVarQueryParameter['resourceType'] = resourceType;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a user\'s cloud messaging token
         * @param {CloudMessagingTokenPutRequest} cloudMessagingTokenPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserCloudMessagingToken: async (cloudMessagingTokenPutRequest: CloudMessagingTokenPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cloudMessagingTokenPutRequest' is not null or undefined
            assertParamExists('updateUserCloudMessagingToken', 'cloudMessagingTokenPutRequest', cloudMessagingTokenPutRequest)
            const localVarPath = `/v1/user/cloudtoken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cloudMessagingTokenPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user\'s profile
         * @param {ProfilePutRequest} profilePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile: async (profilePutRequest: ProfilePutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profilePutRequest' is not null or undefined
            assertParamExists('updateUserProfile', 'profilePutRequest', profilePutRequest)
            const localVarPath = `/v1/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profilePutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete user profile
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserAccount(authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserAccount(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete user company connection
         * @param {string} otherUserId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserCompanyConnection(otherUserId: string, companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserCompanyConnection(otherUserId, companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all users in company
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUsersInCompany(companyId: string, authorization?: string, status?: 'active' | 'deleted', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUsersInCompany(companyId, authorization, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all users for a specific resource
         * @param {string} resourceId 
         * @param {'task' | 'project' | 'order' | 'company'} resourceType 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUsersOnResource(resourceId: string, resourceType: 'task' | 'project' | 'order' | 'company', authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserWithRole>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUsersOnResource(resourceId, resourceType, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get information about currently logged in user
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInformationAboutCaller(authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInformationAboutCaller(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user information
         * @param {string} otherUserId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInformation(otherUserId: string, companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInformation(otherUserId, companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check which of the requested permissions user has
         * @param {string} resourceId 
         * @param {'task' | 'project' | 'company' | 'deviation' | 'checklist' | 'checklistItem' | 'documentation' | 'documentationConnection' | 'location' | 'ehs' | 'internalRoutine' | 'order' | 'orderTemplate' | 'facility' | 'salesOpportunity' | 'customer'} resourceType 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersPermissionsOnResource(resourceId: string, resourceType: 'task' | 'project' | 'company' | 'deviation' | 'checklist' | 'checklistItem' | 'documentation' | 'documentationConnection' | 'location' | 'ehs' | 'internalRoutine' | 'order' | 'orderTemplate' | 'facility' | 'salesOpportunity' | 'customer', authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPermissions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersPermissionsOnResource(resourceId, resourceType, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a user\'s cloud messaging token
         * @param {CloudMessagingTokenPutRequest} cloudMessagingTokenPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserCloudMessagingToken(cloudMessagingTokenPutRequest: CloudMessagingTokenPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserCloudMessagingToken(cloudMessagingTokenPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user\'s profile
         * @param {ProfilePutRequest} profilePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserProfile(profilePutRequest: ProfilePutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfile(profilePutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete user profile
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserAccount(authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteUserAccount(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete user company connection
         * @param {string} otherUserId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserCompanyConnection(otherUserId: string, companyId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteUserCompanyConnection(otherUserId, companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all users in company
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsersInCompany(companyId: string, authorization?: string, status?: 'active' | 'deleted', options?: any): AxiosPromise<Array<User>> {
            return localVarFp.getAllUsersInCompany(companyId, authorization, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all users for a specific resource
         * @param {string} resourceId 
         * @param {'task' | 'project' | 'order' | 'company'} resourceType 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsersOnResource(resourceId: string, resourceType: 'task' | 'project' | 'order' | 'company', authorization?: string, options?: any): AxiosPromise<Array<UserWithRole>> {
            return localVarFp.getAllUsersOnResource(resourceId, resourceType, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get information about currently logged in user
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInformationAboutCaller(authorization?: string, options?: any): AxiosPromise<User> {
            return localVarFp.getInformationAboutCaller(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user information
         * @param {string} otherUserId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInformation(otherUserId: string, companyId: string, authorization?: string, options?: any): AxiosPromise<User> {
            return localVarFp.getUserInformation(otherUserId, companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check which of the requested permissions user has
         * @param {string} resourceId 
         * @param {'task' | 'project' | 'company' | 'deviation' | 'checklist' | 'checklistItem' | 'documentation' | 'documentationConnection' | 'location' | 'ehs' | 'internalRoutine' | 'order' | 'orderTemplate' | 'facility' | 'salesOpportunity' | 'customer'} resourceType 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersPermissionsOnResource(resourceId: string, resourceType: 'task' | 'project' | 'company' | 'deviation' | 'checklist' | 'checklistItem' | 'documentation' | 'documentationConnection' | 'location' | 'ehs' | 'internalRoutine' | 'order' | 'orderTemplate' | 'facility' | 'salesOpportunity' | 'customer', authorization?: string, options?: any): AxiosPromise<UserPermissions> {
            return localVarFp.getUsersPermissionsOnResource(resourceId, resourceType, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a user\'s cloud messaging token
         * @param {CloudMessagingTokenPutRequest} cloudMessagingTokenPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserCloudMessagingToken(cloudMessagingTokenPutRequest: CloudMessagingTokenPutRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.updateUserCloudMessagingToken(cloudMessagingTokenPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user\'s profile
         * @param {ProfilePutRequest} profilePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile(profilePutRequest: ProfilePutRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.updateUserProfile(profilePutRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Delete user profile
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUserAccount(authorization?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUserAccount(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete user company connection
     * @param {string} otherUserId 
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUserCompanyConnection(otherUserId: string, companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUserCompanyConnection(otherUserId, companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all users in company
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {'active' | 'deleted'} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getAllUsersInCompany(companyId: string, authorization?: string, status?: 'active' | 'deleted', options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getAllUsersInCompany(companyId, authorization, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all users for a specific resource
     * @param {string} resourceId 
     * @param {'task' | 'project' | 'order' | 'company'} resourceType 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getAllUsersOnResource(resourceId: string, resourceType: 'task' | 'project' | 'order' | 'company', authorization?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getAllUsersOnResource(resourceId, resourceType, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get information about currently logged in user
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getInformationAboutCaller(authorization?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getInformationAboutCaller(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user information
     * @param {string} otherUserId 
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserInformation(otherUserId: string, companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserInformation(otherUserId, companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check which of the requested permissions user has
     * @param {string} resourceId 
     * @param {'task' | 'project' | 'company' | 'deviation' | 'checklist' | 'checklistItem' | 'documentation' | 'documentationConnection' | 'location' | 'ehs' | 'internalRoutine' | 'order' | 'orderTemplate' | 'facility' | 'salesOpportunity' | 'customer'} resourceType 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsersPermissionsOnResource(resourceId: string, resourceType: 'task' | 'project' | 'company' | 'deviation' | 'checklist' | 'checklistItem' | 'documentation' | 'documentationConnection' | 'location' | 'ehs' | 'internalRoutine' | 'order' | 'orderTemplate' | 'facility' | 'salesOpportunity' | 'customer', authorization?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUsersPermissionsOnResource(resourceId, resourceType, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a user\'s cloud messaging token
     * @param {CloudMessagingTokenPutRequest} cloudMessagingTokenPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserCloudMessagingToken(cloudMessagingTokenPutRequest: CloudMessagingTokenPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserCloudMessagingToken(cloudMessagingTokenPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user\'s profile
     * @param {ProfilePutRequest} profilePutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserProfile(profilePutRequest: ProfilePutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserProfile(profilePutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
