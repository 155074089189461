import {
  CompanyControllerApi,
  CompanyFreeTrialOrderRequest,
  CompanyMenuItemPutRequest,
  CompanyPostRequest,
  CompanyPutRequest
} from "../../.generated/api";
import { apiInstance } from "./ApiHttpBase";
import environment from "../../environment";
import { createQuery } from "react-query-kit";

const companyApi = new CompanyControllerApi(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export const useGetCompanyDetails = createQuery({
  queryKey: ["getCompanyDetails"],
  fetcher: async (variables: { companyId: string }) => {
    const response = await companyApi.getCompany(variables.companyId);
    return response.data;
  }
});

export const useGetCompanyPublicInfo = createQuery({
  queryKey: ["getCompanyPublicInfo"],
  fetcher: async (variables: { companyId: string }) => {
    const response = await companyApi.getCompanyPublicInfo(variables.companyId);
    return response.data;
  }
});

export const useGetCompanyLogo = createQuery({
  queryKey: ["getCompanyLogo"],
  fetcher: async (variables: { companyId: string }) => {
    const response = await companyApi.getCompanyLogoUrl(variables.companyId);
    return response.data;
  }
});

export const useGetCompanyReportLogo = createQuery({
  queryKey: ["getCompanyReportLogo"],
  fetcher: async (variables: { companyId: string }) => {
    const response = await companyApi.getCompanyReportLogoUrl(
      variables.companyId
    );
    return response.data;
  }
});

export const useGetInboundEmailConfig = createQuery({
  queryKey: ["getInboundEmailConfig"],
  fetcher: async (variables: { companyId: string }) => {
    const response = await companyApi.getInboundEmailSettings(
      variables.companyId
    );
    return response.data;
  }
});

export async function getCompanySubscriptionStatus(companyId: string) {
  return companyApi.getCompanySubscriptionStatus(companyId);
}

export async function createCompany(request: CompanyPostRequest) {
  await companyApi.postCompany(request);
}

export async function getCompanyDetails(companyId: string) {
  const response = await companyApi.getCompany(companyId);
  return response.data;
}

export async function updateCompanyMenu(
  companyId: string,
  request: CompanyMenuItemPutRequest
) {
  await companyApi.updateCompanyMenuItems(companyId, request);
}

export async function updateCompanyDetails(
  companyId: string,
  data: CompanyPutRequest
) {
  return companyApi.putCompany(companyId, data);
}

export async function updateCompanyOnFreeTrialOrder(
  companyId: string,
  data: CompanyFreeTrialOrderRequest
) {
  return companyApi.putCompanyOnFreeTrialOrder(companyId, data);
}

export async function getLogoUploadUrl(companyId: string, contentType: string) {
  return companyApi.getCompanyLogoUploadUrl(contentType, companyId);
}

export async function getReportLogoUploadUrl(
  companyId: string,
  contentType: string
) {
  return companyApi.getCompanyReportLogoUploadUrl(contentType, companyId);
}

export async function deleteCompanyLogo(companyId: string) {
  return companyApi.deleteCompanyLogo(companyId);
}

export async function deleteCompanyReportLogo(companyId: string) {
  return companyApi.deleteCompanyReportLogo(companyId);
}

export async function getCompanyReportLogo(companyId: string) {
  const response = await companyApi.getCompanyReportLogoUrl(companyId);
  return response.data;
}

export async function invertCompanyRestrictedPhaseAccess(companyId: string) {
  const { data } =
    await companyApi.invertCompanyRestrictedPhaseAccess(companyId);

  return data;
}

export async function upsertInboundEmailConfig(
  companyId: string,
  email: string
) {
  const response = await companyApi.upsertInboundEmailConfig(companyId, {
    email: email
  });
  return response.data;
}

export async function deleteInboundEmailConfig(companyId: string) {
  await companyApi.deleteInboundEmailConfig(companyId);
}
