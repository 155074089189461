import {
  createFileRoute,
  Link,
  useLoaderData,
  useNavigate,
  useParams,
  useRouter,
  useSearch
} from "@tanstack/react-router";
import { useCompanyId } from "../../../../../../hooks/useCompanyId";
import { recurringOrderTabs } from "../../../../../../router/Routes";
import {
  addUserToTemplate,
  archiveOrderTemplate,
  createRecurringOrderTemplate,
  removeUserFromTemplate,
  useGetOrderTemplateById
} from "../../../../../../service/api/RecurringOrderApi";
import React, { useEffect, useState } from "react";
import { useToast } from "../../../../../../hooks/useToast";
import useDialog from "../../../../../../hooks/useDialog";
import { Trans, useTranslation } from "react-i18next";
import {
  getOrderPriceTypeName,
  getOrderTypeName,
  getPaymentMethodName,
  useGetOrdersByRecurringTemplateId
} from "../../../../../../service/api/OrderApi";
import { useGetCustomerById } from "../../../../../../service/api/CustomerApiV3";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { getUsers } from "../../../../../../service/api/UserApi";
import { Frequency, rrulestr } from "rrule";
import { addDays } from "date-fns";
import CustomDialog from "../../../../../../components/common/CustomDialog";
import Button from "../../../../../../components/ds/Button";
import PageTitle from "../../../../../../components/common/PageTitle";
import DeleteBlock from "../../../../../../components/buttons/DeleteBlock";
import DropDown from "../../../../../../components/ds/Dropdown/Dropdown";
import IconButton from "../../../../../../components/ds/buttons/IconButton";
import DropdownItem from "../../../../../../components/ds/Dropdown/DropdownItem";
import { TypedTab } from "../../../../../../components/ds/Tab";
import Icon from "../../../../../../components/ds/Icon";
import { rRuleToString } from "../../../../../../components/dialogs/task/RecurringEnums";
import Card from "../../../../../../components/ds/Card";
import IconWithText from "../../../../../../components/IconWithText";
import { formatCurrency } from "../../../../../../utils/currencyFormatter";
import CustomerComponent from "../../../../../../components/common/CustomerComponent";
import AutoComplete from "../../../../../../components/inputs/AutoComplete";
import NoteComponent from "../../../../../../components/NoteComponent";
import Checkbox from "../../../../../../components/ds/Checkbox";
import Pagination from "../../../../../../components/ds/Pagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from "../../../../../../components/ds/TableNew";
import {
  formatFullNumericDate,
  formatWeekDay
} from "../../../../../../utils/DateTimeFormatter";
import Badge from "../../../../../../components/ds/Badge";
import ChecklistPage from "../../../../../../pages/projectDetails/checklist/ChecklistPage";
import ProductConsumptionTab from "../../../../../../pages/projectDetails/projectProducts/ProductConsumptionTab";
import DocumentationTab from "../../../../../../pages/documentation/DocumentationTab";
import { z } from "zod";

const orderTemplateSchema = z.object({
  showDialog: z.boolean().optional().default(false),
  customerId: z.string().optional(),
  tab: recurringOrderTabs.optional().catch("overview")
});

export const Route = createFileRoute(
  "/_protected/dashboard/orders/template/$templateId/"
)({
  validateSearch: orderTemplateSchema,
  loader: ({ context: { queryClient, companyId }, params: { templateId } }) => {
    return queryClient.fetchQuery({
      ...useGetOrderTemplateById.getOptions({ companyId, templateId })
    });
  },
  component: OrderTemplatePage
});

function OrderTemplatePage() {
  const companyId = useCompanyId();
  const navigate = useNavigate();
  const { templateId } = useParams({
    from: "/_protected/dashboard/orders/template/$templateId/"
  });
  const template = useLoaderData({
    from: "/_protected/dashboard/orders/template/$templateId/"
  });
  const { showDialog, customerId, tab } = useSearch({
    from: "/_protected/dashboard/orders/template/$templateId/"
  });
  const [fetchOnlyPendingOrders, setFetchOnlyPendingOrders] = useState(false);
  const [page, setPage] = useState(0);
  const { showSuccessToast, showErrorToast } = useToast();
  const { isOpen, onOpen, onClose } = useDialog();
  const { t } = useTranslation();
  const router = useRouter();

  useEffect(() => {
    if (!showDialog) return;
    const isDismissed = localStorage.getItem("showOrderDialog");
    if (isDismissed === null) {
      onOpen();
    }
  }, []);

  function dismissModal() {
    localStorage.setItem("showOrderDialog", "false");
    onClose();
  }

  async function copyTemplate() {
    if (!template) return;

    try {
      const response = await createRecurringOrderTemplate(companyId, {
        customerId: template.customerId,
        priceType: template.priceType,
        price: template.price,
        companyId: template.companyId,
        firstDate: template.firstDate,
        title: template.title + t("copy"),
        defaultStatusId: template.defaultStatus.id,
        paymentMethod: template.paymentMethod,
        referencePersonName: template.referencePersonName,
        type: template.type,
        poNumber: template.poNumber,
        duration: template.duration,
        description: template.description,
        referencePersonNumber: template.referencePersonNumber,
        address: template.address,
        recurrencePattern: template.recurrencePattern,
        finalLastDate: template.finalLastDate
      });

      showSuccessToast(t("Kopi opprettet"));
      await navigate({
        to: "/dashboard/orders/template/$templateId",
        params: {
          templateId: response.id
        },
        search: {
          tab: "overview",
          showDialog: false
        }
      });
    } catch (_) {
      showErrorToast(t("Feil ved kopiering av mal"));
    }
  }

  const [upcomingPage, setUpcomingPage] = useState(0);

  const ordersQuery = useGetOrdersByRecurringTemplateId({
    variables: {
      companyId,
      templateId,
      page,
      onlyPending: !fetchOnlyPendingOrders
    },
    placeholderData: keepPreviousData
  });

  const customerQuery = useGetCustomerById({
    variables: { companyId, customerId: template.customerId ?? "" },
    enabled: template.customerId !== undefined
  });

  const customer = customerQuery.data;

  const userQuery = useQuery({
    queryKey: ["users", companyId],
    queryFn: async () => getUsers(companyId, "active")
  });

  const orderType = template.type
    ? ` (${getOrderTypeName(template.type)})`
    : "";
  const recurrencePattern = rrulestr(template.recurrencePattern);

  recurrencePattern.options.until;
  const createUntil =
    recurrencePattern.options.freq === Frequency.YEARLY ? 3650 : 365;
  const lastDate =
    recurrencePattern.options.until ?? addDays(new Date(), createUntil);

  const next = recurrencePattern.between(
    template.currentLastDate ? new Date(template.currentLastDate) : new Date(),
    lastDate
  );

  const userIds = Array.from(template.users).map((u) => u.id);

  const upcomingTotalPages = Math.ceil(next.length / 10);

  const customerText = customer
    ? t(" for ") + customer.formattedCustomerName
    : "";

  return (
    <>
      <CustomDialog
        title={
          t("Gratulerer! Du har laget en ny gjentakende ordremal") +
          customerText
        }
        open={isOpen}
        onClose={onClose}
        maxWidth={"md"}
        buttons={
          <>
            <Button onClick={dismissModal}>{t("Ikke vis igjen")}</Button>
            <Button theme={"white"} onClick={onClose}>
              {t("Lukk")}
            </Button>
          </>
        }
      >
        <p>
          <Trans>
            I malen (ordreintervall) finner du alle fremtidige ordre basert på
            tidsintervallene du har definert.
          </Trans>
        </p>
        <br />
        <p>
          <Trans>
            I løpet av kort tid vil den første ordren din aktiveres i Skyworker.
            Aktive ordre er tilgjengelig under
          </Trans>
          <Link
            to={"/dashboard/orders"}
            params={{}}
            search={{ tab: "overview" }}
            className={"text-secondary-100 underline"}
          >
            {t("“Kommende ordre - Planlagte ordre”")}
          </Link>
          .
        </p>
        <br />
        <p>
          <Trans>
            Alle ordre som skjer to uker frem i tid aktiveres, så de vil være i
            den samme oversikten.
          </Trans>
        </p>
        <br />
        <p className={"font-semibold mb-2"}>{t("Kan jeg endre en mal?")}</p>
        <p>{t("Ja det kan du. Her er et par ting du må huske på:")}</p>
        <ul className={"ml-4 mt-4"}>
          <li className={"text-sm list-disc mb-3"}>
            <Trans>
              Du kan alltid endre malen og endringene vises i alle fremtidige
              ordre som aktiveres.
            </Trans>
          </li>
          <li className={"text-sm list-disc mb-3"}>
            <Trans>
              Dersom en ordre er aktivert og ligger under “planlagte ordre”, må
              endringer foretas på den individuelle ordre.
            </Trans>
          </li>
          <li className={"text-sm list-disc mb-3"}>
            <Trans>
              Husk å legge til produkter, dokumenter, sjekklister eller annen
              informasjon med en gang, om du ønsker at endringene skal bli med
              på fremtidige ordre.
              {/* denne setningen var originalt halvferdig, denne løsningen krever godkjenning*/}
            </Trans>
          </li>
        </ul>
      </CustomDialog>
      <PageTitle
        title={template.title}
        onBackClick={async () => {
          await navigate({
            to: customerId
              ? "/dashboard/customers/$customerId"
              : "/dashboard/orders",
            search: customerId ? { tab: "overview" } : { tab: "overview" },
            params: customerId ? { customerId: customerId } : {}
          });
        }}
        actions={
          <div className={"flex gap-4"}>
            <DeleteBlock
              title={t("Slett mal?")}
              deleteFunction={() => archiveOrderTemplate(companyId, templateId)}
              onDeleted={async () => {
                showSuccessToast(t("Mal slettet"));
                await navigate({
                  to: "/dashboard/orders",
                  search: {
                    tab: "overview"
                  }
                });
              }}
              render={(onClick) => (
                <Button onClick={onClick}>{t("Slett mal")}</Button>
              )}
              confirmationText={t(
                "Sletter du denne malen vil det ikke opprettes flere gjentagende ordre fra denne malen."
              )}
            />
            <DropDown
              button={
                <IconButton icon="more_vert" variant={"surface"} size={"4"} />
              }
            >
              <DropdownItem onClick={copyTemplate} label={t("Kopier mal")} />
            </DropDown>
          </div>
        }
      />
      <TypedTab
        tabs={recurringOrderTabs.options}
        getLabel={(tab) => t(tab)}
        selected={tab}
        onChange={(tab) =>
          navigate({
            to: "/dashboard/orders/template/$templateId",
            search: { tab: tab, showDialog: false },
            params: {
              templateId
            }
          })
        }
      >
        <div>
          <div
            className={
              "bg-white rounded-1 border mb-4 inline-flex items-center"
            }
          >
            <div
              className={
                "w-12 h-12 bg-secondary-10 rounded-l-1 flex items-center justify-center"
              }
            >
              <Icon icon={"event_repeat"} />
            </div>
            <div className={"mx-4"}>{rRuleToString(recurrencePattern)}</div>
          </div>
          <div className={"grid grid-cols-3 gap-4 mb-4"}>
            <Card
              title={t("Oversikt")}
              trailing={
                <IconButton icon={"edit"} asChild>
                  <Link
                    to={"/dashboard/orders/template/$templateId/update"}
                    params={{
                      templateId
                    }}
                  />
                </IconButton>
              }
            >
              <p className={"text-rg font-bold mb-3"}>{t("details")}</p>
              <div className={"flex flex-col gap-3"}>
                <IconWithText
                  icon={"notes"}
                  text={template.description}
                ></IconWithText>
                <IconWithText
                  icon={"timer"}
                  text={t("xHours", { count: template.duration })}
                />
                <IconWithText
                  icon={"person"}
                  text={template.referencePersonName}
                />
                <IconWithText
                  icon={"123"}
                  text={template.poNumber?.toString()}
                />
                {(template.priceType === "Hourly" ||
                  template.priceType === "Fixed") && (
                  <IconWithText
                    icon={"payments"}
                    text={`${formatCurrency(template.price)} (${t(getOrderPriceTypeName(template.priceType))})`}
                  />
                )}
                {template.paymentMethod && (
                  <IconWithText
                    icon={"credit_card"}
                    text={getPaymentMethodName(template.paymentMethod)}
                  />
                )}
                {template.priceType === "NonBillable" && (
                  <IconWithText
                    icon={"payments"}
                    text={getOrderPriceTypeName(template.priceType) + orderType}
                  />
                )}
              </div>
            </Card>
            {/*<Card title={t("customer")}>
                    <p className={"text-rg font-bold mb-3"}>{t("details")}</p>
                    <div className={"flex flex-col gap-3"}>
                      <IconWithText
                        icon={"apartment"}
                        text={customer?.formattedCustomerName}
                      ></IconWithText>
                      <IconWithText
                        icon={"123"}
                        text={customer?.orgNumber ?? "-"}
                      />
                      <IconWithText
                        icon={"location_on"}
                        text={customer?.formattedAddress}
                      />
                      <IconWithText
                        icon={"phone"}
                        text={customer?.primaryContact?.mobilePhone}
                      />
                      <IconWithText
                        icon={"email"}
                        text={customer?.primaryContact?.email}
                      />
                    </div>
                  </Card>*/}
            <CustomerComponent
              resourceId={template.id}
              companyId={template.companyId}
              resourceType={"Order"}
            />
            <Card title={t("resources")}>
              <AutoComplete
                options={userQuery.data ?? []}
                onChange={async (u) => {
                  if (u) {
                    await addUserToTemplate(companyId, templateId, u.id);
                    await router.invalidate();
                  }
                }}
                placeholder={t("searchForUser")}
                icon={"search"}
                noOptionsText={t("Ingen brukere funnet")}
                getLabel={(u) => u.name ?? "-"}
                filter={(u) => !userIds.includes(u.id)}
                getKey={(u) => u.id}
              />
              <div className={"border rounded-1 divide-y"}>
                {Array.from(template.users).length === 0 && (
                  <p className={"px-2 py-2"}>{t("Ingen brukere lagt til")}</p>
                )}
                {Array.from(template.users)
                  .sort((a, b) => (a.name ?? "").localeCompare(b.name ?? ""))
                  .map((u) => (
                    <div
                      key={u.id}
                      className={"flex items-center justify-between px-2 py-1"}
                    >
                      <p>{u.name}</p>
                      <IconButton
                        icon={"delete"}
                        onClick={async () => {
                          await removeUserFromTemplate(
                            companyId,
                            templateId,
                            u.id
                          );
                          await router.invalidate();
                        }}
                      />
                    </div>
                  ))}
              </div>
            </Card>
          </div>
          <div className={"mb-4"}>
            <NoteComponent
              resourceId={template.id}
              resourceType={"orderTemplate"}
            />
          </div>
          <Card
            title={t("Aktive ordre")}
            className={"max-h-[700px] mb-4"}
            trailing={
              <Checkbox
                label={t("Vis fullførte ordre")}
                isNoPadding
                isChecked={fetchOnlyPendingOrders}
                onChange={() => setFetchOnlyPendingOrders((prev) => !prev)}
              />
            }
            footerContent={
              <Pagination
                totalPages={ordersQuery.data?.totalPages ?? 0}
                page={page}
                setPage={setPage}
              />
            }
          >
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>#</TableHeaderCell>
                  <TableHeaderCell>{t("title")}</TableHeaderCell>
                  <TableHeaderCell>{t("Dato")}</TableHeaderCell>
                  <TableHeaderCell>{t("Referanseperson")}</TableHeaderCell>
                  <TableHeaderCell>{t("value")}</TableHeaderCell>
                  <TableHeaderCell>{t("PO-nummer")}</TableHeaderCell>
                  <TableHeaderCell>{t("status")}</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {ordersQuery.data?.data.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7}>{t("Ingen ordre funnet")}</TableCell>
                  </TableRow>
                )}
                {ordersQuery.data?.data.map((order) => {
                  return (
                    <TableRow
                      key={order.id}
                      onClick={async () => {
                        await navigate({
                          to: "/dashboard/orders/$orderId/overview",
                          params: {
                            orderId: order.id
                          }
                        });
                      }}
                    >
                      <TableCell>{order.orderNumber}</TableCell>
                      <TableCell>{order.title}</TableCell>
                      <TableCell>
                        {order.date
                          ? formatFullNumericDate(new Date(order.date))
                          : "-"}
                      </TableCell>
                      <TableCell>{order.referencePersonName}</TableCell>
                      <TableCell>{formatCurrency(order.price)}</TableCell>
                      <TableCell>{order.poNumber}</TableCell>
                      <TableCell>
                        <Badge>{order.status.title}</Badge>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Card>
          <div className={"mb-4"}>
            <Card
              title={t("Fremtidige ordre")}
              footerContent={
                <Pagination
                  setPage={setUpcomingPage}
                  page={upcomingPage}
                  totalPages={upcomingTotalPages}
                />
              }
            >
              <Table>
                <TableHeader>
                  <TableHeaderCell>{t("title")}</TableHeaderCell>
                  <TableHeaderCell>{t("Dag")}</TableHeaderCell>
                  <TableHeaderCell>{t("Dato")}</TableHeaderCell>
                </TableHeader>
                <TableBody>
                  {next
                    .slice(upcomingPage * 10, upcomingPage * 10 + 10)
                    .map((date) => {
                      return (
                        <TableRow disabled key={date.toISOString()}>
                          <TableCell>{template.title}</TableCell>
                          <TableCell>{formatWeekDay(date)}</TableCell>
                          <TableCell>{formatFullNumericDate(date)}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Card>
          </div>
        </div>
        <ChecklistPage
          resourceId={template.id}
          resourceType={"recurringOrder"}
        />
        <ProductConsumptionTab
          resourceId={template.id}
          resourceType="order-template"
        />
        <DocumentationTab
          resourceId={template.id}
          resourceName={template.title}
        />
      </TypedTab>
    </>
  );
}
