import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import DocumentationTab from "../../../../../../../pages/documentation/DocumentationTab";

export const Route = createFileRoute(
  "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/documentation"
)({
  component: SalesOpportunityDocumentationPage
});

function SalesOpportunityDocumentationPage() {
  const salesOpportunity = useLoaderData({
    from: "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout"
  });

  return (
    <DocumentationTab
      resourceId={salesOpportunity.id}
      resourceName={salesOpportunity.title}
    />
  );
}
