import {
  createFileRoute,
  useLoaderData,
  useParams,
  useSearch
} from "@tanstack/react-router";
import OfferList from "../../../_layout/-components/OfferList";
import {
  offerSearchSchema,
  useGetPaginatedOffersByCompanyId
} from "../../../../../../../service/api/OfferApi";
import { useAppStore } from "../../../../../../../state/app_store";

export const Route = createFileRoute(
  "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/offer"
)({
  validateSearch: offerSearchSchema,
  loaderDeps: ({ search }) => search,
  loader: async ({
    context: { queryClient, companyId },
    deps: { status, ownerId, page, sortDirection, sortField, query },
    params: { salesOpportunityId }
  }) => {
    return await queryClient.fetchQuery({
      ...useGetPaginatedOffersByCompanyId.getOptions({
        companyId,
        status,
        ownerId,
        page,
        sortDirection,
        sortField,
        query,
        pageSize: useAppStore.getState().pageSize,
        salesOpportunityId
      })
    });
  },
  component: SalesOpportunityOfferListPage
});

function SalesOpportunityOfferListPage() {
  const offers = useLoaderData({
    from: "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/offer"
  });
  const searchParams = useSearch({
    from: "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/offer"
  });
  const { salesOpportunityId } = useParams({
    from: "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/offer"
  });

  return (
    <OfferList
      data={offers}
      offerSearchParams={searchParams}
      salesOpportunityId={salesOpportunityId}
    />
  );
}
