import { z } from "zod";
import {
  OfferCheckbox,
  OfferCheckboxTypeEnum,
  OfferDescription,
  OfferProductGroup,
  OfferSubsection,
  OfferTextField
} from "../../../../../../.generated/api";

export const offerProductLineSchema = z.object({
  type: z.union([z.literal("freetext"), z.literal("product")]),
  title: z.string().min(1),
  description: z.string().nullish(),
  productId: z.string().nullish(),
  quantity: z.coerce.number(),
  unit: z.string().optional(),
  price: z.coerce.number(),
  discount: z.coerce.number().min(0).max(100),
  vat: z.union([
    z.literal("25"),
    z.literal("15"),
    z.literal("12"),
    z.literal("0")
  ])
});

export const offerProductSectionSchema = z.object({
  type: z.literal("products"),
  title: z.string().min(1),
  isOption: z.boolean(),
  products: z.array(offerProductLineSchema)
});

export const offerCheckboxSectionSchema = z.object({
  type: z.literal("checkbox"),
  title: z.string().min(1),
  required: z.boolean()
});

export const offerTextFieldSectionSchema = z.object({
  type: z.literal("textField"),
  title: z.string().min(1),
  required: z.boolean()
});

export const offerDescriptionSectionSchema = z.object({
  type: z.literal("description"),
  title: z.string().min(1),
  description: z.string().min(1)
});

export const attachmentSchema = z.object({
  url: z.string(),
  file: z.instanceof(File).optional(),
  fileId: z.string().optional(),
  fileType: z.string().optional(),
  filename: z.string(),
  role: z.string().optional()
});

export const offerFormSchema = z.object({
  title: z.string().min(1),
  description: z.string().optional(),
  expiryDate: z.coerce.date(),
  sections: z.array(
    z.discriminatedUnion("type", [
      offerProductSectionSchema,
      offerCheckboxSectionSchema,
      offerTextFieldSectionSchema,
      offerDescriptionSectionSchema
    ])
  ),

  attachments: z.array(attachmentSchema),
  saveAsTemplate: z.boolean()
});

export type AttachmentData = z.infer<typeof attachmentSchema>;
export type OfferProductLineData = z.infer<typeof offerProductLineSchema>;
export type OfferFormData = z.infer<typeof offerFormSchema>;
export type OfferProductSectionData = z.infer<typeof offerProductSectionSchema>;

export function getNewLineData(
  type: "freetext" | "product"
): OfferProductLineData {
  if (type === "freetext") {
    return {
      type: "freetext",
      title: "",
      quantity: 1,
      vat: "25",
      discount: 0,
      price: 0
    };
  } else {
    return {
      type: "product",
      title: "",
      quantity: 0,
      vat: "0",
      discount: 0,
      price: 0
    };
  }
}

export function isOfferCheckbox(
  section: OfferSubsection
): section is OfferCheckbox {
  return section.type === OfferCheckboxTypeEnum.Checkbox;
}

export function isOfferTextField(
  section: OfferSubsection
): section is OfferTextField {
  return section.type === OfferCheckboxTypeEnum.TextField;
}

export function isOfferDescription(
  section: OfferSubsection
): section is OfferDescription {
  return section.type === OfferCheckboxTypeEnum.Description;
}

export function isOfferProducts(
  section: OfferSubsection
): section is OfferProductGroup {
  return section.type === OfferCheckboxTypeEnum.ProductGroup;
}

export function mapSectionsToFormData(
  sections: OfferSubsection[]
): OfferFormData["sections"] {
  return sections.map((section) => {
    if (isOfferProducts(section)) {
      return {
        type: "products",
        title: section.title,
        isOption: section.isOption,
        products: section.products.map((product) => {
          return {
            type: product.productId ? "product" : "freetext",
            productId: product.productId ?? undefined,
            title: product.title,
            description: product.description,
            quantity: product.count,
            unit: product.unitName ?? undefined,
            price: product.price,
            discount: product.rebate,
            vat: product.mva.toString() as "0" | "12" | "15" | "25"
          };
        })
      };
    } else if (isOfferDescription(section)) {
      return {
        type: "description",
        title: section.title,
        description: section.description
      };
    } else if (isOfferCheckbox(section)) {
      return {
        type: "checkbox",
        title: section.title,
        required: section.required
      };
    } else if (isOfferTextField(section)) {
      return {
        type: "textField",
        title: section.title,
        required: section.required
      };
    } else {
      throw new Error(`Unsupported section type: ${section}`);
    }
  });
}

export const offerSendFormSchema = z.object({
  sendOffer: z.boolean(),
  customerEmail: z.string().email(),
  customerPhone: z.string().nullish(),
  message: z.string().nullish(),
  ownerId: z.string(),
  contactPerson: z.string().optional(),
  contactEmail: z.string().email(),
  contactPhone: z.string().nullish()
});

export type OfferSendFormData = z.infer<typeof offerSendFormSchema>;

export const offerCheckboxCustomerSchema = z.object({
  type: z.literal("Checkbox"),
  id: z.string(),
  required: z.boolean(),
  value: z.boolean()
});

export const offerTextFieldCustomerSchema = z.object({
  type: z.literal("TextField"),
  id: z.string(),
  required: z.boolean(),
  value: z.string().optional()
});

export const offerDescriptionCustomerSchema = z.object({
  type: z.literal("Description"),
  id: z.string(),
  value: z.string().nullish()
});

export const offerProductGroupCustomerSchema = z.object({
  type: z.literal("ProductGroup"),
  id: z.string(),
  isOption: z.boolean(),
  value: z.boolean(),
  products: z.array(offerProductLineSchema)
});

export const offerCustomerResponseSchema = z
  .object({
    customerType: z.enum(["company", "private"]),
    orgNumber: z.string().optional(),
    companyName: z.string().optional(),
    address: z.string().nullish(),
    postalCode: z.string().nullish(),
    postalArea: z.string().nullish(),
    contactPerson: z.string().optional(),
    contactEmail: z.string().email(),
    contactPhone: z.string().optional()
  })
  .superRefine((schema, ctx) => {
    if (schema.customerType === "company") {
      if (!schema.orgNumber) {
        ctx.addIssue({
          code: "custom",
          path: ["orgNumber"],
          message: "Dette feltet er påkrevd for bedriftskunder"
        });
      }
      if (!schema.companyName) {
        ctx.addIssue({
          code: "custom",
          path: ["companyName"],
          message: "Dette feltet er påkrevd for bedriftskunder"
        });
      }
    }
  });

export const offerResponseFormSchema = z
  .object({
    customer: offerCustomerResponseSchema,
    offerAccepted: z.boolean(),
    responseMessage: z.string().optional(),
    ehfEmail: z.string().email().nullish(),
    invoiceReference: z.string().nullish(),
    sections: z.array(
      z.discriminatedUnion("type", [
        offerCheckboxCustomerSchema,
        offerTextFieldCustomerSchema,
        offerDescriptionCustomerSchema,
        offerProductGroupCustomerSchema
      ])
    )
  })
  .superRefine((schema, ctx) => {
    if (schema.offerAccepted === false) {
      return;
    }

    // the logic below causes problem when offer is sent to existing customer that has no address
    /*else {
      if (!schema.customer.address) {
        ctx.addIssue({
          code: "custom",
          path: [`customer.address`],
          message: "Dette feltet er påkrevd"
        });
      }
      if (!schema.customer.postalCode) {
        ctx.addIssue({
          code: "custom",
          path: [`customer.postalCode`],
          message: "Dette feltet er påkrevd"
        });
      }
      if (!schema.customer.postalArea) {
        ctx.addIssue({
          code: "custom",
          path: [`customer.postalArea`],
          message: "Dette feltet er påkrevd"
        });
      }
      if (!schema.customer.contactPerson) {
        ctx.addIssue({
          code: "custom",
          path: [`customer.contactPerson`],
          message: "Dette feltet er påkrevd"
        });
      }
    }*/

    schema.sections.forEach((section, index) => {
      if (section.type === "TextField") {
        if (section.required && !section.value) {
          ctx.addIssue({
            code: "custom",
            path: [`sections.${index}.value`],
            message: "Dette feltet er påkrevd"
          });
        }
      } else if (section.type === "Checkbox") {
        if (section.required && !section.value) {
          ctx.addIssue({
            code: "custom",
            path: [`sections.${index}.value`],
            message: "Dette feltet er påkrevd"
          });
        }
      }
    });
  });

export type OfferResponseFormData = z.infer<typeof offerResponseFormSchema>;
type OfferResponseSection = OfferResponseFormData["sections"][number];

export function isOfferProductSection(
  section: OfferResponseSection
): section is z.infer<typeof offerProductGroupCustomerSchema> {
  return section.type === OfferCheckboxTypeEnum.ProductGroup;
}

export function mapSectionsToResponseFormData(
  sections: OfferSubsection[]
): OfferResponseFormData["sections"] {
  return sections.map((section) => {
    if (isOfferProducts(section)) {
      return {
        type: "ProductGroup",
        id: section.id,
        isOption: section.isOption,
        value: section.isChosen,
        products: section.products.map((product) => {
          return {
            type: "product",
            title: product.title,
            description: product.description,
            quantity: product.count,
            price: product.price,
            discount: product.rebate,
            vat: product.mva.toString() as "0" | "12" | "15" | "25"
          };
        })
      };
    } else if (isOfferDescription(section)) {
      return {
        type: "Description",
        id: section.id
      };
    } else if (isOfferCheckbox(section)) {
      return {
        type: "Checkbox",
        id: section.id,
        required: section.required,
        value: section.customerChecked
      };
    } else if (isOfferTextField(section)) {
      return {
        type: "TextField",
        id: section.id,
        required: section.required,
        value: section.customerResponse
      };
    } else {
      throw new Error(`Unsupported section type: ${section}`);
    }
  });
}
