import React from "react";
import IconButton from "./buttons/IconButton";
import ButtonNew from "./ButtonNew";
import RadixIcon from "./RadixIcon";
import Select from "./NewSelect";
import { Text } from "@radix-ui/themes";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../state/app_store";
import { useRouter } from "@tanstack/react-router";

type PaginationProps = {
  page: number;
  totalPages?: number;
  setPage: (page: number) => void;
  showPageSizePicker?: boolean;
};

function range(start: number, end: number) {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
}

export default function PaginationNew({
  page,
  totalPages = 0,
  setPage,
  showPageSizePicker = false
}: PaginationProps) {
  const siblingCount = 1;
  const { t } = useTranslation();
  const pageSize = useAppStore((s) => s.pageSize);
  const router = useRouter();

  let pages: (number | undefined)[] = [];
  const totalPageNumbers = siblingCount + 5;
  if (totalPageNumbers >= totalPages) {
    pages = range(1, totalPages);
  } else {
    const leftSiblingIndex = Math.max(page - siblingCount, 1);
    const rightSiblingIndex = Math.min(page + siblingCount, totalPages);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPages - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPages;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);
      pages = [...leftRange, undefined, totalPages];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(totalPages - rightItemCount + 1, totalPages);
      pages = [firstPageIndex, undefined, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      pages = [
        firstPageIndex,
        undefined,
        ...middleRange,
        undefined,
        lastPageIndex
      ];
    }
  }

  return (
    <div className={"flex gap-2 justify-center items-center"}>
      <IconButton
        disabled={page === 0}
        variant={"ghost"}
        icon={"keyboard_arrow_left"}
        onClick={() => setPage(page - 1)}
      />

      {pages.map((mappedPage, index) => {
        if (!mappedPage) {
          return (
            <RadixIcon
              key={index}
              icon={"more_horiz"}
              className={"w-8 h-8 flex! items-center! justify-center!"}
            />
          );
        }

        return (
          <ButtonNew
            key={index}
            variant={mappedPage - 1 === page ? "solid" : "soft"}
            onClick={() => setPage(mappedPage - 1)}
            className={"w-8 h-8"}
          >
            {mappedPage}
          </ButtonNew>
        );
      })}

      <IconButton
        disabled={page === totalPages - 1 || totalPages === 0}
        variant={"ghost"}
        icon={"keyboard_arrow_right"}
        onClick={() => {
          setPage(page + 1);
        }}
      />

      {showPageSizePicker && (
        <>
          <Text size={"2"} color={"gray"} className={"ml-2"}>
            {t("itemsPerPage")}
          </Text>
          <Select
            size={"2"}
            value={pageSize.toString()}
            onChange={(size) => {
              setPage(0);
              useAppStore.getState().setPageSize(+size);
              router.invalidate();
            }}
            triggerProps={{ variant: "ghost", color: "gray" }}
            options={[
              { label: "10", value: "10" },
              { label: "15", value: "15" },
              { label: "25", value: "25" },
              { label: "50", value: "50" }
            ]}
          />
        </>
      )}
    </div>
  );
}
