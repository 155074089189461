import { createFileRoute, useParams } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import PageTitle from "../../../../../../components/common/PageTitle";
import AddSingleProduct from "../../../../../../pages/projectDetails/projectProducts/components/AddSingleProduct";

export const Route = createFileRoute(
  "/_protected/dashboard/orders/$orderId/products/add"
)({
  component: AddProductToOrder
});

function AddProductToOrder() {
  const { orderId } = useParams({
    from: "/_protected/dashboard/orders/$orderId/products/add"
  });
  const { t } = useTranslation();

  return (
    <>
      <div className="flex w-full justify-between">
        <PageTitle title={t("addFromProductCatalog")} />
      </div>
      <AddSingleProduct resourceId={orderId} resourceType="order" />
    </>
  );
}
