import React, { forwardRef } from "react";
import {
  IconButton as RadixIconButton,
  IconButtonProps as RadixIconButtonProps,
  Slot,
  Slottable
} from "@radix-ui/themes";
import RadixIcon from "../RadixIcon";

export type IconButtonProps = RadixIconButtonProps & {
  icon: string;
};

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ icon, variant = "soft", type = "button", ...props }, ref) => {
    let child = <RadixIcon icon={icon} size={props.size} />;
    if (props.asChild) {
      child = (
        <Slot>
          <RadixIcon icon={icon} size={props.size} />
          <Slottable>{props.children}</Slottable>
        </Slot>
      );
    }

    return (
      <RadixIconButton type={type} variant={variant} ref={ref} {...props}>
        {child}
      </RadixIconButton>
    );
  }
);

export default IconButton;
