import {
  FilePostRequestFileResourceTypeEnum,
  FileRequest,
  OfferApi,
  OfferDetails,
  OfferPostRequest,
  OfferPutRequest,
  OfferSendPostRequest,
  OfferStatusEnum,
  OfferTemplatePostRequest,
  ProjectPostRequestActivityCreationStrategyEnum,
  RespondToOfferRequest
} from "../../.generated/api";
import environment from "../../environment";
import axios from "axios";
import { createQuery } from "react-query-kit";
import { apiInstance } from "./ApiHttpBase";
import { z } from "zod";
import { QueryClient } from "@tanstack/react-query";
import { fetchPublicCompanyInfo } from "../../utils/exportPdf";
import { createProjectV2 } from "./ProjectApiV2";
import { updateProjectEconomy } from "./ProjectEconomyApi";
import { createCustomerRelation } from "./CustomerRelationApiV3";
import { generateOfferPdf } from "../export/ExportApi";
import { mapOfferSubsections } from "../../utils/exportUtils";
import { createFolder } from "./FolderApi";
import { uploadFile } from "./FileApi";
import { createCompanyOrder, getCompanyOrderStatuses } from "./OrderApi";

export const offerSearchSchema = z.object({
  status: z.nativeEnum(OfferStatusEnum).optional(),
  ownerId: z.string().optional(),
  page: z.number().optional(),
  sortDirection: z.enum(["ASC", "DESC"]).optional(),
  sortField: z
    .union([
      z.literal("Title"),
      z.literal("OfferNumber"),
      z.literal("CreatedAt"),
      z.literal("UpdatedAt"),
      z.literal("Status")
    ])
    .optional(),
  query: z.string().optional(),
  selectedOfferId: z.string().optional()
});
export type OfferSearchParamsType = z.infer<typeof offerSearchSchema>;

type OfferSortField =
  | "Title"
  | "OfferNumber"
  | "CreatedAt"
  | "UpdatedAt"
  | "Status";

const defaultInstance = axios.create();
const offerApi = new OfferApi(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export async function getOfferByPathUri(pathUri: string) {
  const response = await offerApi.getOfferByPathUri(pathUri);
  return response.data;
}

export async function respondToOffer(
  pathUri: string,
  request: RespondToOfferRequest
) {
  const response = await offerApi.respondToOffer(pathUri, request);
  return response.data;
}

export const useGetOfferById = createQuery({
  queryKey: ["getOfferById"],
  fetcher: async (variables: { companyId: string; offerId: string }) => {
    const response = await offerApi.getOfferById(
      variables.companyId,
      variables.offerId
    );
    return response.data;
  }
});

export const useGetOffersByProjectId = createQuery({
  queryKey: ["getOffersByProjectId"],
  fetcher: async (variables: { companyId: string; projectId: string }) => {
    const response = await offerApi.getOffersByProjectId(
      variables.companyId,
      variables.projectId
    );
    return response.data;
  }
});

export const useGetOffersBySalesOpportunityId = createQuery({
  queryKey: ["getOffersBySalesOpportunityId"],
  fetcher: async (variables: {
    companyId: string;
    salesOpportunityId: string;
  }) => {
    const response = await offerApi.getOffersBySalesOpportunityId(
      variables.companyId,
      variables.salesOpportunityId
    );
    return response.data;
  }
});

export const useGetOffersByCompanyId = createQuery({
  queryKey: ["getOffersByCompanyId"],
  fetcher: async (variables: { companyId: string }) => {
    const response = await offerApi.getOffersByCompanyId(variables.companyId);
    return response.data;
  }
});

export const useGetPaginatedOffersByCompanyId = createQuery({
  queryKey: ["getOffersByCompanyId"],
  fetcher: async (variables: {
    companyId: string;
    salesOpportunityId?: string;
    status?: OfferStatusEnum;
    ownerId?: string;
    page?: number;
    pageSize?: number;
    sortDirection?: "ASC" | "DESC";
    sortField?: OfferSortField;
    query?: string;
  }) => {
    const response = await offerApi.getPaginatedOffersByCompanyId(
      variables.companyId,
      variables.salesOpportunityId,
      variables.status,
      variables.ownerId,
      variables.page,
      variables.sortDirection,
      variables.sortField,
      variables.query,
      variables.pageSize
    );
    return response.data;
  }
});

export const useGetOfferByPathUri = createQuery({
  queryKey: ["getOfferByPathUri"],
  fetcher: async (variables: { pathUri: string }) => {
    const response = await offerApi.getOfferByPathUri(variables.pathUri);
    return response.data;
  }
});

export const useGetOfferTemplatesByCompanyId = createQuery({
  queryKey: ["getOfferTemplatesByCompanyId"],
  fetcher: async (variables: { companyId: string }) => {
    const response = await offerApi.getOfferTemplatesByCompanyId(
      variables.companyId
    );
    return response.data;
  }
});

type OfferToProjectDetails = {
  projectTitle: string;
  projectDescription?: string;
  projectNumberSeriesId?: string;
  fromDateTime: string;
  toDateTime: string;
};
export async function convertOfferToProject(
  companyId: string,
  offer: OfferDetails,
  details: OfferToProjectDetails,
  language: string,
  currency: string,
  queryClient: QueryClient
) {
  const company = await fetchPublicCompanyInfo(companyId, queryClient);
  const project = await createProjectV2({
    title: details.projectTitle,
    description: details.projectDescription,
    activityCreationStrategy:
      ProjectPostRequestActivityCreationStrategyEnum.Hours,
    companyId: companyId,
    fromDateTime: details.fromDateTime,
    toDateTime: details.toDateTime,
    contractValue: offer.totalValue.toString(),
    estimatedHours: undefined,
    projectSeriesId: details.projectNumberSeriesId
  });
  await updateProjectEconomy(project.id, {
    estimatedRevenue: offer.totalValue,
    isHourly: false,
    sendHourNotification: false
  });
  if (offer.customer)
    await createCustomerRelation(
      companyId,
      offer.customer?.id,
      project.id,
      "Project"
    );
  const blob = await generateOfferPdf({
    title: offer.title,
    description: offer.description ?? "",
    companyLogoUrl: company.companyLogoUrl,
    frontPageImageUrl: company.reportLogoUrl,
    customer: {
      email: offer.customerEmail ?? "",
      phone: offer.customerPhone ?? "",
      contactPersonName: offer.customer?.primaryContact?.firstName ?? "",
      address: offer.customer?.primaryContact?.address ?? ""
    },
    subsections: mapOfferSubsections(offer.subsections),
    company: {
      companyName: company.name,
      phone: company.contactPhone,
      email: company.contactEmail,
      orgNumber: company.orgNumber,
      contactPersonName: company.contactPerson
    },
    attachments: offer.attachments?.map((a) => ({
      fileUrl: a.downloadUrl,
      fileType: a.fileType,
      name: a.name
    })),
    language: language,
    currency: currency
  });
  const pdfFile = new File(
    [blob],
    `${offer.title.replace(/\s+/g, "_")}(Tilbud).pdf`,
    {
      type: "application/pdf",
      lastModified: Date.now()
    }
  );
  const folder = await createFolder(project.id, "Kontrakter");
  await uploadFile(
    pdfFile,
    project.id,
    undefined,
    folder.id,
    FilePostRequestFileResourceTypeEnum.General
  );
  //await offerApi.archiveOffer(companyId, offer.id);
  return project.id;
}
type OfferToOrderDetails = {
  orderTitle: string;
  orderDescription?: string;
  date: string;
  duration: number;
};
export async function convertOfferToOrder(
  companyId: string,
  offer: OfferDetails,
  details: OfferToOrderDetails,
  queryClient: QueryClient,
  language: string,
  currency: string
) {
  const orderStatuses = await getCompanyOrderStatuses(companyId);
  const company = await fetchPublicCompanyInfo(companyId, queryClient);
  const customer = offer.customer;
  const status =
    orderStatuses.find((s) => s.title === "Ikke påbegynt") ??
    orderStatuses?.[0];
  const orderId = await createCompanyOrder(companyId, {
    companyId: companyId,
    title: details.orderTitle,
    description: details.orderDescription ?? "",
    date: details.date,
    duration: details.duration,
    address: customer?.primaryAddress?.address,
    price: offer.totalValue,
    priceType: "Fixed",
    referencePersonName: customer?.formattedCustomerName ?? "",
    referencePersonNumber: "",
    statusId: status.id,
    tagIds: []
  });
  const blob = await generateOfferPdf({
    title: offer.title,
    description: offer.description ?? "",
    companyLogoUrl: company.companyLogoUrl,
    frontPageImageUrl: company.reportLogoUrl,
    customer: {
      email: offer.customerEmail ?? "",
      phone: offer.customerPhone ?? "",
      contactPersonName: customer?.primaryContact?.firstName ?? "",
      address: customer?.primaryContact?.address ?? ""
    },
    subsections: mapOfferSubsections(offer.subsections),
    company: {
      companyName: company.name,
      phone: company.contactPhone,
      email: company.contactEmail,
      orgNumber: company.orgNumber,
      contactPersonName: company.contactPerson
    },
    attachments: offer.attachments?.map((a) => ({
      fileUrl: a.downloadUrl,
      fileType: a.fileType,
      name: a.name
    })),
    language: language,
    currency: currency
  });
  const pdfFile = new File(
    [blob],
    `${offer.title.replace(/\s+/g, "_")}(Tilbud).pdf`,
    {
      type: "application/pdf",
      lastModified: Date.now()
    }
  );
  const folder = await createFolder(orderId[0], "Kontrakter");
  await uploadFile(
    pdfFile,
    orderId[0],
    undefined,
    folder.id,
    FilePostRequestFileResourceTypeEnum.General
  );
  //await archiveOffer(companyId, offer.id);
  if (customer)
    await createCustomerRelation(companyId, customer.id, orderId[0], "Order");
  return orderId[0];
}

export async function updateOfferStatus(
  companyId: string,
  offerId: string,
  offerStatus: OfferStatusEnum,
  message?: string
) {
  const response = await offerApi.updateOfferStatus(companyId, offerId, {
    status: offerStatus,
    message: message
  });
  return response.data;
}

export async function updateOfferSalesOpportunity(
  companyId: string,
  offerId: string,
  salesOpportunityId: string
) {
  await offerApi.updateOfferSalesOpportunity(
    companyId,
    offerId,
    salesOpportunityId
  );
}

export async function removeOfferSalesOpportunity(
  companyId: string,
  offerId: string
) {
  await offerApi.removeOfferSalesOpportunity(companyId, offerId);
}

export async function getOfferById(companyId: string, id: string) {
  const response = await offerApi.getOfferById(companyId, id);
  return response.data;
}

export async function deleteOfferTemplate(
  companyId: string,
  templateId: string
) {
  await offerApi.deleteOfferTemplate(companyId, templateId);
}

export async function createOrReplaceTemplate(
  companyId: string,
  request: OfferTemplatePostRequest
) {
  const response = await offerApi.createOrReplaceTemplate(companyId, request);
  return response.data;
}

export async function getOffersByCustomerId(
  companyId: string,
  customerId: string
) {
  const response = await offerApi.getOffersByCustomerId(companyId, customerId);
  return response.data;
}

export async function createOffer(
  companyId: string,
  request: OfferPostRequest
) {
  const response = await offerApi.createOffer(companyId, request);
  return response.data;
}

export async function updateOffer(
  companyId: string,
  offerId: string,
  request: OfferPutRequest
) {
  const response = await offerApi.updateOffer(companyId, offerId, request);
  return response.data;
}

export async function getOfferFileUploadUrl(
  companyId: string,
  contentType = "application/pdf"
) {
  const response = await offerApi.getOfferUploadUrl(companyId, {
    contentType //: "application/pdf"
  });
  return response.data;
}

export async function archiveOffer(companyId: string, offerId: string) {
  await offerApi.archiveOffer(companyId, offerId);
}

export async function sendOffer(
  companyId: string,
  offerId: string,
  request: OfferSendPostRequest
) {
  await offerApi.sendOffer(companyId, offerId, {
    sendOffer: request.sendOffer,
    customerId: request.customerId,
    companyName: request.companyName,
    message: request.message,
    expirationDate: request.expirationDate,
    ownerId: request.ownerId,
    customerContact: {
      email: request.customerContact.email,
      phone: request.customerContact.phone
    }
  });
}

export async function uploadOfferFile(
  companyId: string,
  file: File
): Promise<FileRequest> {
  const [{ id, url }, body] = await Promise.all([
    getOfferFileUploadUrl(companyId, file.type),
    file.arrayBuffer()
  ]);

  const formattedFileName = file.name.replace(/[^\x00-\x7F]/g, "");
  const headers = {
    "Content-Disposition": `filename=${formattedFileName}`,
    "Content-Type": file.type
  };
  await defaultInstance.put(url, body, {
    headers: headers
  });
  return {
    fileId: id,
    fileType: file.type,
    name: file.name
  };
}
