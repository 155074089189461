import { createFileRoute, useParams } from "@tanstack/react-router";
import React from "react";
import { UpsertOfferPage } from "../../../../../../../pages/sales/salesOppertunity/details/offer/UpsertOfferPage";

export const Route = createFileRoute(
  "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/new"
)({
  component: CreateOfferPage
});

function CreateOfferPage() {
  const { salesOpportunityId } = useParams({
    from: "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/offer/new"
  });
  return <UpsertOfferPage salesOpportunityId={salesOpportunityId} />;
}
