import { ComboboxItem as AriaComboboxItem } from "@ariakit/react";
import React, { ReactNode, useRef } from "react";

type ComboboxItemProps = {
  children: ReactNode;
  value: string;
  onClick: VoidFunction;
};

export function ComboboxItem({ children, value, onClick }: ComboboxItemProps) {
  const ref = useRef<HTMLDivElement>(null);

  function onFocus() {
    ref.current?.focus();
    ref.current?.setAttribute("data-highlighted", "");
  }

  function onUnfocus() {
    ref.current?.blur();
    ref.current?.removeAttribute("data-highlighted");
  }

  return (
    <AriaComboboxItem
      ref={ref}
      focusOnHover
      onFocus={onFocus}
      onBlur={onUnfocus}
      onMouseEnter={onFocus}
      onMouseLeave={onUnfocus}
      onClick={onClick}
      className="rt-reset rt-BaseMenuItem rt-DropdownMenuItem h-auto py-2"
      value={value}
    >
      {children}
    </AriaComboboxItem>
  );
}
