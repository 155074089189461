import { createFileRoute } from "@tanstack/react-router";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import { useTranslation } from "react-i18next";
import { useGetActivitiesByCompanyId } from "../../../../../service/api/ActivitiesApi";
import React, { useMemo, useState } from "react";
import { useSort } from "../../../../../hooks/useSort";
import { Activity } from "../../../../../.generated/api";
import {
  getActivityTypeLabel,
  getTypeLabel,
  getValueTypeLabel
} from "../../../../../service/api/utils";
import Icon from "../../../../../components/ds/Icon";
import PageLayout from "../../../../../components/PageLayout";
import CardNew from "../../../../../components/ds/CardNew";
import ButtonNew from "../../../../../components/ds/ButtonNew";
import Table from "../../../../../components/ds/RadixTable/Table";
import TableHeader from "../../../../../components/ds/RadixTable/TableHeader";
import TableRow from "../../../../../components/ds/RadixTable/TableRow";
import TableBody from "../../../../../components/ds/RadixTable/TableBody";
import TableHeaderCell from "../../../../../components/ds/RadixTable/TableHeaderCell";
import TableCell from "../../../../../components/ds/RadixTable/TableCell";
import { TextFieldNew } from "../../../../../components/ds/TextFieldNew";
import { searchBarSize } from "../../../../../components/ds/sizes";
import NewSelect from "../../../../../components/ds/NewSelect";
import ActivityPreview from "./-components/ActivityPreview";
import useDialog from "../../../../../hooks/useDialog";
import CreateActivityDialog from "./-components/CreateActivityDialog";
import { useGetActivityRelations } from "../../../../../service/api/ActivityRelationApi";
import { formatCurrency } from "../../../../../utils/currencyFormatter";

type ActivitySort = "Number" | "Name";
type FilterOptions = "All" | "Project" | "Internal" | "Order";

export const Route = createFileRoute(
  "/_protected/dashboard/economy/_layout/activity"
)({
  component: ActivityPage
});

function ActivityPage() {
  const companyId = useCompanyId();
  const { t } = useTranslation();
  const [selectedActivity, setSelectedActivity] = useState<Activity>();
  const { onClose, isOpen, onOpen } = useDialog();

  const activityQuery = useGetActivitiesByCompanyId({
    variables: { companyId }
  });

  const [filter, setFilter] = useState<FilterOptions>("All");

  const { query, setQuery, sortField, registerHeader, sortDirection } =
    useSort<ActivitySort>("Number", "activitySort");

  const activities = activityQuery.data ?? [];
  const notTaskActivities = useMemo(
    () => activities.filter((a) => a.type !== "Task"),
    [activities]
  );

  const relationQuery = useGetActivityRelations({
    variables: {
      companyId
    }
  });

  const activityRelations = relationQuery.data ?? [];

  const filteredActivities = useMemo(() => {
    if (!notTaskActivities) return [];
    let filtered = [...notTaskActivities];

    if (filter === "Project") {
      filtered = notTaskActivities.filter((a) => a.type === "Project");
    } else if (filter === "Internal") {
      filtered = notTaskActivities.filter((a) => a.type === "Company");
    } else if (filter === "Order") {
      filtered = notTaskActivities.filter((a) => a.type === "Order");
    }

    if (sortField === "Number") {
      filtered = filtered.sort((a, b) =>
        sortDirection === "ASC"
          ? a.activityNumber - b.activityNumber
          : b.activityNumber - a.activityNumber
      );
    } else {
      filtered = filtered.sort((a, b) =>
        sortDirection === "ASC"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name)
      );
    }

    if (query) {
      filtered = filtered.filter((a) =>
        a.name.toLowerCase().includes(query.toLowerCase())
      );
    }

    return filtered;
  }, [filter, notTaskActivities, sortField, sortDirection, query]);

  return (
    <>
      <CreateActivityDialog
        open={isOpen}
        onClose={onClose}
        onCreated={activityQuery.refetch}
      />
      <PageLayout
        preview={
          selectedActivity && (
            <ActivityPreview
              activity={selectedActivity}
              onClosePreview={() => setSelectedActivity(undefined)}
              onUpdate={() => {
                activityQuery.refetch();
                relationQuery.refetch();
              }}
              relations={activityRelations.filter(
                (r) => r.activityId === selectedActivity.id
              )}
            />
          )
        }
        previewOffset={"regularWithTabBar"}
      >
        <CardNew
          inset
          title={t("activities")}
          trailing={
            <ButtonNew icon="add" onClick={onOpen}>
              {t("addActivity")}
            </ButtonNew>
          }
          headerContent={
            <div className={"flex justify-between w-full"}>
              <TextFieldNew
                icon={"search"}
                className={searchBarSize}
                placeholder={t("Søk etter aktivitetsnavn")}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <NewSelect<FilterOptions>
                value={filter}
                onChange={(v) => {
                  if (!v) return;
                  setFilter(v);
                }}
                options={[
                  { label: t("Alle timeføringsaktiviteter"), value: "All" },
                  { label: t("project"), value: "Project" },
                  { label: t("Intern"), value: "Internal" },
                  { label: t("workOrders"), value: "Order" }
                ]}
                className={"max-w-[250px]"}
              ></NewSelect>
            </div>
          }
        >
          <Table variant={"ghost"}>
            <TableHeader>
              <TableRow>
                <TableHeaderCell sortFn={registerHeader("Number")}>
                  {t("activityNumber")}
                </TableHeaderCell>
                <TableHeaderCell sortFn={registerHeader("Name")}>
                  {t("Aktivitet")}
                </TableHeaderCell>
                <TableHeaderCell>{t("hourlyRate")}</TableHeaderCell>
                <TableHeaderCell>{t("Type")}</TableHeaderCell>
                <TableHeaderCell>{t("activityType")}</TableHeaderCell>
                <TableHeaderCell>{t("Måleenhet")}</TableHeaderCell>
                <TableHeaderCell>{t("Lønnsart")}</TableHeaderCell>
                <TableHeaderCell>{t("standard")}</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredActivities.map((activity) => (
                <TableRow key={activity.id} className={"group"}>
                  <TableCell>
                    <ButtonNew
                      onClick={() => setSelectedActivity(activity)}
                      variant={"ghost"}
                      highContrast
                      className={"group-hover/tr:underline"}
                    >
                      #{activity.activityNumber}
                    </ButtonNew>
                  </TableCell>
                  <TableCell>{activity.name}</TableCell>
                  <TableCell>
                    {activity.hourlyRate > 0 &&
                      formatCurrency(activity.hourlyRate)}
                  </TableCell>
                  <TableCell>{t(getTypeLabel(activity.type))}</TableCell>
                  <TableCell>
                    {t(getActivityTypeLabel(activity.activityType))}
                  </TableCell>
                  <TableCell>
                    {t(getValueTypeLabel(activity.valueType))}
                  </TableCell>
                  <TableCell>{activity.salaryTypeId}</TableCell>
                  <TableCell py={"2"}>
                    {activity.isProjectDefault && <Icon icon={"check"} />}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardNew>
      </PageLayout>
    </>
  );
}
