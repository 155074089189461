import { operations } from "../../.generated/export";
import { exportClient } from "./ExportClient";

export type OfferSections =
  operations["createOfferPdf"]["requestBody"]["content"]["application/json"]["subsections"];
export type ChecklistItems =
  operations["createChecklistPdf"]["requestBody"]["content"]["application/json"]["items"];
export type CrewMembers =
  operations["createCrewList"]["requestBody"]["content"]["application/json"]["crewMembers"];

export async function generateDeviationPdf(
  data: operations["createDeviationPdf"]["requestBody"]["content"]["application/json"]
) {
  const response = await exportClient.POST("/posts/deviation", {
    body: data,
    parseAs: "blob"
  });

  return response.data!;
}

export async function generateInspectionPdf(
  data: operations["createInspectionPdf"]["requestBody"]["content"]["application/json"]
) {
  const response = await exportClient.POST("/posts/inspection", {
    body: data,
    parseAs: "blob"
  });

  return response.data!;
}

export async function generateCrewListPdf(
  data: operations["createCrewList"]["requestBody"]["content"]["application/json"]
) {
  const response = await exportClient.POST("/posts/crew-list", {
    body: data,
    parseAs: "blob"
  });

  return response.data!;
}

export async function generateFinalProjectReportPdf(
  data: operations["createFinalProjectReport"]["requestBody"]["content"]["application/json"]
) {
  const response = await exportClient.POST("/posts/final-project-report", {
    body: data,
    parseAs: "blob"
  });

  return response.data!;
}

export async function generateOfferPdf(
  data: operations["createOfferPdf"]["requestBody"]["content"]["application/json"]
) {
  const response = await exportClient.POST("/posts/offer", {
    body: data,
    parseAs: "blob"
  });

  return response.data!;
}

export async function generateChecklistPdf(
  data: operations["createChecklistPdf"]["requestBody"]["content"]["application/json"]
) {
  const response = await exportClient.POST("/posts/checklist", {
    body: data,
    parseAs: "blob"
  });

  return response.data!;
}

export async function generateWorkDocumentationPdf(
  data: operations["createWorkDocumentationPdf"]["requestBody"]["content"]["application/json"]
) {
  const response = await exportClient.POST("/posts/work-documentation", {
    body: data,
    parseAs: "blob"
  });

  return response.data!;
}

export async function generateEhsItemPdf(
  data: operations["createEhsItemPdf"]["requestBody"]["content"]["application/json"]
) {
  const response = await exportClient.POST("/posts/ehs-item", {
    body: data,
    parseAs: "blob"
  });

  return response.data!;
}
