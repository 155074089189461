import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import { useGetSalesOpportunityById } from "../../../../../../service/api/SalesOpportunityApi";
import { useGetCustomerById } from "../../../../../../service/api/CustomerApiV3";
import UpsertSalesOpportunityPage from "../../-components/UpsertSalesOpportunityPage";

export const Route = createFileRoute(
  "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/update"
)({
  preloadGcTime: 0,
  preloadStaleTime: 0,
  loader: async ({
    context: { queryClient, companyId },
    params: { salesOpportunityId }
  }) => {
    const salesOpportunity = await queryClient.fetchQuery(
      useGetSalesOpportunityById.getOptions({ salesOpportunityId, companyId })
    );
    const customer = await queryClient.fetchQuery(
      useGetCustomerById.getOptions({
        companyId,
        customerId: salesOpportunity.customer.id
      })
    );

    return {
      salesOpportunity,
      customer
    };
  },
  component: UpdateSalesOpportunityPage
});

function UpdateSalesOpportunityPage() {
  const { salesOpportunity, customer } = useLoaderData({
    from: "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/update"
  });

  return (
    <UpsertSalesOpportunityPage
      salesOpportunity={salesOpportunity}
      customer={customer}
    />
  );
}
