import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import InspectionTab from "../../../../../../../pages/sales/salesOppertunity/details/inspection/InspectionTab";

export const Route = createFileRoute(
  "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout/inspection"
)({
  component: SalesOpportunityInspectionPage
});

function SalesOpportunityInspectionPage() {
  const salesOpportunity = useLoaderData({
    from: "/_protected/dashboard/sales/sales-opportunity/$salesOpportunityId/_layout"
  });

  return (
    <div className={"p-5"}>
      <InspectionTab salesOpportunity={salesOpportunity} />
    </div>
  );
}
