import {
  ProjectPostRequest,
  ProjectPutRequest,
  ProjectV2Api,
  PublicResourcePutRequest
} from "../../.generated/api";
import environment from "../../environment";
import { apiInstance } from "./ApiHttpBase";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { createQuery } from "react-query-kit";
import { SortDirection } from "./SalesOpportunityApi";

export type ProjectSortField =
  | "Title"
  | "ProjectNumber"
  | "Created"
  | "PhaseTitle";

const projectApiV2 = new ProjectV2Api(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export const useGetPublicProjectInfoByIds = createQuery({
  queryKey: ["getPublicProjectInfoByIds"],
  fetcher: async (variables: { projectIds: string[] }) => {
    const response = await projectApiV2.getPublicProjectInfoByIds(
      variables.projectIds
    );
    return response.data;
  }
});

export const useGetPublicProjectById = createQuery({
  queryKey: ["getPublicProjectById"],
  fetcher: async (variables: { projectId: string }) => {
    const response = await projectApiV2.getPublicProjectInfoById(
      variables.projectId
    );
    return response.data;
  }
});

export const useGetProjectById = createQuery({
  queryKey: ["getProjectById"],
  fetcher: async (variables: { projectId: string }) => {
    const response = await projectApiV2.getProjectById(variables.projectId);
    return response.data;
  }
});

export const useSearchCompanyProjects = createQuery({
  queryKey: ["searchCompanyProjects"],
  fetcher: async (variables: {
    companyId: string;
    query?: string;
    page?: number;
    pageSize?: number;
    sortField?: ProjectSortField;
    sortDirection?: SortDirection;
    fromDate?: Date;
    toDate?: Date;
    fetchOnlyAssigned?: boolean;
    status?: "active" | "deleted";
  }) => {
    const response = await projectApiV2.searchCompanyProjects(
      variables.companyId,
      variables.page,
      variables.sortDirection ?? "DESC",
      variables.sortField ?? "Title",
      variables.query ?? "",
      variables.pageSize,
      variables.fromDate?.toISOString(),
      variables.toDate?.toISOString(),
      variables.fetchOnlyAssigned,
      variables.status
    );
    return response.data;
  }
});

export const useSearchProjectsByParentId = createQuery({
  queryKey: ["searchProjectsByParentId"],
  fetcher: async (variables: {
    parentId: string;
    query: string;
    page?: number;
    sortField?: "Title" | "ProjectNumber";
    sortDirection?: "ASC" | "DESC";
  }) => {
    const response = await projectApiV2.getProjectsByParentId(
      variables.parentId,
      variables.sortField ?? "Title",
      variables.query,
      variables.page,
      variables.sortDirection ?? "DESC"
    );

    return response.data;
  }
});

export const useSearchActiveProjectsByIds = createQuery({
  queryKey: ["searchActiveProjectsByIds"],
  fetcher: async (variables: {
    companyId: string;
    projectIds: string[];
    query: string;
    page: number;
    sortField: "Title" | "ProjectNumber" | "Created";
    sortDirection: "ASC" | "DESC";
    fromDate?: Date;
    toDate?: Date;
  }) => {
    const response = await projectApiV2.getPaginatedActiveProjectsByIds(
      variables.companyId,
      variables.query,
      variables.sortField,
      undefined,
      variables.projectIds,
      variables.page,
      variables.sortDirection ?? "DESC",
      variables.fromDate?.toISOString(),
      variables.toDate?.toISOString()
    );
    return response.data;
  }
});

export async function getProjectReportLogoUploadUrl(
  companyId: string,
  projectId: string,
  contentType: string
) {
  return projectApiV2.getProjectReportLogoUploadUrl(
    contentType,
    companyId,
    projectId
  );
}

export async function deleteProjectReportLogo(
  companyId: string,
  projectId: string
) {
  return projectApiV2.deleteProjectReportLogo(companyId, projectId);
}

export const useGetProjectReportLogo = createQuery({
  queryKey: ["getProjectReportLogo"],
  fetcher: async (variables: { companyId: string; projectId: string }) => {
    const response = await projectApiV2.getProjectReportLogoUrl(
      variables.companyId,
      variables.projectId
    );
    return response.data;
  }
});

export const projectQueries = createQueryKeys("project", {
  getById: (projectId: string) => ({
    queryKey: [projectId],
    queryFn: async () => {
      const response = await projectApiV2.getProjectById(projectId);
      return response.data;
    }
  }),
  getByParentId: (parentId: string, page?: number) => ({
    queryKey: [parentId, page],
    queryFn: async () => {
      const response = await projectApiV2.getProjectsByParentId(
        parentId,
        "ProjectNumber",
        "",
        page,
        "DESC"
      );
      return response.data;
    }
  })
});

export async function createProjectV2(request: ProjectPostRequest) {
  const response = await projectApiV2.createProject(request);
  return response.data;
}

export async function getProjectById(projectId: string) {
  const response = await projectApiV2.getProjectById(projectId);
  return response.data;
}

export async function getCompanyProjectsBetweenDates(
  companyId: string,
  from: string,
  to: string
) {
  const response = await projectApiV2.getCompanyProjectsBetweenDates(
    companyId,
    from,
    to
  );
  return response.data;
}

export async function getUserRootProjects(
  companyId: string,
  sortField: "Title" | "ProjectNumber" | "Created" | "PhaseTitle",
  sortDirection: "ASC" | "DESC",
  page: number
) {
  const result = await projectApiV2.getUserRootProjects(
    companyId,
    sortField,
    page,
    sortDirection
  );
  return result.data;
}

export async function updateProject(
  projectId: string,
  request: ProjectPutRequest
) {
  const response = await projectApiV2.updateProject(projectId, request);
  return response.data;
}

export async function getProjectsByIds(
  companyId: string,
  projectIds: string[]
) {
  const result = await projectApiV2.getProjectsByIds(companyId, projectIds);
  return result.data;
}

export async function setProjectUpdatedTimestamp(projectId: string) {
  await projectApiV2.setProjectUpdatedTimestamp(projectId);
}

export async function updatePublicResources(
  resourceId: string,
  resourceType: "order" | "project",
  request: PublicResourcePutRequest[]
) {
  await projectApiV2.updatePublicResources(resourceId, resourceType, request);
}
