import { productsInstance } from "./ProductsModuleHttpBase";
import {
  EstimationOfferApi,
  EstimationOfferPostRequest,
  EstimationOfferPutRequest
} from "../../.generated/api";
import environment from "../../environment";
import { createQuery } from "react-query-kit";
import { SortDirection } from "../../hooks/useSort";

export type EstimationOfferSortField = Parameters<
  typeof estimationOfferApi.getPaginatedEstimationOffersByCompanyId
>[4];

const estimationOfferApi = new EstimationOfferApi(
  undefined,
  environment.skwApiNewBaseUrl,
  productsInstance
);

export const useGetPaginatedEstimationOffersByCompanyId = createQuery({
  queryKey: ["getPaginatedEstimationOffersByCompanyId"],
  fetcher: async (variables: {
    companyId: string;
    page?: number;
    sortField?: EstimationOfferSortField;
    sortDirection?: SortDirection;
    query?: string;
    // archived?: boolean;
  }) => {
    const response =
      await estimationOfferApi.getPaginatedEstimationOffersByCompanyId(
        variables.companyId,
        undefined,
        variables.page,
        variables.sortDirection,
        variables.sortField,
        variables.query
      );

    return response.data;
  }
});

export const useGetEstimationOfferById = createQuery({
  queryKey: ["getEstimationOfferById"],
  fetcher: async (variables: { estimationId: string; companyId: string }) => {
    const response = await estimationOfferApi.getEstimationOfferById(
      variables.companyId,
      variables.estimationId
    );
    return response.data;
  }
});

export async function createEstimation(
  companyId: string,
  reqBody: EstimationOfferPostRequest
) {
  return estimationOfferApi.createEstimationOffer(companyId, reqBody);
}

export async function deleteEstimation(
  companyId: string,
  estimationId: string
) {
  await estimationOfferApi.deleteEstimationOffer(companyId, estimationId);
}

export async function updateEstimation(
  companyId: string,
  estimationId: string,
  reqBody: EstimationOfferPutRequest
) {
  return await estimationOfferApi.updateEstimationOffer(
    companyId,
    estimationId,
    reqBody
  );
}
