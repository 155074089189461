import React from "react";
import { createFileRoute } from "@tanstack/react-router";
import PageLayout from "../../../../../components/PageLayout";
import { t } from "i18next";
import Integrations from "../../../../../static/images/integrasjoner.png";
import CenteredPageLayout from "../../../../../components/ds/CenteredPageLayout";
import CardNew from "../../../../../components/ds/CardNew";
import { Link, Text } from "@radix-ui/themes";

export const Route = createFileRoute(
  "/_protected/dashboard/settings/_layout/integration"
)({
  component: IntegrationSettingsPage
});

function IntegrationSettingsPage() {
  return (
    <PageLayout>
      <CenteredPageLayout>
        <CardNew title={t("integrations")}>
          <Text as={"p"}>{t("integrationText")}</Text>
          <Text size={"2"}>{t("contactUsAt")}</Text>{" "}
          <Link size={"2"} href={"mailto:hei@skyworker.no"}>
            hei@skyworker.no
          </Link>{" "}
          <Text size={"2"}>{t("toGetStarted")}</Text>
          <img
            className={"mt-4 mx-auto rounded-2"}
            src={Integrations}
            alt={"Logo til forskjellige regnskapssystemer"}
          />
        </CardNew>
      </CenteredPageLayout>
    </PageLayout>
  );
}
